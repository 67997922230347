import { ICampoExtraAgente } from "@agent/interfaces/campo-extra-agentes.interface";

export class AgenteNuevoModel {
  idFinanciera: number;
  idAgente: number;
  idCobrador: number;
  nombre: string;
  segundoNombre: string;
  apellidoP: string;
  apellidoM: string;
  sexo: string;
  email: string;
  telefonoCelular: string;
  pwd: string;
  rolCobrador: boolean;
  rolDistribuidor: boolean;
  cobradorInterno: boolean;

    constructor() {
        this.rolCobrador = true;
        this.rolDistribuidor = false;
        this.cobradorInterno = true;
    }
}
