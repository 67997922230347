import { Injectable } from '@angular/core';
import { ServicesGeneralService } from '../servicio-general/servicio-general.service';
import { environmentSelector } from '../../../../environments/environment.selector';

@Injectable()
export class ServiciosSaldosKobraService extends ServicesGeneralService {
  public override baseURL: string = environmentSelector().kobraServices.serviciosSaldosKobra;
  protected override propertie: string = 'serviciosSaldosKobra';

  descargarClientes(queryParams: string){
    return this.getFile(`/financieras-grid-download?${queryParams}`)
  }
  
  descargarMovimientosClientesExcel(queryParams: string) {
    return this.get(`/movimientos-clientes-excel${queryParams}`);
  }

  descargarMovimientosAgentesExcel(queryParams: string) {
    return this.get(`/cobradores-movs-excel${queryParams}`);
  }

  obtenerPeriodosPorFacturarExcel(queryParams:string) {
   return this.get(`/cobradores-periodos-por-facturar-excel${queryParams}`);
  }

  obtenerPeriodosFacturadosExcel(queryParams:string) {
   return this.get(`/cobradores-periodos-facturados-excel${queryParams}`);
  }
}
