import { Injectable } from '@angular/core';
import { ServicesGeneralService } from '../servicio-general/servicio-general.service';
import { environmentSelector } from '../../../../environments/environment.selector';

@Injectable()
export class ServiciosKobraService extends ServicesGeneralService  {
  public override baseURL: string = environmentSelector().kobraServices.servicios;
  protected override propertie: string = 'servicios';
  
}
