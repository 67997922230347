import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-token-expiration-modal',
  templateUrl: './token-expiration-modal.component.html',
  styleUrls: ['./token-expiration-modal.component.css']
})
export class TokenExpirationModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TokenExpirationModalComponent>,

  ) {}

  cerrarModal(resultado: boolean) {
    this.dialogRef.close(resultado); // Cierra el modal
  }
}
