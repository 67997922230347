<h1 mat-dialog-title>Asignar Etiquetas</h1>

<mat-dialog-content class="modal-content">

  <form [formGroup]="etiquetaForm" class="form-container">
    
      <mat-select-search
      
      class="id-card-field pago-gestor"
      [label]="'Cliente'"
      [placeHolder]="'Selecciona un cliente'"
      [value]="'idCliente'"
      [labelField]="'nombre'"
      [icon]="'account_balance'"
      [options]="lenders"
      formControlName="idCliente"
      [(ngModel)]="etiquetaClienteVisita.idCliente"
      (ngModelChange)="loadProducts()"
      [loader] ="loadings.financieras"
      [maxlength]="100"
    >
    </mat-select-search>

    <mat-select-search
    class="id-card-field pago-gestor"
    [label]="'Producto'"
    [placeHolder]="'Selecciona un producto'"
    [value]="'idProducto'"
    [labelField]="'nomProducto'"
    [icon]="'lightbulb_circle'"
    [options]="products"
    formControlName="idProducto"
    [(ngModel)]="etiquetaClienteVisita.idProducto"
    [loader] ="loadings.productos"
    [maxlength]="100"
    >
    </mat-select-search>

    <!-- <div class="flex-container">
        <mat-select-search
        style="width: 90%"
        class="id-card-field pago-gestor"
        [label]="'Etiqueta'"
        [placeHolder]="'Selecciona una etiqueta'"
        [value]="'idEtiquetaVisita'"
        [labelField]="'etiquetaVisita'"
        [icon]="'sell'"
        [options]="etiquetas"
        [disabled]="loadings.etiquetas"
        formControlName="idEtiquetaVisita"
        [(ngModel)]="etiquetaClienteVisita.idEtiquetaVisita"
      >
      
      </mat-select-search>
      
        <button mat-stroked-button class="boton-accion-front flex-item" matTooltip="Agregar etiqueta" mat-tooltip-panel (click)="addEtiqueta()">
          <mat-icon>add</mat-icon>
        </button>
      </div> -->

      <!-- <div class="flex-container">
        <mat-form-field *ngIf="!mostrarNuevaEtiqueta" style="width: 90%;">
          <mat-select 
            placeholder="Seleccione una etiqueta"
            formControlName="idEtiquetaVisita" 
            [(ngModel)]="etiquetaClienteVisita.idEtiquetaVisita"
            (ngModelChange)="onEtiquetaSelectChange($event)">
            <mat-option (click)="toggleMostrarNuevaEtiqueta()">Etiqueta nueva...</mat-option>
            <mat-option *ngFor="let etiqueta of etiquetas" [value]="etiqueta.idEtiquetaVisita">
              {{ etiqueta.etiquetaVisita }}
            </mat-option>
          </mat-select>
          <mat-icon matSuffix>sell</mat-icon> 
        </mat-form-field>
      
        <mat-form-field *ngIf="mostrarNuevaEtiqueta" style="width: 90%;">
          <input matInput placeholder="Ingrese la nueva etiqueta" [(ngModel)]="nuevaEtiqueta" formControlName="nuevaEtiquetaVisita">
          <button matSuffix mat-icon-button aria-label="Clear" (click)="toggleMostrarNuevaEtiqueta();">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      
        <button mat-stroked-button class="boton-accion-front flex-item" matTooltip="Agregar etiqueta" mat-tooltip-panel (click)="addEtiqueta()">
          <mat-icon>add</mat-icon>
        </button>
      </div> -->
      
      
    
  </form>

  <mat-card class="etiquetas-card">
    <mat-card-header>
      <mat-card-title>Etiquetas</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <div class="chip-list-container">
        <mat-chip-listbox>
          <mat-chip-option *ngFor="let etiqueta of data.itemsSelected" 
          (click)="removeEtiqueta(etiqueta.idEtiquetaVisita)" class="accion-seleccionada">
            {{ etiqueta.etiquetaVisita }}
            <mat-icon class="kobra-delete-icon" style="vertical-align: middle;">delete</mat-icon>

          </mat-chip-option>
        </mat-chip-listbox>
      </div>
    </mat-card-content>
  </mat-card>

</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="cerrarModal(false)">
    <mat-icon class="kobra-icon">close</mat-icon>Cancelar
  </button>
  <button mat-stroked-button class="boton-guardar" (click)="onSubmit()">
    <mat-icon>save</mat-icon>Asignar
  </button>
</mat-dialog-actions>
