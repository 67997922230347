import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../application/shared/application.service";
import { VisitService } from "../../../visit/visit.service";
import { environment } from "../../../../environments/environment";
import * as moment from 'moment';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import {ModalSeleccionDomicilioComponent} from '../seleccionar-domicilio/domicilio.component';

@Component({
  selector: 'descarga-formato-cuenta.component',
  templateUrl: 'descarga-formato-cuenta.component.html',
  styleUrls: ["descarga-formato-cuenta.component.css"],
  providers: [
    VisitService
  ]
})
export class ModalDescargaFormatoCuentaComponent {
  public cargandoArchivos: boolean = false;
  public descargandoArchivo: boolean = false;
  public cardAyuda: boolean = false;
  public archivos: any;
  public momentFecha;
  public formatosCuentas:any
  idAcreditadoCuentaDomicilio: number;

  constructor(
    public dialogRef: MatDialogRef<ModalDescargaFormatoCuentaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private app: ApplicationService,
    private visitService: VisitService,
    public dialog: MatDialog
  ) {
    this.archivos = this.data.archivos;
    this.momentFecha = moment;
    this.formatosCuentas = this.data.formatosCuentas;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
    //this.obtenerArchivos();
  }

  public descargarArchivo(archivo: any, formato: string) {
    this.descargandoArchivo = true;
    
    let params = `?token=${localStorage.getItem('access-token')}&idTipoFormato=2&formato=${formato}`;
    params += `&idFinanciera=${this.data.idFinanciera}&idCuentaMS=${this.data.idCuentaMS}&idExterno=${this.data.idExterno}`;
    params += `&idAcreditado=${this.data.idAcreditado}&idCuenta=${this.data.idCuenta}&idAcreditadoCuentaDomicilio=${this.idAcreditadoCuentaDomicilio}`;

   this.visitService.descargarFormatoCuentas(params,this.data.idCliente,archivo.idFormato).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        this.descargandoArchivo = false;
        const blob = new Blob([event.body], { type: event.body.type });
        saveAs(blob, archivo.nombreArchivo);
      }
    }, error => {
      this.descargandoArchivo = false;
      this.app.showSnackbar(
        "Aviso",
        "Ocurrio un problema al intentar descargar el archivo.",
        3000,
        "error"
      );
    });
  }
  
  private getFileNameFromContentDisposition(header: string): string | null {
    if (header) {
      const matches = header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (matches && matches.length > 1) {
        const filename = matches[1].replace(/['"]/g, '');
        return decodeURIComponent(filename.trim());
      }
    }
    return null;
  }
  

  ayudaDescripcion(archivo: any){
    archivo.isDescripcion = !archivo.isDescripcion;
  }

  openDialogDomicilio(archivo: any, formato: string): void {

    const dialogRef = this.dialog.open(ModalSeleccionDomicilioComponent, {
        //height: '400px',
        width: '500px',
        //disableClose: true,
        data:{
                domicilios: this.data.domicilios,
            }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result.success) {
          this.idAcreditadoCuentaDomicilio = result.idAcreditadoCuentaDomicilio;
          this.descargarArchivo(archivo, formato);
        }
    });
  }

  validarDomicilio(archivo: any, formato: string){
    if(this.data.domicilios.length > 1){
      this.openDialogDomicilio(archivo, formato);
    }else{
      this.idAcreditadoCuentaDomicilio = this.data.domicilios[0].idAcreditadoCuentaDomicilio;
      this.descargarArchivo(archivo, formato);
    }
  }

}
