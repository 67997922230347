import { GrupoVariable, Variable } from 'src/app/catalogos/checklists/interfaces/variables/variables.interface';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Inject } from '@angular/core';



@Component({
    selector: 'app-variables',
    templateUrl: './variables.component.html',
    styleUrls: []
  })
  export class VariablesComponent {

    @Input() grupoVariable: GrupoVariable[] = [];
    @Input() seleccionable: boolean;
    @Input() esCredito: boolean;
    @Output() onClickVariable: EventEmitter<any> = new EventEmitter();
    agente: Variable[] = [];
    cuenta: Variable[] = [];
    cuentaDetalle: Variable[] = [];
    generales: Variable[] = [];
    loadVariables: boolean = false;

    /*public tablaCuenta: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: []
    }

    public tablaCuentaDetalle: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: []
    }*/

    ngOnInit() {
      //this.tablaCuenta.columnas = this.getVariableColumns();
      //this.tablaCuentaDetalle.columnas = this.getVariableColumns();
      this.obtienerVariable();
    }


    /*getVariableColumns() {
      console.log(this.seleccionable);
      return [
        {
          name: 'ID Variable',
          key: 'claveInterna',
          hide: true
        },
        {
          name: ' ',
          key: 'claveUsuario',
          class: 'kobra-icon',
          type: this.seleccionable ? 'icono' : 'copy',
          icono: this.seleccionable ? 'check_circle' : '',
          tooltip: this.seleccionable ? 'Añadir Variable' : '',
          hide: false
        },
        {
          name: 'Variable',
          key: 'claveUsuario',
          hide: false
        },
        {
          name: 'Descripción',
          key: 'descripcion',
          hide: false
        }
      ];
    }*/

    private obtienerVariable(): void {
      this.loadVariables = true;
      this.grupoVariable.forEach(subArray => {
        if(subArray.nombre === 'agente'){
          this.agente = subArray.vars;
        }

        if(subArray.nombre === 'cuenta'){
          this.cuenta = subArray.vars;
        }

        if(subArray.nombre === 'cuentaDetalle'){
          this.cuentaDetalle = subArray.vars;
        }
      });


      this.loadVariables = false;
    }

    onClick(variable: Variable, columnPressed: string) {
       this.onClickVariable.emit({ variable, columnPressed });
     }
  }
