import { Injectable } from "@angular/core";
import { ServicesVisitasService } from "@servicesVisitas/services-visitas.service";
import { catchError, map, Observable, of } from "rxjs";
import { ApplicationService } from "src/app/application/shared/application.service";
import { ServiciosSaldosKobraService } from "src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service";

@Injectable({
  providedIn: 'root'
})
export class ConceptosEtiquetasVisitasService {

  constructor(
    private visitasService: ServicesVisitasService,
    private saldosKobraServ: ServiciosSaldosKobraService,
    private app: ApplicationService,
  ) {

  }

  obtenerEtiquetas(): Observable<any> {
    let queryParams = `?_paginate=0`;

    return this.visitasService.get(`/catalogos/etiquetas-visitas${queryParams}`);
  }

  obtenerConceptosEtiquetasPaginado(query: string) {
    return this.saldosKobraServ.get(`/conceptos-etiquetas-visitas-grid${query}`);
  }

  obtenerConceptosEtiquetas() {
    return this.saldosKobraServ.get(`/conceptos-etiquetas-visitas`);
  }

  agregarConceptoEtiqueta(dataConcepto: any): Observable<any>{
    return this.saldosKobraServ.post(`/conceptos/etiquetas`, dataConcepto);
  }

}