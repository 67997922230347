import { VisitaDetalleCoordenadas } from './modelos/visita-detalle-cordenadas.model';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { LenderService } from '@lender/lender.service';
import { environmentSelector } from '../../environments/environment.selector';
import { Component, OnInit, ElementRef, Input, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { MatDialog } from '@angular/material/dialog';
import { Account } from "../account/account.model";
import { Agent } from "../agent/agent.model";
import { Checklist } from "../checklist/checklist.model";
import { VisitClarification } from "../visit-clarification/models/visit-clarification";
import { AddressValidation } from "../checklist/addressValidation.model";
import { BusinessValidation } from "../checklist/businessValidation.model";
import { DebtCollection } from "../checklist/debtCollection.model";
import { ChecklistService } from "../checklist/checklist.service";
import { VisitClarificationService } from "../visit-clarification/visit-clarification.service";
import { VisitClarificationComponent } from "../visit-clarification/visit-clarification.component";
import { Client } from "../client/client.model";
import { ClientAddress } from "../client-address/client-addres.model";
import { GeneralChecklist } from "../general-checklist/general-checklist.model";
import { Visit } from "./visit.model";
import { EmailCollected } from "../email-collected/email-collected.model";
import { PhoneCollected } from "../phone-collected/phone-collected.model";
import { VisitService } from "./visit.service";
import { CalificacionVisita } from "../../app/calificaciones-visita/calificacion-visita";
import { CalificacionVisitaService } from "../../app/calificaciones-visita/calificacion-visita.service";
import { CuentaService } from '../cuentas/shared/cuenta.service';
import { AccountService } from "../account/account.service";
import { ApplicationService } from "src/app/application/shared/application.service";
import { Subscription } from "rxjs";
import { Financiera } from "../financieras/shared/financiera.model";
import { ChecklistDinamico } from "./checklist-dinamico-modelos/checklist-dinamico.model";
import { Pregunta } from "./checklist-dinamico-modelos/checklist-dinamico-pregunta.model";
import { take } from 'rxjs/internal/operators/take';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { FormGroup } from "@angular/forms";
import {ModalAsignarCuentaComponent} from '../account/modales/asignar/asignar-cuenta.component';
import {ModalEstatusCuentaComponent} from '../account/modales/estatus/estatus-cuenta.component';
import {ModalDescargaFormatoComponent} from './modales/descarga-formato/descarga-formato.component';
import { ModalEtiquetaComponent } from './modales/etiqueta/etiqueta.component';
import { FotoModalComponent } from '../componentes/fotos/modal/foto-modal.component';
import * as moment from 'moment';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { LoadingService } from '../shared/components/loading-bar/loading-bar.service';
import { TableDialogComponent } from '../shared/components/table/dialog/table-dialog.component';
import { Utils } from '../shared/components/utils';

declare const $: any;

@Component({
    // moduleId: module.id,
    selector: 'visit-detail',
    templateUrl: 'visit-detail.component.html',
    styleUrls: ['visit-detail.component.css'],
    providers: [
        VisitService,
        CalificacionVisitaService,
        ChecklistService,
        CuentaService,
        VisitClarificationService,
        AccountService,
        LenderService

    ]
})

export class VisitDetailComponent implements OnInit, OnChanges  {
    public $confirmModal: any;
    public $cambiarEtiquetaModal: any;
    sessionData: SessionData;
    public agent: Agent;
    public client: Client;
    public clientAddress: ClientAddress;
    public idVisita: number;
    public idAgent: number;
    public idFinanciera: number;
    public idCliente: number;
    public idProducto: number;
    public folioVisita: any;
    public visit: Visit;
    public account: Account;
    public debtCollection: DebtCollection;
    public businessValidation: BusinessValidation;
    public addressValidation: AddressValidation;
    public calificacionAcreditado: CalificacionVisita;
    public calificacionAdministradorFinanciera: CalificacionVisita;
    public calificacionSuperAdministrador: CalificacionVisita;
    public checklistProductId: number;
    public loadingChecklist: boolean;
    public loadingClarification: boolean = true;
    public emailsCollected: EmailCollected[] = [];
    public phonesCollected: PhoneCollected[] = [];
    public financiera: Financiera;
    public detalleAcreditadoStack: number[];
    public checklists: ChecklistDinamico;
    public clarification: VisitClarification;
    public etiquetas: any[] = [];
    public etiquetasCuenta = [];
    public etiquetaActual: any = null;
    public nvaEtiqueta: any = null;
    public url = null;
    public formAsignacion: FormGroup;
    public assignComment: string;
    public horariosVisitas = {};
    public establecerVisita: boolean = true;
    public fechaVisita: string;
    public idCuenta: number;
    public fechaAsignacionMinDate: Date = new Date();
    public idCobrador: number;
    public nombreAgente: string;
    public revisita: boolean = false;
    public etiquetasCuentaHistorial = [];
    public nombreAgenteAparto: string;
    /******Cambiar productos cuenta */
    public selectedStatus: string = null;
    public selectedTag: number = null;
    public idProductoNvo: number = null;
    public productos: any;
    public motivos: any;
    public nonSelectedTagWarning: boolean = false;
    public loadingProducts: boolean = false;
    public cambiarProducto: boolean = false;
    public loadingEtiquetasCuenta: boolean = false;
    public comentarioEtiqueta: string = null;
    /******fin */
    public loadingHistorialEtiquetasCuenta = false;

    public selectedEtiqueta: string = null;
    private ambiente = environmentSelector();

    public latitud;
    public longitud;
    public idCobradorAsignado: number;
    public idAgenteRevisita: number;
    public loadingDineroRecuperado = false;
    public dineroRecuperado = [];
    public montoTotal: number = 0;
    public contadorPagos: number = 0;
    public momentVista;
    public idAgenteAparto: number;
    public coordenadasMaps = [];
    public idDomicilio: any = null;
    public idDomicilioRevisita: any = null;
    public domicilioWarning: boolean = false;
    public domicilioRevisitaWarning: boolean = false;
    public cargandoHistorialDeDcomiclios = false;
    public domicilios: any[];
    public idCobradorAparto: number;
    public idCobradorAsigno: number;
    public nomGestorAsigno: string;
    public archivos= [];
    public formatos:boolean = false;
    public cargarFotos:boolean = false;
    public formatosDescargados = [];
    //public tablaCoordenadas: any;
    clienteActivo: boolean;
    public momentFecha;


    @Output() operationComplete: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
    @Input() visitasACalificar: Visit[];

    public loader: boolean;
    public textLoader: string;
    public visitaIndex: number;
    public loaderRates: boolean;
    public loadingEtiquetas: boolean;
    public loadingCoordenadas: boolean = true;
    public resetRate = 0;
    public archivoGestor: any;

    public itemsSelected: any;
    public coordenadasSeleccionadas: VisitaDetalleCoordenadas[];
    public idMapa: string;
    public idCheckList: any;
    public numVersion: any;
    isComentario: boolean = false;
    isComentarioIA: boolean = true;
    cargandoVisita: boolean = true;
    indexCoordenadaOriginal: number = -1;
    coordenadasVisitasCoompletas: any[] = [];
    htmlAclaraciones:string;

    CLARIFICATION_STATUS = {
        REVISION: 1,
        APPROVED: 2,
        REJECTED: 3,
        CANCELED: 4
    };

    tablaEtiquetasCuentas: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        sort: 'desc',
        sortField: 'fecFinalizada',
        columnas: [
            {
                name: 'ID',
                key: 'idHisAcreditadoCuentaEtiqueta',
                hide: true
            }, {
                name: 'Folio Visita',
                key: 'folioVisita',
                type: 'link',
                hide: false
            }, {
                name: 'Nombre Administrador',
                key: 'nombreAdministradorFullName',
                hide: false
            }, {
                name: 'Nombre Etiqueta',
                key: 'nombreEtiquetaCuenta',
                hide: false
            }, {
                name: 'Comentarios',
                key: 'comentarios',
                hide: false
            }, {
                name: 'Fecha',
                key: 'fecRegistro',
                hide: false,
                type: 'dateTime'
            }
        ]
    }

    tablaDineroRecuperado: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        sort: 'desc',
        sortField: 'fechaPago',
        columnas: [
            {
                name: 'Folio Visita',
                key: 'folioVisita',
                type: 'link',
                link: '/visit/',
                hide: false
            }, {
                name: 'Gestor',
                key: 'nombreCompletoAgente',
                hide: false
            }, {
                name: 'Fecha Pago',
                key: 'fechaPago',
                hide: false,
            }, {
                name: 'Fecha Registro',
                key: 'fechaRegistro',
                hide: false,

            }, {
                name: 'Monto',
                key: 'montoPago',
                hide: false
            }
        ]
    };

    tablaCoordenadas:any  = {
        headers: false,
        tableHeaders: false,
        multiSelect: true,
        sort: 'asc',
        sortField: 'idCoordenadaVisita',
        columnas: [
            {
                name: 'ID',
                key: 'idCoordenadaVisita',
                hide: true
            }, {
                name: 'Tipo Coordenada',
                key: 'nombreCoordenada',
                hide: false
            }, {
                name: 'Ubicación',
                key: 'ubicacion',
                type: "link",
                hide: false
            }, {
                name: 'Fecha/Hora Celular',
                key: 'fecDispositivo',
                type: "dateTime",
                hide: false
            }
        ]
    };

    constructor(
        private app: ApplicationService,
        private route: ActivatedRoute,
        private visitService: VisitService,
        private checklistService: ChecklistService,
        private elementRef: ElementRef,
        private cuentaService: CuentaService,
        private visitClarificationService: VisitClarificationService,
        private dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private lenderService: LenderService,
        private filtrosService: FiltrosService,
        private router: Router,
        private cdr: ChangeDetectorRef,
        private loadingService: LoadingService


    ) {
        this.formatos= false;
        this.archivos= [];
        this.financiera = new Financiera();
        this.detalleAcreditadoStack = [];
        this.sessionData = this.localStorageService.getSessionData();
        this.idMapa = 'mapVisitaDetalle';
    }

    ngOnInit() {
        this.momentFecha = moment;
        this.agent = new Agent();
        this.client = new Client();
        this.clientAddress = new ClientAddress();
        this.visit = new Visit();
        this.visit.coordenadasVisita = [];
        this.account = new Account();
        this.checklists = new ChecklistDinamico();
        this.coordenadasSeleccionadas = [];
        this.sumarRestarDias(this.fechaAsignacionMinDate, -1);
        this.loadMotivos();
        this.route.queryParams.subscribe(params => {
            let oblig = Number.parseInt(params['obligatorio']);
            this.url = oblig;
        })


        if (this.visitasACalificar) {
            this.visitaIndex = 0;
            this.obtenerVisita(this.visitaIndex);
        } else {
            this.readParams();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['visitasACalificar'] && !changes['visitasACalificar'].firstChange) {
            this.visitaIndex = 0;
            this.obtenerVisita(this.visitaIndex);
        }
    }


    public cancelVisit(): void {
        this.textLoader = 'Cancelando visita...';
        this.loader = true;
        // let loading: number = this.app.showLoading('Cancelando visita...');
        const subscription: Subscription = this.visitService.cancel(this.visit.id).subscribe(
            visit => {
                this.visit = visit;
            },
            error => {
                this.app.showError(error);
                this.loader = false;
                // this.app.hideLoading(loading);
                subscription.unsubscribe();
            },
            () => {
                this.loader = false;
                // this.app.hideLoading(loading);
                subscription.unsubscribe();
            }
        );
    }

    private sumarRestarDias(fecha, dias) {
        fecha.setDate(fecha.getDate() + dias);
        this.fechaAsignacionMinDate = fecha;
    }

    onLinkRowVisita({ row, columnPressed }) {
        if (columnPressed === 'folioVisita') {
            this.LinkVisitas(row);
        }
    }

    onLinkRowCoordenada({ row, columnPressed }) {
        if (columnPressed == 'ubicacion') {
            if (row.coordenadas != 'No disponible') {
                window.open(`https://www.google.com/maps/search/?api=1&query=${row.ubicacion}`, '_blank');
            }
        }
    }

    LinkVisitas(visita: any) {

        let url = `/visit/${visita.folioVisita} `;
        window.open(url, '_blank');
        this.router.navigate(['/visit/' + visita.folioVisita]);
    }

    public cambiarEtiqueta() {
        this.textLoader = 'Actualizando etiqueta...';
        this.loader = true;

        this.visitService.editEtiquetas(this.visit.id, {
            idCliente: this.visit.clientID,
            idAgente: this.visit.agent.id,
            etiquetaActual: this.etiquetaActual,
            etiquetaNva: {
                idEtiqueta: this.nvaEtiqueta.idEtiquetaVisita
            }
        }).subscribe((res: any) => {
            this.loader = false;
            this.app.showSnackbar('Aviso', 'Se ha actualizado la etiqueta correctamente.', 3000, 'success');
            this.loadChecklist(this.visit.id, this.visit.agent.id, this.visit.clientID);
        }, (err: any) => {
            this.loader = false;
            this.app.showError(err);
        });
    }

    public confirmCancelVisit(): void {
        this.$confirmModal.modal('show');
    }

    public cambiaEtiquetaModal(): void {
        this.etiquetasCliente();
        this.$cambiarEtiquetaModal.modal('show');
    }

    public etiquetaActualObtener(checklist: any): void {
        if (!this.etiquetaActual) {
            this.etiquetaActual = {
                idChecklistPregunta: checklist.idChecklistPregunta,
                idVisitaChecklistDetalle: checklist.idVisitaChecklistDetalle,
                idChecklistRespuesta: checklist.respuestas[0].idChecklistRespuesta,
                idTipoRespuesta: checklist.respuestas[0].idTipoRespuesta,
                valorRespuesta: checklist.respuestas[0].valorRespuesta
            };
        }
    }

    public etiquetasCliente(): void {
        this.etiquetas = [];
        this.nvaEtiqueta = null;
        this.loadingEtiquetas = true;
        // let loading: number = this.app.showLoading('Cancelando visita...');
        this.visitService.obtenerEtiquetasClienteProducto({
            idCliente: this.visit.clientID,
            idProducto: this.idProducto
        }).pipe(
            take(1)
        ).subscribe((res: any) => {
            this.loadingEtiquetas = false;
            this.etiquetas = res;
            this.abrirModalEtiqueta();
        }, (err: any) => {
            this.loadingEtiquetas = false;
            this.app.showError(err);
        });
    }

    private initNulls(visit: Visit): void {
        visit.account = visit.account || new Account();
        visit.agent = visit.agent || new Agent();
        if (visit.agent) {
            let nombreCompleto = visit.agent.nombre + ' ';
            if (visit.agent.apellidoPaterno) {
                nombreCompleto += visit.agent.apellidoPaterno + ' ';
            }
            if (visit.agent.apellidoMaterno) {
                nombreCompleto += visit.agent.apellidoMaterno + ' ';
            }
            visit.agent.nombreCompleto = nombreCompleto;

        }
        visit.checklist = visit.checklist || new Checklist();
        visit.client = visit.client || new Client();
        visit.clientAddress = visit.clientAddress || new ClientAddress();
        visit.generalChecklist = visit.generalChecklist || new GeneralChecklist();
    }

    private cargarDomicilioAcreditado(id: number, idAcreditadoCuentaDomicilio: number): void {

        let queryString: string = `?idCliente=${this.idCliente}&idAcreditado=${ this.visit.account.idAcreditadoPG}&idCuenta=${this.visit.account.idCuentaPG}&idAcreditadoCuentaDomicilio=${idAcreditadoCuentaDomicilio}`;
        let subscription: Subscription = this.visitService.clientAddress(queryString).subscribe(
            clientAddress => {
                this.clientAddress = clientAddress;
            }, err => {
                this.app.showError(err);
                subscription.unsubscribe();
            }, () => {
                subscription.unsubscribe();
            }
        );

    }

    private cargarAcreditado(id: number): void {
        let subscription: Subscription = this.visitService.client(id).subscribe(
            client => {
                this.client = client;
                this.obtenerDetallesCuentaDinamico(client.idCliente, client.externalID, id);
                this.obtenerArchivosVisita();

            }, err => {
                this.app.showError(err);
                subscription.unsubscribe();
            }, () => {
                subscription.unsubscribe();
            }
        );
    }

    private loadFinanciera(idFinanciera: number): void {
        this.detalleAcreditadoStack.push(1);
        this.visitService.obtenerClienteFinanciera(idFinanciera).subscribe(
            res => {
                this.clienteActivo = res["activo"]
                this.financiera = res;
            },
            err => {
                this.app.showError(err);
                this.detalleAcreditadoStack.pop();
            },
            () => this.detalleAcreditadoStack.pop()
        );
    }


    private loadVisitRates(idCliente: number, idAgente: number, idVisita: number,folio: string): void {
        this.loaderRates = true;

        this.visitService.obtenerCalificaciones(idCliente, idAgente, idVisita ,folio).subscribe(
            calificaciones => {

                this.calificacionAcreditado = undefined;
                this.calificacionAdministradorFinanciera = undefined;
                this.calificacionSuperAdministrador = undefined;

                for (let i = 0; i < calificaciones.length; i++) {
                    const calificacion = calificaciones[i];
                    if (calificacion.idAcreditado) {
                        this.calificacionAcreditado = calificacion;
                    } else if (calificacion.idAdministradorFinanciera) {
                        this.calificacionAdministradorFinanciera = calificacion;
                    } else if (calificacion.idSuperAdministrador) {
                        this.calificacionSuperAdministrador = calificacion;
                    }
                }

                this.loaderRates = false;
            }
        );
    }

    private loadEmailsCollected(id: number, idAgent: number, idCliente: number): void {
        this.visitService.getEmailsCollected(id, idAgent, idCliente, this.idFinanciera).subscribe(
            emails => {
                this.emailsCollected = emails;
            }
        );
    }

    private loadPhonesCollected(id: number, idAgent: number, idCliente: number): void {

        this.visitService.getPhonesCollected(id, idAgent, idCliente, this.idFinanciera).subscribe(
            phones => {

                this.phonesCollected = phones;
            }
        );
    }

    private loadPhotosCollected(visit: Visit): void {
        this.cargarFotos = true;
        this.visitService.loadPhotosCollected(visit.id, visit.agent.id, visit.clientID, this.idFinanciera).subscribe(
            photos => {
                this.visit.visitPhoto = photos;
                this.setPhotoPath(visit);
            }
        );
    }


    private loadVisit(id: string, loadRates: boolean = false): void {
        const queryParams: string = '';
        this.textLoader = 'Cargando detalle de visita...';
        this.loader = true;
        this.cargandoVisita = true;

        // let loading: number = this.app.showLoading('Cargando detalle de visita...');
        const subscription: Subscription = this.visitService.getOne(id, queryParams).subscribe(
            (visit: Visit) => {

                this.initNulls(visit);
                visit.visitPhoto = [];
                this.loadPhotosCollected(visit);
                this.visit = visit;

                if (visit.totalRevisitas === 0) {
                    this.revisita = false;
                } else {
                    this.revisita = true;
                }
                //this.revisita = visit.revisita;
                this.nombreAgente = visit.agent.nombre + ' ' + visit.agent.apellidoPaterno + ' ' + visit.agent.apellidoMaterno;
                this.idCobrador = visit.agent.idCobrador;
                this.idVisita = visit.id;
                this.folioVisita = visit.folio;
                this.idCheckList = visit.idChecklist;
                this.numVersion = visit.numVersion;
                this.visit.idAcreditadoCuentaDomicilio = visit.idAcreditadoCuentaDomicilio;
                this.visit.idDomicilioAcreditado = visit.idDomicilioAcreditado;
                this.idAgent = visit.agent.id;
                this.idFinanciera = visit.account.lenderID;
                this.idCliente = visit.clientID;
                this.idProducto = visit.idProducto;
                this.visit.clientID = visit.clientID;
                this.visit.generalChecklist.id = visit.generalChecklist.id;
                this.visit.generalChecklist.createdAt = visit.generalChecklist.createdAt;
                this.visit.generalChecklist.mockLocation = visit.generalChecklist.mockLocation;
                this.visit.generalChecklist.offline = visit.generalChecklist.offline;
                this.visit.coordenadasVisita = this.visit.coordenadasVisita ? JSON.parse(this.visit.coordenadasVisita) : [];
                Visit.ajustarCoordenadasPendientes(/*this.visit.tiposCoordenada, */this.visit.coordenadasVisita);
                if(this.visit.coordenadasVisita && this.visit.coordenadasVisita.length > 0){
                    for (let vi = 0; vi < this.visit.coordenadasVisita.length; vi++) {
                        const visitaCoordenada = this.visit.coordenadasVisita[vi];
                        this.coordenadasVisitasCoompletas.push(visitaCoordenada);
                        //Se valida el visita coordenada de punto original para meterla en las coordenadas seleccionadas
                        if(visitaCoordenada.idTipoVisitaCoordenada == 8){
                            this.indexCoordenadaOriginal = vi;
                        }
                    }

                    if(this.indexCoordenadaOriginal >= 0){
                        this.visit.coordenadasVisita.splice(this.indexCoordenadaOriginal, 1);
                        this.onSelected([]);
                    }
                }

                this.latitud = visit.latFotos ? visit.latFotos : visit.latFin;
                this.longitud = visit.lngFotos ? visit.lngFotos : visit.lngFin;
                this.coordenadasMaps = [{lat: this.latitud, lng: this.longitud }];
                //if(loadRates){
                    this.loadVisitRates( visit.clientID, visit.agent.id, this.idVisita, this.folioVisita);
                //}
                this.loadingCoordenadas = false;

                this.loadChecklist(this.visit.id, this.visit.agent.id, this.visit.clientID);
                this.loadClarification(this.visit.id, this.visit.agent.id, this.visit.clientID);
                this.loadPhonesCollected(this.visit.id, this.visit.agent.id, this.visit.clientID);
                this.loadEmailsCollected(this.visit.id, this.visit.agent.id, this.visit.clientID);
                this.idCuenta = visit.account.id;
                //this.cargarDetalleCuenta(visit.account.id);
                this.cargarDomicilioAcreditado(visit.account.id, visit.idAcreditadoCuentaDomicilio);
                this.cargarAcreditado(visit.account.id);
                this.loadFinanciera(visit.account.lenderID);
                this.cargarEtiquetasCuenta(this.idCliente);
                this.cargarHistorialEtiquetasCuenta(this.idCliente, this.idCuenta);
                this.cargarHistorialPagosRecabados();
                this.cargarDomicilios(this.idCliente, this.visit.account.idAcreditadoPG, this.visit.account.idCuentaPG);
                this.loadProducts();
                this.obtenerArchivos();

            }, err => {
                this.loader = false;
                if(err == 'Error 404: not found.'){
                    this.filtrosService.guardarFiltros({}, false, null, true);
                }
                else{
                    this.app.showError(err);
                }
                this.cargandoVisita = false;
                // this.app.hideLoading(loading);
                subscription.unsubscribe();
            }, () => {
                this.loader = false;
                this.cargandoVisita = false;
                // this.app.hideLoading(loading);
                subscription.unsubscribe();
            }
        );
    }

    private loadChecklist(id: number, idAgent: number, clienteId: number): void {
        this.loadingChecklist = true;
        this.checklists.preguntas = [];
        this.checklistService.getChecklist(id, idAgent, clienteId, this.idFinanciera)
            .subscribe(data => {
                this.checklists.preguntas = Pregunta.mapArray(data.checklist);
                let country = "&Country=";
                country+= environmentSelector().country;

                for (let p = 0; p < this.checklists.preguntas.length; p++) {
                    for(let r = 0; r < this.checklists.preguntas[p].respuestas.length; r++){
                        let respuesta = this.checklists.preguntas[p].respuestas[r];
                        if(respuesta.idTipoRespuesta == 11 && respuesta.valorRespuesta){
                            respuesta.fullPath =
                                `${this.ambiente.kobraServices.storage}/checklists/foto?nombre=${respuesta.valorRespuesta}&token=${localStorage.getItem('access-token')}${country}`;
                        }
                        if(respuesta.idTipoRespuesta == 12){
                          for (let c = 0; c < respuesta.columnas.length; c++) {
                            const columna = respuesta.columnas[c];
                            if(!respuesta.columnsTabla){
                              respuesta.columnsTabla = [];
                            }
                            respuesta.columnsTabla.push({
                              name: columna.nombre,
                              key: columna.idChecklistRespuesta.toString(),
                              type: columna.idTipoRespuesta == 5 ? 'date' :
                                (columna.idTipoRespuesta == 6 || columna.idTipoRespuesta == 7 || columna.idTipoRespuesta == 8 ? 'boolean' :
                                  (columna.idTipoRespuesta == 3 || columna.idTipoRespuesta == 4 ? 'number' : 'string')
                                )
                            });
                          }
                        }
                    }
                }

                this.loadingChecklist = false;

            }, (error: string) => {
                this.loadingChecklist = false;
                this.app.showError(error);
            });
    }

    private loadClarification(id: number, idAgent: number, idCliente: number): void {
        this.loadingClarification = true;
        this.visitClarificationService.get(id, idAgent, idCliente, this.idFinanciera)
            .subscribe(data => {
                this.clarification = data;
                this.loadingClarification = false;
            }, (error: string) => {
                this.loadingClarification = false;
                this.app.showError(error);
            });
    }

    private readParams(): void {
        this.route.params.subscribe(
            (params: Params) => {
                if (params['folio']) {

                    this.idVisita = params['folio'];
                    this.folioVisita = params['folio'];

                    this.loadVisit(params['folio'], true);
                }
            }
        );
    }

    private obtenerDetallesCuentaDinamico(lenderID: number, idExternal: string, idCuenta: number) {
        //this.loadingAccountDetail = true;
        this.visitService.obtenerDetallesCuentaDinamico(lenderID, idExternal, idCuenta)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                for (let i = 0; i < res.data.length; i++) {
                    if (res.data[i].idCuentaCampo == 54) {
                        this.visit.account.totalDue = res.data[i].valor
                    }

                    if (res.data[i].idCuentaCampo == 72) {
                        this.visit.account.requiredPayment = res.data[i].valor
                    }

                    if (res.data[i].idCuentaCampo == 48) {
                        this.visit.account.daysPastDue = res.data[i].valor
                    }
                    //this.idCuenta = res.data[i].idCuenta;
                }
                //this.loadingAccountDetail = false;

                //this.cuentaDetalles = res.data;
            }, (err: any) => {
                this.app.showError(err);
                //this.loadingAccountDetail = false;
            });
    }

    private setPhotoPath(visit: Visit): void {
        let country = "&Country=";
        country+= environmentSelector().country;
        for (let i = 0; i < visit.visitPhoto.length; i++) {
            visit.visitPhoto[i].fullPath =
                `${this.ambiente.kobraServices.storage}/visitas/${visit.id}/foto/${visit.visitPhoto[i].id}?token=${localStorage.getItem('access-token')}&idAgente=${visit.agent.id}&idFinanciera=${visit.account.lenderID}${country}`;
        }
        this.cargarFotos = false;
    }

    private setRating(selector: string, rate: number): void {
        $(this.elementRef.nativeElement)
            .find(selector)
            .rating('set rating', rate);
    }

    downloadPDF() {


        let queryParams = `?idAgente=${this.idAgent}&folio=${this.folioVisita}&idClienteAdministrador=${this.sessionData.idClienteAdministrador}`;
        if (this.idFinanciera) {
            queryParams += `&idFinanciera=${this.idFinanciera}&idCuenta=${this.visit.account.id}`;
        }

        this.visitService.get(`/${this.idVisita}/detalle-download-pdf${queryParams}`)
        .subscribe((res: any) => {
            this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 1000, 'success');
        }, (error: any) => {
            this.app.showError(error);
        });
    }

    downloadContrato() {

        this.loadingService.setLoading(true);

         const queryParams = `?idAgente=${this.archivoGestor.idAgente}&idCuenta=${this.visit.account.idCuentaPG}`;
            this.visitService.descargarContratoDetalleVisita(queryParams, this.archivoGestor.idCliente, this.visit.id).subscribe(event => {
              if (event.type === HttpEventType.Response) {
                this.loadingService.setLoading(false);
                const blob = new Blob([event.body], { type: event.body.type });
                saveAs(blob, "Contrato-visita-" + this.folioVisita);
               // console.log(event)
              }
            }, error => {
                this.loadingService.setLoading(false);

        });
    }


    obtenerVisita(pos: any) {
        //this.cargarHistorialEtiquetasCuenta();
        if (pos === 'loadInfo') {
            this.loadVisitRates(this.idCliente, this.idAgent, this.idVisita, this.folioVisita);
            return;
        }

        if (pos === 'siguiente') {
            this.operationComplete.emit(true);
            this.loadVisitRates(this.idCliente, this.idAgent, this.idVisita, this.folioVisita);
            //this.closeModal.emit();
        } else {
            this.resetRate = this.resetRate === 0 ? undefined : 0;
            this.visitaIndex = pos;

            this.idVisita = this.visitasACalificar['id'];
            this.folioVisita = this.visitasACalificar['folio'];
            this.coordenadasSeleccionadas = [];
            this.loadVisit(this.folioVisita, true);
        }
    }

    requestClarification() {
        this.openClarification(this.CLARIFICATION_STATUS.REVISION);
    }

    approveClarification() {
        this.openClarification(this.CLARIFICATION_STATUS.APPROVED);
    }

    rejectClarification() {
        this.openClarification(this.CLARIFICATION_STATUS.REJECTED);
    }

    cancelClarification() {
        this.openClarification(this.CLARIFICATION_STATUS.CANCELED);
    }

    openClarification(idStatus: number) {
        setTimeout(() => {
            const dialogRef = this.dialog.open(VisitClarificationComponent, {
                width: '500px',
                //height: '350px',
                data: {
                    idVisit: this.idVisita,
                    idAgent: this.idAgent,
                    idClient: this.visit.clientID,
                    idStatus
                }
            });

            dialogRef.afterClosed().subscribe(idStatus => {
                if (Boolean(idStatus)) {
                    this.clarification = <VisitClarification>{
                        idStatus: +idStatus
                    };

                    if (idStatus !== this.CLARIFICATION_STATUS.REVISION) {
                        this.visit.puedeAclarar = false;
                    }

                    this.loadClarification(this.visit.id, this.visit.agent.id, this.visit.clientID);
                }
            });
        });
    }

    private obtenerArchivosVisita(): void {
        let subscription: Subscription = this.visitService.getOneArchivosAcreditados(`?idAcreditado=${this.visit.account.idAcreditadoPG}&idCuenta=${this.visit.account.idCuentaPG}&idFinanciera=${this.idFinanciera}&idCliente=${this.idCliente}&idAgente=${this.visit.agent.id}&idVisita=${this.visit.id}`).subscribe(
            res => {
                if (res) {
                    if( res.length > 1 ) {
                        this.app.showError('Huno un problema al cargar el contrato de esta visita.');
                    } else {
                        this.archivoGestor = res[0];
                    }
                }

            }, err => {
                this.app.showError(err);
                subscription.unsubscribe();
            }, () => {
                subscription.unsubscribe();
            }
        );
    }

    public onSelected(rows) {
        this.itemsSelected = rows.slice();
        this.coordenadasSeleccionadas = [];
        if(this.indexCoordenadaOriginal>=0){
            this.coordenadasSeleccionadas.push({
                fecDispositivo: this.coordenadasVisitasCoompletas[this.indexCoordenadaOriginal].fecDispositivo,
                tipoCoordenada: this.coordenadasVisitasCoompletas[this.indexCoordenadaOriginal].idTipoVisitaCoordenada,
                lat: this.coordenadasVisitasCoompletas[this.indexCoordenadaOriginal].lat,
                lng: this.coordenadasVisitasCoompletas[this.indexCoordenadaOriginal].lng,
                nombreCoordenada: this.coordenadasVisitasCoompletas[this.indexCoordenadaOriginal].nombreCoordenada,
                coordenadaOriginal: true
            });
        }
        this.itemsSelected.forEach(item => {
            this.coordenadasSeleccionadas.push({
                fecDispositivo: item.fecDispositivo,
                tipoCoordenada: item.idTipoVisitaCoordenada,
                lat: item.lat,
                lng: item.lng,
                nombreCoordenada: item.nombreCoordenada
            });
        });
    }

    public cargarFotoCompleta(urlFoto: string){
        const dialogRef = this.dialog.open(FotoModalComponent, {
            data: {
                urlFoto: urlFoto
            }
        });

        dialogRef.afterClosed().subscribe(data => {});
    }

    /*******Metodos cambio de producto cuenta */
    private loadMotivos(): void {
        this.visitService.obtenerEtiquetasOperaciones()
            .subscribe(
                motivos => {
                    this.motivos = motivos;
                },
                error => this.app.showError(error)

            );
    }

    public changeMotivo(): void {
        if (this.selectedTag) {
            this.nonSelectedTagWarning = false;
        } else {
            this.nonSelectedTagWarning = true;
        }
    }

    public changeCambiarProducto(): void {
        if (!this.cambiarProducto) {
            this.idProductoNvo = null;
        }
    }

    loadProducts() {
        this.loadingProducts = true;
        this.lenderService.obtenerProductosCliente(this.visit.clientID)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                this.loadingProducts = false;
                this.productos = res.data;
            }, (err: any) => {
                this.loadingProducts = false;
                this.app.showError(err);
            });
    }

    cargarEtiquetasCuenta(idCliente: number) {
        this.loadingEtiquetasCuenta = true;
        this.visitService.obtenerEtiquetasCuenta(idCliente)
            .pipe(
                take(1)
            ).subscribe((respuesta: any) => {
                this.loadingEtiquetasCuenta = false;
                this.etiquetasCuenta = respuesta.data;
            }, (err: any) => {
                this.loadingEtiquetasCuenta = false;
                this.app.showError(err);
            });
    }

    cargarHistorialEtiquetasCuenta(idCliente: number, idCuenta) {
        this.loadingHistorialEtiquetasCuenta = true;
        this.visitService.obtenerHistorialEtiquetasCuenta(idCliente, idCuenta)
            .pipe(
                take(1)
            ).subscribe((respuesta: any) => {
                this.loadingHistorialEtiquetasCuenta = false;
                for (let i = 0; i < respuesta.data.length; i++) {
                    const etiqueta = respuesta.data[i];
                    respuesta.data[i].nombreAdministradorFullName = `${etiqueta.nombreAdministrador} ${etiqueta.apellidoPaternoAdministrador}${etiqueta.apellidoPaternoAdministrador ? ' ' : ''}${etiqueta.apellidoMaternoAdministrador?etiqueta.apellidoMaternoAdministrador:''}`

                }
                this.etiquetasCuentaHistorial = respuesta.data;
            }, (err: any) => {
                this.loadingHistorialEtiquetasCuenta = true;
                this.app.showError(err);
            });
    }

      cargarHistorialPagosRecabados() { this.idCliente, this.visit.account.idAcreditadoPG, this.visit.account.idCuentaPG
        let queryString: string =  `?idCliente=${this.idCliente}&idVisita=${this.idVisita}&idAgente=${this.idAgent}`;
        this.montoTotal = 0;
        this.loadingDineroRecuperado = true;
        this.visitService.cargarHistorialPagosRecabados(queryString)
            .pipe(
                take(1)
            ).subscribe((respuesta: any) => {
                this.loadingDineroRecuperado = false;
                for (let i = 0; i < respuesta.data.length; i++) {
                    const datos = respuesta.data[i];
                    let idAgente = '';
                    let nombreCompletoAgente;
                    let nombreCompletoAgente2;

                    if(datos.idAgente) {
                        idAgente = `${datos.idAgente} -`;
                    }

                    nombreCompletoAgente = `${datos.nombreAgente} ${datos.apellidoPaternoAgente ? datos.apellidoPaternoAgente : ''} ${datos.apellidoMaternoAgente}`;
                    nombreCompletoAgente2 = `${datos.nombreAgente2} ${datos.apellidoPaternoAgente2 ? datos.apellidoPaternoAgente2 : ''} ${datos.apellidoMaternoAgente2}`;

                    if(nombreCompletoAgente) {
                        respuesta.data[i].nombreCompletoAgente = `${idAgente} ${nombreCompletoAgente}`;
                    }else {
                        respuesta.data[i].nombreCompletoAgente = `${idAgente} ${nombreCompletoAgente2}`;
                    }

                    this.montoTotal = this.montoTotal +  parseInt(datos.montoPago);
                    this.contadorPagos++;
                    respuesta.data[i].montoPago = '$' + datos.montoPago;
                    respuesta.data[i].fechaPago = this.momentVista(datos.fechaPago).format('DD/MM/YYYY');
                    respuesta.data[i].fechaRegistro = this.momentVista(datos.fechaRegistro).format('DD/MM/YYYY');

                }
                this.dineroRecuperado = respuesta.data;
            }, (err: any) => {
                this.loadingDineroRecuperado = true;
                this.app.showError(err);
            });
    }

    public cargarDomicilios(idCliente: number, idAcreditado: number, idCuenta: number) {
        this.cargandoHistorialDeDcomiclios = true;
        this.visitService.obtenerDomicilios(idCliente, idAcreditado, idCuenta, true)
            .pipe(take(1))
            .subscribe((respuesta: any) => {
                this.cargandoHistorialDeDcomiclios = false;
                for (let i = 0; i < respuesta.data.length; i++) {
                    let direccion: string;
                    direccion = (respuesta.data[i].calle ? respuesta.data[i].calle +' ' : '') +
                                                (respuesta.data[i].numeroExt ? respuesta.data[i].numeroExt +', ' : '') +
                                                (respuesta.data[i].numeroInt ? respuesta.data[i].numeroInt +', ' : '') +
                                                (respuesta.data[i].colonia ? respuesta.data[i].colonia +' ' : '') +
                                                (respuesta.data[i].codigoPostal ? respuesta.data[i].codigoPostal +', ' : '') +
                                                (respuesta.data[i].municipio ? respuesta.data[i].municipio+', ' : '') +
                                                (respuesta.data[i].estado ? respuesta.data[i].estado +'.' : '') ;
                    respuesta.data[i].selectDomicilio = respuesta.data[i].folioDomicilio + ' - ' + direccion;
                }
                this.domicilios = respuesta.data;
            },
                (error: any) => {
                    this.cargandoHistorialDeDcomiclios = false;
                    this.app.showError(error);
                }
            );
    }

    public onChangeDomicilio() {

        if(!this.idDomicilio) {
            this.domicilioWarning =  true;
        }else {
            this.domicilioWarning =  false;
        }
    }

    public onChangeDomicilioRevisita() {

        if(!this.idDomicilioRevisita) {
            this.domicilioRevisitaWarning =  true;
        }else {
            this.domicilioRevisitaWarning =  false;
        }
    }

    openDialogAsignar(): void {

      if( this.domicilios.length === 0){
        this.app.showSnackbar(
          "Aviso",
          "Es necesario que la cuenta tenga por lo menos un domicilio activo.",
          5000,
          "warning"
        );
        return;
      }
        this.account.lenderID = this.idFinanciera;
        this.account.idCuenta = this.idCuenta;
        this.account.id = this.visit.account.id;
        this.account.idCliente = this.idCliente;
        this.account.idAcreditadoPG = this.visit.account.idAcreditadoPG;
        this.account.idCuentaPG = this.visit.account.idCuentaPG;
        this.account.product.id = this.idProducto;

        const dialogRef = this.dialog.open(ModalAsignarCuentaComponent, {
            //height: '400px',
            //width: '800px',
            //disableClose: true,
            data:{
                    opcion: 2,
                    account:this.account,
                    domiciliosSeleccionados: [],
                    domicilios: this.domicilios,
                    idCobrador: this.idCobrador,
                    nombreCobrador: this.nombreAgente,
                    idAgente: this.idAgent,
                    idAgenteRevisita: this.idAgent,
                    nombreAcreditado: this.client.fullName,
                    folioVisita: this.folioVisita,
                    idVisitaPsg: this.visit.id,
                    idDomicilioAcreditado: this.visit.idDomicilioAcreditado
                }
        });

        dialogRef.afterClosed().subscribe(result => {

            if(result) {

                this.revisita = true;
            }
        });
    }

  openDialogEstatus(): void {

    if( this.domicilios.length === 0){
      this.app.showSnackbar(
        "Aviso",
        "Es necesario que la cuenta tenga por lo menos un domicilio activo.",
        5000,
        "warning"
      );
      return;
    }
      const dialogRef = this.dialog.open(ModalEstatusCuentaComponent, {
          //height: '400px',
          width: '500px',
          //disableClose: true,
          data:{
                  opcion: 1,
                  domicilios: this.domicilios,
                  motivos: this.motivos,
                  domiciliosSeleccionados: [],
                  idFinanciera: this.idFinanciera,
                  idCliente: this.idCliente,
                  idAcreditadoPG: this.visit.account.idAcreditadoPG,
                  idCuentaPG: this.visit.account.idCuentaPG,
                  idCuenta: this.idCuenta
              }
      });

      dialogRef.afterClosed().subscribe(result => {


      });
  }


    public obtenerArchivos() {
        this.visitService.obtenerFormatosDescargados(this.idCliente, this.idCheckList, this.numVersion, this.idVisita, this.idAgent)
            .pipe(take(1))
            .subscribe((respuesta: any) => {
                this.archivos = respuesta.data;

                if(respuesta.data.length > 0){
                    this.formatos = true;
                    respuesta.data.forEach(item => {
                        item.isDescripcion = false;
                    });
                    this.formatosDescargados = respuesta.data;
                }
                //this.operationComplete.emit(true);
                this.cargandoVisita = false;
        },(error: any) => {
            this.app.showError(error);
        });
    }


  openDialogFormato(): void {
 //console.log(this.formatosDescargados);
    this.dialog.open(ModalDescargaFormatoComponent, {
        data:{
                idCliente:this.idCliente,
                idVisita: this.idVisita,
                idAgente: this.idAgent,
                formatosDescargados: this.formatosDescargados
            }
    });

  }

  openDialogCheckListsEtiqueta(): void {
    this.etiquetasCliente();
  }

  abrirModalEtiqueta(){
    const dialogRef = this.dialog.open(ModalEtiquetaComponent, {
        width: '400px',
        data:{
                id: this.visit.id,
                etiquetas: this.etiquetas,
                idCliente: this.visit.clientID,
                idAgente: this.visit.agent.id,
                etiquetaActual: this.etiquetaActual,
                ignoreSortChange: true
            }
    });

    dialogRef.afterClosed().subscribe(result => {

        if(result) {
            this.loadChecklist(this.visit.id, this.visit.agent.id, this.visit.clientID);
        }
    });
  }

  getAnswerValue(respuesta: any): string {
    let valor: string = "";
    if (respuesta.idTipoRespuesta === 8 || respuesta.idTipoRespuesta === 6) {
      if (respuesta.valorRespuesta === 'true') {
        return 'Sí';
      } else if (respuesta.valorRespuesta === 'false') {
        return 'No';
      } else {
        return 'Sin respuesta';
      }
    }

    if (respuesta.idTipoRespuesta === 3 || respuesta.idTipoRespuesta === 4){
      return respuesta.valorRespuesta ? Utils.formatearNumero(respuesta.valorRespuesta) : "Sin respuesta";
    }

    if (respuesta.idTipoRespuesta === 5) {
        return respuesta.valorRespuesta ? this.momentFecha(respuesta.valorRespuesta).format('DD/MM/YYYY') : "Sin respuesta";
    }

    return respuesta.valorRespuesta ? respuesta.valorRespuesta : "Sin respuesta";
  }

    getSingleAnswerValue(checklist: any) {

        const respuesta = checklist.respuestas[0];
        return { valor: this.getAnswerValue(respuesta), hasPhoto: respuesta.idTipoRespuesta === 11, hasTabla: respuesta.idTipoRespuesta === 12 };
    }

     toggleCommentView(visit: any) {

        if (visit.comentariosIA !== null) {
            this.isComentarioIA = !this.isComentarioIA;
        }

        this.isComentario = !this.isComentario;
    }

    abrirDialogoTabla(checklist: any, respuesta: any) {
      const dialogRef = this.dialog.open(TableDialogComponent, {
        width: '80%',
        data: {
          columns: respuesta.columnsTabla,
          items: respuesta.valorRespuesta,
          headers: false,
          multiselect: false,
          title: checklist.nombrePregunta,
          subtitle: respuesta.nombreRespuesta || ''
        }
      });

    }

    /*******Fin */
}
