import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take, map } from 'rxjs/operators';
import { LenderService } from '@lender/lender.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ClientesEtiquetasVisitasService } from 'src/app/catalogos/clientes-etiquetas-visitas/clientes-etiquetas-visitas.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Lender } from '@lender/lender.model';



@Component({
  selector: 'app-modal-asignar',
  templateUrl: './modal-asignar.component.html',
  styleUrls: ['./modal-asignar.component.css'],
  providers: [
    LenderService
  ]
})
export class ModalAsignarComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<ModalAsignarComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lenderService: LenderService,
    private app: ApplicationService,
    private localStorageService: LocalStorageService,
    public clientesEtiquetasVisitasService: ClientesEtiquetasVisitasService,
    private fb: FormBuilder,

  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.etiquetaForm = this.fb.group({
      idCliente: ['', Validators.required],
      idProducto: ['', Validators.required],
      idEtiquetaVisita: [!this.sessionData.isSuperAdmin ? this.sessionData.idCliente + '' : ''],
      // nuevaEtiquetaVisita: ['']
    });

    this.etiquetaClienteVisita.idCliente = !this.sessionData.isSuperAdmin ? this.sessionData.idCliente : null;
  }
  
  lenders: Lender[];
  public etiquetaForm: FormGroup;
  sessionData: SessionData;
  products: Array<any>;
  etiquetas: Array<any>;
  // mostrarNuevaEtiqueta = false;
  // nuevaEtiqueta: string = '';
  // nuevaEtiquetasArray: string[] = []; // Array para acumular las nuevas etiquetas

  etiquetaClienteVisita: any = {
    idCliente: null,
    idProducto: null,
    idEtiquetaVisita: null
  };
  loadings: any = {
    financieras: false,
    productos: false,
    etiquetas: false
  }

  ngOnInit() {
    this.loadLenders();
    this.loadClientesEtiquetasVisita();
    this.limpiarItemsInactivos(); 
  }

  // toggleMostrarNuevaEtiqueta() {
  //   this.mostrarNuevaEtiqueta = !this.mostrarNuevaEtiqueta;
  //   this.nuevaEtiqueta = '';
  //   this.etiquetaForm.get('nuevaEtiquetaVisita')?.reset();
  // }

  // onEtiquetaSelectChange(selectedValue: any) {
  //   if (selectedValue === 'nuevaEtiqueta') {
  //     this.toggleMostrarNuevaEtiqueta();
  //   }
  // }

  limpiarItemsInactivos() {
    this.data.itemsSelected = this.data.itemsSelected.filter(item => item.activo === true);
  }

  loadLenders(): void {
    this.loadings.financieras = true;
    this.lenderService.getLenders().subscribe(
      (res: any) => {
        res.data.sort(this.compare);
        this.lenders = res.data;
        this.loadings.financieras = false;
      },
      (err: any) => {
        this.loadings.financieras = false;
        this.app.showError(err);
      }
    );
  }

  loadProducts() {
    this.products = [];
    if (this.etiquetaClienteVisita.idCliente !== null) {
      this.loadings.productos = true;
      this.lenderService.obtenerProductosCliente(this.etiquetaClienteVisita.idCliente)
        .pipe(
          take(1),
          map((res: any) => {
            return res;
          })
        ).subscribe((res: any) => {
          this.loadings.productos = false;
          this.products = res.data;
        }, (err: any) => {
          this.loadings.productos = false;
          this.app.showError(err);
        });
    }
  }

  loadClientesEtiquetasVisita() {
    this.etiquetas = [];
    this.loadings.etiquetas = true;
    this.clientesEtiquetasVisitasService.obtenerGlobalEtiquetas()
      .pipe(
        take(1),
        map((res: any) => {
          return res;
        })
      ).subscribe((res: any) => {
        this.etiquetas = res.data;
        this.loadings.etiquetas = false;
      }, (err: any) => {
        this.loadings.etiquetas = false;
        this.app.showError(err);
      });
  }

  // addEtiqueta() {
  //   const nuevaEtiquetaTexto = this.nuevaEtiqueta ? this.nuevaEtiqueta.trim() : '';

  //   if (this.mostrarNuevaEtiqueta && nuevaEtiquetaTexto) {
  //     // Normalizar el texto de la nueva etiqueta
  //     const nuevaEtiquetaNormalizada = this.normalizeText(nuevaEtiquetaTexto);

  //     // Verificar si la nueva etiqueta ya existe en `nuevaEtiquetasArray` o en `this.etiquetas`
  //     const etiquetaYaAgregadaEnNuevas = this.nuevaEtiquetasArray.some(et => this.normalizeText(et) === nuevaEtiquetaNormalizada);
  //     const etiquetaYaAgregadaEnExistentes = this.etiquetas.some(et => this.normalizeText(et.etiquetaVisita) === nuevaEtiquetaNormalizada);

  //     if (etiquetaYaAgregadaEnNuevas || etiquetaYaAgregadaEnExistentes) {
  //       this.app.showSnackbar(
  //         "Aviso",
  //         "La etiqueta ya ha sido agregada.",
  //         3000,
  //         "warning"
  //       );
  //       return; // Salir de la función si la etiqueta ya existe en cualquiera de los dos arrays
  //     }

  //     // Agregar la nueva etiqueta al array `nuevaEtiquetasArray`
  //     this.nuevaEtiquetasArray.push(nuevaEtiquetaTexto);

  //     // Agregar la nueva etiqueta a `itemsSelected` para visualización
  //     this.data.itemsSelected.unshift({
  //       idEtiquetaVisita: null, // Mantener null porque es una nueva etiqueta
  //       etiquetaVisita: nuevaEtiquetaTexto
  //     });

  //     // Limpiar la entrada
  //     this.nuevaEtiqueta = '';
  //     this.mostrarNuevaEtiqueta = false;

  //   } else {
  //     // Manejar etiqueta existente
  //     const selectedEtiqueta = this.etiquetas.find(et => et.idEtiquetaVisita === this.etiquetaForm.get('idEtiquetaVisita')?.value);
  //     if (selectedEtiqueta) {
  //       // Verificar si la etiqueta seleccionada ya está en `itemsSelected`
  //       const etiquetaYaAgregada = this.data.itemsSelected.find(et => et.idEtiquetaVisita === selectedEtiqueta.idEtiquetaVisita);
  //       if (etiquetaYaAgregada) {
  //         this.app.showSnackbar(
  //           "Aviso",
  //           "La etiqueta seleccionada ya ha sido agregada.",
  //           3000,
  //           "warning"
  //         );
  //         return; // Salir de la función si la etiqueta ya existe
  //       }

  //       // Agregar la etiqueta existente a `itemsSelected` y a `idEtiquetaVisita`
  //       this.data.itemsSelected.unshift({
  //         idEtiquetaVisita: selectedEtiqueta.idEtiquetaVisita,
  //         etiquetaVisita: selectedEtiqueta.etiquetaVisita
  //       });

  //       // Actualizar el array `idEtiquetaVisita` en el formulario
  //       let etiquetaIds = this.etiquetaForm.get('idEtiquetaVisita').value || [];
  //       etiquetaIds.push(selectedEtiqueta.idEtiquetaVisita);
  //       this.etiquetaForm.patchValue({ idEtiquetaVisita: etiquetaIds });
  //     }

  //     // Resetear el campo de selección
  //     this.etiquetaForm.get('idEtiquetaVisita')?.reset();
  //     }
  // }

  normalizeText(text: string): string {
    return text
      .toLowerCase() // Convertir a minúsculas
      .normalize("NFD").replace(/[\u0300-\u036f]/g, "") // Eliminar acentos y caracteres especiales
      .replace(/[^a-z0-9\s]/g, '') // Eliminar caracteres especiales
      .trim(); // Eliminar espacios en blanco al principio y al final
  }

  removeEtiqueta(idEtiquetaVisita: number) {
    this.data.itemsSelected = this.data.itemsSelected.filter(et => et.idEtiquetaVisita !== idEtiquetaVisita);
    if (this.data.itemsSelected.length === 0) {
      this.cerrarModal(false);
    }
  }

  private compare(a, b) {
    const nombreA = a.nombre.toUpperCase();
    const nombreB = b.nombre.toUpperCase();

    let comparison = 0;
    if (nombreA > nombreB) {
      comparison = 1;
    } else if (nombreA < nombreB) {
      comparison = -1;
    }
    return comparison;
  }

  checkControl(control: string) {
    return this.etiquetaForm.controls[control].invalid && this.etiquetaForm.controls[control].touched;
  }

  getErrorMessage(control: string) {
    return this.etiquetaForm.controls[control].hasError('required') ? 'Este campo es requerido.' : '';
  }

  cerrarModal(resultado: boolean) {
    this.etiquetaForm.reset(); // Resetea el formulario
    this.data.itemsSelected = []; // Limpia las etiquetas seleccionadas
    // this.nuevaEtiquetasArray = []; // Limpia el array de nuevas etiquetas
    this.dialogRef.close(resultado); // Cierra el modal
  }

  onSubmit() {
    if (this.etiquetaForm.valid) {
      this.app.showLoading("Asignando etiquetas...");

        // Asegúrate de que solo los elementos activos estén en itemsSelected
        this.limpiarItemsInactivos();

      // Filtrar los elementos en itemsSelected para separar IDs de etiquetas existentes
      const etiquetaIds = this.data.itemsSelected
        .filter(et => et.idEtiquetaVisita !== null)
        .map(et => et.idEtiquetaVisita);

      // Construir el payload para enviar
      const payload = {
        idCliente: this.etiquetaForm.get('idCliente').value,
        idProducto: this.etiquetaForm.get('idProducto').value,
        idEtiquetaVisita: etiquetaIds,
        // nuevaEtiquetaVisita: this.nuevaEtiquetasArray // Aquí se envía todo el array acumulado de nuevas etiquetas
      };

      // Envía el payload
      this.clientesEtiquetasVisitasService.nuevaEtiqueta(payload)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.app.hideLoading();
    
        // Verifica la longitud de itemsSelected para determinar el texto
        const etiquetasCount = this.data.itemsSelected.length;
        const mensaje = etiquetasCount > 1
          ? `Se asignaron correctamente las ${etiquetasCount} etiquetas`
          : `Se asignó correctamente la etiqueta`;
    
        this.app.showSnackbar('¡Aviso!', mensaje, 3000, 'success');
    
        this.etiquetaForm.reset(); // Resetea todo el formulario
        this.data.itemsSelected = []; // Limpiar las etiquetas seleccionadas
        // this.nuevaEtiqueta = ''; // Limpia el campo de nueva etiqueta
        // this.mostrarNuevaEtiqueta = false; // Resetea la opción de mostrar nueva etiqueta
        // this.nuevaEtiquetasArray = []; // Limpiar el array de nuevas etiquetas
        this.cerrarModal(true);
      }, (error: any) => {
        this.app.hideLoading();
        this.app.showError(error);
      });
    } else {
      (<any>Object).values(this.etiquetaForm.controls).forEach((control: FormControl) => {
        control.markAsTouched();
      });
    }
  }

}
