<mat-form-field style="width: 100%;" [hintLabel]="hintLabel">
    <mat-label>{{ label }}</mat-label>
    <div class="icon-container">
      <!-- Modo Texto -->
      <input
      *ngIf="isTextInputMode"
      type="text"
      #input
      style="border: 0px"
      placeholder="{{ isTextInputMode ? 'Escribe un nuevo registro' : placeHolder }}"
      matInput
      [formControl]="myControl"
      [attr.maxlength]="maxlength"
    />
      <input
        *ngIf="!isTextInputMode"
        type="text"
        #input
        style="border: 0px"
        placeholder="{{ placeHolder }}"
        matInput textSanitizer
        [formControl]="myControl"
        [matAutocomplete]="auto"
        [attr.maxlength]="maxlength"
        />
      <mat-icon class="clear-icon" *ngIf="hasValue() && limpiar" (click)="clearValue()">clear</mat-icon>

    </div>
    <mat-autocomplete #auto="matAutocomplete" style="width: 100%; display: block;" (optionSelected)="onOptionSelected($event)">

        <ng-container *ngIf="filteredArray && filteredArray.length > 0">
            <mat-option *ngFor="let option of filteredArray" [value]="option[labelField]">
                {{option[labelField]}}
            </mat-option>
        </ng-container>
        <ng-container *ngIf="filteredArray && filteredArray.length == 0">
            <mat-option disabled>
                No se encontraron resultados
            </mat-option>
        </ng-container>
        <ng-container *ngIf="!filteredArray || filteredArray.length == 0">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option[labelField]">
                {{option[labelField]}}
            </mat-option>
        </ng-container>

        <mat-option *ngIf="loading" disabled class="kobra-search">
            <app-loader></app-loader>
        </mat-option>
    </mat-autocomplete>

    <!-- Botón de Alternancia de Modo -->
    <mat-icon 
      matSuffix 
      (click)="toggleMode()" 
      [ngClass]="{'toggle-icon': toggleIconBehavior}" 
      [matTooltip]="toggleIconBehavior ? (isTextInputMode ? 'Seleccionar del listado' : 'Agregar nuevo') : ''" 
      mat-tooltip-panel-above
    >
      {{ isTextInputMode ? 'format_list_bulleted' : icon }}
    </mat-icon>
    <mat-error *ngIf="error">{{ errorMessage }}</mat-error>
    <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</mat-form-field>
