<h1 mat-dialog-title>Editar domicilio</h1>
<mat-dialog-content class="modal" >
  <!-- <app-loader *ngIf="loader"></app-loader> -->
  <div content >
    <mat-form-field>  
      <mat-label>Calle</mat-label>
      <input matInput textSanitizer  placeholder="Escribe la calle" maxlength="255" [(ngModel)]="calle">
    </mat-form-field>

    <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="70px"> 
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-form-field>
          <mat-label>Num. Ext.</mat-label>
          <input matInput textSanitizer  placeholder="Escribe el numero exterior" maxlength="50" [(ngModel)]="numeroExt">
        </mat-form-field>
      </mat-grid-tile>

      <mat-grid-tile colspan="1" rowspan="1">
        <mat-form-field>
          <mat-label>Num. Int.</mat-label>
          <input matInput textSanitizer  placeholder="Escribe el numero interior"  maxlength="50" [(ngModel)]="numeroInt">
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list-responsive> 
    
    <div style="margin-top: 10px;">
      <mat-form-field >
        <mat-label>Entre Calles</mat-label>
        <input matInput textSanitizer  placeholder="Escribe la entre calle"  maxlength="255" [(ngModel)]="entreCalles">
      </mat-form-field>
    </div>

    <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="70px"> 
      <mat-grid-tile colspan="1" rowspan="1">
           <mat-select-search 
            [disabled]="data.type === 'down-punishment'" 
            [label]="labelEstado"
            [placeHolder]="loaders['Estados'] ? 'Cargando información...' : 'Seleccione...'" 
            [value]="'idEstado'"
            [labelField]="'nombre'" 
            [icon]="'arrow_drop_down'" 
            [options]="estados" 
            [(ngModel)]="idEstado" 
            [limpiar]="false"
            [loader]="loaders['Estados']"
            (ngModelChange)="changeEstado()"

            required>
          </mat-select-search>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-select-search 
        [disabled]="data.type === 'down-punishment'" 
        [label]="labelMunicipio"
        [placeHolder]="loaders['Municipios'] ? 'Cargando información...' : 'Seleccione...'" 
        [value]="'idMunicipio'"
        [labelField]="'nombre'" 
        [icon]="'arrow_drop_down'" 
        [options]="municipios" 
        [(ngModel)]="idMunicipio" 
        [limpiar]="false"
        [loader]="loaders['Municipios']"
        (ngModelChange)="changeMunicipio()"
        required>
      </mat-select-search>
      </mat-grid-tile>
    </mat-grid-list-responsive> 
    <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="70px"> 
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-select-search 
        [disabled]="data.type === 'down-punishment'" 
        [label]="'Codigo Postal'"
        [placeHolder]="loaders['CodigoPostal'] ? 'Cargando información...' : 'Seleccione...'" 
        [value]="'codigoPostal'"
        [labelField]="'codigoPostal'" 
        [icon]="'arrow_drop_down'" 
        [options]="codigosPostales" 
        [(ngModel)]="codigoPostal" 
        [limpiar]="false"
        [loader]="loaders['CodigoPostal']"
        (ngModelChange)="changeCodigoPostal()"
        required>
        </mat-select-search>
      </mat-grid-tile>
      
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-form-field *ngIf="!mostrarColonia">
          <mat-label>{{labelColonia}}</mat-label>
          <mat-select 
          [(ngModel)]="idColonia" 
          (ngModelChange)="changeColonias()">
            <mat-option (click)="toggleMostrarColonia()">Otra colonia...</mat-option>
            <mat-option *ngFor="let colonia of colonias" [value]="colonia.idColonia">
              {{colonia.nombre}}
            </mat-option>
          </mat-select>
          <mat-progress-bar mode="indeterminate" *ngIf="loaders['Colonias']"></mat-progress-bar>
        </mat-form-field>
        <mat-form-field *ngIf="mostrarColonia">
          <input matInput textSanitizer  placeholder="Ingrese la colonia" [(ngModel)]="nuevaColonia">
          <button matSuffix mat-icon-button aria-label="Clear" (click)="toggleMostrarColonia(); hideMatSelect()">
            <mat-icon>clear</mat-icon>
          </button>
        </mat-form-field>
      </mat-grid-tile>
    </mat-grid-list-responsive> 
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-flat-button class="boton-cancelar" (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button class="boton-guardar" (click)="save()"><mat-icon style="color:white !important">sync</mat-icon>Actualizar</button>
</mat-dialog-actions>