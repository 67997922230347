import { AssignedAccount } from "../assigned-account/assigned-account.model";
import { OperationTag } from "../operation-tag/operation-tag.model";
import { Client } from "../client/client.model";
import { ClientAddress } from "../client-address/client-addres.model";
import { Group } from "../group/group.mode";
import { Lender } from "../lender/lender.model";
import { Product } from "../product/product.model";
import { TakenAccount } from "../taken-account/taken-account.model";
import { Visit } from "../visit/visit.model";

export class Account {
  id: number;
  assigned: AssignedAccount;
  client: Client;
  clientID: number;
  idCuenta: number;
  clienteActivo: boolean;
  clientAddress: ClientAddress;
  createdAt: Date;
  daysPastDue: number;
  daysInPlatform: number;
  daysUntilFirstVisit: number;
  daysTaken: number;
  daysUntilExpiration: number;
  groups: Group[];
  initialCapital: number;
  lastPaymentAmount: number;
  lastPaymentDate: number;
  lender: Lender;
  lenderID: number;
  loanDate: Date;
  outstandingAmount: number;
  outstandingPayments: number;
  paymantsMade: number;
  product: Product;
  requiredPayment: number;
  selected: boolean;
  status: string;
  tag: OperationTag;
  taken: TakenAccount;
  totalDue: number;
  totalPaid: number;
  visits: Visit[];
  visibility: string;
  updatedAt: Date;
  idProducto: number;
  nameProduct: string;
  fecVisita: string;
  totalVisitas: number;
  grupo: string;
  sucursal: string;
  idCliente:number;
  idAcreditado: number;
  idAcreditadoPG: number;
  idCuentaPG: number;
  lat: number;
  lng: number;
  nombreEtiquetaCuenta:string;
  domicilios:Array<any>;
  idAcreditadoCuentaDomicilio: number;
  telefonoCelular: string;
  externalID: string;
  subExternalID: string;
  nombreAcreditado:string;
  tipoCobertura:string;
  coordenadas:string;
  urlDetalleCuenta:string;
  nombreGrupo:string;

  constructor() {
    this.assigned = new AssignedAccount();
    this.client = new Client();
    this.clientAddress = new ClientAddress();
    this.groups = [];
    this.lender = new Lender();
    this.product = new Product();
    this.tag = new OperationTag();
    this.taken = new TakenAccount();
    this.visits = [];
  }

  public static map(data: any): Account {
    let instance = new Account();
    instance.clienteActivo = data.clienteActivo
    instance.id = data.idCuenta || data.id || 0;
    // instance.assigned = data.attr;
    instance.client = data.client;
    instance.clientID = data.idAcreditado;
    instance.createdAt = data.createdAt;
    instance.daysPastDue = data.diasMoratorios;
    instance.telefonoCelular = data.telefonoCelular;
    // instance.daysInPlatform = data.attr;
    // instance.daysUntilFirstVisit = data.attr;
    // instance.daysTaken = data.attr;
    instance.initialCapital = data.capitalInicial;
    instance.lastPaymentAmount = data.importeUltimoPago;
    instance.lastPaymentDate = data.fechaUltimoPago;
    instance.lenderID = data.idFinanciera;
    instance.loanDate = data.fechaCreditoOtorgamiento;
    instance.outstandingAmount = data.totalPendiente;
    instance.outstandingPayments = data.numeroPagosPendientes;
    instance.paymantsMade = data.numeroPagosRealizados;
    // instance.product = data.attr;
    instance.requiredPayment = data.saldoExigible;
    // instance.selected = data.attr;
    instance.status = data.estatus || data.status;
    if(instance.status === 'Espera') {
      instance.status = "Visitada";
    }
    
    instance.product.name = data.nombreProducto;
    instance.product.id = data.idProduct;
    instance.idCliente = data.idCliente
    // instance.tag = data.attr;
    // instance.taken = data.attr;
    instance.totalDue = data.totalPendiente;
    instance.totalPaid = data.totalAbonado;
    // instance.visibility = data.attr;
    instance.updatedAt = data.updatedAt;
    instance.grupo = data.grupo;
    instance.sucursal = data.sucursal;
    instance.totalVisitas = data.totalVisitas;
    instance.idAcreditado = data.idAcreditado;
    instance.lat = data.lat;
    instance.lng = data.lng;
    if(data.client && data.client.address){
      instance.client.address = ClientAddress.map(data.client.address);
    }
    if(data.client && data.client.externalID){
      instance.client.externalID = data.client.externalID
    }
    //instance.client.address = data.client && data.client.address ? ClientAddress.map(data.client.address) : null;
    //instance.client.externalID = data.client && data.client.externalID ? data.client.externalID : null;
    instance.selected = data.selected || false;
    instance.nombreEtiquetaCuenta = data.nombreEtiquetaCuenta || null;

    instance.idAcreditadoPG = data.idAcreditadoPG;
    instance.idCuentaPG = data.idCuentaPG;
    instance.tipoCobertura = data.tipoCobertura;
    instance.nombreGrupo = data.nombreGrupo;

    return instance;
  }
}
