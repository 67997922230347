<h1 mat-dialog-title>Vincular productos</h1>
<mat-dialog-content>
  <div>
    <div *ngIf="sessionData.isSuperAdmin">
      <mat-select-search
        class="id-card-field"
        [label]="'Cliente'"
        [placeHolder]="'Selecciona un cliente'"
        [value]="'option'"
        [labelField]="'nombre'"
        [icon]="'account_balance'"
        [options]="data.lenders"
        [(ngModel)]="financiera" 
        (ngModelChange)="changeLender($event);"
      >
      </mat-select-search>
    </div>
    <div>
      <mat-form-field style="width: 100%;">
        <mat-label>Producto</mat-label>
        <mat-select [(ngModel)]="selectedProducts" multiple [disabled]="desactivarProducto">
          <mat-option *ngFor="let producto of productos" [value]="producto">{{producto.nomProducto}}</mat-option>
        </mat-select>
        <mat-icon matSuffix  class="icono-limpiar-solo"
        *ngIf="selectedProducts" 
        (click)="selectedProducts = null">clear</mat-icon>
      </mat-form-field>
      <!-- <div align="left" class="limpiar-seleccion-kobra" *ngIf="selectedProducts">
        <a (click)="selectedProducts = null"><i>Limpiar selección</i></a>
      </div> -->
    </div>
    <div *ngFor="let product of selectedProducts">
      <div *ngIf="product.idProducto === 15" >
        <mat-form-field style="width: 100%;">
          <mat-label>Tipo crédito</mat-label>
          <mat-select [(ngModel)]="selectedCredits" multiple>
            <mat-option *ngFor="let credito of credits" [value]="credito">{{credito.nombreTipoCredito}}</mat-option>
          </mat-select>
          <mat-icon matSuffix  class="icono-limpiar-solo"
          *ngIf="selectedProducts" 
          (click)="selectedProducts = null">clear</mat-icon>
        </mat-form-field>
        <!-- <div align="left" class="limpiar-seleccion-kobra" *ngIf="selectedProducts">
          <a (click)="selectedProducts = null"><i>Limpiar selección</i></a>
        </div> -->
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="onNoClick()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  <button mat-stroked-button  class="boton-guardar" (click)="vincularProductos()"><mat-icon style="color:white !important">sync</mat-icon>Actualizar</button>
</mat-dialog-actions>