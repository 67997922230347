<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; inactivarHijo()"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <div class="div-filtros-side">
      <li class="filtro-side">
        <mat-form-field class="pago-gestor" style="width: 100%;">
          <mat-label>Nombre concepto</mat-label>
          <input matInput textSanitizer 
            [(ngModel)]="filters.nombre"
            (ngModelChange)="this.contabilizadorDeFiltros('generales') ;"
            placeholder="Capture el nombre del concepto a buscar"
            maxlength="100">
            <mat-icon matSuffix  class="icono-limpiar"
            *ngIf="filters.nombre" 
            (click)="filters.nombre = null; this.contabilizadorDeFiltros('generales')">clear</mat-icon>
          <mat-icon matSuffix>sell</mat-icon>
        </mat-form-field>
      
      </li>
      <li class="filtro-side">
        <mat-form-field class="pago-gestor" style="width: 100%;">
            <mat-label>Tipo Tarifa</mat-label>
            <mat-select [(ngModel)]="filters.tipoTarifa" (selectionChange)="this.contabilizadorDeFiltros('generales');">
              <mat-option [value]="'etiquetaVisita'">Etiqueta Visita</mat-option>
              <mat-option [value]="'producto'">Producto</mat-option>
            </mat-select>
            <mat-icon matSuffix class="icono-limpiar-solo" 
            *ngIf="filters.tipoTarifa != null" 
            (click)="filters.tipoTarifa = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon> 
          </mat-form-field>      
      </li>
     
    </div>
    
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; readQueryParams()">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="limpiarFiltros()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar filtros
    </button>
  </ol>
</mat-sidenav>
  
  <div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
        <div class="button-toggle-group-container">
          <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
            <mat-button-toggle value="filtros" 
                (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
                matTooltip="Filtrar cuentas"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">filter_alt</mat-icon>
                <span *ngIf="totalFiltros  >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros  }}</span>

            </mat-button-toggle>
            <mat-button-toggle value="actualizar"
              (click)="limpiarFiltros(); productosSaldosService.obtenerPaquetes(filters.page ? filters.page : 1, filters);"
              matTooltip="Refrescar"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">sync</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
            <mat-button-toggle value="ayuda" 
              matTooltip="Ayuda"
              mat-tooltip-panel-above
              (click)="ayuda = !ayuda;">
              <mat-icon color="primary">help</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
    </div>
    
    <mat-menu #menu="matMenu">
       
        <button mat-menu-item [disabled]="itemsSelected.length !== 1" (click)="onEdit(itemsSelected[0]);" >
            <mat-icon class="kobra-icon">edit</mat-icon>
            <span>Editar</span>
        </button> 
    </mat-menu>
    
    <app-table 
      [classContainer]="'tabla-completa'"
      [loading]="productosSaldosService.loader"
      [columns]="tabla.columns"
      [items]="productosSaldosService.paginationInfo.pageData"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      (sortChange)="sortChange($event)"
      (onSelectedRows)="onSelected($event)"
      (onDoubleClick)="onDoubleClick($event)"

    >
      <div t-header>
        <mat-card *ngIf="ayuda" class="cardAyuda">
          <mat-card-content>
            <p><mat-icon color="primary">help</mat-icon>
              El módulo "Tarifas Agentes" es una sección crucial dedicada a la configuración de las tarifas que se pagarán a los
              agentes por cada visita realizada, con una diferenciación basada en el tipo de producto. Este módulo es esencial
              para la gestión financiera de las operaciones de campo y para asegurar una compensación justa y adecuada a los
              agentes.
          
              En este apartado, los usuarios tienen la posibilidad de configurar el pago de dos maneras distintas, lo que añade
              una gran flexibilidad al sistema. La primera opción consiste en dar de alta un producto con un precio general para
              todas las visitas. Esta es una forma sencilla y directa de establecer una tarifa estándar para cada tipo de visita,
              independientemente del cliente al que se visite.
          
              La segunda opción permite una personalización más detallada, donde el pago por visita se edita a nivel de cliente.
              Esto significa que el precio pagado por una visita puede variar dependiendo del cliente específico al que se visita.
              Esta flexibilidad es particularmente útil en escenarios donde diferentes clientes pueden tener acuerdos o
              necesidades distintas, lo que requiere una adaptación en la tarifa pagada por las visitas realizadas a esos
              clientes.
            </p>
            Acciones:
            <p>
              <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda permitiendo aplicar filtros
              basados en diversos criterios.
            </p>
            <p>
              <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de
              filtros aplicados.
            <p>
              <mat-icon class="kobra-icon">edit</mat-icon><b> Editar: </b> Esta acción permite la actualización y modificación de
              los pagos a nivel cliente.
            </p>
          
          </mat-card-content>
        </mat-card>
      </div>
      
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="productosSaldosService.pagination?.totalRows"
          [pageIndex]="productosSaldosService.pagination?.page-1"
          [pageSize]="productosSaldosService.pagination.pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
  </div>
  