import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[validateNumericInput]'
})
export class ValidateNumericInputDirective {
    @HostListener('keypress', ['$event'])
    onKeyPress(event: KeyboardEvent): void {
        const charCode = event.key.charCodeAt(0);

        // Permitir solo números
        if (charCode < 48 || charCode > 57) {
            event.preventDefault();
        }
    }
}
