<app-modal [title]="data.title" [subtitle]="data.subtitle" [custom]="true">

  <mat-card class="card-ancho" >
    <mat-card-content class="contenido-cuestionario">
      <mat-form-field hintLabel="Mínimo 30 caracteres" #primerControl style="width:100%">
        <mat-label>{{label}}</mat-label>
        <textarea matInput textSanitizer [placeholder]="placeHolder"
          maxlength="1000"
          [(ngModel)]="textResponse"
          required
        ></textarea>
        <mat-hint align="end">{{ textResponse.length }} / 1000</mat-hint>
      </mat-form-field>
    </mat-card-content>
  </mat-card>

  <div class="action-buttons-modal" m-actions>
    <button class="boton-cancelar" mat-flat-button (click)="onClose()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
    <button mat-stroked-button [ngClass]="action" (click)="save()"><mat-icon>{{buttonOkIcon}}</mat-icon>{{buttonOk}}</button>
  </div>
</app-modal>
