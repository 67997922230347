import { Respuesta } from "./checklist-dinamico-respuesta.model";

export class Pregunta {
    idVisitaChecklistDetalle: number;
    idSolicitudCreditoChecklistDetalle: number;
    idChecklistPregunta: number;
    idChecklistPreguntaPadre: number;
    nombrePregunta: string;
    ordenPregunta: number;
    respuestas: Respuesta[]

    constructor() {
        this.respuestas = [];
    }

    public static map(data: any): Pregunta {
        let instance = new Pregunta();
        instance.idVisitaChecklistDetalle = data.idVisitaChecklistDetalle || data.idSolicitudCreditoChecklistDetalle || null;
        //instance.idVisitaChecklistDetalle = data.idSolicitudCreditoChecklistDetalle || null;
        // instance.assigned = data.attr;
        instance.idChecklistPregunta = data.idChecklistPregunta;
        instance.idChecklistPreguntaPadre = data.idChecklistPreguntaPadre || null;
        instance.nombrePregunta = data.nombrePregunta || null;
        instance.ordenPregunta = data.ordenPregunta || null;
        instance.respuestas = data.respuestas || [];

        return instance;
    }

    public static mapArray(data: any[]): Pregunta[]{
        let instances: Pregunta[] = [];
        for (let i = 0; i < data.length; i++) {
            instances.push(Pregunta.map(data[i]));
        }
        return instances;
    }
}
