<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; inactivarHijo()"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <div class="div-filtros-side">
      <li class="filtro-side" *ngIf="sessionData.isSuperAdmin">
        <mat-select-search
          class="id-card-field"
          [label]="'Cliente'"
          [placeHolder]="'Selecciona un cliente'"
          [value]="'idCliente'"
          [labelField]="'nombre'"
          [icon]="'account_balance'"
          [options]="lenders"
          [(ngModel)]="filters.idCliente"
          (ngModelChange)="loadProducts()"
          [maxlength]="100"
        >
        </mat-select-search>
       
      </li>
      <li class="filtro-side">
        <mat-select-search
          class="id-card-field"
          [label]="'Producto'"
          [placeHolder]="'Selecciona un producto'"
          [value]="'idProducto'"
          [labelField]="'nomProducto'"
          [icon]="'lightbulb_circle'"
          [options]="products"
          [(ngModel)]="filters.idProducto"
          [maxlength]="100"
        >
        </mat-select-search>
      
      </li>

      <li class="filtro-side">
        
        <mat-form-field style="width: 100%;">
          <input matInput placeholder="Etiqueta visita" [(ngModel)]="filters.etiquetaVisita" maxlength="100">
          <mat-icon matSuffix class="icono-limpiar" 
          *ngIf="filters.etiquetaVisita != null" 
          (click)="filters.etiquetaVisita = null;$event.stopPropagation();">clear</mat-icon> 
        <mat-icon matSuffix>sell</mat-icon>
        </mat-form-field>

      
      </li>

      <li class="filtro-side">
        <mat-form-field style="width: 100%;">
            <mat-label>Estatus</mat-label>
            <mat-select
              [(ngModel)]="filters.estatus"
              placeholder="Seleccione..."
            >
              <mat-option [value]="false">Inactiva</mat-option>
              <mat-option [value]="true">Activa</mat-option>
            </mat-select>
            <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filters.estatus != null" 
                  (click)="filters.estatus = null;$event.stopPropagation();">clear</mat-icon> 
            <mat-icon matSuffix>visibility</mat-icon>
        </mat-form-field>
      </li>
    </div>
    
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; filtrar();">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="limpiarFiltros()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
  
  <div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
        <div class="button-toggle-group-container">
          <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
            <mat-button-toggle value="filtros" 
                (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
                matTooltip="Filtrar"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">filter_alt</mat-icon>
                <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

            </mat-button-toggle>
            <mat-button-toggle value="descargas"  
                (click)="descargarListado()" 
                matTooltip="Descargar etiquetas"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">cloud_download</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="actualizar"
              (click)="clientesEtiquetasVisitasService.obtenerEtiquetas(filters.page ? filters.page : 1, filters);"
              matTooltip="Refrescar"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">sync</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="subMenu"  
              matTooltip="Acciones"
              mat-tooltip-panel-above
              [matMenuTriggerFor]="menu">
              <mat-icon class="kobra-icon">more_vert</mat-icon>
            </mat-button-toggle>
            <!-- <mat-button-toggle value="ayuda" 
              matTooltip="Ayuda"
              mat-tooltip-panel-above
              (click)="ayuda = !ayuda;">
              <mat-icon color="primary">help</mat-icon>
            </mat-button-toggle> -->
          </mat-button-toggle-group>
        </div>
    </div>
    
    <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['form']" queryParamsHandling="preserve">
            <mat-icon class="kobra-icon">done_all</mat-icon>
            <span>Asignación masiva</span>
        </button>
        <button mat-menu-item [disabled]="validarSeleccion(true)"
        (click)="openModalEstatus(true)">
             <mat-icon class="kobra-icon">check</mat-icon>
             <span>Activar</span>
         </button> 
        <button mat-menu-item [disabled]="validarSeleccion(false)"
        (click)="openModalEstatus(false)">
             <mat-icon class="kobra-delete-icon">do_not_disturb-on</mat-icon>
             <span>Inactivar</span>
         </button> 
    </mat-menu>
    
    <app-table class="table-margen"
      [classContainer]="'tabla-completa'"
      [loading]="clientesEtiquetasVisitasService.loader"
      [columns]="tabla.columns"
      [items]="clientesEtiquetasVisitasService.paginationInfo.pageData"
      [headers]="tabla.headers"
      (onSelectedRows)="onSelected($event)"
      [multiSelect]="tabla.multiSelect"
      (sortChange)="setSortField($event)"
    >
      <div t-header>
        <mat-card *ngIf="ayuda" class="car-margin">
          <mat-card-content>
            <p>
              En este catálogo podrás agregar las etiquetas que se pueden marcar en las visitas.
            </p>
          </mat-card-content>
        </mat-card>
      </div>
      
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="clientesEtiquetasVisitasService.pagination?.totalRows"
          [pageIndex]="clientesEtiquetasVisitasService.pagination?.page-1"
          [pageSize]="clientesEtiquetasVisitasService.pagination.pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
  </div>
  