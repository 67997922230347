import { Injectable } from '@angular/core';
import { ServicesGeneralService } from '../servicio-general/servicio-general.service';
import { environmentSelector } from '../../../../environments/environment.selector';
@Injectable({
  providedIn: 'root'
})
export class ServicesVisitasService extends ServicesGeneralService {
  protected override baseURL: string = environmentSelector().kobraServices.visitas;
  protected override propertie: string = 'visitas';

}