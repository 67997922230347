<router-outlet (activate)="activarHijo()" (deactivate)="desactivarHijo()"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
  <mat-tab-group>
    <mat-tab label="Periodos por facturar">
      <div style="margin-top: 10px;">
        <div class="button-container">
          <div class="button-toggle-group-container">
            <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
              <mat-button-toggle value="filtros" (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
                matTooltip="Filtrar" mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">filter_alt</mat-icon>
                <span *ngIf="totalFiltrosFacturar  >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltrosFacturar  }}</span>

              </mat-button-toggle>
              <mat-button-toggle value="actualizar" (click)="obtenerPeriodosXFacturar(filtros.page || 1)"
                matTooltip="Refrescar" mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">sync</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="descarga" matTooltip="Descargar listado" mat-tooltip-panel-above
                (click)="obtenerPeriodosXFacturarExcel()">
                <mat-icon class="kobra-icon">cloud_download</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="ayuda" matTooltip="Ayuda" mat-tooltip-panel-above (click)="ayuda = !ayuda;">
                <mat-icon color="primary">help</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>


      <app-table [loading]="CMFXLoader" [columns]="tablaMovimientos.columns" [items]="CMXFpagination.pageData"
        [headers]="tablaMovimientos.headers" [tableHeaders]="tablaMovimientos.tableHeaders"
        (sortChange)="setSortFieldXFacturar($event)" [multiSelect]="tablaMovimientos.multiSelect" (onCtrlClicLinkRow)="onCtrlClick($event)"
        (onLinkRow)="onClick($event)">

        <ng-container t-paginator>
          <div class="total">
            <strong>Total: {{totalPeriodosXFacturar | currency}}</strong>
          </div>
        </ng-container>

        <ng-container t-paginator>
          <mat-paginator showFirstLastButtons [length]="CMXFpagination?.totalRows" [pageIndex]="CMXFpagination?.page-1"
            [pageSize]="CMXFpageSize" (page)="CMXFonPage($event)" aria-label="Select page of periodic elements">
          </mat-paginator>

          <mat-card *ngIf="ayuda" class="cardAyuda">

            <mat-card-content style="text-align: left;">
              <p><mat-icon color="primary">help</mat-icon>
                El módulo "Facturaciones Agentes (Periodos por facturar)" es una herramienta diseñada específicamente para gestionar y visualizar las facturaciones pendientes relacionadas con los agentes. Este módulo se enfoca en mostrar un resumen consolidado de todos los movimientos financieros de cada agente, organizados por períodos de facturación, y proporciona detalles clave para la realización de los pagos correspondientes.

                En esta sección, los usuarios pueden acceder a la suma total de los movimientos realizados por cada agente, agrupados por el período de facturación correspondiente. Esto incluye todas las transacciones y actividades por las que el agente debe recibir un pago, lo que facilita una visión clara del monto total a pagar en cada período.
                
              </p>
              Acciones:
              <p>
                <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda permitiendo
                aplicar filtros basados en diversos criterios.
              </p>
              <p>
                <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los
                criterios de filtros aplicados.
              </p>
              <p>
                <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar
                la información para su análisis o uso fuera de línea.
              </p>
            </mat-card-content>
          </mat-card>

        </ng-container>
      </app-table>
    </mat-tab>

    <mat-tab label="Periodos facturados">
      <div style="margin-top: 10px;">
        <div class="button-container">
          <div class="button-toggle-group-container">
            <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
              <mat-button-toggle value="filtros"
                (click)="filtrosSideNavDos.toggle(); filtrosAbiertosDos = !filtrosAbiertosDos;" matTooltip="Filtrar"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">filter_alt</mat-icon>
                <span *ngIf="totalFiltrosFacturados  >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltrosFacturados  }}</span>

              </mat-button-toggle>
              <mat-button-toggle value="actualizar" (click)="obtenerPeriodosFacturados(filtros.page || 1)"
                matTooltip="Actualizar" mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">sync</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="descarga" matTooltip="Descargar listado" mat-tooltip-panel-above
                (click)="obtenerPeriodosFacturadosExcel()">
                <mat-icon class="kobra-icon">cloud_download</mat-icon>
              </mat-button-toggle>
              <mat-button-toggle value="ayuda" matTooltip="Ayuda" mat-tooltip-panel-above
                (click)="ayudaFactura = !ayudaFactura;">
                <mat-icon color="primary">help</mat-icon>
              </mat-button-toggle>
            </mat-button-toggle-group>
          </div>
        </div>
      </div>


      <app-table [loading]="CFLoader" [columns]="tablaFacturas.columns" [items]="CFpagination.pageData"
        [headers]="tablaFacturas.headers" [tableHeaders]="tablaFacturas.tableHeaders"
        (sortChange)="setSortFieldFacturados($event)" [multiSelect]="tablaFacturas.multiSelect" (onCtrlClicLinkRow)="onCtrlClick($event)"
        (onLinkRow)="onClick($event)">

        <ng-container t-paginator>
          <div class="total">
            <strong>Total: {{totalPeriodosFacturados | currency}}</strong>
          </div>
        </ng-container>
        <ng-container t-paginator>
          <mat-paginator showFirstLastButtons [length]="CFpagination?.totalRows" [pageIndex]="CFpagination?.page-1"
            [pageSize]="CFpageSize" (page)="CFonPage($event)" aria-label="Select page of periodic elements">
          </mat-paginator>


          <mat-card *ngIf="ayudaFactura" class="cardAyuda">

            <mat-card-content style="text-align: left;">
              <p><mat-icon color="primary">help</mat-icon>
                El módulo "Facturaciones Agentes (Periodos facturados)" es una herramienta diseñada específicamente
                para gestionar y visualizar las facturaciones ya facturadas relacionadas con los agentes. Este módulo se
                enfoca en mostrar un resumen consolidado de todos los movimientos financieros de cada agente,
                organizados por períodos de facturación, y proporciona detalles clave para la realización de los pagos
                correspondientes, los cuales ya fueron realizados.

                En esta sección, los usuarios pueden acceder a la suma total de los movimientos realizados por cada
                agente, agrupados por el período de facturación correspondiente. Esto incluye todas las transacciones y
                actividades de las que el agente debe recibió un pago, lo que facilita una visión clara del monto total
                a pagado en cada período. </p>
              Acciones:
              <p>
                <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda permitiendo
                aplicar filtros basados en diversos criterios.
              </p>
              <p>
                <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los
                criterios de filtros aplicados.
              </p>
              <p>
                <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar
                la información para su análisis o uso fuera de línea.
              </p>
            </mat-card-content>
          </mat-card>
        </ng-container>
      </app-table>
    </mat-tab>
  </mat-tab-group>
</div>


<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start"
  [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button matTooltip="Ocultar filtros" mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()">
      <mat-icon>cancel</mat-icon>
    </button>
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Generales
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search 
            class="id-card-field" 
            [label]="'Periodo de facturación'"
              [placeHolder]="'Busca por id o nombre'" 
              [value]="'idCobradorFacturacionPeriodo'" 
              [labelField]="'nombre'"
              [icon]="'search'" 
              [realizarBusqueda]="periodosSearch"
              [(ngModel)]="filtros.porFacturar.idCobradorFacturacionPeriodo"
              (ngModelChange)="contabilizadorDeFiltrosFacturar('generalesFacturar');"

              >
            </mat-select-search>

          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Agente
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search class="id-card-field" [label]="'Agente'" [placeHolder]="'Busca por id o nombre'"
              [value]="'idCobrador'" [labelField]="'nombre'" [icon]="'search'" [realizarBusqueda]="cobradoresSearch"
              [(ngModel)]="filtros.porFacturar.idCobrador" [minBusqueda]="1" (ngModelChange)="contabilizadorDeFiltrosFacturar('agentesFacturar');"              >
            </mat-select-search>

          </li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>


  <ol class="lista-filtros-buttons">
    <button class="boton-accion-front" style="width: 100%;" mat-stroked-button color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; openCMXFFilterSet();">
      <mat-icon>filter_alt</mat-icon>
      Filtrar
    </button>
    <button style="width: 100%; margin-top: 10px;" mat-stroked-button (click)="clearFilterCMXFFilter()">
      <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
      Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>

<mat-sidenav #filtrosSideNavDos fixedInViewport autosize class="sidenav-right" mode="side" position="start"
  [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button matTooltip="Ocultar filtros" mat-tooltip-panel-above
      (click)="filtrosSideNavDos.toggle()">
      <mat-icon>cancel</mat-icon>
    </button>
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="true">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Generales
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search 
            class="id-card-field" 
            [label]="'Periodo de facturación'"
            [placeHolder]="'Busca por id o nombre'" 
            [value]="'idCobradorFacturacionPeriodo'" 
            [labelField]="'nombre'"
            [icon]="'search'" 
            [realizarBusqueda]="periodosSearch"
            [(ngModel)]="filtros.facturados.idCobradorFacturacionPeriodo"
            (ngModelChange)="contabilizadorDeFiltrosFacturados('generalesFacturados');"
            >

            </mat-select-search>

          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Estatus</mat-label>
              <mat-select [(ngModel)]="filtros.facturados.idEstatus" placeholder="Seleccione..."
              (ngModelChange)="contabilizadorDeFiltrosFacturados('generalesFacturados');"
              >
                <ng-container *ngFor="let estatus of cobFacturasServ.estatusFacturaciones$ | async">
                  <mat-option [value]="estatus.idEstatus">{{estatus.nombre}}</mat-option>
                </ng-container>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filtros.facturados.idEstatus != null" 
                  (click)="filtros.facturados.idEstatus = null;$event.stopPropagation();contabilizadorDeFiltrosFacturados('generalesFacturados')">clear</mat-icon> 
              <mat-icon matSuffix>tag</mat-icon>
            </mat-form-field>
          
          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Agente
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search class="id-card-field" [label]="'Agente'" [placeHolder]="'Busca por id o nombre'"
              [value]="'idCobrador'" [labelField]="'nombre'" [icon]="'search'" [realizarBusqueda]="cobradoresSearch"
              [(ngModel)]="filtros.facturados.idCobrador" [minBusqueda]="1" (ngModelChange)="contabilizadorDeFiltrosFacturados('agentesFacturados');"
              >
            </mat-select-search>

          </li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>


  <ol class="lista-filtros-buttons">
    <button class="boton-accion-front" style="width: 100%;" mat-stroked-button color="primary"
      (click)="filtrosSideNavDos.toggle(); filtrosAbiertosDos=!filtrosAbiertosDos; openCFFilterSet();">
      <mat-icon>filter_alt</mat-icon>
      Filtrar
    </button>
    <button style="width: 100%; margin-top: 10px;" mat-stroked-button (click)="clearCFFilter()">
      <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
      Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>