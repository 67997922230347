<div class="app content tabla kobra ">
  <form [formGroup]="tarifaConceptosForm">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Editar Tarifas</mat-card-title>
      </mat-card-header>
      <mat-card-content class="kobra-card-content">
        <div class="kobra two columns">
          <div class="kobra column">
            <mat-form-field class="pago-gestor">
              <mat-label>Concepto:</mat-label>
              <input matInput textSanitizer formControlName="nombre" placeholder="Introduce el nombre">
              <mat-icon matSuffix>{{ isProducto ? 'inventory' : 'label' }}</mat-icon>
              <mat-error *ngIf="checkControl('nombre')">Este campo es obligatorio y debe tener menos de 40 caracteres.</mat-error>
            </mat-form-field>
          </div>
          <div class="kobra column">
            <mat-form-field class="pago-gestor">
              <mat-label>Precio:</mat-label>
              <input matInput textSanitizer
               formControlName="precioProducto" 
              placeholder="Introduce el costo del paquete" 
              type="number" 
              (input)="onInputLimite('precioProducto')"  
              (change)="compararCambios()">
              <mat-icon matSuffix>price_change</mat-icon>
              <span matTextPrefix>$&nbsp;</span>
              <mat-error *ngIf="checkControl('precioProducto')">El precio es obligatorio y debe ser un valor numérico.</mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- Tarifa por Cliente -->
        <mat-card>
          <mat-card-header>
            <mat-card-title>Tarifa por Cliente</mat-card-title>
          </mat-card-header>
          <mat-card-content class="kobra-card-content">
            <div class="kobra two columns">
              <div class="kobra column">
                <mat-select-search class="id-card-field" [label]="'Cliente'" [placeHolder]="'Selecciona un cliente'"
                  [value]="'idFinanciera'" [labelField]="'name'" [icon]="'account_balance'" [options]="lenders"
                  [(ngModel)]="idLender" (ngModelChange)="onChangeLender($event);"
                  [ngModelOptions]="{standalone: true}" [maxlength]="100">
                </mat-select-search>
              </div>
              <div class="kobra column">
                <mat-form-field>
                  <mat-label>Precio</mat-label>
                  <input matInput textSanitizer 
                  type="number" 
                  formControlName="precioConcepto" 
                  placeholder="0" 
                  (input)="onInputLimite('precioConcepto')" >
                  <mat-icon matSuffix>request_quote</mat-icon>
                  <span matTextPrefix>$&nbsp;</span>
                  <mat-error *ngIf="checkControl('precioConcepto')">El precio es obligatorio y debe ser numérico.</mat-error>
                </mat-form-field>
              </div>
            </div>

            <!-- Botones de Acción -->
            <div class="action-buttons" align="right">
              <button mat-stroked-button class="boton-accion-front" type="button" (click)="agregarConcepto()">
                <mat-icon>{{ modoEdicion ? 'edit' : 'add' }}</mat-icon> {{ modoEdicion ? 'Editar' : 'Agregar' }}
              </button>
              <button class="boton-cancelar" mat-flat-button type="button" *ngIf="editar" (click)="cancelarEdicion()"
                style="margin-left: 10px;">
                <mat-icon class="kobra-icon">close</mat-icon> Cancelar Edición
              </button>
            </div>

            <app-table [loading]="cargandoLista" [columns]="tablatarifasCobradores.columnas" [items]="tarifasAgregadas"
              [headers]="tablatarifasCobradores.headers" [tableHeaders]="tablatarifasCobradores.tableHeaders"
              [ignoreSortChange]="true" [multiSelect]="tablatarifasCobradores.multiSelect"
              (onLinkRow)="onClick($event)" [classContainer]="'table-container-tarifas'">
            </app-table>

          </mat-card-content>
        </mat-card>

      </mat-card-content>
      
        <mat-card-actions align="end" style="gap:10px">
          <button [disabled]="desabilitaButon" class="boton-cancelar" type="button" mat-flat-button routerLink="../">
            <mat-icon class="kobra-icon">close</mat-icon>Cancelar
          </button>
          <button mat-stroked-button [disabled]="!hayCambios" type="button"
            [ngClass]="{'boton-guardar': hayCambios, 'buton-disabled' : !hayCambios}" (click)="onSubmit()">
            <mat-icon>save</mat-icon>Guardar
          </button>
        </mat-card-actions>

    </mat-card>
  </form>
</div>
