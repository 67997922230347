<div>
  <div>
    <mat-checkbox *ngIf="checkFiltrado" [(ngModel)]="pregunta.filtrarPregunta" (ngModelChange)="onFilterQuestion()"></mat-checkbox>
  </div>

  <div class="negrita" style="margin-top: 10px !important;">
    <mat-label>
      {{pregunta.nombrePregunta}}
      <ng-container *ngIf="respuestas[0].requerida">
        <span style="color: red;">*</span>
      </ng-container>
    </mat-label>
  </div>
  <form [formGroup]="dynamicForm">
    <ng-container *ngFor="let respuesta of respuestas" style="margin-left: 20px;">
      <ng-container [ngSwitch]="respuesta.idTipoRespuesta">
          <ng-container *ngSwitchCase="1">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || 'Escribe una respuesta'}}</mat-label>
              <input matInput textSanitizer [maxlength]="respuesta.maxCaracteres ? respuesta.maxcaracteres : 2000" [formControlName]="respuesta.idChecklistRespuesta.toString()" type="text" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)">
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="2">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || 'Escribe una respuesta'}}</mat-label>
              <textarea matInput textSanitizer [maxlength]="respuesta.maxCaracteres ? respuesta.maxcaracteres : 2000" [formControlName]="respuesta.idChecklistRespuesta.toString()" rows="4" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)"></textarea>
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="3">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || 'Escribe una respuesta'}}</mat-label>
              <input matInput decimalValidator [decimalPlaces]="0" type="number"  [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)">
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="4">
            <mat-form-field [ngClass]="{'margen-Botton': dynamicForm.get(respuesta.idChecklistRespuesta.toString())?.hasError('pattern')}">
              <mat-label>{{respuesta.nombreRespuesta || 'Escribe una respuesta'}}</mat-label>
              <input matInput
                     textSanitizer
                     decimalValidator
                     type="number"
                     [decimalPlaces]="6"
                     [formControlName]="respuesta.idChecklistRespuesta.toString()"
                     [(ngModel)]="respuesta.valor"
                     (ngModelChange)="onChangeValue(respuesta, pregunta)">
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
              <mat-error *ngIf="dynamicForm.get(respuesta.idChecklistRespuesta.toString())?.hasError('pattern')">
                El valor debe ser un número decimal válido con hasta 6 decimales.
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="5">
              <mat-label>{{respuesta.nombreRespuesta || ''}}</mat-label>
              <mat-date-picker-formater
                [label]="respuesta.nombreRespuesta"
                [placeHolder]="'Capture la fecha'"
                [(ngModel)]="respuesta.valor"
                [formControlName]="respuesta.idChecklistRespuesta.toString()"
                (ngModelChange)="onChangeValue(respuesta, pregunta)"
                [limpiar]="false"
              />
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
          </ng-container>

          <ng-container *ngSwitchCase="6">
            <mat-form-field>
              <mat-checkbox class="kobra-checkbox" [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)">
                {{respuesta.nombreRespuesta}}
              </mat-checkbox>
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>.
              </mat-error>
            </mat-form-field>

          </ng-container>

          <ng-container *ngSwitchCase="7">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || ''}}</mat-label>
              <mat-radio-group  [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)">
                <mat-radio-button class="kobra-radio" *ngFor="let opt of respuesta.options" [value]="opt.id">
                  {{opt.label}}
                </mat-radio-button>
              </mat-radio-group>
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="8">
            <mat-label>{{respuesta.nombreRespuesta || ''}}</mat-label>
            <input hidden matInput textSanitizer [formControlName]="respuesta.idChecklistRespuesta.toString()" type="text">
            <div class="kobra two columns" style="width: 250px;">
              <div class="kobra column">
                <div class="kobra two columns">
                  <div class="kobra column">
                    <mat-label>No</mat-label>
                  </div>
                  <div class="kobra column" style="margin-left: -20px;">
                    <mat-slide-toggle class="kobra-slide-toggle" [ngModelOptions]="{standalone: true}" [(ngModel)]="respuesta.switchNo" (ngModelChange)="onSwitchValueChange(respuesta, pregunta, 'No')"></mat-slide-toggle>
                  </div>
                </div>
              </div>
              <div class="kobra column">
                <div class="kobra two columns">
                  <div class="kobra column">
                    <mat-label>Sí</mat-label>
                  </div>
                  <div class="kobra column" style="margin-left: -20px;">
                    <mat-slide-toggle class="kobra-slide-toggle" [ngModelOptions]="{standalone: true}" [(ngModel)]="respuesta.switchSi" (ngModelChange)="onSwitchValueChange(respuesta, pregunta, 'Si')"></mat-slide-toggle>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngSwitchCase="9">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || 'Selecione una respuesta'}}</mat-label>
              <mat-select  [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor" (ngModelChange)="onChangeValue(respuesta, pregunta)">
                <!-- <mat-option [value]="null" disabled >Selecciona una opción</mat-option> -->
                <mat-option *ngFor="let opt of respuesta.options" [value]="opt.id">{{opt.label}}</mat-option>
              </mat-select>
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="10">
            <mat-form-field>
              <mat-label>{{respuesta.nombreRespuesta || ''}}</mat-label>
              <input matInput textSanitizer
                [formControlName]="respuesta.idChecklistRespuesta.toString()"
                [(ngModel)]="respuesta.valor"
                [ngxTimepicker]="picker"
                placeholder="Selecciona una hora"
                readonly="true"
                (ngModelChange)="onChangeValue(respuesta, pregunta)">
                <mat-icon matSuffix>access_time</mat-icon>
              <ngx-material-timepicker #picker></ngx-material-timepicker>
              <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                Esta respuesta es <strong>requerida</strong>
              </mat-error>
            </mat-form-field>
          </ng-container>

          <ng-container *ngSwitchCase="11">
            <div class="foto" *ngIf="respuesta.nombreRespuesta">
              <span>{{respuesta.nombreRespuesta || ""}}</span>
            </div>
            <div *ngIf="respuesta.valor" style="text-align: center !important;">
              <foto-zoom [height]="200"  [mostrarBotonDescargar]="false"  *ngIf="respuesta.valor" [foto]="obtenerFotoChecList(respuesta.valor)" [posicion]="1"  (click)="cargarFotoCompleta(obtenerFotoChecList(respuesta.valor))"></foto-zoom>
              <button  mat-stroked-button class="boton-accion-eliminar" (click)="eliminarFotoCheckLists(respuesta.valor)">
                <mat-icon >delete_forever</mat-icon>Eliminar
              </button>
            </div>
            <app-upload-file *ngIf="!respuesta.valor" [allowMultiple]="false" [mostrarBotonEliminar]="false" [acceptedTypes]="acceptFiles" (filesSelectedEmitter)="subirFoto($event, respuesta, pregunta)"></app-upload-file>
          </ng-container>

          <ng-container *ngSwitchCase="12">
            <input matInput hidden textSanitizer [formControlName]="respuesta.idChecklistRespuesta.toString()" type="text">
            <div>
              <span>{{respuesta.nombreRespuesta || ""}}</span>
            </div>
            <div style="text-align: center !important;">
              <mat-card>
                <mat-card-content style="max-height: 380px; overflow: auto;">
                  <div class="button-container">
                    <div class="button-toggle-group-container">
                      <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
                        <mat-button-toggle value="subMenu"
                          matTooltip="Acciones"
                          mat-tooltip-panel-above
                          [matMenuTriggerFor]="menu">
                          <mat-icon class="kobra-icon">more_vert</mat-icon>
                        </mat-button-toggle>
                      </mat-button-toggle-group>
                    </div>
                  </div>

                  <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="agregarRegistroTabla(respuesta)">
                        <mat-icon class="kobra-icon">add</mat-icon>
                        <span>Nuevo</span>
                    </button>
                    <button mat-menu-item [disabled]="itemsSelected && itemsSelected.length !== 1" (click)="editarRegistroTabla(itemsSelected[0], respuesta)">
                      <mat-icon class="kobra-icon">edit</mat-icon>
                      <span>Editar</span>
                    </button>
                    <button mat-menu-item [disabled]="itemsSelected && itemsSelected.length !== 1" (click)="borrarRegistro(itemsSelected[0], respuesta)">
                      <mat-icon class="kobra-delete-icon">
                        do_not_disturb_on
                      </mat-icon>
                      <span>Eliminar</span>
                    </button>
                  </mat-menu>

                  <app-table
                    *ngIf="respuesta.valor"
                    [columns]="respuesta.columnas"
                    [items]="respuesta.valor"
                    [headers]="false"
                    [tableHeaders]="respuesta.tableHeaders"
                    [ignoreSortChange]="false"
                    [multiSelect]="false"
                    (onDoubleClick)="editarRegistroTabla(itemsSelected[0], respuesta)"
                    (onSelectedRows)="onSelected($event)"
                    >
                  </app-table>
                </mat-card-content>
              </mat-card>
            </div>
            <app-upload-file *ngIf="!respuesta.valor" [allowMultiple]="false" [mostrarBotonEliminar]="false" [acceptedTypes]="acceptFiles" (filesSelectedEmitter)="subirFoto($event, respuesta, pregunta)"></app-upload-file>
          </ng-container>
      </ng-container>
    </ng-container>
  </form>

</div>

