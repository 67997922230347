import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { TarifaConceptosService } from 'src/app/tarifa-conceptos/tarifa-conceptos.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { take } from 'rxjs/operators';
import { ProductosSaldosService } from '../productos-saldos.service';
import { Lender } from "../../../lender/lender.model";
import { LenderService } from "../../../lender/lender.service";
import { Producto } from "./producto.model";


@Component({
  selector: 'app-editar-tarifa',
  templateUrl: './editar-tarifa.component.html',
  styleUrls: ['./editar-tarifa.component.css'],
  providers: [TarifaConceptosService, LenderService]
})
export class TarifaProductosComponent implements OnInit {
  public hayCambios: boolean = false; // Nueva variable para controlar si hay cambios
  public estadoOriginal: { precioProducto: number, tarifasAgregadas: any[] } = {
    precioProducto: 0,
    tarifasAgregadas: []
  };
  public loaderText: string;
  public tarifaConceptosForm: FormGroup;
  public desabilitaButon = false;
  public hijosActivos: boolean = false;
  public formTarifas: FormGroup;
  public lenders: Lender[];
  public idLender: number = null;
  public acuerdo: boolean = false;
  public selectedFinanciera: string;
  public loaderAdd: string;
  public globalIdFInanciera: number;
  public editarPrecio: number;
  public editarNombreFinanciera: string;
  public editar: boolean = false;
  public producto: Producto;
  public totalRegistros: number;
  public modoEdicion: boolean = false;
  public cargandoLista = true;
  public tarifasAgregadas: any[] = [];
  public isProducto: boolean = true; // Diferenciar entre productos y etiquetas

  tablatarifasCobradores: any = {
    headers: false,
    tableHeaders: false,
    multiSelect: false,
    columnas: [
      { name: 'Cliente', key: 'financiera', hide: false },
      { name: 'Concepto', key: 'nombre', hide: false },
      { name: 'Precio', key: 'precio', hide: false },
      {
        name: ' ', key: 'eliminar', hide: false,
        icono: 'delete_forever', tooltip: "Eliminar", type: 'icono'
      }
    ]
  };

  constructor(
    private acRoute: ActivatedRoute,
    private fb: FormBuilder,
    private tarifaConceptosService: TarifaConceptosService,
    private app: ApplicationService,
    private productosSaldosService: ProductosSaldosService,
    private router: Router,
    private lenderService: LenderService
  ) {
    this.tarifaConceptosForm = this.fb.group({
      nombre: ['', [Validators.required, Validators.maxLength(40)]],
      precioProducto: ['',[Validators.required, Validators.max(999999)]],
      precioConcepto: ['', [Validators.required, Validators.max(999999)]],
      idTarifa: '', // Añadimos el campo idTarifa
      financiera: '',
      items: this.fb.array([])
    });
  }

  ngOnInit() {
    this.init();
    this.estadoOriginal.precioProducto = this.tarifaConceptosForm.controls['precioProducto'].value;
  
  }

  init() {
    const tipoConcepto = this.acRoute.snapshot.queryParamMap.get('tipoConcepto');
    this.isProducto = tipoConcepto === 'Producto'; // Verificar si es producto o etiqueta visita

    this.producto = new Producto();
    this.producto.idConcepto = +this.acRoute.snapshot.queryParamMap.get('idConcepto');
    this.producto.idTarifa = +this.acRoute.snapshot.queryParamMap.get('idTarifa'); // Captura el idTarifa desde la URL
    this.producto.nombre = this.acRoute.snapshot.queryParamMap.get('nombre');
    this.producto.precio = +this.acRoute.snapshot.queryParamMap.get('precio');

    // Asignamos valores iniciales al formulario
    this.tarifaConceptosForm.controls['nombre'].setValue(this.producto.nombre);
    this.tarifaConceptosForm.controls['nombre'].disable();
    this.tarifaConceptosForm.controls['precioProducto'].setValue(this.producto.precio);
    this.tarifaConceptosForm.controls['precioProducto'].enable();
    this.tarifaConceptosForm.controls['idTarifa'].setValue(this.producto.idTarifa); // Añadimos idTarifa al form
    this.tarifaConceptosForm.controls['precioConcepto'].setValue(0);

    this.loadLenders();
    this.cargarDatosEditar();
  }

  compararCambios() {
    const precioProductoActual = this.tarifaConceptosForm.controls['precioProducto'].value;
    const tarifasAgregadasActuales = [...this.tarifasAgregadas];
  
    // Verificar si se eliminó o agregó un elemento en la lista
    if (tarifasAgregadasActuales.length !== this.estadoOriginal.tarifasAgregadas.length) {
      this.hayCambios = true;
      return;
    }
  
    // Comparar el precio del producto
    if (precioProductoActual !== this.estadoOriginal.precioProducto) {
      this.hayCambios = true;
      return;
    }
  
    // Comparar cada tarifa por cliente
    for (let i = 0; i < tarifasAgregadasActuales.length; i++) {
      const originalTarifa = this.estadoOriginal.tarifasAgregadas[i];
      const actualTarifa = tarifasAgregadasActuales[i];
  
      // Comparar si el cliente o el precio han cambiado
      if (!originalTarifa || actualTarifa.idFinanciera !== originalTarifa.idFinanciera || actualTarifa.precio !== originalTarifa.precio) {
        this.hayCambios = true;
        return;
      }
    }
  
    // Si no hay cambios, lo dejamos en false
    this.hayCambios = false;
  }
  

  // Cargar las financieras o gestores dependiendo del tipo de concepto
  loadLenders() {
    this.loaderText = "Cargando clientes...";
    this.lenderService.getAll('all').subscribe(
      lenders => {
        this.lenders = lenders;
        this.loaderText = null;
      },
      err => this.app.showError(err)
    );
  }

  // Cargar los datos de tarifas para editar
  cargarDatosEditar() {
    if (this.producto.idConcepto > 0) {
      this.loaderText = 'Cargando información...';
      this.tarifaConceptosService.obtenerPaqueteVisitasPrecios(this.producto.idConcepto).subscribe(tarifasCobradores => {
        this.tarifasAgregadas = tarifasCobradores;
        this.producto.tarifasCobradores = tarifasCobradores;
        this.loaderText = null;
        this.totalRegistros = this.tarifasAgregadas.length;
        this.cargandoLista = false;
        this.estadoOriginal.tarifasAgregadas = [...this.tarifasAgregadas]; // Clonamos para tener la referencia original

      },
        error => {
          this.app.showError(error);
          this.loaderText = null;
        });
    }
  }

    // Método para monitorear cambios en el formulario
    monitorChanges() {
      this.tarifaConceptosForm.valueChanges.subscribe(() => {
        const precioConcepto = this.tarifaConceptosForm.controls['precioConcepto'].value;
  
        // Si cambia el precioConcepto, actualizamos la variable a true
        if (precioConcepto !== this.producto.precio) {
          this.hayCambios = true;
        }
        this.compararCambios();
      });
    }

    agregarConcepto() {
      const msg = this.validarConcepto();
    
      if (msg) {
        return this.app.showSnackbar('¡Aviso!', msg, 3000, 'warning');
      }
    
      const idxFinancieras = this.tarifasAgregadas.findIndex(x => x.idFinanciera === +this.idLender);
    
      if (idxFinancieras >= 0) {
        // Actualiza el concepto existente
        const financieraExistente = this.tarifasAgregadas[idxFinancieras];
        financieraExistente.precio = +this.tarifaConceptosForm.controls['precioConcepto'].value;
      } else {
        // Agrega un nuevo concepto
        const nuevoConcepto = {
          idConcepto: this.producto.idConcepto,
          nombre: this.producto.nombre,
          precio: +this.tarifaConceptosForm.controls['precioConcepto'].value,
          idFinanciera: +this.idLender,
          financiera: this.selectedFinanciera,
        };
    
        this.tarifasAgregadas = [...this.tarifasAgregadas, nuevoConcepto];
      }
    
      this.totalRegistros = this.tarifasAgregadas.length;
      this.hayCambios = true; // Marca que hubo un cambio
      this.limpiar();
    }
    

  validarConcepto(): string | null {
    const idLender = this.idLender; // O recuperarlo del formulario si lo agregas ahí
    const precioConcepto = this.tarifaConceptosForm.controls['precioConcepto'].value;
  
    // Asegúrate de que idLender tenga un valor numérico válido
    if (!idLender || isNaN(idLender)) {
      return 'Selecciona un cliente válido.'; // Mensaje si idLender está vacío o no es numérico
    }
  
    if (!precioConcepto) {
      return 'Favor de agregar un precio.'; // Mensaje de error si precioConcepto está vacío
    }
  
    return null; // Retorna null si todas las validaciones son correctas
  }

  limpiar(): void {
    // Restablece todo el formulario
    this.tarifaConceptosForm.reset({
      nombre: this.producto.nombre, // Mantén el nombre, ya que no se puede modificar
      precioProducto: this.producto.precio, // Restablece el precioProducto a su valor original
      precioConcepto: 0, // Restablece el precioConcepto
      idTarifa: this.producto.idTarifa // Mantén el idTarifa
    });
  
    // Reinicia los valores adicionales fuera del formulario
    this.idLender = null;
    this.selectedFinanciera = '';
    this.modoEdicion = false;
  }
  

  borrarConcepto(registroSeleccionado: any) {
    this.tarifasAgregadas = this.tarifasAgregadas.filter((item) => item.idFinanciera !== registroSeleccionado.idFinanciera);

    this.compararCambios(); // Llamamos a la función para verificar si hay cambios
  }

  onSubmit() {

    if (!this.hayCambios) {
      return this.app.showSnackbar('No hay cambios para guardar.', '', 3000, 'info');
    }

    
    // Deshabilita el botón para evitar múltiples envíos
    this.desabilitaButon = true;
    this.loaderText = 'Guardando información...';

    // Prepara los datos del producto
    this.producto.precio = this.tarifaConceptosForm.controls['precioProducto'].value;
    this.producto.nombre = this.tarifaConceptosForm.controls['nombre'].value;

    // Prepara los datos adicionales (por ejemplo, tarifas agregadas)
    this.producto.tarifasCobradores = this.tarifasAgregadas.map((tarifa) => ({
      idConcepto: tarifa.idConcepto,
      nombre: tarifa.nombre,
      cargo: tarifa.cargo,
      precio: tarifa.precio,
      financiera: tarifa.financiera,
      idFinanciera: tarifa.idFinanciera,
    }));

    // Llama al método que guarda el producto con las tarifas
    this.productosSaldosService.editarProducto(this.producto.idConcepto, this.producto)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.desabilitaButon = false;
        if (res.success) {
          this.app.showSnackbar('¡Aviso!', 'Información guardada correctamente.', 3000, 'success');
          this.router.navigate([`catalogos/tarifas-agentes/lista-tarifa`]);
          this.hayCambios = false; // Restablecemos la variable después de guardar

        } else {
          this.app.showSnackbar('¡Aviso!', 'Ocurrió un error al guardar la información.', 3000, 'error');
        }
      }, (error: any) => {
        this.desabilitaButon = false;
        this.loaderText = null;
        this.app.showError(error);
      });
  }


  editarConcepto(row) {
    this.editar = true;
    this.tarifaConceptosForm.controls['precioConcepto'].setValue(+row.precio);
    this.idLender = row.idFinanciera;
    this.selectedFinanciera = row.financiera;
    this.modoEdicion = true; // Activar modo edición para el cliente seleccionado
  }
  

  cancelarEdicion() {
    this.editar = false;
    this.limpiar();
  }

  checkControl(control: string) {
    return this.tarifaConceptosForm.controls[control].invalid && this.tarifaConceptosForm.controls[control].touched;
  }

  onChangeLender(event: any) {
    if (!event) {
      // Si no hay un cliente seleccionado, volvemos a modo agregar
      this.idLender = null;
      this.modoEdicion = false; // Restablecemos el modo de edición
      return;
    }
  
    // Si hay un cliente seleccionado, obtenemos su información
    const index: number = this.lenders.findIndex((x: any) => x.idFinanciera == this.idLender);
    
    if (index === -1) {
      this.selectedFinanciera = ''; // Si no se encuentra, limpiamos la selección
      return;
    }
  
    this.selectedFinanciera = this.lenders[index].nombre;
  
    // Si el cliente ya está en la tabla de tarifas agregadas, activamos modo edición
    const indexEnTablatarifasCobradores = this.tarifasAgregadas.findIndex(item => item.idFinanciera === +this.idLender);
    this.modoEdicion = indexEnTablatarifasCobradores !== -1;
  }
  
  onClick({ row, columnPressed }) {
    if (columnPressed == 'eliminar') {
      this.borrarConcepto(row);
    }
  }
  
  public onInputLimite(controlName: string): void {
    const control = this.tarifaConceptosForm.get(controlName); // Obtén el control usando el nombre del control
    
    if (!control.value) {
      control.setValue(null);
      return;
    }
  
    const value = parseFloat(control.value);
    const minValue = 1.00;
    const maxValue = 99999.99;
    const regex = /^\d+(\.\d{1,2})?$/;
  
     // Evita valores negativos
     if (value < 0) {
      setTimeout(() => {
        control.setValue(minValue); // Establece el valor mínimo permitido (1.00) si el valor es negativo
      }, 10);
      return; // Salimos de la función si el valor es negativo
    }
    
    if (value > maxValue) {
      setTimeout(() => {
        control.setValue(maxValue); // Establece el valor máximo permitido
      }, 10);
    } else if (value == null || value < minValue || isNaN(value)) {
      setTimeout(() => {
        control.setValue(minValue); // Establece el valor mínimo permitido
      }, 10);
    }
  
    if (!regex.test(value + "")) {
      const newValue = parseFloat(parseFloat(value + "").toFixed(2));
      if (!isNaN(newValue)) {
        setTimeout(() => {
          control.setValue(newValue); // Redondea a 2 decimales si no coincide con el regex
        }, 10);
      }
    }
  }
  
  
}
