import { Injectable } from '@angular/core';
import { ServicesGeneralService } from '../servicio-general/servicio-general.service';
import { environmentSelector } from '../../../../environments/environment.selector';


@Injectable()
export class MobileKobraService extends ServicesGeneralService {
  public override baseURL: string = environmentSelector().kobraServices.mobile;
  protected override propertie: string = 'mobile';

}
