import { Injectable } from '@angular/core';
import { ServicesGeneralService } from '../servicio-general/servicio-general.service';
import { environmentSelector } from '../../../../environments/environment.selector';


@Injectable()
export class ClientService extends ServicesGeneralService {
  public override baseURL: string = environmentSelector().kobraServices.serviciosClientes;
  protected override propertie: string = 'serviciosClientes';

}
