import { Injectable, OnDestroy } from '@angular/core';
import io from 'socket.io-client';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ApplicationService } from '../application/shared/application.service';
import { BehaviorSubject } from 'rxjs';
import { NotificationTypes, UriTypes } from './notification-types';
import { NotificationInterface } from './notification-interface';
import { environmentSelector } from 'src/environments/environment.selector';
import moment from 'moment';
import { HttpClient } from '@angular/common/http';
import { SnackbarService } from '../shared/components/snackbar/snackbar.service';
import { ServicesGeneralService } from '../core/http/servicio-general/servicio-general.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationService extends ServicesGeneralService implements OnDestroy {
    NotificationTypes = NotificationTypes;
    UriTypes = UriTypes;
    socket: any;
    private notificationsSubject = new BehaviorSubject<NotificationInterface[]>(this.getNotifications());
    notifications$ = this.notificationsSubject.asObservable();
    private environment = environmentSelector();
    paisSeleccionado: { abreviacion: any; };
    abreviacion: string;
    private socketInitialized = false;
    protected override baseURL: string = environmentSelector().kobraServices.notifications + environmentSelector().kobraServices.notificationsUri;
    public override reloadBaseUrl(archivo:string = ""){
        this.baseURL = environmentSelector().kobraServices.notifications + environmentSelector().kobraServices.notificationsUri;
    }

    constructor(
        private app: ApplicationService,
        private localStorageService: LocalStorageService,
        private http: HttpClient,
        private snackbarService: SnackbarService,
    ) {
        super(http);
        this.initSocket();
        this.subscribeToLocalStorageChanges();
        
    }

    subscribeToLocalStorageChanges() {
        window.addEventListener('storage', (event) => {
            if (event.key === 'notifications') {
                const updatedNotifications = JSON.parse(event.newValue || '[]');
                this.notificationsSubject.next(updatedNotifications);
            }
        });
    }

    initSocket() {
        this.paisSeleccionado = JSON.parse(localStorage.getItem('paisSeleccionado'));
        this.abreviacion = this.paisSeleccionado?.abreviacion;

        const idUsuario = this.localStorageService.getSessionData()?.idUsuario;
        if (idUsuario && this.abreviacion) {
            this.socket = io(this.environment.kobraServices.notifications, {
                path: this.environment.kobraServices.notificationsUri + "/socket.io",
                query: { idUsuario: idUsuario + this.abreviacion },
                reconnection: true
            });
            this.setupSocketListeners();
            this.loadNotifications(0);

        }

    }

    setupSocketListeners() {
        this.socket.on('disconnect', () => {});

        this.socket.on('notificacion', (pData: any) => {

            const { mensaje, tipoNotificacion, uri, extraData, banLeida, idNotification } = pData;
            const date = moment().format('DD/MM/YYYY hh:mmA');

            const notification: NotificationInterface = {
                tipoNotificacion: tipoNotificacion,
                mensaje: mensaje,
                uri: uri,
                date: date,
                extraData: extraData || {},
                banLeida: banLeida,
                idNotification: idNotification
            };

            this.addNotification(notification);

            this.snackbarService.newToast({
                data: notification.mensaje,
                duration: 3000,
                position: { horizontal: 'right', vertical: 'bottom' },
                type: 'success'
            });


        });
    }

    addNotification(pNotification: NotificationInterface): void {
        const notifications = this.getNotifications();

        // Verificar si la notificación ya existe
        const exists = notifications.some(n => n.idNotification === pNotification.idNotification);
        if (exists) {
            return; // No agregar la notificación si ya existe
        }

        const uriBase = UriTypes[pNotification.uri ?? 0];
        const tipoNotificacion = NotificationTypes[pNotification.tipoNotificacion ?? 0];

        const newNotification: NotificationInterface = {
            ...pNotification,
            uri: uriBase,
            tipoNotificacion: tipoNotificacion
        };

        notifications.unshift(newNotification);
        localStorage.setItem('notifications', JSON.stringify(notifications));
        this.notificationsSubject.next(notifications);
    }


    getNotifications(): NotificationInterface[] {
        return JSON.parse(localStorage.getItem('notifications') || '[]');
    }

    loadNotifications(offset: number) {
        const idUsuario = this.localStorageService.getSessionData()?.idUsuario;
        if (idUsuario) {
            this.get<{message:NotificationInterface[]}>(
                `/notifications?idUsuario=${idUsuario + this.abreviacion}&offset=${offset}&limit=50`
            ).subscribe(response => {
                if (response.success && response.data.message.length) {
                    const newNotifications = response.data.message.map(data => this.mapDataToNotification(data));
                    if (offset === 0) { // Si es la primera carga
                        this.notificationsSubject.next(newNotifications);
                        localStorage.setItem('notifications', JSON.stringify(newNotifications));
                    } else { // Cargas subsecuentes, agregamos a la lista existente
                        const allNotifications = [...this.notificationsSubject.value, ...newNotifications];
                        this.notificationsSubject.next(allNotifications);
                        localStorage.setItem('notifications', JSON.stringify(allNotifications));
                    }
                }
            }, error => {
                console.error('Error loading notifications:', error);
            });
        }
    }

    mapDataToNotification(pData: any): NotificationInterface {
        return {
            tipoNotificacion: NotificationTypes[pData.tipoNotificacion] || 'ADVICE', // Usa el tipo desde NotificationTypes o un valor predeterminado
            mensaje: pData.txtMensaje,
            uri: UriTypes[pData.uri] || '/', // Usa el URI desde UriTypes o un valor predeterminado
            date: moment(pData.fecRegistro).format('DD/MM/YYYY hh:mmA'), // Formatea la fecha según lo necesites
            extraData: pData.extraData || {},
            banLeida: pData.banLeida,
            idNotification: pData._id
        };
    }

    markNotificationAsRead(pIdNotification: string): void {
        this.patch(`/notifications/${pIdNotification}`, '')
            .subscribe({
                next: (res) => {

                    this.updateLocalNotification(pIdNotification);
                },
                error: (error) => {
                    console.error('Error marking notification as read:', error);
                }
            });
    }

    updateLocalNotification(pIdNotification: string): void {
        const notifications = this.getNotifications();
        const index = notifications.findIndex(n => n.idNotification === pIdNotification);
        if (index !== -1) {
            notifications[index].banLeida = true;
            localStorage.setItem('notifications', JSON.stringify(notifications));
            this.notificationsSubject.next(notifications);
        }
    }


    clearNotifications(): void {
        this.notificationsSubject.next([]);
        this.markAllNotificationsAsRead();
    }

    markAllNotificationsAsRead(): void {
        const notifications = this.getNotifications();
        const unreadNotifications = notifications.filter(n => !n.banLeida);

        unreadNotifications.forEach(notification => {
            this.markNotificationAsRead(notification.idNotification);
        });
    }

    ngOnDestroy() {
        if (this.socket) {
            this.socket.disconnect();
        }
    }
}
