import { Component, Input, QueryList, ViewChildren } from '@angular/core';

import { ICampoExtraAgente } from '@agent/interfaces/campo-extra-agentes.interface';
import { FieldComponent } from 'src/app/shared/components/form/field/field.component';
import { TipoDato } from 'src/app/shared/components/enums/enumeradores.enum';

declare let $: any;

@Component({
  selector: 'app-campos-extras-agentes-form',
  templateUrl: './campos-extras-agentes-form.component.html',
  styleUrls: ['./campos-extras-agentes-form.component.css']
})
export class CamposExtrasAgentesFormComponent {
  @Input() camposExtrasAgentes: ICampoExtraAgente[];
  @Input() colsXl: number;
  @Input() colsMd: number;
  @Input() colsSm: number;
  @Input() rowHeight: string;
  @ViewChildren(FieldComponent) hijos!: QueryList<FieldComponent>;
  public tipoDato = TipoDato;

  constructor(
  ) {
  }

  /**
   * Valida los elementos para devolver si el formulario de los campos extras es válido.
   * @Auth Uriel Yair Gámez Rosales
   */
  validaElementos() {
    let esFormularioCompletoValido: boolean = true;
    this.hijos.forEach((hijo) => {
      hijo.activarValidacion();
      if (!hijo.esValido()) {
        esFormularioCompletoValido = false;
      }
    });

    return esFormularioCompletoValido;
  }
}
