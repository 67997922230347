import { respuesta } from './../credit-request/checklist-dinamico.model';
import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Account } from '../account/account.model';
import { ClientService } from "../client/client.service";
import { Lender } from "../lender/lender.model";
import { LenderService } from "../lender/lender.service";
import { VisitService } from "../visit/visit.service";
import { GeneralChecklistService } from "../general-checklist/general-checklist.service";
import { FormValidation } from "../shared/form-validation/form-validation.model";
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Tag } from '@lender/tag.model';
import { PaymentMethod } from '@lender/payment-method.model';
import { take, map, takeUntil, filter } from 'rxjs/operators';
import { StorageKobraService } from '../core/http/storage-kobra/storage-kobra.service';
import { AgentService } from '@agent/agent.service';
import { Subject } from 'rxjs/internal/Subject';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AcreditadoDialog } from '../visit/acreditado-dialog/acreditado-dialog.component';
import { Router } from '@angular/router';
import { MatStepper } from '@angular/material/stepper';
import { MatSelectSearch } from "../shared/components/material-responsive/mat-select-search.component";
import { UploadFileComponent } from '../shared/components/upload-file-v2/upload-file.component';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';
import { FotoModalComponent } from '../componentes/fotos/modal/foto-modal.component';
import { environmentSelector } from '../../environments/environment.selector';
import { AccountService } from "../account/account.service";
import { Subscription } from "rxjs";
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';


declare var $: any;

@Component({
    selector: 'app-visit-create',
    templateUrl: './visit-create.component.html',
    styleUrls: ['./visit-create.component.css'],
    providers: [
        ClientService,
        LenderService,
        VisitService,
        GeneralChecklistService,
        StorageKobraService,
        AgentService,
        AccountService
    ]
})
export class VisitCreateComponent implements OnInit, OnDestroy {
    public form: FormGroup;
    public checklistForm: FormGroup;
    public lenderID: number;
    files: File[] = [];
    filesPdf: File[] = [];
    listado:number;
    listadoContrato:number;
    selected = new FormControl(0);
    selectedContratos = new FormControl(0);

    public checklistTypeValidation: FormValidation;
    public lenders: Lender[];
    public tags: Tag[];
    public paymentMethods: PaymentMethod[];
    public today: Date = new Date();
    public paymentPromiseDate: Date;
    public revisitDate: Date;
    public idAcreditado: number;
    public idVisita: number;
    public folioVisita: string
    public idFileAsset: number;
    public idGeneralChecklist: number;
    public idFinishVisit: number;
    public idChecklist: number;
    idVersionCheckList : number;
    public idProduct: number = 0;
    public firstStep: boolean = true;
    public secondStep: boolean = false;
    public thirdStep: boolean = false;
    public showSuccessVisitPhoto: boolean = false;
    public showFinishVisit: boolean = false;
    public nombreProducto: string;
    public selectedChecklist: number;
    public selectedTag: number;
    public intencionPago: number = 0;
    public selectedPaymentMethod: number;
    public account: Account;
    public checklist: any = [];
    public cargarTablaAcreditados = false
    public acreditados = [];
    public textoAcreditado = '';
    public lender: Lender;
    public acreditadoSeleccionado: any = null;
    public agente: any = null;
    selectedIndex: number = 0;
    idExtenoAcreditado: any;
    email: string;
    telefono: string;
    primerPaso: boolean = false;
    segundoPaso: boolean = false;
    tercerPaso: boolean = false;
    isAbrioModal = false;
    isVerificaBorrador = false;

    private ambiente = environmentSelector();
    private base64textString: string;

    private lat: number;
    private lng: number;
    private mock: boolean = false;
    private idCliente: any;
    private idAgente: any;
    public imagesFlag: boolean = true;
    public pdfFlag: boolean = true;
    public idAcreditadoSelecionado: number;
    public idClienteSeleccionado: number;
    public idCuentaSeleccionado: number;
    acceptFiles: string = '.png,.gif,.jpeg,.jpg';
    acceptFilesPdf : string = '.pdf';
    tablaCorreos:any;
    tablaTelefonos:any;
    emails: Array<any>;
    telefonos: Array<any>;
    foto:any;
    @ViewChild('stepper') stepper: MatStepper;
    firstFormGroup: FormGroup;
    secondFormGroup: FormGroup;
    @ViewChild('fileInput') public fileInput: any;
    @ViewChild('emailInput') public emailInput: ElementRef;
    @ViewChild('telefonoInput') public telefonoInput: ElementRef;
    @ViewChild('matSelectSearchAgente') matSelectSearch: MatSelectSearch;
    @ViewChild('matSelectSearchCuenta') matSelectSearchCuenta: MatSelectSearch;
    @ViewChild('imagen') imagen: UploadFileComponent;
    @ViewChild('pdf') pdf: UploadFileComponent;

    isEditable = false;
    arrayBorrador = [];
    fotosBorrador:any = [];
    gestorSubeArchivoObligatorio:boolean;
    gestorSubeArchivo:boolean;
    contratos: any = [];
    descargandoArchivo = false

    private _onDestroy = new Subject<void>();

    constructor(
        private app: ApplicationService,
        private formBuilder: FormBuilder,
        private lenderService: LenderService,
        private visitService: VisitService,
        private storageServ: StorageKobraService,
        public agenteServ: AgentService,
        private rootElement: ElementRef,
        public dialog: MatDialog,
        private routeNavigate: Router,
        private accountService: AccountService,
        private http: HttpClient
    ) {
        this.checklist.extraInfo = {
            telefonos: [],
            emails: []
        };
        this.crearFormulario();
    }

    crearFormulario(){
        this.firstFormGroup = this.formBuilder.group({
            lenderID: [null, Validators.required],
            idAcreditado: [null, Validators.required],
            agente: [null, Validators.required],
        });

    }

    ngOnInit() {
        this.account = new Account();
        this.loadLenders();
    }

    ngAfterViewChecked() {

    }

    ngOnDestroy() {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    public loadLenders(): void {
        this.lenderService.getAll().subscribe(
            (lenders) => {
                this.lenders = lenders;
            }
        );
    }

    public onChangeLender(event) {
        this.lenderID = this.firstFormGroup.get('lenderID').value;
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.lender = lender;
                }
            });
            //this.matSelectSearchCuenta.focusInput();
        }
        else {
            this.lender = null;
        }
    }

    limpiarCliente(){
        this.lender = null;
        this.lenderID = null;
        this.firstFormGroup.get('lenderID').setValue(null);
    }

    limpiarAgente(){
        this.agente = null;
        this.firstFormGroup.get('agente').setValue(null);
    }

    handleFiles(files: File[]) {
        this.files = files;
    }

    handleFilesPdf(files: File[]) {
        this.filesPdf = files;
    }

    public uploadPdf(): void {

        if(!this.filesPdf.length) {
            this.app.showSnackbar("Aviso", "Favor de seleccionar un archivo.", 3000,"warning");
            return;
        }else{
            var reader = new FileReader();
            reader.onload = this._handleReaderLoadedPdf.bind(this);
            reader.readAsBinaryString(this.filesPdf[0]);
        }
    }

    private _handleReaderLoadedPdf(readerEvt: any): void {
        var binaryString = readerEvt.target.result;
        var base64textString = `data:${this.filesPdf[0].type};base64,${btoa(binaryString)}`;

        let dataCreatePhoto = {
            idAgente: this.idAgente,
            idCobrador: this.agente.idCobrador,
            archivo: base64textString
        };

        let loading = this.app.showLoading('Subiendo contrato...');

        this.storageServ.post(`/clientes/${this.lenderID}/visitas/${this.idVisita}/archivo/${this.idAcreditado}`, dataCreatePhoto)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                this.app.hideLoading(loading);
                this.pdfFlag = false;
                this.pdf.removeFile();
                this.obtenerContratosVisita();
                this.app.showSnackbar("¡Éxito!", "El contrato se ha subido correctamente.", 3000, "success");
            }, (error: any) => {
                this.app.hideLoading(loading);
                this.app.showError(error);
                this.app.showSnackbar("¡Error!", "Ocurrio un error al intentar subir el contrato.", 3000, "error");

            });
    }

    public uploadImage(): void {

        if(!this.files.length) {
            this.app.showSnackbar("Aviso", "Favor de seleccionar un archivo.", 3000,"warning");
            return;
        }else{
            var reader = new FileReader();
            reader.onload = this._handleReaderLoaded.bind(this);
            this.foto = this.files[0];
            reader.readAsBinaryString(this.foto);

        }
    }

    private _handleReaderLoaded(readerEvt: any): void {
        var binaryString = readerEvt.target.result;
        var base64textString = `data:${this.foto.type};base64,${btoa(binaryString)}`;

        let dataCreatePhoto = {
            idAgente: this.idAgente,
            archivo: base64textString
        };

        let loading = this.app.showLoading('Subiendo imagen...');

        this.storageServ.post(`/clientes/${this.idCliente}/visitas/${this.idVisita}/foto`, dataCreatePhoto)
            .pipe(
                take(1)
            ).subscribe((res: any) => {

                this.imagesFlag = false;
                this.imagen.removeFile();
                this.loadPhotosCollected();
                this.app.hideLoading(loading);
                this.app.showSnackbar("¡Éxito!", "La foto se ha subido correctamente.", 3000, "success");
            }, (error: any) => {
                this.app.hideLoading(loading);
                this.app.showError(error);
                this.app.showSnackbar("¡Error!", "Ocurrio un error al intentar subir la foto.", 3000, "error");

            });
    }

    public verificarVisita() {
        this.isVerificaBorrador = true;
        if(!this.lenderID){
            this.app.showSnackbar("¡Aviso!", "Es necesario seleccionar un cliente", 3400, "warning");
            return;
        }

        if(!this.idAcreditado || !this.acreditadoSeleccionado){
            this.app.showSnackbar("¡Aviso!", "Es necesario seleccionar una cuenta", 3400, "warning");
            return;
        }

        if(!this.agente){
            this.app.showSnackbar("¡Aviso!", "Es necesario seleccionar un agente", 3400, "warning");
            return;
        }

        let loading = this.app.showLoading('Verificando información de la visita...');
        this.visitService.obtenerBorrador(this.lender.idCliente, this.agente.idAgente, this.acreditadoSeleccionado)
            .pipe(take(1))
            .subscribe((respuesta: any) => {
                this.isVerificaBorrador = false;
                this.app.hideLoading(loading);
                if(respuesta.data['isTieneBorrador']){
                    const dialogRef = this.dialog.open(ConfirmacionDialog, {
                        //height: '400px',
                        //width: '300px',
                        //disableClose: true,
                        data:{
                            titulo: "Confirmar",
                            mensaje: `Hemos identificado que hay un borrador de visita asociado a esta cuenta. <br> ¿Te gustaría recuperar los datos del borrador?`,
                            icono:"done",
                            boton1: "No",
                            boton2: "Sí",
                            claseAccion: "boton-accion-front"
                        }
                    });

                    dialogRef.afterClosed().subscribe(result => {
                        if(result){
                            let loadingBorrador = this.app.showLoading('Obteniendo información del borrador...');
                            let arrayCheckList: any;
                            this.arrayBorrador = respuesta.data;

                            // = this.arrayBorrador['fotos']
                            this.nombreProducto = this.arrayBorrador['borrador']['nombreProducto'];
                            this.folioVisita = this.arrayBorrador['borrador']['folio'];
                            this.idVisita = this.arrayBorrador['borrador']['idVisita'];
                            this.idCliente = this.arrayBorrador['borrador']['idCliente'];
                            this.idAgente = this.arrayBorrador['borrador']['idAgente'];
                            arrayCheckList = this.arrayBorrador['borrador']['checklist'];
                            this.idProduct = this.arrayBorrador['borrador']['idProducto'];
                            this.idAcreditado = this.acreditadoSeleccionado.idAcreditadoMS;
                            this.idChecklist = arrayCheckList['preguntas']['idChecklist'];
                            this.idVersionCheckList = arrayCheckList['preguntas']['idVersion'];
                            arrayCheckList.preguntas = arrayCheckList.preguntas.preguntas.filter((pregunta: any) => pregunta.respuestas.length);
                            this.checklist = arrayCheckList;
                            this.acomodarTipoTabla();
                            this.cargarDatosChecklist();
                            this.obtenerCheckListInfo(true);
                            if(this.arrayBorrador['fotos'].length > 0){
                                this.loadPhotosCollected();
                            }

                            this.firstStep = false;
                            this.secondStep = true;
                            this.primerPaso = true;
                            this.stepper.next();

                            this.app.hideLoading(loadingBorrador);
                        }else{
                            this.app.hideLoading(loading);
                            this.createVisit();
                        }
                    });

                }else{
                    this.app.hideLoading(loading);
                    this.createVisit();
                }
            },
                (error: any) => {
                    this.isVerificaBorrador = false;
                    this.app.showError(error);
                }
            );
    }

    //prepara la información al estilo admin ya que este al ser mas maleable se estructura distinto al de la aplicación.
    acomodarTipoTabla(){
      let preguntas = this.checklist.preguntas.filter((pregunta) => pregunta.respuestas.some((respuesta) => respuesta.idTipoRespuesta == 12));
      for (let index = 0; index < preguntas.length; index++) {
        const pregunta = preguntas[index];
        const respuestas = pregunta.respuestas.filter((respuesta) => respuesta.idTipoRespuesta == 12);
        for (let j = 0; j < respuestas.length; j++) {
          const respuesta = respuestas[j];
          let respuestaTabla = this.checklist.respuestas.find(
            (respuestaTabla) => respuestaTabla.idRespuesta == respuesta.idChecklistRespuesta
              && respuestaTabla.idPregunta == pregunta.idChecklistPregunta
          );

          respuesta.valor=[];
          for (let r = 0; r < respuestaTabla.valorTabla.length; r++) {
            const registro = respuestaTabla.valorTabla[r];
            let value: any = {};

            for (let c = 0; c < registro.columnas.length; c++) {
              const columna = registro.columnas[c];
              const columnaRespuesta = respuesta.columnas[c];
              value[columna.idChecklistRespuesta.toString()] = columna.valor;
              if(columna.idTipoRespuesta == 9){
                const columnaOpcion = columnaRespuesta.options.find((opcion) => opcion.id == parseInt(columna.valor));
                value[columna.idChecklistRespuesta.toString()+"Text"] = columnaOpcion.label;
              }
            }
            respuesta.valor.push(value);
          }
        }
      }
    }

    public createVisit(): void {

        let data = {
            idExterno: this.idExtenoAcreditado,
            idCobrador: this.agente.idCobrador,
            isMock: this.mock,
            lat: this.acreditadoSeleccionado['latitud'],
            lng: this.acreditadoSeleccionado['longuitud'],
            idFinanciera: this.lenderID,
            isAdmin: true,
            //alex
            idCliente: this.acreditadoSeleccionado.idCliente,
            idAcreditadoPG: this.acreditadoSeleccionado.idAcreditado,
            idCuentaPG: this.acreditadoSeleccionado.idCuenta,
            idDomicilioAcreditado: this.acreditadoSeleccionado.idDomicilioAcreditado
        };

        let loading = this.app.showLoading('Creando visita...');

        this.visitService.post('/visita', data)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                const { folio, idVisita, nombreProducto, checklist, idAcreditado, idCliente, idAgente } = res;
                this.nombreProducto = nombreProducto;
                this.folioVisita = folio;
                this.idVisita = idVisita;
                this.idCliente = idCliente;
                this.idAgente = idAgente;

                checklist.preguntas = checklist.preguntas.filter((pregunta: any) => pregunta.respuestas.length);
                this.checklist = checklist;
                this.idAcreditado = idAcreditado;
                this.idProduct = this.checklist.idProducto;
                this.firstStep = false;
                this.secondStep = true;
                this.primerPaso = true;


                this.createChecklistParams();
                this.obtenerCheckListInfo(false);
                this.app.hideLoading(loading);
                this.app.showSnackbar("¡Éxito!", "La visita se ha creado correctamente.", 3000, "success");
                this.stepper.next();
            }, (err: any) => {
                this.app.hideLoading(loading);
                this.app.showError(err);
            });
    }


    saveChecklist() {
        //alex
        if (!this.checklist.invalid) {
            let loading = this.app.showLoading('Finalizando visita...');
            let payLoad: any = { ...this.checklist };
            payLoad.respuestaPreguntas = [];
            let preguntas: any = payLoad.preguntas;
            //let respuestasHijas: any = [];

            preguntas.forEach(pregunta => {
                pregunta.respuestas.forEach(respuesta => {
                  //limpia los valores para que tenga el valor correcto
                  if(respuesta.idTipoRespuesta == 12){
                    let columnasOpciones = respuesta.columnas.filter(columna => columna.idTipoRespuesta == 9);
                    if(!respuesta.valor){
                      respuesta.valorPoner = null;
                    }
                    else{
                      for (let index = 0; index < respuesta.valor.length; index++) {
                        const respuestaCol = respuesta.valor[index];

                        for (let j = 0; j < columnasOpciones.length; j++) {
                          const columna = columnasOpciones[j];
                          if(respuestaCol[columna.idChecklistRespuesta.toString()]){
                            respuestaCol[columna.idChecklistRespuesta.toString()] = respuestaCol[columna.idChecklistRespuesta.toString()+'Text'];
                          }
                          else{
                            respuestaCol[columna.idChecklistRespuesta.toString()] = null;
                          }
                          delete respuestaCol[columna.idChecklistRespuesta.toString()+'Text'];
                        }
                      }
                      respuesta.valorPoner = JSON.stringify(respuesta.valor);
                    }
                  }

                  payLoad.respuestaPreguntas.push({
                      idPregunta: pregunta.idChecklistPregunta,
                      idRespuesta: respuesta.idChecklistRespuesta,
                      idTipoRespuesta: respuesta.idTipoRespuesta,
                      accion: respuesta.accion,
                      valor: respuesta.idTipoRespuesta == 6 ?
                        (
                          respuesta.valor ? true : false
                        ) :
                        respuesta.idTipoRespuesta == 8 ?
                        (
                          respuesta.valor == 'true' || respuesta.valor == '1' ? '1' : respuesta.valor == 'false' || respuesta.valor == '0' ? '0' : ''
                        ) : (respuesta.idTipoRespuesta == 12 ? respuesta.valorPoner || null :
                        respuesta.valor || '')
                  });
                });
            });
            delete payLoad.preguntas;
            payLoad.numVersionChecklist = payLoad.idVersion;
            this.visitService.post(`/${this.agente.idCobrador}/clientes/${this.lenderID}/visitas/${this.idVisita}`, payLoad)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.app.hideLoading(loading);
                    this.reiniciarProceso();

                    this.app.showSnackbar("¡Éxito!", "La visita se ha finalizado correctamente.", 3000, "success");
                }, (err: any) => {
                    this.app.showSnackbar("¡Error!", "Ocurrio un error al finalizar la visita.", 3000, "error");
                    this.app.hideLoading(loading);
                    this.app.showError(err);
                });
        }else{
            this.app.showSnackbar("¡Aviso!", "Favor de verificar el cuestionario para poder finalizar la visita.", 3000, "warn");
        }
    }

    reiniciarProceso(){
        this.contratos = [];
        this.fotosBorrador = [];
        this.firstFormGroup.reset();
        this.limpiarCliente();
        this.limpiarAgente();
        this.resetAcreditadoFilter();
        this.primerPaso = false;
        this.segundoPaso = false;
        this.tercerPaso = false;
        this.imagesFlag = true;
        this.pdfFlag = true;
        this.stepper.reset();
        this.gestorSubeArchivoObligatorio = false;
        this.gestorSubeArchivo = false;
    }

    cargarDatosChecklist() {
        this.checklist.extraInfo = {
            telefonos: this.arrayBorrador['borrador']['telefonos'],
            emails: this.arrayBorrador['borrador']['correos']
        };
        this.checklist.idAcreditado = this.idAcreditado;
        this.checklist.comentarios = this.arrayBorrador['borrador']['comentarios'];
        this.checklist.invalid = true;
        this.checklist.interest = 1;
        this.checklist.mock = false;
        this.checklist.coordenasVisita = [];
        this.checklist.horaLocalDispositivo = "";
        this.checklist.latFin = this.arrayBorrador['borrador']['lat'];
        this.checklist.lngFin = this.arrayBorrador['borrador']['lng'];
        this.checklist.idAcreditadoPG = this.acreditadoSeleccionado.idAcreditado;
        this.checklist.idCuentaPG = this.acreditadoSeleccionado.idCuenta;
        this.checklist.idCliente = this.acreditadoSeleccionado.idCliente;
        this.checklist.idDomicilioAcreditado = this.acreditadoSeleccionado.idDomicilioAcreditado
        this.checklist.idProducto = this.idProduct;
        this.checklist.idChecklist = this.idChecklist;
        this.checklist.idVersion = this.idVersionCheckList;
        this.checklist.idFinanciera = this.lenderID;
        this.checklistValid();
    }

    createChecklistParams() {
        this.checklist.extraInfo = {
            telefonos: [],
            emails: []
        };
        this.checklist.idAcreditado = this.idAcreditado;
        this.checklist.comentarios = "";
        this.checklist.invalid = true;
        this.checklist.interest = 1;
        this.checklist.mock = false;
        this.checklist.coordenasVisita = [];
        this.checklist.horaLocalDispositivo = "";
        this.checklist.latFin = this.acreditadoSeleccionado.latitud,
        this.checklist.lngFin = this.acreditadoSeleccionado.longuitud,
        //alex
        this.checklist.idAcreditadoPG = this.acreditadoSeleccionado.idAcreditado;
        this.checklist.idCuentaPG = this.acreditadoSeleccionado.idCuenta;
        this.checklist.idCliente = this.acreditadoSeleccionado.idCliente;
        this.checklist.idDomicilioAcreditado = this.acreditadoSeleccionado.idDomicilioAcreditado
        this.checklistValid();
    }

    checklistValid() {
        var seValidara = true;
        for (let p = 0; p < this.checklist.preguntas.length; p++) {
            const pregunta = this.checklist.preguntas[p];
            this.mostrarPregunta(pregunta.idChecklistPreguntaCondicion, pregunta.idChecklistPreguntaPadre);
            if (!this.validarValor(pregunta)) {
                seValidara = false;
                p = this.checklist.preguntas.length;
            }
        }
        this.checklist.invalid = !seValidara;
    }

    validarValor(pregunta) {
        var response = true;
        for (let r = 0; r < pregunta.respuestas.length; r++) {
            const respuesta = pregunta.respuestas[r];
            if (this.mostrarPregunta(pregunta.idChecklistPreguntaCondicion, pregunta.idChecklistPreguntaPadre) && respuesta.requerida && !respuesta.valor) {
                response = false;
                r = pregunta.respuestas.length;
            }
        }
        return response;
    }

    agregarEmail(email: string) {
        if (email == null) return;

        email = email.trim();

        if (email == "") return;

        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(String(email).toLowerCase())) {
            this.app.showSnackbar("¡Aviso!", "El email no es correcto", 3000, "warning");
            return;
        }

        //this.checklist.extraInfo.emails = [...this.checklist.extraInfo.emails, [email]];
        this.checklist.extraInfo.emails.push(email);
        this.email = '';
        //this.app.showSnackbar("¡Aviso!", "Correo agregado correctamente", 3000, "success");
    }

    removerEmail(index: number) {
        this.checklist.extraInfo.emails.splice(index, 1);
        //this.app.showSnackbar("¡Aviso!", "Correo eliminado correctamente", 3000, "success");
    }

    agregarTelefono(telefono: string) {
        if (telefono == null || telefono == "") return;

        /*let reg = /^\d+$/;

        if (!reg.test(telefono)) {
            this.app.showSnackbar("¡Aviso!", "El teléfono no es correcto", 3000, "warning");
            return;
        }*/

        this.checklist.extraInfo.telefonos.push(telefono);
        this.telefono = '';
        //this.app.showSnackbar("¡Aviso!", "Teléfono agregado correctamente", 3000, "success");
    }

    removerTelefono(index: number) {
        this.checklist.extraInfo.telefonos.splice(index, 1);
        //this.app.showSnackbar("¡Aviso!", "Teléfono eliminado correctamente", 3000, "success");
    }

    noWhitespaceValidator(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;

        return isValid ? null : { 'required': true };
    }

    onTerminarVisita() {
        this.saveChecklist();
    }

    resetAgenteFilter() {
        this.agente = null;
    }

    onAcreditadoFilterSelected(acreditado: any) {
        this.idExtenoAcreditado = acreditado.idExterno;
        this.idAcreditadoSelecionado = acreditado.idAcreditado;
        this.idClienteSeleccionado = acreditado.idCliente;
        this.idCuentaSeleccionado = acreditado.idCuenta;
    }

    resetAcreditadoFilter() {
        this.idExtenoAcreditado = null;
        this.acreditadoSeleccionado = null;
        this.idAcreditado = null;
        this.firstFormGroup.get('idAcreditado').setValue(null);
    }

    /*mostrarPregunta(pregunta) {
        let mostrarEstaPregunta = pregunta.idChecklistPreguntaPadre ? false : true;
        if (mostrarEstaPregunta) {
            pregunta.visible = true;
            return mostrarEstaPregunta;
        }

        let preguntaPadre = this.obtenerPreguntaPadrePorId(pregunta.idChecklistPreguntaPadre);

        if (preguntaPadre) {
            for (let i = 0; i < preguntaPadre.respuestas.length; i++) {
                let respuetaActual = preguntaPadre.respuestas[i];
                let valorRespuesta = respuetaActual.valor;
                let condicionRespueta = this.obtenerCondicionRespuesta(valorRespuesta, preguntaPadre.respuestas[i]);
                if (!condicionRespueta) {
                    pregunta.visible = false;
                    return false;
                }

                for (let j = 0; j < condicionRespueta.when.then.show.length; j++) {
                    if (condicionRespueta.when.then.show[j] == pregunta.idChecklistPreguntaCondicion) {
                        pregunta.visible = true;
                        return true;
                    }
                }
            }
        }

        pregunta.visible = mostrarEstaPregunta;
        return mostrarEstaPregunta;
    }*/



    /*obtenerPreguntaPadrePorId(idChecklistPreguntaPadre) {
        let preguntaPadre = null;

        preguntaPadre =
            this.checklist.preguntas.find(pregunta => pregunta.idChecklistPreguntaCondicion == idChecklistPreguntaPadre);

        return preguntaPadre;
    }*/

    /*obtenerCondicionRespuesta(valorRespuesta, respuestaChecklist) {
        for (let i = 0; i < respuestaChecklist.condiciones.length; i++) {
            const condicionActual = respuestaChecklist.condiciones[i];
            if (valorRespuesta == condicionActual.when.response ||
                (valorRespuesta && condicionActual.when.response == 1) ||
                (!valorRespuesta && condicionActual.when.response == 0)) {
                return condicionActual;
            }
        }
        return null;
    }*/

    mostrarPregunta(idChecklistPreguntaCondicion: number, idChecklistPreguntaPadre: number): boolean {

        let preguntaPadre = this.obtenerPreguntaPadrePorId(idChecklistPreguntaPadre);
        let mostrarEstaPregunta = preguntaPadre == null ? true : false;
        if (mostrarEstaPregunta) {
          return mostrarEstaPregunta;
        }

        for (let i = 0; i < preguntaPadre.respuestas.length; i++) {
          const respuestaActual = preguntaPadre.respuestas[i];
          let valorRespuesta = respuestaActual.valor;

          const condicionRespuesta = this.obtenerCondicionPorRespuesta(valorRespuesta, respuestaActual);
          if (condicionRespuesta) {
            for (let j = 0; j < (condicionRespuesta.when.then?.show || []).length; j++) {
                if (idChecklistPreguntaCondicion === (condicionRespuesta.when.then?.show || [])[j]) {
                    return true;
                }
            }
          }
        }

        return mostrarEstaPregunta;
    }

    obtenerCondicionPorRespuesta(valorRespuesta: string, respuestaChecklist: any){
      let condicionRespuesta = null;

        let respuestaBooleana: boolean = respuestaChecklist.idTipoRespuesta == 8 || respuestaChecklist.idTipoRespuesta == 7  || respuestaChecklist.idTipoRespuesta == 6;
        condicionRespuesta = respuestaChecklist.condiciones.find(
            (condicion) =>
            valorRespuesta === condicion.when.response ||
            (respuestaBooleana && (valorRespuesta==='true' || valorRespuesta == '1') && condicion.when.response == 1) ||
            (respuestaBooleana && (valorRespuesta==='false' || valorRespuesta == '0') && condicion.when.response == 0)
        );

      return condicionRespuesta;
    }

    obtenerPreguntaPadrePorId(idChecklistPreguntaPadre: number) {
        if (idChecklistPreguntaPadre != null) {
          const preguntaPadre = this.checklist.preguntas.find((pregunta) => {
            return idChecklistPreguntaPadre === pregunta.idChecklistPreguntaCondicion;
          });
          return preguntaPadre || null;
        }
        return null;
    }

    asignarAgente(){
        this.agente = this.firstFormGroup.get('agente').value;
    }

    openDialog(): void {
        this.idAcreditado = this.firstFormGroup.get('idAcreditado').value;

        if(this.idAcreditado && this.lender && !this.isAbrioModal){
            this.isAbrioModal = true;
            const dialogRef = this.dialog.open(AcreditadoDialog, {
                data : {
                    idAcreditado : this.idAcreditado,
                    idCliente : this.lender.idCliente
                },
            });

            dialogRef.afterClosed().subscribe(result => {
                if(result){
                    this.acreditadoSeleccionado = result;
                    this.idExtenoAcreditado = this.acreditadoSeleccionado.idExterno;
                    this.matSelectSearchCuenta.cerrarAutocomplete();
                    this.matSelectSearch.focusInput();
                    if(this.acreditadoSeleccionado){
                        this.isAbrioModal = false;
                    }
                }else{
                    this.isAbrioModal = false;
                    this.idAcreditado =  null;
                    this.firstFormGroup.get('idAcreditado').setValue(null);
                }
            });
        }


    }

    public agenteFilterSearch = function(term: string) {
        return this.agenteServ.obtenerAgentes(term)
            .pipe(
                take(1),
                map((res: any) => res.data.map(
                    ({ idCobrador, nombreCompleto, idAgente }) => (
                        { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
                    )
                ))
            );
    }.bind(this);

    acreditadoFilterSearch = function (term: string) {
        return this.agenteServ.obtenerAcreditados(term, this.lenderID)
            .pipe(
                take(1),
                map((res: any) => res.data.map(
                    ({ idAcreditado, nombreCompleto, idCliente, idExterno}) => (
                        { idAcreditado: idAcreditado, nombre: idExterno+' - '+nombreCompleto, idCliente: idCliente }
                    )
                ))
            );
    }.bind(this);

    goPrevious() {
        this.stepper.previous();
    }

    goNext() {

        if(this.selectedIndex === 0 && !this.primerPaso){
            this.verificarVisita();
        }else{
            this.stepper.next();
        }

    }

    currentStepIsValid(): boolean {

        switch (this.selectedIndex) {

            case 0:
                if(this.firstFormGroup.invalid){
                    return false;
                }else{
                    return true;
                }

            case 1:
                if(this.checklist.invalid || !this.checklist.comentarios){
                    return false;
                }else{
                    this.segundoPaso = true;
                    return true;
                }

            case 2:
                return this.tercerPaso;
            default:
                return false;
        }
    }

    onStepChanged(event: any) {
        this.selectedIndex = event.selectedIndex;
    }

    onClick({ row, columnPressed }){
        if(columnPressed == 'eliminar'){
          this.borrarCorreo(row);
        }
      }

    borrarCorreo(registroSeleccionado: any) {
        this.checklist.extraInfo.emails = this.checklist.extraInfo.emails.filter((item) => item.email !== registroSeleccionado.email);
    }

    onClickTelefono({ row, columnPressed }){
        if(columnPressed == 'eliminar'){
          this.borrarTelefono(row);
        }
      }

    borrarTelefono(registroSeleccionado: any) {
        this.checklist.extraInfo.telefonos = this.checklist.extraInfo.telefonos.filter((item) => item.telefono !== registroSeleccionado.telefono);
    }

    validaDatos(){
        if(this.pdfFlag && this.imagesFlag){
            return true;
        }else{
            return false;
        }
    }

    calificacion(calificacion: number){
        this.checklist.interest = calificacion;
    }

    public cargarFotoCompleta(urlFoto: string){
        const dialogRef = this.dialog.open(FotoModalComponent, {
            data: {
                urlFoto: urlFoto
            }
        });
        dialogRef.afterClosed().subscribe(data => {});
    }

    tabSeleccionado(evento){
        this.listado = evento;
    }

    tabSeleccionadoContrato(evento){
        this.listadoContrato = evento;
    }

    private setPhotoPath(fotos: any, idVisita: number): void {
        let country = "&Country=" + environmentSelector().country;
        fotos.forEach(element => {
            element.fullPath =
                `${this.ambiente.kobraServices.storage}/visitas/${idVisita}/foto/${element.idVisitaFoto}?token=${localStorage.getItem('access-token')}&idAgente=${element.idAgente}&idFinanciera=${element.idFinanciera}${country}`;
        });

        this.fotosBorrador = fotos;
    }

    eliminarFoto(foto: any) {

        const dialogRef = this.dialog.open(ConfirmacionDialog, {
          data:{
              titulo: "Confirmar",
              mensaje: `¿Deseas eliminar la foto?`,
              icono:"delete_forever",
              boton1: "No",
              boton2: "Sí",
              claseAccion: "boton-accion-eliminar"
          }
      });

      dialogRef.afterClosed().subscribe(result => {

        if(result){
            //this.cargando = true;
            const loading: number = this.app.showLoading('Eliminando foto...');
            this.visitService.eliminarFotoVisita(foto.idCliente, this.idVisita, foto.idAgente, foto.idVisitaFoto)
            .pipe(take(1))
            .subscribe((respuesta) => {
            if(respuesta.success) {
                this.app.showSnackbar(
                    "Aviso",
                    "La foto se ha eliminado correctamente.",
                    3000,
                    "success"
                    );
                //this.fotosBorrador = this.fotosBorrador.filter(foto => foto.idVisitaFoto !== foto.idVisitaFoto);
                this.loadPhotosCollected();
                this.app.hideLoading(loading);
            }else {
                //this.eliminando = false;
                this.app.showSnackbar(
                "Aviso",
                "Ocurrio un problema al eliminar la foto.",
                3000,
                "error"
                );
                this.app.hideLoading(loading);
            }
            }, error => {
                //his.eliminando = false;
                this.app.hideLoading(loading);
                this.app.showError(error);
            });
          }
      });

    }

    eliminarFotoCheckList(event:any){

        this.checklist.preguntas.forEach((pregunta) => {
            pregunta.respuestas.forEach((respuesta) => {
              if (respuesta.idTipoRespuesta === 11 && respuesta.valor === event) {
                respuesta.valor = null;
              }
            });
        });
    }

    public obtenerCheckListInfo(isConsultarContratos: boolean) {

        this.visitService.checklistInfo(this.idCliente, this.idProduct, this.acreditadoSeleccionado.idAcreditadoMS, this.acreditadoSeleccionado.idAcreditado, this.acreditadoSeleccionado.idCuenta)
            .pipe(take(1))
            .subscribe((respuesta) => {
                this.gestorSubeArchivo = respuesta.data.gestorSubeArchivo;
                this.gestorSubeArchivoObligatorio = respuesta.data.gestorSubeArchivoObligatorio;
                if(isConsultarContratos && this.gestorSubeArchivo){
                    this.obtenerContratosVisita();
                }

            }, error => {
                this.app.showError(error);
            })
    }

    private obtenerContratosVisita(): void {
        let subscription: Subscription = this.visitService.getOneArchivosAcreditados(`?idAcreditado=${this.acreditadoSeleccionado.idAcreditado}&idCuenta=${this.acreditadoSeleccionado.idCuenta}&idCliente=${this.idCliente}&idAgente=${this.agente.idAgente}&idVisita=${this.idVisita}`).subscribe(
            res => {
                if (res) {
                    if( res.length > 1 ) {
                        this.app.showError('Ocurrio un problema al cargar el contrato de esta visita.');
                    } else {
                        this.contratos = res;
                    }
                }

            }, err => {
                this.app.showError(err);
                subscription.unsubscribe();
            }, () => {
                subscription.unsubscribe();
            }
        );
    }

    public descargarArchivo(archivo: any) {
        this.descargandoArchivo = true;

        const queryParams = `?ruta=${archivo.ruta}&nombreArchivo=${archivo.nombre}`;

      this.visitService.descargarArchivoStorage(queryParams, this.idCliente).subscribe(event => {
          if (event.type === HttpEventType.Response) {
            this.descargandoArchivo = false;
            const blob = new Blob([event.body], { type: event.body.type });
            saveAs(blob, archivo.nombre);
          }
        }, error => {
          this.descargandoArchivo = false;
        });
    }

    openDialogConfirmacion(archivo:any): void {

        const dialogRef = this.dialog.open(ConfirmacionDialog, {
            //height: '400px',
            //width: '300px',
            //disableClose: true,
            data:{
                    titulo: "Confirmar",
                    mensaje: "¿Deseas eliminar el contrato?",
                    icono: "delete_forever",
                    colorIcono: "warn",
                    boton1: "No",
                    boton2: "Sí",
                    texto: archivo.nombreArchivo,
                    claseAccion: "boton-accion-eliminar"
                }
        });

        dialogRef.afterClosed().subscribe(result => {

            if(result) {
                let params = `idAgente=${this.agente.idAgente}&idVisita=${this.idVisita}
                &idCuenta=${this.acreditadoSeleccionado.idCuenta}&idTipoArchivoAcreditado=${archivo.idTipoArchivoAcreditado}
                &ruta=${archivo.ruta}/${archivo.nombre}`

                const loading: number = this.app.showLoading('Eliminando contrato...');
                this.visitService.eliminarContratoVisita(this.idCliente, this.acreditadoSeleccionado.idAcreditado, archivo.idCuentaArchivo, params)
                .pipe(take(1))
                .subscribe((respuesta) => {
                if(respuesta.success) {
                    this.app.showSnackbar(
                        "Aviso",
                        "El contrato se ha eliminado correctamente.",
                        3000,
                        "success"
                        );
                   this.contratos = [];
                    this.app.hideLoading(loading);
                }else {
                    this.app.showSnackbar(
                    "Aviso",
                    "Ocurrio un problema al eliminar el contrato.",
                    3000,
                    "error"
                    );
                    this.app.hideLoading(loading);
                }
                }, error => {
                    this.app.hideLoading(loading);
                    this.app.showError(error);
                });
            }
        });
    }

    private loadPhotosCollected(): void {
        //this.cargarFotos = true;
        this.visitService.obternerFotosVisita(this.idVisita, this.agente.idAgente, this.idCliente, null).subscribe(
            photos => {
                //this.fotosBorrador = photos;
                this.setPhotoPath(photos.data, this.idVisita);
            }

        );
    }

    validarFinalizar(): boolean {
        return this.fotosBorrador.length > 0 && (!this.gestorSubeArchivoObligatorio || this.contratos.length > 0);
    }

}

