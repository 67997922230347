import { Page } from './../shared/interfaces/page';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { environmentSelector } from '../../environments/environment.selector';
import { PromisePaginator } from './promesas-pago.paginator';
import { PromisePayment } from './PromisePayment.model';
import { Lender } from '../lender/lender.model';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { PromesasPagoService } from './promesas-pago.service';
import { LenderService } from '../lender/lender.service';
import { VisitService } from '../visit/visit.service';
import { AgentService } from '@agent/agent.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import { Router } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';

declare var $: any;

@Component({
    selector: 'promesas-pago',
    templateUrl: './promesas-pago.component.html',
    styleUrls: ['./promesas-pago.component.css'],
    providers: [
        LenderService,
        PromesasPagoService,
        VisitService,
        AgentService
    ]
})
export class PromesasPagoComponent implements OnInit {
    private environment = environmentSelector();

    private $filterModal: any;
    lenders: Lender[];
    ayuda = false;
    tablaPromesas: any;
    pageSize: number = 50;
    public labelEstado: string;
    public labelMunicipio: string;
    cargandoLista = false;
    filtrosAbiertos= false;
    hijosActivos: boolean = false;
    /**
     * names
     */
    public name() {

    } metodos: any;
    public estatusPromesas: any;
    public promisePaginator: PromisePaginator = new PromisePaginator();
    public listFields: any = {};
    sessionData: SessionData;
    public promises: PromisePayment[];
    @ViewChild('agenteFilterInput') private agenteFilterInput: any;
    private agenteFilterFlag = false;
    private agenteFilterFounded: any;
    public filterAgentAvailable: boolean;

    @ViewChild('acreditadoFilterInput') private acreditadoFilterInput: any;
    private acreditadoFilterFlag = false;
    private acreditadoFilterFounded: any;
    @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;


    public today: Date = new Date();
    public httpError: any;
    public idLender: any;
    public queryParamsGlobal: string;

    public datosConsulta: any = {
        slt: '"idExternoCliente", "folioVisita", "idCuenta", "nombreAcreditado", "apePatAcreditado",'+
            ' "apeMatAcreditado", "idFinanciera", "nombreCliente",' +
            ' "nombreAgente", "apePatAgente", "apeMatAgente", "montoPagoPromesa", "fechaPagoPromesa", comentarios, "metodoPagoPromesa",'+
            ' "nombrePromesaPagoEstatus", "fecVisitaRegistro", "idCobrador", "fecVisitaFinalizo", municipio, estado, "subIdExterno", "folioDomicilio"',
        pageSize: 100,
        rows: 100,
        sortField: 'fecVisitaRegistro',
        sortAsc: '',
        query: ""
    }

    public filters:any = {
        financiera: null,
        externoID: null,
        acreditado: null,
        agente: null,
        metodoPagoID: null,
        fechaInicio: null,
        fechaFin: null,
        visitFolio: null,
        estatusPromesaID: null,
        filtered: false,
        soloAgentesActivos: true,
        excluirDemo: true,
        page: 1
    }

    public loadings: any = {
        metodosPago: true,
        estatusPromesaPago: true
    }

    public contabilizadorFiltros: FiltersContabilizer;
  
    public totalFiltros: number = 0;

    constructor(
        private app: ApplicationService,
        private localStorageService: LocalStorageService,
        private filtrosService: FiltrosService,
        private visitService: VisitService,
        private promiseService: PromesasPagoService,
        private lenderService: LenderService,
        private agenteServ: AgentService,
        private rootElement: ElementRef,
        private routeNavigate: Router,
    ) {
        this.labelMunicipio = this.environment.labelMunicipio;
        this.labelEstado = this.environment.labelEstado;
        this.promises = [];
        this.crearTabla();
        if (this.promisePaginator) {
            this.promisePaginator.pagination.currentPage = 0;
        }
        this.sessionData = this.localStorageService.getSessionData();
        this.filtrosService.obtenerFiltrosIniciales(this.filters, this.suscribeParams.bind(this));
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();
        this.contabilizadorDeFiltrosTotales();
    }

    desactivarHijo(){
         this.hijosActivos = false;
        this.filtrosService.asignarOpcion();
        if(this.filters && this.filters.financiera){
            this.filtrosService.guardarFiltros(this.filters, true);
        }
        this.filtrosService.obtenerFiltrosIniciales(this.filters, this.suscribeParams.bind(this));
    }

    private agregarContabilizadoresDeFiltros(){
        this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
            'financiera',
            'excluirDemo'
        ]);

        this.contabilizadorFiltros.add('Generales', 'generales', [
            'visitFolio',
            'metodoPagoID',
            'estatusPromesaID'
        ]);

        this.contabilizadorFiltros.add('Cuenta', 'cuenta', [
            'acreditado'
        ]);

        this.contabilizadorFiltros.add('Fechas visita', 'fechasV', [
            'fechaInicio',
            'fechaFin'
        ]);

        this.contabilizadorFiltros.add('Agente', 'agente', [
            'agente'
        ]);
    }

    public contabilizadorDeFiltros(filtroName: string){
        return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
      }
      

    public contabilizadorDeFiltrosTotales():void{
        
        

        let totalFiltrosArray: number[] = [];
        if(this.sessionData.isSuperAdmin){
            totalFiltrosArray.push( this.contabilizadorDeFiltros('superAdmin') );
        }
        totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('cuenta') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('fechasV') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('agente') );

        this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }

    ngOnInit() {
    }

    crearTabla() {
        this.tablaPromesas = {
            headers: false,
            multiSelect: false,
            columns: [{
                    name: 'Cliente',
                    key: 'nombreCliente',
                    hide: false,
                    sortName: "cli.nombre"
                },
                {
                    name: 'ID Cuenta',
                    key: 'id',
                    hide: false,
                    sortName: "a.id_externo_cliente"
                },{
                    name: 'ID Subcuenta',
                    key: 'subIdExterno',
                    hide: false,
                    sortName: "ac.sub_id_externo_cliente"
                }, {
                    name: 'Folio Domicilio',
                    key: 'folioDomicilio',
                    hide: false,
                    sortName: 'acd.folio_domicilio'
                },
                {
                    name: 'Folio Visita',
                    key: 'folioVisita',
                    hide: false,
                    type: 'link',
                    sortName: "v.folio",
                    link: '/promesas-pago/visit/',

                }, {
                    name: 'Nombre Cuenta',
                    key: 'nombreCompletoAcreditado',
                    hide: false,
                    type: 'link',
                    sortName: "a.nombre"
                }, {
                    name: 'ID Agente',
                    key: 'idCobrador',
                    hide: false,
                    sortName: "ag.id_externo_kobra"
                }, {
                    name: 'Agente',
                    key: 'nombreCobrador',
                    hide: false,   
                    type: 'link',
                    sortName: "ag.nombreCobrador"
                }, {
                    name: 'Monto',
                    key: 'monto',
                    hide: false,
                    type: 'money',
                    sortName: "pp.monto"
                }, {
                    name: 'Fecha a Pagar',
                    key: 'fechaPagar',
                    hide: false,
                    type: 'date',
                    sortName: "pp.fec_promesa_pago"
                }, {
                    name: 'Comentario',
                    key: 'comentario',
                    hide: false,
                    sortName: "v.comentarios"
                }, {
                    name: 'Método Pago',
                    key: 'metodoPago',
                    hide: false,
                    sortName: "mp.nombre"
                }, {
                    name: 'Estatus Promesa de Pago',
                    key: 'estatusPromesa',
                    hide: false,
                    sortName: "es.nombre"
                }, {
                    name: 'Fecha Registro',
                    key: 'fechaRegistro',
                    hide: false,
                    type: 'date',
                    sortName: "v.fec_registro"
                }, {
                    name: this.labelMunicipio,
                    key: 'municipio',
                    hide: false,
                    sortName: "acd.municipio"
                }, {
                    name: this.labelEstado,
                    key: 'estado',
                    hide: false,
                    sortName: "acd.estado"
                }
            ]
        }
    }

    private suscribeParams(filtrosNuevos: any): void{
        var loadPromise = false;

        if(filtrosNuevos){
          loadPromise = true;
          this.filters = filtrosNuevos;
          this.filters.filtered = true;

          if(this.filters.financiera){
            this.idLender = this.filters.financiera.idFinanciera;
          }

          if(this.filters.agente){
            this.agenteFilterSearch(this.filters.agente.idCobrador + '', true);
          }

          if(this.filters.acreditado){
            this.acreditadoFilterSearch(this.filters.acreditado.idAcreditado + '', true);
          }

          this.promisePaginator.pagination.currentPage = this.filters.page || 1;
        }

        this.loadMetodos();
        this.loadEstatus();
        this.initListColumns();
        this.loadLenders();

        //validamos que no sea super admin para crear los filtros por defecto
        if(this.sessionData.isSuperAdmin){
          this.readHashParams(loadPromise);
        }
    }

    activarHijo(){
        this.hijosActivos = true;
      }
      
    public descargarListado(): void {
        if(this.promises){
          if(this.promises.length>0){

            this.visitService.descargarPromesasDePago(this.queryParamsGlobal)
                .pipe(take(1))
                .subscribe((respuesta) => {
                    this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 3000, 'success');
                }, (error: any) => {
                    this.app.showError(error);
                });
          }else{
            this.app.showSnackbar('Aviso', 'Es necesario que haya registros para descargar', 3000, 'warning');
          }
        }
        else{
            this.app.showSnackbar('Aviso', 'Es necesario que haya registros para descargar', 3000, 'warning');
        }
    }

    private initListColumns(): void {
        var listFields: any = JSON.parse(localStorage.getItem('PromiseFields')) || {};

        this.listFields.visitFolio = listFields.visitFolio == false ? false : true;
        this.listFields.lender = listFields.lender == false ? false : true;
        this.listFields.externalID = listFields.externalID == false ? false : true;
        this.listFields.clientFullName = listFields.clientFullName == false ? false : true;
        this.listFields.agentID = listFields.agentID == false ? false : true;
        this.listFields.agentFullName = listFields.agentFullName == false ? false : true;
        this.listFields.amount = listFields.amount == false ? false : true;
        this.listFields.promiseDate = listFields.promiseDate == false ? false : true;
        this.listFields.paymentMethod = listFields.paymentMethod == false ? false : true;
        this.listFields.promiseStatus = listFields.promiseStatus == false ? false : true;
        this.listFields.registerDate = listFields.registerDate == false ? false : true;
        this.listFields.comment = listFields.comment == false ? false : true;
    }

    public saveInStorage(): void {
        localStorage.setItem('PromiseFields', JSON.stringify(this.listFields));
    }

    loadFiltersDefault(){
        this.idLender = this.sessionData.idFinanciera;
        this.filters.fechaInicio = new Date();
        this.filters.fechaFin = new Date();
        this.filters.fechaInicio.setMonth(this.filters.fechaInicio.getMonth()-1);
        this.contabilizadorDeFiltrosTotales();
    }

    public readHashParams(load: boolean) {

        /*if (!load) {
            return false;
        }*/
        this.loadPromise(this.promisePaginator.pagination.currentPage > 0 ? this.promisePaginator.pagination.currentPage : 1);
    }

    public loadLenders(): void {

        this.lenderService.getAll('all').subscribe(
            res => {
                this.lenders = res;
                if(!this.sessionData.isSuperAdmin){
                    this.loadFiltersDefault();
                    this.onChangeLender(this.idLender);
                    this.readHashParams(true);
                }
            },
            err => {
                this.app.showError(err);
            }
            
        );
    }

    public loadMetodos(): void {
        this.loadings.metodosPago = true;

        this.visitService.obtenerMetodosDePago().subscribe(
            res => {
                this.metodos = res;
                this.loadings.metodosPago = false;
            },
            err => {
                this.app.showError(err);
                this.loadings.metodosPago = false;
            },
            () => this.loadings.metodosPago = false
        );
    }

    public loadEstatus(): void {
        this.loadings.estatusPromesaPago = true;

        this.promiseService.obtenerEstatusPromesas().subscribe(
            res => {
                this.estatusPromesas = res;
                this.loadings.estatusPromesaPago = false;
            },
            err => {
                this.app.showError(err);
                this.loadings.metodosPago = false;
            },
            () => this.loadings.metodosPago = false
        );
    }

    public loadPromise(page: number): void {
        if (!this.filters.financiera && this.sessionData.isSuperAdmin) {
            //this.app.showSnackbar('Aviso', 'Es necesario seleccionar una financiera', 3000, 'warning');
            return;
        }

        if (!this.datosConsulta.sortField) {
            this.datosConsulta.sortField = 'qv.inicioVisita';
        }
        let queryParams: string = '&rows=' + this.datosConsulta.pageSize;
        queryParams += this.crearURLQuery();
        this.promisePaginator.pagination.nextPage = page || 1;

        location.hash = page ? page.toString() : '1';
        this.promisePaginator.pagination.currentPage = page;
        if (!page) {
            page = 1;
        }
        this.promisePaginator.pagination.perPage = this.datosConsulta.pageSize || 100;

        if(this.filters.page != page){
          this.filters.page = page;
          this.filtrosService.guardarFiltros(this.filters);
        }
        queryParams += '&page=' + page;
        this.cargandoLista = true;
        let subscription: Subscription = this.promiseService.getPromise(this.promisePaginator.pagination, queryParams).subscribe(

            (paginator: PromisePaginator) => {
                this.promisePaginator = paginator;
                this.promises = paginator.data;
                this.cargandoLista = false;
            },
            (error) => {
                this.app.showError(error);
                this.cargandoLista = false;

                subscription.unsubscribe();
            },
            () => {
                this.cargandoLista = false;
                subscription.unsubscribe();
            }
        );
    }

    public onChangeLender(event){
        if(event){
            this.lenders.forEach(lender => {
                if(lender.idFinanciera == event){
                    this.filters.financiera = lender;
                    if (lender.financieraDemo) {
                        this.filters.excluirDemo = false;
                    }
                }
            });
        }
        else{
             this.filters.financiera = null;
        }
        this.contabilizadorDeFiltros('superadmin');
    }

    /*INICIO Metodos Grid*/
    public setSortField(sort: any) {
        this.datosConsulta.sortAsc = sort.direction;
        if (sort.active != this.datosConsulta.sortField) {
            this.datosConsulta.sortField = sort.active;
        }
        this.loadPromise(this.filters.page);
    }

    private crearURLQuery(): string {
        this.queryParamsGlobal = '';

        if (this.datosConsulta.slt) {
            this.queryParamsGlobal += (this.queryParamsGlobal ? '&' : '?') + 'slt=' + this.datosConsulta.slt;
        }

        if (this.datosConsulta.sortField) {
            this.queryParamsGlobal += (this.queryParamsGlobal ? '&' : '?') + 'orderBy=' + this.datosConsulta.sortField + (this.datosConsulta.sortAsc ? ' ' + this.datosConsulta.sortAsc : '');
        }

        if (this.filters.financiera) {
            this.queryParamsGlobal += (this.queryParamsGlobal ? '&' : '?') + 'idCliente=' + this.filters.financiera.idCliente;
        }

        if (this.filters.visitFolio && this.filters.visitFolio != '') {
            this.queryParamsGlobal += (this.queryParamsGlobal ? '&' : '?') + 'folioVisita=' + this.filters.visitFolio;
        }

        if (this.filters.acreditado) {
            this.queryParamsGlobal += (this.queryParamsGlobal ? '&' : '?') + 'idAcreditado=' + this.filters.acreditado.idAcreditado;
        }

        if (this.filters.agente) {
            this.queryParamsGlobal += (this.queryParamsGlobal ? '&' : '?') + `idAgente=${this.filters.agente.idCobrador}`;
        }

        if (this.filters.metodoPagoID) {
            this.queryParamsGlobal += (this.queryParamsGlobal ? '&' : '?') + `idMetodoPago=${this.filters.metodoPagoID}`;
        }
        if (this.filters.fechaInicio) {
            let date = new Date(this.filters.fechaInicio);
            let strDate: string = date.toISOString();
            this.queryParamsGlobal += '&fromDate=' + strDate.split('T')[0];
        }

        if (this.filters.fechaFin) {

            let date = new Date(this.filters.fechaFin);
            let strDate: string = date.toISOString();
            this.queryParamsGlobal += '&toDate=' + strDate.split('T')[0];
        }

        if (this.filters.estatusPromesaID) {
            this.queryParamsGlobal += (this.queryParamsGlobal ? '&' : '?') + `idEstatusPromesa=${this.filters.estatusPromesaID}`;
        }

        if (this.filters.soloAgentesActivos) {
            this.queryParamsGlobal += (this.queryParamsGlobal ? '&' : '?') + `soloAgentesActivos=${this.filters.soloAgentesActivos}`;
        }

        if (this.filters.excluirDemo) {
            this.queryParamsGlobal += (this.queryParamsGlobal ? '&' : '?') + `financieraDemo=false`;
        }

        return this.queryParamsGlobal;
    }

    

    public setFilter(): void {
        //console.log(this.filters)
        this.filters.filtered = true;
        this.filters.page = 1;
        this.filtrosService.guardarFiltros(this.filters);
        const camposDiferentesANull = Object.keys(this.filters).filter(campo => this.filters[campo] !== null);

        if (camposDiferentesANull.length < 4) {
            this.filtrosSideNav.toggle();
            this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar un filtro válido.`, 3000, 'warning');
            return; 
            //Visitas (Todas las visitas) Por lo menos un filtro - OJO: no aplica el checkbox Excluir demo
        }
        if(this.filters.financiera){
          this.loadPromise(this.filters.page);
        }
        this.contabilizadorDeFiltrosTotales();
    }

    public clearFilter(): void {
        Object.keys(this.filters).forEach(key => {
          if(this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera')){
            this.filters[key] = null;
          }
        });
        this.filters.page = 1;
        this.filters.excluirDemo = true;
        this.filters.soloAgentesActivos = true;
        this.filters.filtered = false;
        this.idLender = null;
        this.filtrosService.removerFiltros();
        //this.loadPromise(1);
    }

    public showFilter(): void {
        this.$filterModal.modal('show');
    }
    /*FIN Metodos Grid*/

    /*Inicio Metodos Filtros*/

    public agenteFilterSearch = function(term: string) {
        return this.agenteServ.obtenerAgentes(term, null)
          .pipe(
            take(1),
            map((res: any) => res.data.map(
              ({ idCobrador, nombreCompleto, idAgente }) => (
                { idCobrador: idCobrador, nombre: `${idCobrador} - ${nombreCompleto}`, idAgente: idAgente }
              )
            ))
        );
    }.bind(this);

    public tooglefilterAgentAvailable() {
        this.agenteFilterInput.searchService.reset();
        this.agenteFilterInput._element.nativeElement.firstChild.firstChild.focus();
    }

    /*onAgenteFilterSelected(agente: any) {
        this.agenteFilterFlag = true;
        this.filters.agenteID = agente.idCobrador;
    }*/

    resetAgenteFilter() {
        this.filters.agenteID = null;
        this.agenteFilterInput._element.nativeElement.firstChild.firstChild.value = '';
    }

    acreditadoFilterSearch = function (term: string) {
        return this.agenteServ.obtenerAcreditados(term, this.filters.financiera && this.filters.financiera.idFinanciera ? this.filters.financiera.idFinanciera: null)
          .pipe(
            take(1),
            map((res: any) => res.data.map(
              ({ idAcreditado, nombreCompleto, idCliente, idExterno}) => (
                { idAcreditado: idAcreditado, nombre: idExterno+' - '+nombreCompleto, idCliente: idCliente }
              )
            ))
          )
    }.bind(this);

    /*onAcreditadoFilterSelected(acreditado: any) {
        this.acreditadoFilterFlag = true;
        this.filters.acreditadoID = acreditado.idAcreditado;
    }*/

    resetAcreditadoFilter() {
        this.filters.acreditadoID = null;
        this.acreditadoFilterInput._element.nativeElement.firstChild.firstChild.value = '';
    }

    resetFinancieraFilter() {
      this.idLender = null;
      this.filters.financiera = null;
    }

    onPage(newPagina: any): void {
        if(newPagina.pageIndex+1 !==  this.promisePaginator.pagination.currentPage || newPagina.pageSize !== this.pageSize){
          this.pageSize = newPagina.pageSize;
            this.loadPromise(newPagina.pageIndex+1);
        }
      }
    
    onClick({ row, columnPressed }) {

       if(columnPressed === 'nombreCompletoAcreditado') {
            this.linkDetalleCuenta(row);
        } else if(columnPressed === 'folioVisita') {
            this.LinkVisitas(row);
        }else if(columnPressed === 'nombreCobrador'){
            this. linkDetalleAgente(row);
        }
    }

    linkDetalleCuenta(cuenta: any, openInNewTab: boolean = false): void {
        // Construcción del query string
        let queryString = `?idExterno=${cuenta.id}`;
        queryString += cuenta.subIdExterno != null ? `&subIdExterno=${cuenta.subIdExterno}` : "";
      
        if (this.sessionData.isSuperAdmin) {
          queryString += `&idCliente=${cuenta.idCliente}`;
        }
      
        // Construcción de la URL completa
        const url = `/promesas-pago/cuenta${queryString}`;
      
        if (openInNewTab) {
          // Abre en una nueva pestaña
          window.open(url, '_blank');
        } else {
          // Navega en la misma pestaña
          this.routeNavigate.navigateByUrl(url);
        }
    }

    linkDetalleAgente(data: any, openInNewTab: boolean = false): void {
        // Construye la URL
        const url = `/promesas-pago/agentes/${data.idCobrador}`;
      
        if (openInNewTab) {
          // Abre en una nueva pestaña
          window.open(url, '_blank');
        } else {
          // Navega en la misma pestaña
          this.routeNavigate.navigateByUrl(url);
        }
    }
    
    LinkVisitas(visita: any) {
        this.routeNavigate.navigate(['/visit/' + visita.folioVisita]);
    }

    onCtrlClick({ row, columnPressed }) {
        if(columnPressed === 'nombreCompletoAcreditado') {
            this.linkDetalleCuenta(row,true);
        } else if(columnPressed === 'nombreCobrador'){
            this. linkDetalleAgente(row, true);
        }
    }

    /*Fin Metodos Filtros */
}
