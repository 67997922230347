<app-modal [title]="data.title" [subtitle]="data.subtitle" [custom]="true">

  <mat-card class="card-ancho" >
    <mat-card-content class="contenido-cuestionario">
      <app-table
        [classContainer]="'tabla-completa'"
        [columns]="data.columns"
        [items]="data.items"
        [headers]="data.headers"
        [tableHeaders]="data.tableHeaders"
        [multiSelect]="data.multiSelect"
        [ignoreSortChange]="data.ignoreSortChange"
      >
      </app-table>
    </mat-card-content>
  </mat-card>

  <div class="action-buttons-modal" m-actions>
    <button class="boton-cancelar" mat-flat-button (click)="onClose()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
  </div>
</app-modal>
