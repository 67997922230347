import { Financiera } from './../../../financieras/shared/financiera.model';
import { SessionData } from './../../../shared/interfaces/session-data';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationService } from '../../../application/shared/application.service';
import { Observable } from 'rxjs';
import { AgentService } from '../../agent.service';
import { take } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { LenderService } from '@lender/lender.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { ProductosService } from 'src/app/catalogos/permisos-producto/productos.service';
import { IProductosAdministrador} from 'src/app/catalogos/usuarios/productos/productos.interface';

@Component({
  selector: 'vinculacion-productos.component',
  templateUrl: 'vinculacion-productos.component.html',
  styleUrls: ['vinculacion-productos.component.css'],
  providers: [AgentService, LenderService],
})
export class ModalVinculacionProductosComponent {
  public selectedProducts: any[];
  public selectedCredits: any[];
  public cargando = false;
  public idLender: number;
  public credits: any[];
  sessionData: SessionData;
  lenders: any;
  productos: IProductosAdministrador[] = [];
  cargandoProductos: boolean = false;
  financiera: any[] = []; 
  idCliente:number;
  desactivarProducto:boolean = true;

  constructor(
    private localStorageService: LocalStorageService,
    private agentService: AgentService,
    public dialogRef: MatDialogRef<ModalVinculacionProductosComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private app: ApplicationService,
    private lenderService: LenderService,
    private productosServ: ProductosService
  ) {
    
    this.sessionData = this.localStorageService.getSessionData();
    if(!this.sessionData.isSuperAdmin){
      this.idLender = this.sessionData.idFinanciera;
    }
    
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
   if(this.idLender){
    this.loadCredits();
   }

  }

  changeLender(financiera: any) {
    this.selectedCredits = null;
    if (financiera) {
      this.idLender = financiera.idFinanciera; // Actualiza el idLender
      this.idCliente =  financiera.idCliente;
      this.loadProductos();
      this.loadCredits();
    }else {
      this.idLender = null; 
      this.idCliente =  null;
      this.desactivarProducto = true;
      this.selectedProducts = null;
    }
  }

  loadProductos(): void {
    this.cargandoProductos = true;
    this.desactivarProducto = true;
    this.productosServ.obtenerProductosPorCliente(this.idCliente).pipe(
      take(1)
    ).subscribe({
      next: (res: IProductosAdministrador[]) => {
        this.productos = res;
      },
      error: (error: any) => {
        this.app.showError(error);
        this.cargandoProductos = false;
        this.desactivarProducto = false;
      },
      complete: () => {
        this.cargandoProductos = false;
        this.desactivarProducto = false;
      }
    });
  }

  private loadCredits() {
    if (this.idLender) {
      this.lenderService.obtenerTiposCreditoCliente(this.idLender).pipe(
        take(1)
      ).subscribe(
        (res: any) => {
          this.credits = res.data;
        },
        (err: any) => {
          this.app.showError(err);
        }
      );
    }
  }

  validarVinculacion() {
    let bRespuesta = true;

    if (!this.idLender) {
      this.app.showSnackbar('Aviso', 'Favor de seleccionar un cliente.', 3000, 'warning');
      bRespuesta = false;
    }else if (!this.selectedProducts || this.selectedProducts.length === 0) {
      this.app.showSnackbar('Aviso', 'Favor de seleccionar al menos un producto.', 3000, 'warning');
      bRespuesta = false;
    }else {
      this.selectedProducts.forEach(element => {
        if (element.idProducto === 15) {
            
          if (!this.selectedCredits || this.selectedCredits.length === 0) {
            this.app.showSnackbar('Aviso', 'Favor de seleccionar al menos un crédito.', 3000, 'warning');
            bRespuesta = false;
          }
        }
      });
  
    }

    
    return bRespuesta;
  }

  vincularProductos() {
    
    if (this.validarVinculacion()) {

      this.cargando = true;
      let loading: number = this.app.showLoading(`Vinculando producto...`);
      const observables: Observable<any>[] = this.data.seleccionAgentes.map((agente: any) => {
        return this.vincularProducto(agente);
      });

      forkJoin(observables).subscribe(
        (results: any[]) => {
          const contador = results.filter((result) => result === true).length;
          this.cargando = false;
          this.selectedProducts = [];
          this.selectedCredits = [];
          this.app.showSnackbar('¡Aviso!', `${contador} agentes fueron vinculados correctamente.`, 3000, 'success');
          this.app.hideLoading(loading);
          this.dialogRef.close(true);
        },
        (error) => {
          this.app.hideLoading(loading);
          this.cargando = false;
          this.app.showSnackbar('Error', 'Hubo un problema al vincular los agentes.', 3000, 'error');
        }
      );
    }
  }

  private vincularProducto(agente: any): Observable<boolean> {
    let creditosSeleccionados:any = [];

    const productosSeleccionados = {
      idsProductos: this.selectedProducts.map((product) => product.idProducto),
    };

    if (this.selectedCredits) {
      creditosSeleccionados = {
        idsTipoCredito: this.selectedCredits.map((credito) => credito.idTipoCredito),
      };
    }

    return new Observable<boolean>((observer) => {
      this.agentService.vincularProducto(agente.id, this.idLender, productosSeleccionados).pipe(
        take(1)
      ).subscribe(
        (respuesta: any) => {
          if (!respuesta.success && respuesta.error.message !== '') {
            this.app.showSnackbar('¡Aviso!', respuesta.error.message, 3000, 'warning');
          }

          if (respuesta.success &&  this.selectedCredits) {
            this.vincularTiposCredito(agente.id, creditosSeleccionados);
          }

          observer.next(true);
          observer.complete();
        },
        (error) => {
          this.dialogRef.close(false);
          this.app.showError(error);
          observer.next(false);
          observer.complete();
        }
      );
    });
  }

  private vincularTiposCredito(idAgente: any, creditosSeleccionados: any) {
    //this.setStatusMsg = `Vinculando tipos de crédito ${index + 1} de ${this.data.seleccionAgentes.length}`;
    this.agentService
      .vincularTiposCredito(idAgente, this.idLender, creditosSeleccionados)
      .pipe(take(1))
      .subscribe(
        (res: any) => {
          
        },
        (err: any) => {
          this.app.showError(err);
        }
      );
  }

  
}
