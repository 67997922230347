<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
    <mat-toolbar>
      <button class="filter-button-side" mat-flat-button
        matTooltip="Ocultar filtros"
        mat-tooltip-panel-above
        (click)="filtrosSideNav.toggle()"
      >
        <mat-icon>cancel</mat-icon>
      </button>
      Filtros
    </mat-toolbar>
    <ol class="lista-filtros-side">
      <div class="div-filtros-side">
        <li class="filtro-side">
          <mat-select-search
            class="id-card-field"
            [label]="'Cliente'"
            [placeHolder]="'Selecciona un cliente'"
            [value]="'idFinanciera'"
            [labelField]="'nombre'"
            [icon]="'account_balance'"
            [options]="lenders"
            [(ngModel)]="usuServ.filtros.idFinanciera"
            [ngModelOptions]="{standalone: true}"
          >
          </mat-select-search>

        </li>
        <li class="filtro-side">
            <mat-form-field class="pago-gestor" style="width: 100%;">
                <mat-label>Nombre</mat-label>
                <input matInput textSanitizer
                [(ngModel)]="usuServ.filtros.nombre"
                placeholder="Capture el nombre a buscar">
                <mat-icon matSuffix  class="icono-limpiar"
                *ngIf="usuServ.filtros.nombre"
                (click)="usuServ.filtros.nombre = null">clear</mat-icon>
                <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
            <!-- <div align="left" *ngIf="usuServ.filtros.nombre" class="limpiar-seleccion-kobra">
              <a (click)="usuServ.filtros.nombre = null"><i>Limpiar</i></a>
            </div> -->
        </li>
        <li class="filtro-side">
            <mat-form-field class="pago-gestor" style="width: 100%;">
                <mat-label>Apellido paterno</mat-label>
                <input matInput textSanitizer
                [(ngModel)]="usuServ.filtros.apellidoPaterno"
                placeholder="Capture el apellido paterno a buscar">
                <mat-icon matSuffix  class="icono-limpiar"
                *ngIf="usuServ.filtros.apellidoPaterno"
                (click)="usuServ.filtros.apellidoPaterno = null">clear</mat-icon>
                <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
            <!-- <div align="left" *ngIf="usuServ.filtros.apellidoPaterno" class="limpiar-seleccion-kobra">
              <a (click)="usuServ.filtros.apellidoPaterno = null"><i>Limpiar</i></a>
            </div> -->
        </li>
        <li class="filtro-side">
            <mat-form-field class="pago-gestor" style="width: 100%;">
                <mat-label>Apellido materno</mat-label>
                <input matInput textSanitizer
                [(ngModel)]="usuServ.filtros.apellidoMaterno"
                placeholder="Capture el apellido materno a buscar">
                <mat-icon matSuffix  class="icono-limpiar"
                *ngIf="usuServ.filtros.apellidoMaterno"
                (click)="usuServ.filtros.apellidoMaterno = null">clear</mat-icon>
                <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>
            <!-- <div align="left" *ngIf="usuServ.filtros.apellidoMaterno" class="limpiar-seleccion-kobra">
              <a (click)="usuServ.filtros.apellidoMaterno = null"><i>Limpiar</i></a>
            </div> -->
        </li>
        <li class="filtro-side">
            <mat-form-field class="pago-gestor" style="width: 100%;">
                <mat-label>Email</mat-label>
                <input matInput textSanitizer
                [(ngModel)]="usuServ.filtros.email"
                placeholder="Capture el email a buscar">
                <mat-icon matSuffix  class="icono-limpiar"
                *ngIf="usuServ.filtros.email"
                (click)="usuServ.filtros.email = null">clear</mat-icon>
                <mat-icon matSuffix>mail</mat-icon>
            </mat-form-field>
            <!-- <div align="left" *ngIf="usuServ.filtros.email" class="limpiar-seleccion-kobra">
              <a (click)="usuServ.filtros.email = null"><i>Limpiar</i></a>
            </div> -->
        </li>
        <li class="filtro-side">
            <mat-form-field class="pago-gestor" style="width: 100%;">
                <mat-label>Estatus</mat-label>
                <mat-select [(ngModel)]="usuServ.filtros.activo">
                    <mat-option *ngFor="let stat of usuServ.status" [value]="stat.id">
                      {{stat.nombre}}
                    </mat-option>
                </mat-select >
                <mat-icon matSuffix  class="icono-limpiar-solo"
                  *ngIf="usuServ.filtros.activo"
                  (click)="usuServ.filtros.activo = null">clear</mat-icon>
            </mat-form-field>
            <!-- <div align="left" *ngIf="usuServ.filtros.activo" class="limpiar-seleccion-kobra">
              <a (click)="usuServ.filtros.activo = null"><i>Limpiar selección</i></a>
            </div> -->
        </li>
      </div>

      <button
      class="boton-accion-front"
        style="width: 100%;"
        mat-stroked-button
        color="primary"
        (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; filtrar(1)">
          <mat-icon>filter_alt</mat-icon>
          Filtrar
      </button>
      <button
        style="width: 100%; margin-top: 10px;"
        mat-stroked-button
        (click)="limpiarFiltros()">
          <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
          Limpiar Filtros
      </button>
    </ol>
</mat-sidenav>

<div class="app content tabla kobra" *ngIf="!hijosActivos">

  <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros"
              (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
              matTooltip="Filtrar administradores"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">filter_alt</mat-icon>
              <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="usuServ.getGrid(usuServ.filtros.page ? usuServ.filtros.page : 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda"
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
  </div>

  <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['nuevo']" queryParamsHandling="preserve">
          <mat-icon class="kobra-icon">add</mat-icon>
          <span>Nuevo</span>
      </button>
      <button mat-menu-item (click)="onEdit()" queryParamsHandling="preserve"  [disabled]="!(selectedRow)">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item (click)="sucursalEditar()" [disabled]="!(selectedRow)">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Sucursales</span>
      </button>
      <button mat-menu-item [disabled]="!(selectedRow)" (click)="productoEditar()">
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Productos</span>
      </button>
      <button mat-menu-item *ngIf="selectedRow && selectedRow?.estatus === 'Inactivo'" (click)="onActivate()" queryParamsHandling="preserve">
        <mat-icon class="kobra-icon">
          check
        </mat-icon>
        <span>Activar</span>

      </button>
      <button mat-menu-item *ngIf="(selectedRow && selectedRow?.estatus === 'Activo') || !selectedRow" (click)="onDelete()" queryParamsHandling="preserve"  [disabled]="!(selectedRow)">
        <mat-icon class="kobra-delete-icon">
          do_not_disturb_on
        </mat-icon>
        <span>Inactivar</span>

      </button>

  </mat-menu>
    <app-table class="table-margen"
      [classContainer]="'tabla-completa'"
      *ngIf="usuServ.pagination"
      [loading]="usuServ.loader"
      [columns]="tabla.columns"
      [items]="usuServ.pagination?.pageData"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      (onSelectedRows)="onSelected($event)"
      (sortChange)="sortChange($event)"
      (filtersChange)="filtersChange($event)"
      (searchChange)="searchChange($event)"
      (onDoubleClick)="onDoubleClick($event)"
    >
    <!--div class="list-actions-kobra" t-left>
      <button
          mat-stroked-button
          class="kobra-button-cat"
          [routerLink]="['nuevo']" queryParamsHandling="preserve"
      >
          <mat-icon class="kobra-icon">add</mat-icon>
          Agregar
      </button>
      <button
          mat-stroked-button
          class="kobra-button-cat"
          (click)="onEdit()"
      >
          <mat-icon class="kobra-icon">edit</mat-icon>
          Editar
      </button>
      <button
          mat-stroked-button
          class="kobra-button-cat"
          (click)="onDelete()"
      >
          <mat-icon class="kobra-delete-icon">delete</mat-icon>
          Inactivar
      </button>
    </div-->


      <!--div class="ui mini icon buttons">
        <button mat-stroked-button *ngIf="sessionData.isSuperAdmin" [ngClass]="{primary: usuServ.filtros.filtered}"
          matTooltip="Filtrar"
          mat-tooltip-panel-above
          (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
        >
          <mat-icon class="kobra-icon" *ngIf="!usuServ.filtros.filtered">filter_alt_off</mat-icon>
          <mat-icon class="kobra-icon" *ngIf="usuServ.filtros.filtered">filter_alt</mat-icon>
        </button>
        <button mat-stroked-button *ngIf="sessionData.isSuperAdmin"
          matTooltip="Ayuda"
          mat-tooltip-panel-above
          class="botonAyuda"
          (click)="ayudaComentario(!ayuda)"
        >
          <mat-icon color="primary">help</mat-icon>
        </button>
      </div-->

      <div t-header>
        <mat-card *ngIf="ayuda" class="cardAyuda">
          <mat-card-content>
           <p><mat-icon color="primary">help</mat-icon>
            El módulo Administradores está diseñado para manejar eficientemente el listado de usuarios del sistema, enfocándose en aquellos con roles administrativos. Este módulo es fundamental para la gestión de accesos y control de usuarios. Para garantizar la seguridad y la autenticidad, los usuarios se dan de alta utilizando el correo institucional de la empresa. A continuación, se detallan sus principales características y funcionalidades:
            </p>
           Acciones:
           <p>
             <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a los conceptos, permitiendo aplicar filtros basados en diversos criterios.
           </p>
           <p>
             <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
           </p>
           <p>
             <mat-icon class="kobra-icon">add</mat-icon><b> Nuevo: </b> Permite la incorporación de nuevos usuarios al sistema, facilitando la expansión y actualización del equipo administrativo según sea necesario.
           </p>
           <p>
             <mat-icon class="kobra-icon">edit</mat-icon><b> Editar: </b> Esta función es crucial para mantener actualizada la información de los usuarios. Permite modificar detalles como permisos, roles, y datos personales.
           </p>
           <p>
             <mat-icon  class="kobra-icon">do_not_disturb_on</mat-icon><b> Activar/Inactivar: </b> Ofrece la posibilidad de desactivar o activar usuarios del sistema, una acción esencial para mantener la seguridad y la relevancia del estatus de los usuarios.
           </p>
         </mat-card-content>
       </mat-card>
     </div>


      <ng-container t-paginator>
        <mat-paginator
          showFirstLastButtons
          [length]="usuServ.pagination?.totalRows"
          [pageIndex]="usuServ.pagination?.page-1"
          [pageSize]="datosConsulta.rows"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>

    <app-loading [message]="'Inactivando administrador...'" *ngIf="inactivando">
    </app-loading>
    <!--mat-card-footer class="kobra-footer-catalogo" >
      <i>Inactivando administrador...</i>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-card-footer-->
</div>
