
import { MapboxCuentasComponent } from './../mapbox/cuentas/mapbox-cuentas.component';
import { FiltrosService } from '../application/shared/filtros.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

import { Account } from "./account.model";
import { FiltrosCuentas } from "./modelos/filtros-cuentas.model";
import { AccountService } from "./account.service";
import { AccountPaginator } from "./account.paginator";
import { OperationTag } from "../operation-tag/operation-tag.model";
import { AgentService } from "../agent/agent.service";
import { Lender } from "../lender/lender.model";
import { LenderService } from "../lender/lender.service";
import { VisitService } from '../visit/visit.service';
import { MessageService } from "../shared/message/message.service";
import { StateService } from '../state/state.service';
import { MunicipalityService } from '../municipality/municipality.service';
import { environmentSelector } from '../../environments/environment.selector';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { FormValidation } from '../core/form-validation/form-validation';
import { ApplicationService } from '../application/shared/application.service';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { SessionData } from '../shared/interfaces/session-data';
import { take } from 'rxjs/internal/operators/take';
import { map } from 'rxjs/internal/operators/map';
import { ModalAsignarCuentaComponent } from './modales/asignar/asignar-cuenta.component';
import { ModalEstatusCuentaComponent } from './modales/estatus/estatus-cuenta.component';
import { ModalEtiquetaOperacionesComponent } from './modales/etiqueta-operaciones/etiqueta-operaciones.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import * as turf from '@turf/turf';
import { CuentaService } from "../cuentas/shared/cuenta.service";
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';
import { Router, NavigationExtras } from '@angular/router';
import { Location } from '@angular/common';
import { MatSidenav } from '@angular/material/sidenav';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';


@Component({
    selector: 'account-list',
    templateUrl: 'account-list.component.html',
    styleUrls: [
        'account-list.component.css'
    ],
    providers: [
        AccountService,
        AgentService,
        LenderService,
        VisitService,
        AgentService,
        StateService,
        MunicipalityService,
        CuentaService
    ]
})

export class AccountListComponent implements OnInit {
    sessionData: SessionData;
    filtrosAbiertos: boolean = false;
    public pageSize: number = 50;
    private environment = environmentSelector();

    public accountPaginator: AccountPaginator;
    public accounts: any;
    public accountsSelected: Account[];
    public agentSearchText: string;
    public assignComment: string;
    public assignMessage: string;
    public idLender: any = null;
    public filterLender: any = null;
    public filterVisibility: any = null;
    public filterAccountAssignedEndDate: Date;
    public filterAccountAssignedStartDate: Date;
    public filterAccountEndDate: Date;
    public filterAccountStartDate: Date;
    public filterEstatus: any = null;
    public filterGeoloc: any = null;
    public filterProductType: any = null;
    public filterTag: any = null;
    public dibujando = false;
    public eventoCreado = false;

    public httpError: any;
    public lenders: Lender[];
    public listFields: any = {};
    public listFiltered: boolean;
    public search: string;
    public selectedAgent: any = null;
    public selectedStatus: any = null;
    public selectedTag: any = null;
    public showActions: boolean;
    public sortField: string;
    public sortAsc: string;
    public tags: OperationTag[];
    public etiquetas: Array<any>;
    public sucursales: Array<any>;
    public today: string = new Date().toISOString();

    public fechaCreacionMaxDate: Date;
    public fechaCreacionMinDate: Date;
    public fechaAsignacionMaxDate: Date;
    public fechaAsignacionMinDate: Date;
    public fechaImportacionMaxDate: Date;
    public fechaImportacionMinDate: Date;
    public fechaAsignacionCuentaMinDate: Date = new Date();
    public fechaAsignacionCuentaMaxDate: Date;

    public financieraVld: FormValidation;
    public visibilidadVld: FormValidation;
    public etiquetaVld: FormValidation;
    public estatusVld: FormValidation;
    public geolocVld: FormValidation;
    public tipoCuentaVld: FormValidation;
    public fechaCreacionVld: FormValidation;
    public fechaAsignacionVld: FormValidation;

    public tagLoader = false;
    public setTagMsg: string;

    public labelColonia: string;
    public labelEstado: string;
    public labelMunicipio: string;
    public labelCodigoPostal: string;
    public pais: string;
    public nonSelectedTagWarning: boolean = false;
    public estados: any[];
    public municipios: any[];
    public establecerVisita: boolean = true;
    public establecerFecha: boolean = true;
    public styleModel: string = "hidden";
    public formAsignacion: FormGroup;
    public horariosVisitas = {};
    public fechaVisita: string;
    public fechaLimite: string;
    public numDiasLimite: number;
    public idCobrador: number;
    public momentFecha;
    public diasVencimiento: number;
    public mapHeight: number = 600;
    public asignarTodasLasCuentas = false;
    public loadingEtiquetasCuenta: boolean = false;
    public etiquetasCuenta = [];
    public geoCercaACrear: any = {};
    public contadorCuentas: number = 0;
    public contadorSeleccion: number = 0;
    public cuentasSeleccionadas: number = 0;
    public asignarSeleccion: boolean = true;
    public redInterna: boolean = false;
    public motivos: any;
    public etiquetasOperaciones: any;
    public ayuda: boolean = false;
    private isLoad = true;
    public isLoadCuentas = false;
    public hijosActivos: boolean = false;
    public tabla: any;
    public tablaCuentas: any = [];
    public isLoadCuentasMapa = false;
    public altoVentana: number = 0;
    itemsSelected: Array<any>[] = [];
    public queryParamsGlobal: string;

    @ViewChild('mapaCuentas') private mapaCuentas: MapboxCuentasComponent;

    @ViewChild('agenteFilterInput') private agenteFilterInput: any;

    @ViewChild('asignarAgenteFilterInput') private asignarAgenteFilterInput: any;
    private asignarAgenteFilterFlag = false;
    private asignarAgenteFilterFound: any;

    @ViewChild('acreditadoFilterInput') private acreditadoFilterInput: any;

    @ViewChild('grupoFilterInput') private grupoFilterInput: any;

    @ViewChild('filtrosSideNav') filtrosSideNav!: MatSidenav;

    public products: Array<any>;
    public loadingProducts: boolean;
    filtrosLimpios: string = '';
    slt: string = 'lenderID,lenderName,productName, idProducto,accountID,clientID,idCliente,idAcreditadoPG,idCuentaPG,clientFullName,externalID,accountStatus,accountCreatedAt,agentID,agentFullName,takenAccountTakenDate,takenAccountExpirationDate,accountDaysInPlatform,accountDaysTaken,city, municipality,state, suburb,clientAddressGeoloc,operationTagID,operationTagName,accountAssignedDate,visibility, clienteActivo, fecVisita, hrInicio1, hrFin1, hrInicio2, hrFin2, totalVisitas, sucursal, grupo, diasMoratorios, lat, lng, calle, numeroInterior, numeroExterior, cp,nombreEtiquetaCuenta, subExternalID, folioDomicilio, idDomicilioAcreditado, folioDomicilio, tipoCobertura, idAcreditadoCuentaDomicilio, domicilioCompleto, telefonoCelular';
    adip: string = '"accountDaysInPlatform"';

    public subscribeRouterParamsBounded: Function;

    public loadings: any = {
        sucursales: false,
        etiquetas: false,
        municipios: false,
        estados: true
    }

    public filtros: FiltrosCuentas;
    public fechas = {
        fechaCreacionInicioFormateada: ''
    }

    public contabilizadorFiltros: FiltersContabilizer;
    public totalFiltros: number = 0;


    constructor(
        private app: ApplicationService,
        private filtrosService: FiltrosService,
        private localStorageService: LocalStorageService,
        private accountService: AccountService,
        private lenderService: LenderService,
        private visitService: VisitService,
        private messageService: MessageService,
        private rootElement: ElementRef,
        private stateService: StateService,
        private municipalityService: MunicipalityService,
        private agenteServ: AgentService,
        public dialog: MatDialog,
        private cuentaService: CuentaService,
        private dateAdapter: DateAdapter<Date>,
        private router: Router,
        private location: Location
    ) {
        this.accounts = [];
        this.labelColonia = this.environment.labelColonia;
        this.labelMunicipio = this.environment.labelMunicipio;
        this.labelEstado = this.environment.labelEstado;
        this.labelCodigoPostal = this.environment.labelCodigoPostal;
        this.pais = this.environment.pais;
        this.crearTabla();
        this.altoVentana = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
        this.fechaCreacionMaxDate = new Date();
        this.fechaAsignacionMaxDate = new Date();
        this.fechaImportacionMaxDate = new Date();
        this.sessionData = this.localStorageService.getSessionData();
        this.dateAdapter.setLocale('es-MX');
        this.filtros = new FiltrosCuentas();
        var filtrosString = JSON.stringify(this.filtros);
        this.filtrosLimpios = this.utf8ToBase64(filtrosString);
        this.contabilizadorFiltros = new FiltersContabilizer();
        this.agregarContabilizadoresDeFiltros();
        this.subscribeRouterParamsBounded = this.subscribeRouterParams.bind(this);
        this.contabilizadorDeFiltrosTotales();
    }


    private agregarContabilizadoresDeFiltros(){
        this.contabilizadorFiltros.add('Súper Administrador', 'superAdmin', [
            'idCliente',
            'excludeFinancierasDemo'
        ]);

        this.contabilizadorFiltros.add('Generales', 'generales', [
            'idExterno',
            'visibilidad',
            'estatus',
            'geoloc',
            'tipoCuenta',
            'sucursal',
            'etiquetaKobra',
            'idEtiquetaCuenta'
        ]);

        this.contabilizadorFiltros.add('Cuenta', 'cuenta', [
            'acreditado',
            'diasMoratorios'
        ]);

        this.contabilizadorFiltros.add('Grupo', 'grupo', [
            'idGrupo'
        ]);

        this.contabilizadorFiltros.add('Ubicación', 'ubicacionMX', [
            'idEstado',
            'idMunicipio',
            'codigoPostal',
            'colonia'
        ]);
        this.contabilizadorFiltros.add('Ubicación', 'ubicacion', [
            'estado',
            'municipio',
            'codigoPostal',
            'colonia'
        ]);

        this.contabilizadorFiltros.add('Fechas creación', 'fechasC', [
            'fechaCreacionInicio',
            'fechaCreacionFin'
        ]);

        this.contabilizadorFiltros.add('Fechas asignación', 'fechasA', [
            'fechaAsignacionInicio',
            'fechaAsignacionFin'
        ]);

        this.contabilizadorFiltros.add('Fechas importación', 'fechasI', [
            'fechaImportacionInicio',
            'fechaImportacionFin'
        ]);

        this.contabilizadorFiltros.add('Fechas total', 'fechasTotal', [
            'fechaImportacionInicio',
            'fechaImportacionFin',
            'fechaCreacionInicio',
            'fechaCreacionFin',
            'fechaAsignacionInicio',
            'fechaAsignacionFin'
        ]);

        this.contabilizadorFiltros.add('Agente', 'agente', [
            'agente'
        ]);
    }

    public contabilizadorDeFiltros(filtroName: string){
        return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filtros);
      }


    public contabilizadorDeFiltrosTotales():void{


        let totalFiltrosArray: number[] = [];
        if(this.sessionData.isSuperAdmin){
            totalFiltrosArray.push( this.contabilizadorDeFiltros('superAdmin') );
         }

        totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('cuenta') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('grupo') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('ubicacionMX') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('ubicacion') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('fechasC') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('fechasA') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('fechasI') );
        totalFiltrosArray.push( this.contabilizadorDeFiltros('agente') );

        this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    }



    private utf8ToBase64(str) {
        return btoa(unescape(encodeURIComponent(str)));
    }

    ngOnInit() {
        this.altoVentana = this.altoVentana * (1 - 0.3);
        this.loadMotivos();
        this.search = '';
        this.sortField = 'ac.fec_registro';
        this.sortAsc = 'desc';
        this.accountPaginator = new AccountPaginator();
        this.mapHeight = window.innerHeight * .70;
        this.filtrosService.obtenerFiltrosGardados<FiltrosCuentas>(this.filtros, this.subscribeRouterParamsBounded);

    }

    crearTabla() {
        this.tablaCuentas = {
            headers: false,
            multiSelect: true,
            columns: [
                {
                    name: 'Cliente',
                    key: 'lender.name',
                    hide: false,
                    sortName: 'cli.nombre'
                },
                {
                    name: 'ID Cuenta',
                    key: 'client.externalID',
                    hide: false,
                    type: 'link',
                    sortName: 'a.id_externo_cliente'
                },
                {
                    name: 'ID Subcuenta',
                    key: 'client.subExternalID',
                    hide: false,
                    sortName: 'ac.sub_id_externo_cliente'
                },
                {
                    name: 'Folio Domicilio',
                    key: 'client.address.folioDomicilio',
                    hide: false,
                    sortName: 'acd.folio_domicilio'
                }, {
                    name: 'Cuenta',
                    key: 'client.fullName',
                    hide: false,
                    sortName: 'a.nombre_completo'
                }, {
                    name: 'Fecha de Creación',
                    key: 'createdAt',
                    type: 'dateTime',
                    hide: false,
                    sortName: 'ac.fec_registro'
                }, {
                    name: 'Producto',
                    key: 'product.name',
                    hide: false,
                    sortName: 'p.nombre'
                }, {
                    name: 'Estatus',
                    key: 'status',
                    hide: false,
                    type: 'estatus',
                    sortName: 'es.nombre'
                }, {
                    name: 'Etiqueta Kobra',
                    key: 'tag.name',
                    hide: false,
                    sortName: 'eo.nombre'
                }, {
                    name: 'Etiqueta Cuenta',
                    key: 'nombreEtiquetaCuenta',
                    hide: false,
                    sortName: 'ec.nombre'
                }, {
                    name: 'Grupo',
                    key: 'grupo',
                    hide: false,
                    sortName: 'cg.nombre'
                }, {
                    name: 'Visitas',
                    key: 'totalVisitas',
                    hide: false,
                    sortName: 'a.total_visitas'
                }, {
                    name: 'Sucursal',
                    key: 'sucursal',
                    hide: false,
                    sortName: 's.nombre'
                }, {
                    name: 'Agente',
                    key: 'taken.agent.fullName',
                    hide: false,
                    sortName: 'COALESCE(ag1.id_externo_kobra,ag2.id_externo_kobra)'
                }, {
                    name: 'Fecha Asignada',
                    key: 'assigned.assignedDate',
                    hide: false,
                    type: 'dateTime',
                    sortName: 'acas.fec_registro'
                }, {
                    name: 'Fecha Apartada',
                    key: 'taken.takenDate',
                    hide: false,
                    type: 'dateTime',
                    sortName: 'aca.fec_registro'
                }, {
                    name: 'Fecha Vencimiento',
                    key: 'taken.expirationDate',
                    hide: false,
                    type: 'date',
                    sortName: 'aca.fec_limite'
                }, {
                    name: 'Fecha Visita',
                    key: 'assigned.fecVisita',
                    hide: false,
                    type: 'dateTime',
                    sortName: 'avh.fec_visita'
                }, {
                    name: 'Días Restantes',
                    key: 'daysUntilExpiration',
                    hide: false,
                    sortName: 'aca.fec_limite'
                }, {
                    name: 'Días en Plataforma',
                    key: 'daysInPlatform',
                    hide: false,
                    sortName: this.adip
                }, {
                    name: 'Días en Apartada',
                    key: 'daysTaken',
                    hide: false,
                    sortName: 'aca.fec_registro'
                }, {
                    name: 'Días Moratorios',
                    key: 'daysPastDue',
                    hide: false,
                    sortName: 'ac.dias_moratorios'
                }, {
                    name: 'Domicilio',
                    key: 'client.address.fullAddress',
                    hide: false
                }, {
                    name: this.labelMunicipio,
                    key: 'client.address.municipality',
                    hide: false,
                    sortName: 'COALESCE(mun.nombre,acd.municipio)'
                }, {
                    name: this.labelEstado,
                    key: 'client.address.state',
                    hide: false,
                    sortName: 'COALESCE(est.nombre,acd.estado)'
                }, {
                    name: this.labelColonia,
                    key: 'client.address.suburb',
                    hide: false,
                    sortName: 'COALESCE(col.nombre,acd.colonia)'
                }, {
                    name: 'Teléfono Celular',
                    key: 'telefonoCelular',
                    hide: false,
                    sortName: 'a.tel_cel'
                }, {
                    name: 'Tipo Cobertura',
                    key: 'tipoCobertura',
                    hide: false,
                    sortName: 'tc.nombre'
                }, {
                    name: 'Geolocalización',
                    key: 'coordenadas',
                    hide: false,
                    type: 'link',
                    sortName: 'acd.lat'
                }, {
                    name: 'Visibilidad',
                    key: 'visibility',
                    hide: false

                }
            ]
        }
    }

    dibujarGeoCerca() {
        const data = this.mapaCuentas.draw.getAll();
        this.geoCercaACrear = {
            type: 'Polygon',
            coordinates: [data.features[0].geometry.coordinates[0]]
        }
        this.revisarCuentasEnRango();
    }

    revisarCuentasEnRango() {
        const turfPolygon = turf.polygon(this.geoCercaACrear.coordinates, { name: 'polygon' });


        for (let i = 0; i < this.accounts.length; i++) {
            const cuenta = this.accounts[i];
            const turfPoint = [cuenta.lng, cuenta.lat];
            if (turf.inside(turfPoint, turfPolygon)) {
                cuenta.selected = true;
                this.contadorCuentas++;
            };
        }

        this.cuentasSeleccionadas = this.contadorCuentas + this.contadorSeleccion;

        if (this.cuentasSeleccionadas > 300) {
            this.asignarSeleccion = false;
            this.app.showSnackbar("Aviso", "No se permite seleccionar más de 300 cuentas, favor de reducir tu selección.", 6000, 'warning');
        }

        this.mapaCuentas.reniniciarTodosLosLayer(this.accounts);
        this.mapaCuentas.draw.deleteAll();
        this.dibujando = false;
    }

    formatearFecha() {
        this.fechas.fechaCreacionInicioFormateada = moment(this.filtros.fechaCreacionInicio).format('DD/MM/YYYY');
    }

    public onInputLimite(): void {
        if (+this.filtros.diasMoratorios > 9999) {
            setTimeout(() => {
                this.filtros.diasMoratorios = 9999 + '';
            }, 10);
        }
    }

    public obtenerClienteRedInterna() {

        if (!this.sessionData.isSuperAdmin) {

            this.lenderService.obtenerClienteRedInterna(this.sessionData.idCliente)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.redInterna = res.data.redInterna;
                }, (err: any) => {
                    this.app.showError(err);
                });
        }
    }


    private subscribeRouterParams(filtrosNuevos: FiltrosCuentas): void {
        var iniciar = false;
        if (filtrosNuevos) {
            this.listFiltered = true;
            iniciar = true;
            this.filtros = filtrosNuevos;
            if (this.filtros.mostrarMapa) {
                this.pageSize = 1000;
            }
            if (this.filtros.financiera)
                this.idLender = this.filtros.financiera.idFinanciera;
            this.accountPaginator.pagination.currentPage = this.filtros.page || 1;

            /*if (this.filtros.idCobrador) {
                this.agenteFilterSearch(this.filtros.idCobrador + '', true);
            }*/

            /*if (this.filtros.idAcreditado) {
                this.acreditadoFilterSearch(this.filtros.idAcreditado + '', true);
            }*/

            /*if (this.filtros.idGrupo) {
                this.grupoFilterSearch(this.filtros.idGrupo + '', true);
            }*/

            if (this.filtros.idMunicipio) {
                iniciar = false;
                this.loadMunicipios(true);
            }
            else if (this.filtros.idEstado) {
                iniciar = false;
                this.loadEstados(true);
            }
        }

        if (!(this.sessionData.isSuperAdmin)) {
            this.filtros.excludeFinancierasDemo = false;
        }

        this.initListColumns();
        this.loadMotivos();


        this.loadLenders();
        if (this.sessionData.isSuperAdmin) {
            this.loadCombos(iniciar);
        }

        this.isLoad = false;
    }

    private loadCombos(iniciar = true) {
        this.loadSucursales();
        this.cargarEtiquetasCuenta();
        this.loadProducts();
        this.loadEstados();
        if (iniciar) {
            this.readHashParams();
        }
    }


    public selectedAgentDropdown(agent: number): void {
        if (agent) {
            this.selectedAgent = +agent;
        }
    }


    public clearFilter(): void {
        Object.keys(this.filtros).forEach(key => {
            if ((this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'idCliente' && key != 'financiera'))) {
                this.filtros[key] = null;
            }
        });
        this.filtros.excludeFinancierasDemo = this.sessionData.isSuperAdmin ? true : false;
        this.filtros.page = 1;
        this.filtrosService.removerFiltros();
        this.idLender = null;
        this.listFiltered = false;

    }

    public createQueryFilter(): string {
        this.queryParamsGlobal = "";

        if (this.sortField) {
            this.queryParamsGlobal += '&orderBy=' + this.sortField + (this.sortAsc ? ' ' + this.sortAsc : '');
        }

        if (this.filtros.financiera) this.queryParamsGlobal += '&idFinanciera=' + this.filtros.financiera.idFinanciera.toString();
        if (this.filtros.idExterno) this.queryParamsGlobal += '&idExterno=' + this.filtros.idExterno.toString();
        if (this.filtros.visibilidad) this.queryParamsGlobal += '&visibilidad=' + this.filtros.visibilidad.toString();
        if (this.filtros.etiqueta) this.queryParamsGlobal += '&idEtiqueta=' + this.filtros.etiqueta.toString();
        if (this.filtros.etiquetaKobra) this.queryParamsGlobal += '&idOperationTag=' + this.filtros.etiquetaKobra.toString();
        if (this.filtros.idEtiquetaCuenta) this.queryParamsGlobal += '&idEtiquetaCuenta=' + this.filtros.idEtiquetaCuenta.toString();
        if (this.filtros.geoloc != null) this.queryParamsGlobal += '&geoloc=' + this.filtros.geoloc.toString();
        if (this.filtros.tipoCuenta) this.queryParamsGlobal += '&idProduct=' + this.filtros.tipoCuenta.toString();
        if (this.filtros.idCuenta) this.queryParamsGlobal += '&idCuenta=' + this.filtros.idCuenta.toString();
        if (this.filtros.agente) this.queryParamsGlobal += '&idCobrador=' + this.filtros.agente.idAgente.toString();
        //if (this.filtros.primerNombreCobrador) this.queryParamsGlobal += '&primerNombreCobrador=' + this.filtros.primerNombreCobrador.toString();
        //if (this.filtros.segundoNombreCobrador) this.queryParamsGlobal += '&segundoNombreCobrador=' + this.filtros.segundoNombreCobrador.toString();
        //if (this.filtros.apellidoPaternoCobrador) this.queryParamsGlobal += '&apellidoPaternoCobrador=' + this.filtros.apellidoPaternoCobrador.toString();
        //if (this.filtros.apellidoMaternoCobrador) this.queryParamsGlobal += '&apellidoMaternoCobrador=' + this.filtros.apellidoMaternoCobrador.toString();
        //if (this.filtros.nombreAcreditado) this.queryParamsGlobal += '&nombreAcreditado=' + this.filtros.nombreAcreditado.toString();
        //if (this.filtros.apellidoPaternoAcreditado) this.queryParamsGlobal += '&apellidoPaternoAcreditado=' + this.filtros.apellidoPaternoAcreditado.toString();
        //if (this.filtros.apellidoMaternoAcreditado) this.queryParamsGlobal += '&apellidoMaternoAcreditado=' + this.filtros.apellidoMaternoAcreditado.toString();
        if (this.filtros.acreditado) this.queryParamsGlobal += '&idAcreditado=' + this.filtros.acreditado.idAcreditado.toString();
        if (this.filtros.financiera) this.queryParamsGlobal += '&idCliente=' + this.filtros.financiera.idCliente.toString();
        if (this.filtros.estatus) this.queryParamsGlobal += '&cuentaEstatus=' + this.filtros.estatus.toString();

        if (this.pais != 'CO') {
            if (this.filtros.idEstado) this.queryParamsGlobal += '&estado=' + this.estados.find(estado => estado.idEstado == this.filtros.idEstado).nombre;
            if (this.filtros.idMunicipio) this.queryParamsGlobal += '&municipio=' + this.municipios.find(municipio => municipio.idMunicipio == this.filtros.idMunicipio).nombre;
            if (this.filtros.codigoPostal) this.queryParamsGlobal += '&codigoPostal=' + this.filtros.codigoPostal.toString();
            if (this.filtros.colonia) this.queryParamsGlobal += '&colonia=' + this.filtros.colonia.toString();
        }
        else {
            if (this.filtros.estado) this.queryParamsGlobal += '&estado=' + this.filtros.estado.toString();
            if (this.filtros.ciudad) this.queryParamsGlobal += '&ciudad=' + this.filtros.ciudad.toString();
            if (this.filtros.municipio) this.queryParamsGlobal += '&municipio=' + this.filtros.municipio.toString();
            if (this.filtros.colonia) this.queryParamsGlobal += '&colonia=' + this.filtros.colonia.toString();
        }
        if (this.filtros.excludeFinancierasDemo) this.queryParamsGlobal += '&excludeFinancierasDemo=' + this.filtros.excludeFinancierasDemo.toString();
        if (this.filtros.sucursal) this.queryParamsGlobal += '&idSucursal=' + this.filtros.sucursal.toString();
        if (this.filtros.idGrupo) this.queryParamsGlobal += '&idGrupo=' + this.filtros.idGrupo.toString();

        if (this.filtros.fechaCreacionInicio) this.queryParamsGlobal += '&cuentaCreacionFechaInicio=' + moment(this.filtros.fechaCreacionInicio).format('YYYY-MM-DD');
        if (this.filtros.fechaCreacionFin) {
            /*let date: Date = new Date(this.filtros.fechaCreacionFin.getTime());
            date.setDate(this.filtros.fechaCreacionFin.getDate() + 1);*/
            this.queryParamsGlobal += '&cuentaCreacionFechaFin=' + moment(this.filtros.fechaCreacionFin).format('YYYY-MM-DD');
        }
        if (this.filtros.fechaAsignacionInicio) this.queryParamsGlobal += '&cuentaAsignadaFechaInicio=' + moment(this.filtros.fechaAsignacionInicio).format('YYYY-MM-DD');
        if (this.filtros.fechaAsignacionFin) {
            /*let date: Date = new Date(this.filtros.fechaAsignacionFin.getTime());
            date.setDate(this.filtros.fechaAsignacionFin.getDate() + 1);*/
            this.queryParamsGlobal += '&cuentaAsignadaFechaFin=' + moment(this.filtros.fechaAsignacionFin).format('YYYY-MM-DD');
        }
        if (this.filtros.fechaImportacionInicio) this.queryParamsGlobal += '&cuentaImportadaFechaInicio=' + moment(this.filtros.fechaImportacionInicio).format('YYYY-MM-DD');
        if (this.filtros.fechaImportacionFin) {
            /*let date: Date = new Date(this.filtros.fechaImportacionFin.getTime());
            date.setDate(this.filtros.fechaImportacionFin.getDate() + 1);*/
            this.queryParamsGlobal += '&cuentaImportadaFechaFin=' + moment(this.filtros.fechaImportacionFin).format('YYYY-MM-DD');
        }
        if (this.filtros.diasMoratorios) this.queryParamsGlobal += '&diasMoratorios=' + parseInt(this.filtros.diasMoratorios);

        if (this.filtros.fecVisita) this.queryParamsGlobal += '&fecVisita=' + this.filtros.fecVisita.toISOString().split('T')[0];

        this.queryParamsGlobal += `&slt=${this.slt}`;

        return this.queryParamsGlobal;
    };

    public descargarListado(opcion: any): void {
        if(!this.idLender && opcion == 1){
            this.app.showSnackbar('Aviso', 'No se puede descargar el listado, no se ha seleccionado un cliente.', 3000, 'warning');
            return;
        }

        let queryParams = `?start=0&opcion=${opcion}` + this.queryParamsGlobal;

        if (this.accounts) {
            if (this.accounts.length > 0) {
                this.accountService.descargarExcelCuentas(queryParams)
                    .pipe(take(1))
                    .subscribe((respuesta) => {
                        this.app.showSnackbar("Aviso", "Tu archivo estará listo en unos momentos, para poder descargarlo ir a la opción de descargas.", 10000, 'success');
                    }, (error: any) => {
                        this.app.showError(error);
                    });
            } else {
                this.app.showSnackbar("Aviso", "No hay resultados para descargar", 5000, 'warning');
            }
        } else {
            this.app.showSnackbar("Aviso", "No hay resultados para descargar", 5000, 'warning');
        }
    }



    private initListColumns(): void {
        let listFields: any = JSON.parse(localStorage.getItem('accountListFields')) || {};
        this.listFields.accountCreatedAt = listFields.accountCreatedAt == false ? false : true;
        this.listFields.lenderName = listFields.lenderName == false ? false : true;
        this.listFields.nameProduct = listFields.nameProduct == false ? false : true;
        this.listFields.clientID = listFields.clientID == false ? false : true;
        this.listFields.externalID = listFields.externalID == false ? false : true;
        this.listFields.clientFullName = listFields.clientFullName == false ? false : true;
        this.listFields.accountStatus = listFields.accountStatus == false ? false : true;
        this.listFields.accountTag = listFields.accountTag == false ? false : true;
        this.listFields.agentID = listFields.agentID == false ? false : true;
        this.listFields.agentFullName = listFields.agentFullName == false ? false : true;
        this.listFields.accountTakenDate = listFields.accountTakenDate == false ? false : true;
        this.listFields.accountAssignedDate = listFields.accountAssignedDate == false ? false : true;
        this.listFields.accountExpirationDate = listFields.accountExpirationDate == false ? false : true;
        this.listFields.accountDaysInPlatform = listFields.accountDaysInPlatform == false ? false : true;
        this.listFields.accountUntilFirstVisit = listFields.accountUntilFirstVisit == false ? false : true;
        this.listFields.accountDaysTaken = listFields.accountDaysTaken == false ? false : true;
        this.listFields.accountMoraDays = listFields.accountMoraDays == false ? false : true;
        this.listFields.clientLocation = listFields.clientLocation == false ? false : true;
        this.listFields.clientCity = listFields.clientCity == false ? false : true;
        this.listFields.clientMunicipality = listFields.clientMunicipality == false ? false : true;
        this.listFields.clientState = listFields.clientState == false ? false : true;
        this.listFields.clientSuburb = listFields.clientSuburb == false ? false : true;
        this.listFields.clientAddressGeoloc = listFields.clientAddressGeoloc == false ? false : true;
        this.listFields.visibility = listFields.visibility == false ? false : true;
        this.listFields.fecVisita = listFields.fecVisita == false ? false : true;
        this.listFields.visitGroup = listFields.visitGroup == false ? false : true;
        this.listFields.countVisits = listFields.countVisits == false ? false : true;
        this.listFields.sucursal = listFields.sucursal == false ? false : true;
        this.listFields.nombreEtiquetaCuenta = listFields.nombreEtiquetaCuenta == false ? false : true;
    }

    public loadAccounts(page?: number, next?: () => void, deactivateHijos: boolean = false): void {

        page = page || 1;
        let queryParams: string = '&rows=' + this.pageSize + '&page=' + page;
        this.isLoadCuentas = true;
        //Se agrega para validar que no haya loadings antes de cargar las visitas en caso de que se vayan a consultar
        queryParams += this.createQueryFilter();
        this.accountPaginator.pagination.nextPage = page || 1;

        if (page != this.filtros.page || deactivateHijos) {
            this.filtros.page = page;
            this.filtrosService.guardarFiltros(this.filtros);
        }
        // this.loaderService.showHttpLoader();
        // this.httpError = null;
        //let cargando = this.app.showLoading('Cargando cuentas...');
        this.accountService
            .obtenerListado(this.accountPaginator.pagination, queryParams, this.pageSize)
            .subscribe(
                (paginator: AccountPaginator) => {
                    this.accountPaginator = paginator;
                    let accounts: Account[] = paginator.data;

                    this.accounts = accounts;

                    if (this.filtros.mostrarMapa) {
                        this.filtros.mostrarMapa = false;
                        this.filtrosService.guardarFiltros(this.filtros);
                    }

                    this.listFiltered = accounts.length > 0;

                    // this.loaderService.hideHttpLoader();
                    //this.allAccountsSelected = false;
                    //this.app.hideLoading(cargando)
                    //this.checkSelecteds();
                    if (next) {
                        next();
                    }

                },
                (error) => {
                    this.app.showError(error);
                    this.isLoadCuentas = false;
                },
                () => this.isLoadCuentas = false
            );
    }

    consultarCuentasMapa(inicio: number, contadorPagina: number) {
        let queryParams: string = this.createQueryFilter();
        let rows = 1000;
        let start = inicio;
        let page = contadorPagina;
        //let cargando = this.app.showLoading('Cargando cuentas...');
        this.isLoadCuentasMapa = true;

        this.accountService.obtenerCuentasMapa(start, rows, page, queryParams)
            .pipe(take(1))
            .subscribe((response: any) => {

                for (let i = 0; i < response.pageData.length; i++) {
                    if (response.pageData[i].lat && response.pageData[i].lng) {
                        let account: Account = new Account();
                        account.lender.nombre = response.pageData[i].lenderName;
                        account.clienteActivo = response.pageData[i].clienteActivo;
                        account.id = response.pageData[i].accountID;
                        account.status = response.pageData[i].accountStatus;
                        account.client.idAcreditado = response.pageData[i].clientID;
                        account.client.externalID = response.pageData[i].externalID;
                        account.client.subExternalID = response.pageData[i].subExternalID;
                        account.client.fullName = response.pageData[i].clientFullName;
                        account.taken.agent.id = response.pageData[i].agentID;
                        account.taken.agent.fullName = response.pageData[i].agentFullName;
                        account.lat = response.pageData[i].lat;
                        account.lng = response.pageData[i].lng;
                        account.idCliente = response.pageData[i].idCliente;
                        account.idAcreditadoPG = response.pageData[i].idAcreditadoPG;
                        account.idCuentaPG = response.pageData[i].idCuentaPG;
                        account.grupo = response.pageData[i].grupo;
                        account.sucursal = response.pageData[i].sucursal;
                        account.client.address.fullAddress = (response.pageData[i].calle ? response.pageData[i].calle + ' ' : '') +
                            (response.pageData[i].numeroExterior ? response.pageData[i].numeroExterior + ', ' : '') +
                            (response.pageData[i].numeroInterior ? response.pageData[i].numeroInterior + ', ' : '') +
                            (response.pageData[i].suburb ? response.pageData[i].suburb + ' ' : '') +
                            (response.pageData[i].suburb ? response.pageData[i].cp + ', ' : '') +
                            (response.pageData[i].municipality ? response.pageData[i].municipality + ', ' : '') +
                            (response.pageData[i].state ? response.pageData[i].state + '.' : '');
                        account.client.address.folioDomicilio = response.pageData[i].folioDomicilio;
                        account.client.address.idDomicilioAcreditado = response.pageData[i].idDomicilioAcreditado;
                        account.domicilios = [{ idDomicilioAcreditado: response.pageData[i].idDomicilioAcreditado }];
                        account.client.address.folioDomicilio = response.pageData[i].folioDomicilio;
                        this.accounts.push(account);
                    }

                }

                start += rows;
                page = page + 1;

                if (response.pageData.length < rows) {

                    //this.allAccountsSelected = false;
                    //this.checkSelecteds();

                    if (this.filtros.mostrarMapa && this.mapaCuentas) {
                        this.mapaCuentas.reniniciarTodosLosLayer(this.accounts);
                    }

                    return;
                } else {
                    this.consultarCuentasMapa(start, page);
                }

            }, (err: any) => {
                this.app.showError(err);
                this.isLoadCuentasMapa = false;
            },
                () => this.isLoadCuentasMapa = false
            );
    }


    public loadSucursales() {
        if (this.filtros.financiera) {
            let uri = '/sucursales';
            this.loadings.sucursales = true;

            uri += `?idCliente=${this.filtros.financiera.idCliente.toString()}`;

            this.visitService.get(uri, true)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.loadings.sucursales = false;
                    this.sucursales = res;
                }, (err: any) => {
                    this.loadings.sucursales = false;
                    this.app.showError(err);
                });
        }
        else {
            this.sucursales = [];
        }
    }

    onAcreditadoSelectionChange(idAcreditado: any) {
        this.filtros.idAcreditado = idAcreditado;
    }

    public onChangeLender(event) {
        if (event) {
            this.lenders.forEach(lender => {
                if (lender.idFinanciera == event) {
                    this.filtros.financiera = lender;
                    this.filtros.idCliente = lender.idCliente;
                    this.filtros.financiera.idCliente = lender.idCliente;
                    if (lender.financieraDemo) {
                        this.filtros.excludeFinancierasDemo = false;
                    }
                }
            });
            this.loadSucursales();
            this.cargarEtiquetasCuenta();
            this.loadProducts();
        }
        else {
            this.filtros.financiera = null;
        }
        this.contabilizadorDeFiltros('superAdmin');

    }

    public validarMoratorios(event) {
        if (event.key == '.') {
            // invalid character, prevent input
            event.preventDefault();
        }
        var valor: string = null;
        var numero: number = 0;
        if (this.filtros.diasMoratorios) {
            valor = this.filtros.diasMoratorios;
            valor = valor + event.key;
            numero = parseInt(valor);
        }
        if (numero > 9999) {
            this.filtros.diasMoratorios = "9999";
            event.preventDefault();
        }

    }

    public onChangeEstate(event) {
        this.contabilizadorDeFiltros('ubicacionMX');
        this.resetFilterByName('municipio');
        this.resetFilterByName('idMunicipio');
        this.loadMunicipios();
    }

    loadingLenders: boolean = false;
    public loadLenders(): void {
        this.loadingLenders = true;
        //let loading = this.app.showLoading('Cargando Clientes...');
        this.lenderService.getAll('all').subscribe(
            lenders => {
                this.lenders = lenders;
                if (!this.sessionData.isSuperAdmin) {
                    this.idLender = this.sessionData.idFinanciera;
                    this.onChangeLender(this.idLender);
                    this.loadCombos();
                    //this.app.hideLoading(loading);
                }
                this.loadingLenders = false;
            },
            err => {
                this.app.showError(err);
                this.loadingLenders = false;
                //this.app.hideLoading(loading);
            },
            () => this.loadingLenders = false
        );
    }

    loadProducts() {
        this.loadingProducts = true;
        if (this.sessionData.isSuperAdmin) {
            if (this.filtros.financiera) {
                this.lenderService.obtenerProductosCliente(this.filtros.financiera.idCliente)
                    .pipe(
                        take(1)
                    ).subscribe((res: any) => {
                        this.loadingProducts = false;
                        this.products = res.data;
                    }, (err: any) => {
                        this.loadingProducts = false;
                        this.app.showError(err);
                    });
            } else {
                this.lenderService.obtenerProductos()
                    .pipe(
                        take(1)
                    ).subscribe((res: any) => {
                        this.loadingProducts = false;
                        this.products = res.data;
                    }, (err: any) => {
                        this.loadingProducts = false;
                        this.app.showError(err);
                    });
            }
        } else {
            this.lenderService.obtenerProductosCliente(this.sessionData.idCliente)
                .pipe(
                    take(1)
                ).subscribe((res: any) => {
                    this.loadingProducts = false;
                    this.products = res.data;
                }, (err: any) => {
                    this.loadingProducts = false;
                    this.app.showError(err);
                });
        }

    }

    public readHashParams(): void {
        this.loadAccounts(this.filtros.page);
    }

    public saveInStorage(): void {
        localStorage.setItem('visitListFields', JSON.stringify(this.listFields));
    }

    /*public selectAllAccounts(): void {
        for (let i = 0; i < this.accounts.length; i++) {
            if (this.accounts[i].clienteActivo) {
                this.accounts[i].selected = this.allAccountsSelected;
            }
        }

        this.checkSelecteds();
    }*/

    public setFilter(): void {

        if (this.filtros.financiera == null) {
            this.filtros.idCliente = null
        }

        if (this.filtros.idCuenta && !Number(this.filtros.idCuenta)) {
            this.app.showSnackbar('¡Aviso!', 'Solo se permiten números en el filtro ID Cuenta.', 3000, 'warning');

            return;
        }

        if (this.filtros.idAgente && !Number(this.filtros.idAgente)) {
            this.app.showSnackbar('¡Aviso!', 'Solo se permiten números en el filtro ID Cobrador.', 3000, 'warning');

            return;
        }

        if ((this.filtros.codigoPostal || this.filtros.colonia) && (!this.filtros.estado && !this.filtros.idEstado)) {
            if (this.pais != 'CO') {
                this.app.showSnackbar('¡Aviso!', 'Es necesario capturar el estado para filtrar por colonia y/o código postal.', 3000, 'warning');
            }
            else {
                this.app.showSnackbar('Aviso', 'Es necesario capturar el estado para filtrar por barrio.', 3000, 'warning');
            }
            return;
        }

        this.listFiltered = true;
        this.filtros.page = 1;
        this.filtrosService.guardarFiltros(this.filtros);

        const camposDiferentesANull = Object.keys(this.filtros).filter(campo => this.filtros[campo] !== null);

        if (camposDiferentesANull.length < 4) {
            this.filtrosSideNav.toggle();
            this.app.showSnackbar('¡Aviso!', `Es requerido seleccionar un filtro válido.`, 3000, 'warning');
            return;
        }


        if (this.filtros.mostrarMapa && this.mapaCuentas) {
            this.accounts = [];
            this.consultarCuentasMapa(0, 1);
        } else {
            this.loadAccounts();
        }

        this.contabilizadorDeFiltrosTotales();


    }

    hijoDesactivado() {
        this.filtrosService.asignarOpcion();
        if(this.filtros && this.filtros.financiera){
            this.filtrosService.guardarFiltros(this.filtros, true);
        }
        this.filtrosService.obtenerFiltrosGardados<FiltrosCuentas>(this.filtros, this.subscribeRouterParamsBounded);
    }

    public setSortField(sort: any) {
        if(sort.isOrder){
            this.sortAsc = sort.direction;
            if (sort.active != this.sortField) {
                this.sortField = sort.active;
            }

            this.loadAccounts(1);
        }
    }

    public setTag(): void {
        /*let accounts: Account[] = [];

        if (!this.selectedTag) {
            this.app.showSnackbar(
                "Aviso",
                "Es necesario elegir una etiqueta",
                3000,
                "warning"
            );
            return;
        }

        if (this.selectedAccount) {
            accounts.push(this.selectedAccount);
        } else {
            accounts = this.getSelectedAccounts();
        }

        if (accounts.length) {
            this.accountsSelected = accounts;
            this.$tagModal.modal('hide');
            this.tagLoader = true;

            this.sendAccountsForUpdateTag(0);
        }*/
    }

    sendAccountsForUpdateTag(pos: number) {
        let cuenta: any = {};
        //alex
        if (pos < this.accountsSelected.length) {
            cuenta = {
                idFinanciera: this.accountsSelected[pos].lenderID,
                idCliente: this.accountsSelected[pos].idCliente,
                idAcreditado: this.accountsSelected[pos].client.idAcreditado,
                idExterno: this.accountsSelected[pos].client.externalID,
                idAcreditadoPG: this.accountsSelected[pos].idAcreditadoPG,
                idCuentaPG: this.accountsSelected[pos].idCuentaPG,
                idAcreditadoCuentaDomicilio: this.accountsSelected[pos].idAcreditadoCuentaDomicilio,
                idEtiquetaOperacion: this.selectedTag,
                idEtiquetaCuenta: null,
                idVisita: null,
                idAgente: null
            };
        }

        if (pos >= this.accountsSelected.length) {
            this.tagLoader = false;
            this.selectedTag = null;
            this.loadAccounts(
                null, () => {
                    this.messageService.success(`
                  ${this.accountsSelected.length} cuentas fueron cambiadas de etiqueta.
                `);
                }
            );
            return;
        }

        this.setTagMsg = `Actualizando etiquetas ${pos + 1} de ${this.accountsSelected.length}`;
        this.cuentaService.actualizarCuenta(this.accountsSelected[pos].id, cuenta).subscribe(
            res => {
                if (res.success) {
                    this.sendAccountsForUpdateTag(pos + 1);
                } else {
                    this.app.showSnackbar(
                        "Aviso",
                        "Ocurrio un Error: " + res.error.message,
                        5000,
                        "error"
                    );
                    this.tagLoader = false;
                    return;
                }
            },
            error => {
                this.app.showError(error);
                this.tagLoader = false;
            }
        );
    }



    public showAgentTodasModal(): void {
        this.asignarTodasLasCuentas = true;
        this.showAgentModal(false);
    }

    public showAgentModal(asignarTodas: boolean = true): void {
        if (asignarTodas) {
            this.asignarTodasLasCuentas = false;
        }

        let cuentasAsignar = this.asignarTodasLasCuentas ? this.accounts : this.accounts.filter((cuenta) => cuenta.selected);

        const clienteInactivo = cuentasAsignar.find(item => item?.['clienteActivo'] === false);

        if (clienteInactivo) {
            const nombreCliente = clienteInactivo['lender']['nombre']
            this.app.showSnackbar("Aviso", 'El cliente "' + nombreCliente + '" se encuentra inactivo', 6000, 'warning');
            return;
        } else {
        }

        if (cuentasAsignar.length == 0) {
            this.app.showSnackbar('Aviso', 'Favor de seleccionar por lo menos una cuenta para poder asignar.', 3000, 'warning');
            return;
        }

        if (!this.filtros.mostrarMapa || (cuentasAsignar.length <= 300)) {
            this.asginarCuentasMapa(cuentasAsignar);
        }
        else {
            this.app.showSnackbar('Aviso', 'No se pueden asingar más de 300 cuentas en el mapa, por favor remueve algunos registros o reduce tu búsqueda.', 3000, 'warning');
        }
    }

    asginarCuentasMapa(cuentasAsignar: any) {
        const dialogRef = this.dialog.open(ModalAsignarCuentaComponent, {
            data: {
                opcion: 3,
                accounts: cuentasAsignar,
                domiciliosSeleccionados: [],
                idCobrador: null,
                nombreCobrador: null,
                idAgente: null,
                nombreAcreditado: null,
                folioVisita: null,
                idVisitaPsg: null,
                domicilios: []
            }
        });

        dialogRef.afterClosed().subscribe(result => {
            this.borrarSeleccion();
            if (result) {

                if (this.filtros.mostrarMapa && this.mapaCuentas) {
                    this.accounts = [];
                    this.consultarCuentasMapa(0, 1);
                } else {
                    this.loadAccounts();
                }
            }
        });
    }

    public asignarCuentasPorRango() {
        if (!this.eventoCreado) {
            this.mapaCuentas.map.on('draw.create', this.dibujarGeoCerca.bind(this));
            this.eventoCreado = true;
        }
        this.mapaCuentas.modoDibujar();
        this.dibujando = true;
    }

    public borrarConteo() {
        this.contadorCuentas = 0;
        this.contadorSeleccion = 0;
        this.cuentasSeleccionadas = 0;
        this.asignarSeleccion = true;
    }

    public borrarSeleccion() {
        this.borrarConteo();
        this.mapaCuentas.borrarSeleccion();
    }


    public showMap(mostrar: boolean): void {
        if (this.filtrosLimpios != this.utf8ToBase64(JSON.stringify(this.filtros))) {
            this.accounts = [];
            this.consultarCuentasMapa(0, 1);
        }
        this.filtros.mostrarMapa = mostrar;
        this.dibujando = mostrar;
        this.eventoCreado = mostrar;
        var filtrosString = JSON.stringify(this.filtros);
        this.filtrosLimpios = this.utf8ToBase64(filtrosString);
    }

    public consultarCuentas() {
        this.borrarConteo();
        this.accounts = [];
        this.mapaCuentas.reniniciarTodosLosLayer(this.accounts);
        this.filtros.mostrarMapa = false;
        this.dibujando = false;
        this.eventoCreado = false;
        this.loadAccounts();
    }

    public showTagModal(account?: Account): void {
        if (account && !account.clienteActivo) {
            return;
        }

        this.selectedTag = !account ? null : account.tag.id;
    }



    public tooglefilterAgentAvailable() {
        this.agenteFilterInput.searchService.reset();
        this.agenteFilterInput._element.nativeElement.firstChild.firstChild.focus();
    }

    public agenteFilterSearch = function (term: string) {
        return this.agenteServ.obtenerAgentes(term)
            .pipe(
                take(1),
                map((res: any) => {
                    if (res.data) {
                        return res.data.map(({ idCobrador, nombreCompleto, idAgente }) => ({
                            idAgente: idAgente,
                            nombre: `${idCobrador} - ${nombreCompleto}`,
                            idCobrador: idCobrador
                        }));
                    } else {
                        return []; // Devuelve un arreglo vacío si no hay datos
                    }
                })
            );
    }.bind(this);

    public asignarAgenteFilterSearch = async (term: string) => {
        if (this.asignarAgenteFilterFlag) {
            this.asignarAgenteFilterFlag = false;
            return this.asignarAgenteFilterFound;
        }

        this.asignarAgenteFilterInput._element.nativeElement.firstChild.firstChild.blur();
        return new Promise<any>(resolve => {
            this.agenteServ.obtenerAgentes(term)
                .pipe(
                    take(1),
                    map((res: any) => res.data.map(
                        ({ idCobrador, nombreCompleto }) => (
                            { idCobrador: idCobrador, nombre: idCobrador + ' - ' + nombreCompleto }
                        )
                    ))
                ).subscribe((res: any) => {
                    this.asignarAgenteFilterFound = res;
                    resolve(res);

                    setTimeout(() => {
                        this.asignarAgenteFilterInput._element.nativeElement.firstChild.firstChild.focus();
                    }, 200);
                }, (error: any) => {
                    this.app.showError(error);
                });
        });
    }

    onAgenteFilterSelected(agente: any) {
        this.filtros.idAgente = agente.idAgente;
    }

    onAsignarAgenteFilterSelected(agente: any) {
        this.asignarAgenteFilterFlag = true;
        this.formAsignacion.get("idCobradorAsignado").patchValue(agente.idCobrador);
    }

    resetAgenteFilter() {
        this.filtros.idAgente = null;
        this.agenteFilterInput._element.nativeElement.firstChild.firstChild.value = '';
        this.agenteFilterInput.searchService.reset();
    }

    resetAsignarAgenteFilter() {
        this.filtros.idCobradorAsignado = null;
        this.asignarAgenteFilterInput._element.nativeElement.firstChild.firstChild.value = '';
        this.asignarAgenteFilterInput.searchService.reset();
    }

    acreditadoFilterSearch = function (term: string) {

        return this.agenteServ.obtenerAcreditados(term, this.filtros.financiera && this.filtros.financiera.idFinanciera ? this.filtros.financiera.idFinanciera : null)
            .pipe(
                take(1),
                map((res: any) => res.data.map(
                    ({ idAcreditado, nombreCompleto, idCliente, idExterno }) => (
                        { idAcreditado: idAcreditado, nombre: idExterno + ' - ' + nombreCompleto, idCliente: idCliente }
                    )
                ))
            )
    }.bind(this);

    public grupoFilterSearch = function (term: string) {
        let uri = '/clientes-grupos-grid';

        uri += `?nombre=${term}`;
        if (this.filtros.financiera && this.filtros.financiera.idFinanciera) {
            uri += `&idFinanciera=${this.filtros.financiera.idFinanciera}`;
        }

        return this.visitService.get(uri, true)
            .pipe(
                take(1)
            );
    }.bind(this);


    resetGrupoFilter() {
        this.filtros.idGrupo = null;
        this.grupoFilterInput._element.nativeElement.firstChild.firstChild.value = '';
    }

    resetFinancieraFilter() {
        this.filtros.financiera = null;
        this.filtros.excludeFinancierasDemo = true;
        this.idLender = null;
    }

    resetFilterByName(controlName) {
        this.filtros[controlName] = null;
    }

    loadEstados(lanzarAccounts: boolean = false): void {
        this.loadings.estados = true;
        this.estados = [];
        this.stateService.get().subscribe(
            (res: any) => {
                this.estados = res;
                this.loadings.estados = false;
                if (lanzarAccounts) {
                    this.loadAccounts(this.filtros.page);
                }
            },
            (err: any) => {
                this.app.showError(err);
                this.loadings.estados = false;
            }
        );
    }

    loadMunicipios(lanzarAccounts: boolean = false): void {
        var query: string = '';
        this.loadings.municipios = true;
        this.municipios = [];
        if (this.filtros.idEstado) {
            query = `?idEstado=${this.filtros.idEstado}`

            this.municipalityService.all(query).subscribe(
                (res: any) => {
                    this.municipios = res;
                    this.loadings.municipios = false;
                    if (lanzarAccounts) {
                        this.loadEstados(lanzarAccounts);
                    }
                },
                (err: any) => {
                    this.app.showError(err);
                    this.loadings.municipios = false;
                }
            );
        }
        else {
            this.loadings.municipios = false;
        }
    }

    cargarEtiquetasCuenta() {
        if (this.filtros.financiera) {
            this.loadingEtiquetasCuenta = true;
            this.visitService.obtenerEtiquetasCuenta(this.filtros.financiera.idCliente)
                .pipe(
                    take(1)
                ).subscribe((respuesta: any) => {
                    this.loadingEtiquetasCuenta = false;
                    this.etiquetasCuenta = respuesta.data;
                }, (err: any) => {
                    this.loadingEtiquetasCuenta = false;
                    this.app.showError(err);
                });
        }
    }

    openDialogAsignar(): void {
        const clienteInactivo = this.itemsSelected.find(item => item?.['clienteActivo'] === false);

        if (clienteInactivo) {
            const nombreCliente = clienteInactivo['lender']['name']
            this.app.showSnackbar("Aviso", 'El cliente "' + nombreCliente + '" se encuentra inactivo', 6000, 'warning');
            return;
        } else {

            const dialogRef = this.dialog.open(ModalAsignarCuentaComponent, {

                data: {
                    opcion: 3,
                    accounts: this.itemsSelected,
                    domiciliosSeleccionados: [],
                    idCobrador: null,
                    nombreCobrador: null,
                    idAgente: null,
                    nombreAcreditado: null,
                    folioVisita: null,
                    idVisitaPsg: null,
                    domicilios: [],
                }
            });

            dialogRef.afterClosed().subscribe(result => {
                if (result) {
                    if (this.filtros.mostrarMapa && this.mapaCuentas) {
                        this.accounts = [];
                        this.consultarCuentasMapa(0, 1);
                    } else {
                        this.loadAccounts();
                    }
                }
            });
        }


    }

    public outputEmiter(seleccionadas: number) {
        this.contadorSeleccion = seleccionadas;
        this.cuentasSeleccionadas = this.contadorCuentas + this.contadorSeleccion;


        if (this.cuentasSeleccionadas > 300) {
            this.asignarSeleccion = false;
            this.app.showSnackbar("Aviso", "No se permite seleccionar más de 300 cuentas, favor de reducir tu selección.", 6000, 'warning');
        } else {
            this.asignarSeleccion = true;
        }

    }

    openDialogEstatus(): void {
        const clienteInactivo = this.itemsSelected.find(item => item?.['clienteActivo'] === false);

        if (clienteInactivo) {
            const nombreCliente = clienteInactivo['lender']['name']
            this.app.showSnackbar("Aviso", 'El cliente "' + nombreCliente + '" se encuentra inactivo', 6000, 'warning');
            return;
        } else {

        const dialogRef = this.dialog.open(ModalEstatusCuentaComponent, {
            width: '500px',
            data: {
                opcion: 2,
                accounts: this.accounts,
                motivos: this.motivos,
                domiciliosSeleccionados: [],
                selectedAccount: this.itemsSelected,
                domicilios: []
            }
        });

        dialogRef.afterClosed().subscribe(result => {

            if (result) {
                this.loadAccounts();

            }
        });
    }
    }

    openDialogEtiquetaOperaciones(): void {

        const clienteInactivo = this.itemsSelected.find(item => item?.['clienteActivo'] === false);

        if (clienteInactivo) {
            const nombreCliente = clienteInactivo['lender']['name']
            this.app.showSnackbar("Aviso", 'El cliente "' + nombreCliente + '" se encuentra inactivo', 6000, 'warning');
            return;
        } else {

            const dialogRef = this.dialog.open(ModalEtiquetaOperacionesComponent, {
                width: '500px',
                data: {
                    motivos: this.motivos,
                    selectedAccount: this.itemsSelected,
                }
            });

            dialogRef.afterClosed().subscribe(result => {

                if (result) {
                    this.loadAccounts();

                }
            });
        }
    }

    private loadMotivos(): void {
        this.visitService.obtenerEtiquetasOperaciones()
            .subscribe(
                motivos => {
                    this.motivos = motivos;
                },
                error => this.app.showError(error)

            );
    }

    ayudaComentario(bool: boolean) {
        this.ayuda = bool;
    }

    onPage(newPagina: any): void {
        if (newPagina.pageIndex + 1 !== this.accountPaginator.pagination.currentPage || newPagina.pageSize !== this.pageSize) {
            this.accountPaginator.pagination.currentPage = newPagina.pageSize;
            this.loadAccounts(newPagina.pageIndex + 1);
        }
    }

    public onSelected(rows) {
        this.itemsSelected = rows.slice();
    }

    onClick({ row, columnPressed }) {
        if (columnPressed == 'coordenadas') {
            if (row.coordenadas != 'No disponible') {
                window.open(`https://www.google.com/maps/search/?api=1&query=${row.coordenadas}`, '_blank');
            }
        } else if (columnPressed === 'client.externalID') {
            this.linkDetalleCuenta(row);
        }
    }

    onCtrlClick({ row, columnPressed }) {
        if (columnPressed === 'client.externalID') {
            this.ctrlClickLinkDetalleCuenta(row);
        }
    }

    ctrlClickLinkDetalleCuenta(cuenta: any) {
        const queryParams = cuenta.urlDetalleCuenta.replace(/\+/g, '%2B');
        window.open(`/cuentas/domicilios/account${queryParams}`, '_blank');
    }


    linkDetalleCuenta(cuenta: any) {
        const queryParams = cuenta.urlDetalleCuenta.replace(/\+/g, '%2B');
        this.router.navigateByUrl(`/cuentas/domicilios/account${queryParams}`);
    }

    btnDetalleCuenta() {
        if (this.itemsSelected.length > 0) {
            const url = this.itemsSelected.map(item => item);
            const queryParams = url[0]['urlDetalleCuenta'].replace(/\+/g, '%2B');
            this.router.navigateByUrl(`/cuentas/domicilios/account${queryParams}`);
        } else {
            console.error('No hay elementos.');
        }
    }

    clearSelection(event: Event) {
        event.stopPropagation();
        this.filtros.visibilidad = null;
    }


}
