import { Injectable } from '@angular/core';
import { ServicesGeneralService } from '../servicio-general/servicio-general.service';
import { environmentSelector } from '../../../../environments/environment.selector';

@Injectable()
export class StorageKobraService extends ServicesGeneralService {
  public override baseURL: string = environmentSelector().kobraStorage;
  protected override propertie: string = 'kobraStorage';

}
