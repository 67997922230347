<div class="app content kobra checklist">
  <mat-card *ngIf="loadings.checklists">
    <app-loader style="margin: 20px;" *ngIf="loadings.checklists" [message]="'Cargando Cuestionario...'"></app-loader>
  </mat-card>

  <ng-container *ngIf="!loadings.checklists && checklistForm">
    <form [formGroup]="checklistForm" class="form contenido" cdk-scrollable>
      <ul class="no-list" cdkDropList (cdkDropListDropped)="drop($event, true, null , null)">
        <ng-container
          *ngFor="let pregunta of checklist.preguntas; index as i;"
          >
          <li cdkDrag [ngStyle]="{'margin-left': pregunta.margen+'px'}" style="margin-right: 10px;"
          formArrayName="preguntas">
            <div class="mat-expansion-panel">
              <div>
                <div style="width: 90%;" class="rompe-texto">
                  <span class="nombre-pregunta" *ngIf="!pregunta.editando" (click)="editarNombrePregunta(pregunta, i)">
                    {{(i + 1)}} - {{pregunta.nombrePregunta || checklistForm.get('preguntas')['controls'][i].get('nombrePregunta').value || 'Nueva pregunta' }}<span class="respuesta-accion" *ngIf="!validarAgregarRespuesta(pregunta)">{{pregunta.respuestas[0].accion}}</span>
                  </span>
                </div>

                <div [formGroupName]="i" *ngIf="pregunta.editando">
                  <input
                    matInput textSanitizer
                    class="name-edit"
                    placeholder="Escriba el nombre de la pregunta"
                    formControlName="nombrePregunta"
                    id="pregunta_{{i}}"
                    (ngModelChange)="valueRespuesta(pregunta, i)"
                    (blur)="pregunta.editando = false"
                    (keypress)="onKeyPresNombrePregunta($event, pregunta, i)"
                    maxlength="200"
                  />
                </div>

                <div align="right" class="more-button">
                  <button mat-icon-button [matMenuTriggerFor]="menu" style="margin-top: 10px;">
                    <mat-icon style="color:#00C776">more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu">
                    <button mat-menu-item *ngIf="validarAgregarRespuesta(pregunta)" (click)="addAnswer(i)"><mat-icon style="color:#00C776">add_thick</mat-icon>Agregar respuesta</button>
                    <button mat-menu-item (click)="abrirDialogoCambiarIndice(i)"><mat-icon style="color:#00C776">format_list_numbered</mat-icon>Cambiar posición pregunta</button>
                    <button mat-menu-item (click)="removeParentQuestion(i)"><mat-icon color="warn">delete</mat-icon>Borrar pregunta</button>
                  </mat-menu>
                </div>
              </div>
              <div [formGroupName]="i">
                <ul class="no-list" cdkDropList (cdkDropListDropped)="dropRespuesta($event, i)">
                  <li *ngFor="let respuesta of pregunta.respuestas; index as j" class="li-child respuesta" cdkDrag>
                    <div (dblclick)="editAnswer(i, j)">
                      <input
                        matInput textSanitizer
                        class="name-edit"
                        *ngIf="respuesta.editando"
                        placeholder="Escriba el nombre de la respuesta"
                        [(ngModel)]="respuesta.nombreRespuesta"
                        [ngModelOptions]="{standalone: true}"
                        (blur)="respuesta.editando = false"
                        id="respuesta_{{i}}_{{j}}"
                        (ngModelChange)="checklistForm.get('preguntas')['controls'][i].get('respuestas')['controls'][j].get('nombreRespuesta').setValue(respuesta.nombreRespuesta)"
                        (keypress)="onKeyPresNombreRespuesta($event, respuesta, i, j)"
                      />
                      <span *ngIf="!respuesta.editando" class="nombre-respuesta" (click)="editarNombreRespuesta(respuesta, i, j)">
                        {{(j + 1)}} - {{respuesta.nombreRespuesta || 'Sin nombre de respuesta'}} <!--mat-chip title="Acción" class="accion" *ngIf="respuesta.idAccionRespuesta">{{respuesta.accion}}</mat-chip-->
                      </span>

                      <p mat-line class="mensaje-info">
                        {{getTypeAnswerName(respuesta)}} ({{respuesta.requerida ? 'requerido' : 'opcional'}})
                      </p>

                      <div align="right" class="more-button">
                        <button (click)="removeAnswer(i,j)" title="Borrar respuesta" mat-icon-button><mat-icon color="warn" class="borrar-respuesta">delete</mat-icon></button>
                      </div>
                      <mat-error
                        mat-line
                        *ngIf="(pregunta.respuestas.length > 1 && !respuesta.nombreRespuesta) ||checklistForm.get('preguntas')['controls'][i].get('respuestas')['controls'][j].get('nombreRespuesta').hasError('required')"
                      >
                        Esta respuesta requiere un nombre
                      </mat-error>
                    </div>
                  </li>
                </ul>
              </div>
              <div>
                <span class="dependencia" *ngIf="pregunta.condicionMostrar">{{pregunta.condicionMostrar}}</span>
              </div>
            </div>

          </li>
        </ng-container>
      </ul>
    </form>
  </ng-container>


  <div class="botones-card-derecha" *ngIf="!loadings.checklists && checklistForm">
    <button class="boton-cancelar" mat-flat-button  (click)="cancel()">
      <mat-icon class="kobra-icon">close</mat-icon>
      Cancelar
    </button>
    <button mat-stroked-button class="boton-accion-front" (click)="addParentQuestion()"
      matTooltip="Nueva pregunta"
      mat-tooltip-panel-above
    >
      <mat-icon>add</mat-icon>
      Agregar nueva pregunta
    </button>
    <button
      *ngIf="!loadings.checklists && checklistForm"
      [disabled]="!checklistForm.dirty || checklistForm.invalid"
      mat-stroked-button
      [ngClass]="{'boton-guardar': checklistForm.dirty && !checklistForm.invalid }"
      (click)="capturarComentario()">
      <mat-icon>
        save
      </mat-icon>
      Guardar
    </button>
  </div>
</div>


