import { IPaginatorGeneral } from './../../shared/components/table/interfaces/tabla.interface';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ServicesVisitasService } from 'src/app/core/http/services-visitas/services-visitas.service';
import { map, catchError } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ClientesEtiquetasVisitasService {
  public loader: boolean;

  public pagination: IPaginatorGeneral;
  public paginationInfo: IPaginatorGeneral;
  public pageSize = 50;
  public fromRowPage: number;
  public toRowPage: number;

  constructor(
    private visitasService: ServicesVisitasService,
    private app: ApplicationService,
    public filtrosService: FiltrosService,
    private fb: FormBuilder
  )
  {
    this.pagination = {
      page: 1,
      pageData: [],
      pages: 0,
      totalRows: 0,
      pageSize: 50
    };
    this.paginationInfo = {
      page: 1,
      pageData: [],
      pages: 0,
      totalRows: 0,
      pageSize: 50
    };
  }

  public obtenerEtiquetas(page: number, filters: any) {
    this.loader = true;
    let idCliente = filters.idCliente || '';
    let idProducto = filters.idProducto || '';
    let etiquetaVisita = filters.etiquetaVisita || '';
    let estatus = filters.estatus || '';

    const campos = '"idCliente", "cliente", "idProducto", "producto", "idEtiquetaVisita", "etiquetaVisita", "activo"';
    if (idCliente != '') {
      idCliente = `&idCliente=${filters.idCliente}`;
    }

    if (idProducto != '') {
      idProducto = `&idProducto=${filters.idProducto}`;
    }

    if (etiquetaVisita != '') {
      etiquetaVisita = `&etiquetaVisita=${filters.etiquetaVisita}`;
    }

    if (filters.estatus != null) {
      estatus = `&activo=${filters.estatus}`;
    }

    let queryParams = `?_paginate=1&_page=${page}&_rows=${this.pageSize}&_slt=${campos}${idCliente}${idProducto}${etiquetaVisita}${estatus}&_orderBy=${filters.sortField}${filters.sortAsc ? ' ' + filters.sortAsc: ''}`;

    if(page != filters.page){
      filters.page = page;
      this.filtrosService.guardarFiltros(filters);
    }

    let etiquetas =  this.visitasService.get(`/clientes/clientes-etiquetas-visitas${queryParams}`)
      .pipe(
        map((res: any) => {
          let datos = res.data;
          for (let i = 0; i < datos.pageData.length; i++) {
            datos.pageData[i].estatus = datos.pageData[i].activo ? 'Activa' : 'Inactiva';
          }
          this.paginationInfo = datos;
          this.pagination.page = this.paginationInfo.page;
          this.pagination.totalRows = this.paginationInfo.totalRows;
          this.pagination.pages = this.paginationInfo.pages;

          return this.pagination.pageData;
        }),
        catchError((error: any) => {
          this.loader = false;
          this.app.showError(error);

          return of([]);
        })
      );
    etiquetas.subscribe((res: any) => {
      this.loader = false;
    }, (err: any) => {
      this.loader = false;
      this.app.showError(err);
    });
  }

  public obtenerGlobalEtiquetas() {
    this.loader = true;
    let queryParams = `?_paginate=0`;

    return this.visitasService.get(`/catalogos/etiquetas-visitas${queryParams}`);
  }

  public nuevaEtiqueta(data) {
    return this.visitasService.post(`/clientes/etiquetas-visitas`, data);
  }

  public actualizarEstatusEtiquetasClientes(data) {
    return this.visitasService.post(`/clientes/etiquetas-visitas/estatus`, data);
  }

  public actualizarEstatusEtiquetas(data) {
    return this.visitasService.post(`/catalogos/etiquetas-visitas/estatus`, data);
  }

  public calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }
}
