import { Injectable } from '@angular/core';
import { interval, Observable, Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { TokenExpirationModalComponent } from './token-expiration-modal/token-expiration-modal.component';
import { LocalStorageService } from '../shared/services/local-storage.service';
import jwtDecode from 'jwt-decode';
import {DecodedToken }from './decoded-token.interface';

@Injectable({
    providedIn: 'root'
})
export class TokenExpirationService {
    private subscription: Subscription | null = null;
    private isCheckerRunning: boolean = false;
    private currentDialogRef: any = null; // Guardar referencia al modal abierto

    constructor(
        private dialog: MatDialog,
        private localStorageService: LocalStorageService // Inyectar el servicio
    ) {
        // Llamar a startTokenExpirationChecker cuando se instancia el servicio
        this.startTokenExpirationChecker();

    }

    startTokenExpirationChecker() {
        // Verifica si ya hay una suscripción activa
        if (this.isCheckerRunning) {
            return;
        }
        
        // Obtener los datos de sesión del LocalStorageService
        const sessionData = this.localStorageService.getSessionData();
        if (!sessionData) {
            console.log('No se encontraron datos de sesión.');
            this.stopTokenExpirationChecker();
            return;
        }
        
        // Decodificar el token desde los datos de sesión
        const token: string | null = localStorage.getItem('access-token');
        if (!token) {
            console.log('No se encontró el token.');
            this.stopTokenExpirationChecker();
            return;
        }

        // Decodificar el token JWT para obtener el tiempo de expiración
        const decodedToken: DecodedToken = jwtDecode<DecodedToken>(token);
        const expTime: number = decodedToken.exp;
        const currentTime: number = Math.floor(Date.now() / 1000);
    
        // Verificar si el token aún es válido
        if (expTime <= currentTime) {
            console.log('El token ya ha expirado.');
            this.stopTokenExpirationChecker(); // Detener el chequeo si el token ya expiró
            return;
        }
        
        this.isCheckerRunning  = true;
    
        // Banderas para evitar mostrar los modales más de una vez
        let tenMinutesWarningShown : boolean = false;
        let fiveMinutesWarningShown : boolean = false;
        let oneMinuteWarningShown : boolean = false;
        
        // Cambia el intervalo para que verifique cada minuto (60000 ms = 1 minuto)
        const checkInterval: Observable<number> = interval(60000);  // Verificar cada minuto
        this.subscription = checkInterval.subscribe(() => {
            const currentTime: number = Math.floor(Date.now() / 1000);
            const timeLeft: number = expTime - currentTime;
            const timeLeftInMinutes: number = Math.floor(timeLeft / 60);
            let fastCheckStarted = false; // Variable para evitar que el intervalo rápido se cree múltiples veces

            if (timeLeft <= 0) {
                this.stopTokenExpirationChecker(); // Detener el interval cuando el token haya expirado
                console.log('El token ha expirado.');
            }
    
            // Mostrar advertencias solo una vez por cada umbral
            if (timeLeftInMinutes <= 10 && !tenMinutesWarningShown) {
                this.closeCurrentDialog();
                this.showModal('La sesión expirará en 10 minutos');
                tenMinutesWarningShown = true;
            } else if (timeLeftInMinutes <= 5 && !fiveMinutesWarningShown) {
                this.closeCurrentDialog();
                this.showModal('La sesión expirará en 5 minutos');
                fiveMinutesWarningShown = true;
            } else if (timeLeftInMinutes <= 3 && !oneMinuteWarningShown && !fastCheckStarted) {
                fastCheckStarted = true; 
                const fastCheckInterval: Observable<number> = interval(1000); 
                this.subscription.add(fastCheckInterval.subscribe(() => {
                    const currentTime: number = Math.floor(Date.now() / 1000);
                    const timeLeft: number = expTime - currentTime;
            
                    if (timeLeft <= 60 && !oneMinuteWarningShown) {
                        this.closeCurrentDialog();
                        this.showModal('La sesión expirará en 1 minuto');
                        oneMinuteWarningShown = true;
                    }
                }));
            }
        });
    }

    closeCurrentDialog() {
        if (this.currentDialogRef) {
            this.currentDialogRef.close();  // Cierra el modal si está abierto
            this.currentDialogRef = null;
        }
    }

    showModal(message: string) {
        this.currentDialogRef = this.dialog.open(TokenExpirationModalComponent, {
            data: { message }
        });
    }

    stopTokenExpirationChecker() {
        if (this.subscription) {
            this.subscription.unsubscribe();  // Detener el intervalo cuando ya no sea necesario
            this.subscription = null;
            this.isCheckerRunning = false;
            this.closeCurrentDialog();  // Cerrar cualquier modal abierto al detener
        }
    }
    
}
