import { LenderService } from '@lender/lender.service';
import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Account } from "./account.model";
import { AccountService } from "./account.service";
import { Visit } from "../visit/visit.model";
import { VisitService } from "../visit/visit.service";
import { CuentaService } from "../cuentas/shared/cuenta.service";
import { ApplicationService } from "src/app/application/shared/application.service";
import { Subscription } from "rxjs";
import { Client } from "../client/client.model";
import { ClientAddress } from "../client-address/client-addres.model";
import { take } from "rxjs/internal/operators/take";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AgentService } from "../agent/agent.service";
import * as moment from 'moment';
import { FormValidation } from "src/app/shared/form-validation/form-validation.model";
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ModalDomicilioComponent} from './modales/modificar-domicilio/form-adress.component';
import {ModalAsignarCuentaComponent} from './modales/asignar/asignar-cuenta.component';
import {ModalEtiquetaCuentaComponent} from './modales/etiqueta/etiqueta-cuenta.component';
import {ModalEstatusCuentaComponent} from './modales/estatus/estatus-cuenta.component';
import {ModalContratosComponent} from './modales/contratos/contratos.component';
import {ModalDescargaFormatoCuentaComponent} from './modales/descarga-formato/descarga-formato-cuenta.component';
import { Coordenadas, CoordenadasUltima } from '../mapbox/modelos/coordenadas.model';
import { slideInAnimation } from '../shared/components/transacciones/router-animations';
import { RouterOutlet } from '@angular/router';
import { ChangeDetectorRef } from '@angular/core';
import { environmentSelector } from 'src/environments/environment.selector';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { Utils } from '../shared/components/utils';

declare let $: any;

@Component({
    selector: "account-detail",
    templateUrl: "account-detail.component.html",
    styleUrls: ["account-detail.component.css"],
    providers: [AccountService, VisitService, CuentaService, AgentService, LenderService],
    animations: [slideInAnimation]
})
export class AccountDetailComponent implements OnInit {

    sessionData: SessionData;
    private expand: string = "?expand=client,clientAddress,visits";
    public account: Account;
    public accounts: Account[];
    public client: Client;
    public clientAddress: ClientAddress;
    public coordenadasMapa: Coordenadas[];
    public coordenadasUltimaVisita: CoordenadasUltima[];
    public lastVisit: Visit;
    public visitas: any[];
    public domicilios: any[];
    public domiciliosBorrados: any[];
    public loadingLastVisit = true;
    public loadingAccountDetail = true;
    public loadingAcreedor: boolean;
    public loadingClientAdressInformation: boolean;
    public loadingAccountHistory = true;
    public cuentaDetalles: any;
    public totalCuentaDetalle:number;
    public banderaBtnEstatus: boolean = true;
    public historialCuenta: Array<any> = new Array();
    public pais: string;
    public labelEstado: string;
    public labelMunicipio: string;
    public labelColonia: string;
    public labelCurp: string;
    public labelRFC: string;
    public editingPersonalInfo: boolean = false;
    public editarCuentaInfo: boolean = false;
    public detalleCuentadiv: boolean = true;
    public editAddress: boolean;
    public kobraDashboardUrl: string;
    public historialMensajes: any;
    public motivos: any;
    public selectedStatus: string = null;
    public file: File[] = null;
    public idFinanciera: number;
    public idCuenta;
    public totalMensajes: number;

    public assignComment: string;
    public subirContratoModal: any;
    //private $confirmaModal: any;
    public assignMessage: string;
    public establecerVisita: boolean = true;
    public horariosVisitas = {};
    public formAsignacion: FormGroup;
    public formRecabadoPagos: FormGroup;
    public formEstatus: FormGroup;
    public motivoValidation: FormValidation;
    public fechaVisita: string;
    public idCobrador: number;
    public styleModel: string = "hidden";
    public cargandoMensajes = true;
    public momentVista;
    public fechaAsignacionMinDate: Date = new Date();
    public fechaAsignacionMaxDate: Date;
    public selectedTag: number = null;
    public nonSelectedTagWarning: boolean = false;
    public domicilioWarning: boolean = false;
    public domicilioAsignarWarning: boolean = false;
    public cargandoInformacionPersonal = true;
    public cargandoHistoriaDomiclios = true;
    public subirContratoAcreditado: boolean = false;
    public tieneContratos: boolean = false;
    public cargandoContratos = false;
    public numDiasLimite: number;
    public momentFecha;
    public diasVencimiento: number;
    public establecerFecha: boolean = true;
    public fechaLimite: string;
    public loadingProducts: boolean = true;
    public cambiarProducto: boolean = false;
    public loadingEtiquetasCuenta: boolean = true;
    public comentarioEtiqueta: string = null;
    public idProductoNvo: number = null;
    public idDomicilio: any = null;
    public idDomicilioAsignar: any = null;
    public productos: any;
    public selectedEtiqueta: string = null;
    public etiquetasCuenta = [];
    public loadingHistorialEtiquetasCuenta = true;
    public loadingDineroRecuperado = false;
    public etiquetasCuentaHistorial = [];
    public dineroRecuperado = [];
    public nombreAgenteAparto: string;
    public montoTotal: number = 0;
    public contadorPagos: number = 0;
    public filtroFechaPago: boolean = false;
    public subIdExterno : string;
    public idExterno: string;
    itemsSelected: Array<any>[] = [];
    public itemsDomcilio: boolean = true;
    public mapa: boolean =  false;
    public idCobradorAparto: number;
    public idCobradorAsigno: number;
    public nomGestorAsigno: string;
    public archivosContratos: any = [];
    public isConsultar: boolean = true;
    public nombreEtiquetasCuenta: string;
    private environment = environmentSelector();
    public utils = Utils;

    clienteActivo: boolean;
    idCheckList: number;
    numVersion: number;
    isFormatos:boolean = false;
    formatosCuentas:any[];

    horaPruba : any;
    public tipoMapa = 1;

    @ViewChild("asignarAgenteFilterInput") private asignarAgenteFilterInput: any;
    private asignarAgenteFilterFlag = false;
    private asignarAgenteFilterFound: any;
    public dataSource;
    tablaVisitas: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        sort: 'desc',
        sortField: 'fecFinalizada',
        columnas: [
            {
                name: 'Id Visita',
                key: 'idVisita',
                hide: true
            }, {
                name: 'Folio',
                key: 'folioVisita',
                type: 'link',
                link: '/visits/visit/',
                hide: false
            }, {
                name: 'Tipo',
                key: 'nombreProducto',
                hide: false
            }, {
                name: 'ID Agente',
                key: 'idAgente',
                hide: true
            }, {
                name: 'Agente',
                key: 'nombreAgenteCompleto',
                type: 'string',
                hide: false
            }, {
                name: 'Comentario',
                key: 'comentarios',
                type: 'string',
                hide: false
            }, {
                name: 'Fecha',
                key: 'fecFinalizada',
                type: 'dateTime',
                hide: false
            }, {
                name: 'Cancelada',
                key: 'cancelada',
                hide: true
            }, {
                name: 'Finalizada',
                key: 'finalizada',
                hide: true
            }, {
                name: 'ID Acreditado',
                key: 'idAcreditado',
                hide: true
            }, {
                name: 'red interna',
                key: 'redInterna',
                hide: true
            }, {
                name: 'repunteo',
                key: 'repunteo',
                hide: true
            }
        ]
    }

    tablaDomicilios: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: true,
        columnas: [
            {
                name: 'idAcreditadoCuentaDomicilio',
                key: 'idAcreditadoCuentaDomicilio',
                hide: true
            }, {
                name: 'idDomicilio',
                key: 'idDomicilioAcreditado',
                hide: true
            }, {
                name: 'Folio',
                key: 'folioDomicilio',
                hide: false
            }, {
                name: 'Estatus',
                key: 'estatus',
                type: 'estatus',
                hide: false
            }, {
                name: 'Dirección',
                key: 'domicilio',
                hide: false
            }, {
                name: this.environment.labelEstado,
                key: 'estado',
                hide: false
            }, {
                name: this.environment.labelMunicipio,
                key: 'municipio',
                hide: false
            }, {
                name: this.environment.labelColonia,
                key: 'colonia',
                hide: false
            }, {
                name: 'Entre Calles',
                key: 'entreCalles',
                hide: false
            }, {
                name: 'Coordenadas',
                key: 'coordenadas',
                type: 'link',
                hide: false
            }, {
                name: 'Tipo Cobertura',
                key: 'tipoCobertura',
                hide: false
            }, {
                name: 'Tipo Geocalización',
                key: 'tipoGeolocalizacion',
                hide: false
            }, {
                name: 'Etiqueta',
                key: 'etiquetaOperacion',
                hide: false
            }, {
                name: 'Días en Apartada',
                key: 'diasApartada',
                hide: false
            }, {
                name: 'Días Restantes',
                key: 'diasRestantes',
                hide: false
            }, {
                name: 'Fecha Vencimiento',
                key: 'fechaVencimiento',
                hide: false
            }, {
                name: 'Agente',
                key: 'agente',
                hide: false
            }
        ]
    }

    tablaDomiciliosBorrados: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        columnas: [
            {
                name: 'idAcreditadoCuentaDomicilio',
                key: 'idAcreditadoCuentaDomicilio',
                hide: true
            }, {
                name: 'idDomicilio',
                key: 'idDomicilioAcreditado',
                hide: true
            }, {
                name: 'Folio',
                key: 'folioDomicilio',
                hide: false
            }, {
                name: 'Dirección',
                key: 'domicilio',
                hide: false
            }, {
                name: this.environment.labelEstado,
                key: 'estado',
                hide: false
            }, {
                name: this.environment.labelMunicipio,
                key: 'municipio',
                hide: false
            }, {
                name: this.environment.labelColonia,
                key: 'colonia',
                hide: false
            }, {
                name: 'Entre calles',
                key: 'entreCalles',
                hide: false
            }, {
                name: 'Coordenadas',
                key: 'coordenadas',
                type: 'link',
                hide: false
            }, {
                name: 'Tipo cobertura',
                key: 'tipoCobertura',
                hide: false
            }, {
                name: 'Tipo geocalización',
                key: 'tipoCobertura',
                hide: false
            }, {
                name: 'Etiqueta',
                key: 'etiquetaOperacion',
                hide: false
            }
        ]
    }
    tablaEtiquetasCuentas: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        sort: 'desc',
        sortField: 'fecFinalizada',
        columnas: [
            {
                name: 'ID',
                key: 'idHisAcreditadoCuentaEtiqueta',
                hide: true
            }, {
                name: 'Folio Visita',
                key: 'folioVisita',
                type: 'link',
                link: '/visits/visit/',
                hide: false
            }, {
                name: 'Nombre Administrador',
                key: 'nombreAdministradorFullName',
                hide: false
            }, {
                name: 'Nombre Etiqueta',
                key: 'nombreEtiquetaCuenta',
                hide: false
            }, {
                name: 'Comentarios',
                key: 'comentarios',
                hide: false
            }, {
                name: 'Fecha',
                key: 'fecRegistro',
                hide: false,
                type: 'dateTime'
            }
        ]
    }

    tablaDineroRecuperado: any = {
        headers: false,
        tableHeaders: false,
        multiSelect: false,
        sort: 'desc',
        sortField: 'fechaPago',
        columnas: [
            {
                name: 'Folio Visita',
                key: 'folioVisita',
                type: 'link',
                link: '/visit/',
                hide: false
            }, {
                name: 'Gestor',
                key: 'nombreCompletoAgente',
                hide: false
            }, {
                name: 'Fecha Pago',
                key: 'fechaPago',
                hide: false,
            }, {
                name: 'Fecha Registro',
                key: 'fechaRegistro',
                hide: false,
            }, {
                name: 'Monto',
                key: 'montoPago',
                hide: false
            }
        ]
    }


    constructor(
        private app: ApplicationService,
        private accountService: AccountService,
        private route: ActivatedRoute,
        private visitService: VisitService,
        private cuentaService: CuentaService,
        private router: Router,
        private localStorageService: LocalStorageService,
        private lenderService: LenderService,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef
    ) {

        this.mapa = false;
        this.coordenadasMapa = [];
        this.coordenadasUltimaVisita = [];
        this.sessionData = this.localStorageService.getSessionData();
        this.editarCuentaInfo = false;
    }

    ngOnInit() {
        this.momentVista = moment;
        this.momentFecha = moment;
        this.account = new Account();
        this.client = new Client();
        this.clientAddress = new ClientAddress();
        this.lastVisit = new Visit();
        this.asginacionVaribles();
        this.readParams();
        this.loadMotivos();
    }

    // ngAfterViewInit() {
    //     this.cdr.detectChanges();
    // }

    // prepareRoute(outlet: RouterOutlet) {
    //     return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
    // }

    private cargarAcreditado(id: number): void {
        this.cargandoInformacionPersonal = true;
        let subscription: Subscription = this.visitService.client(id).subscribe(
            (client) => {

                this.idFinanciera = client.idFinanciera;
                this.cargandoInformacionPersonal = false;
                this.client = client;
                this.obtenerDetallesCuentaDinamico(
                    client.idCliente,
                    client.externalID,
                    id
                );

                //this.cargarDomicilioAcreditado(id, 0);
                this.cargarCuentaVisitas(id, client.idFinanciera);
                this.cargarHistorialCuenta(client.idCliente, client.externalID, id);
                this.loadProducts();
                this.cargarEtiquetasCuenta();
                this.cargarHistorialMensajes(this.account.idCliente, this.account.idAcreditadoPG, this.account.idCuentaPG);
                this.obtenerUltimaVisita(this.account.idCliente, this.account.idAcreditadoPG, this.account.idCuentaPG);
                this.cargarDomicilios(this.account.idCliente, this.account.idAcreditadoPG, this.account.idCuentaPG);
                this.cargarDomiciliosBorrados(this.account.idCliente, this.account.idAcreditadoPG, this.account.idCuentaPG);

                //this.obtenerEstatus(this.account.idCliente, this.account.idAcreditadoPG, this.account.idCuentaPG);

            },
            (err) => {
                this.app.showError(err);
                this.cargandoInformacionPersonal = false;
                subscription.unsubscribe();
            },
            () => {
                subscription.unsubscribe();
                this.cargandoInformacionPersonal = false;
            }
        );
    }

    private obtenerDetallesCuentaDinamico(
        idFinanciera: number,
        idExternal: string,
        idCuenta: number
    ) {
        this.loadingAccountDetail = true;
        this.visitService
            .obtenerDetallesCuentaDinamico(idFinanciera, idExternal, idCuenta)
            .pipe(take(1))
            .subscribe(
                (res: any) => {


                    this.cuentaDetalles = res.data;
                    this.totalCuentaDetalle = Object.keys(this.cuentaDetalles).length;
                    if (this.totalCuentaDetalle === 0) {
                        this.banderaBtnEstatus = false;
                        this.detalleCuentadiv= false;
                    }
                    for (let lista of this.cuentaDetalles) {
                        if (lista.idCuentaCampoTipoDato === 1) {
                            if (lista.valor === '') {
                                lista.valor = 0;
                            }
                            lista.valor = parseInt(lista.valor);
                        } else if (lista.idCuentaCampoTipoDato === 2) {
                            if (lista.valor === '') {
                                lista.valor = 0;
                            }
                        } else if (lista.idCuentaCampoTipoDato === 4) {
                            lista.valor = new Date(lista.valor + 'T00:00:00');
                        } else if (lista.idCuentaCampoTipoDato === 6) {
                            let bol = String((lista.valor).toLowerCase().trim()) === "true";
                            if (bol) {
                                lista.valor = 1;
                            } else {
                                lista.valor = 0;
                            }
                        } else if (lista.idCuentaCampoTipoDato === 5) {
                            lista.valor = new Date(lista.valor);
                        } else if (lista.idCuentaCampoTipoDato === 7) {
                            let fechaActual = new Date();
                            let fecha = this.momentVista(fechaActual).format('YYYY-MM-DD') + 'T' + lista.valor + ':00';
                            lista.valor = new Date(fecha);
                        }
                        this.loadingAccountDetail = false;
                    }
                },
                (err: any) => {
                    this.app.showError(err);
                    this.loadingAccountDetail = false;
                }
            );
            this.loadingAccountDetail = false;
    }

    private cargarHistorialCuenta(idCliente: number, idExternal: string, idCuenta: number) {
        this.loadingAccountHistory = true;
        this.idFinanciera = this.client.idFinanciera;
        this.visitService
            .obtenerHistorialCuenta(idCliente, idExternal, idCuenta)
            .pipe(take(1))
            .subscribe(
                (respuesta: any) => {
                  this.loadingAccountHistory = false;

                  for (let i = 0; i < respuesta.data.length; i++) {
                    if(respuesta.data[i].estatus === 'Espera'){
                      respuesta.data[i].estatus = 'Visitada';
                    }
                  }

                  this.historialCuenta = respuesta.data;

                },
                (err: any) => {
                    this.app.showError(err);
                    this.loadingAccountHistory = false;
                }
            );
    }

    private cargarCuenta(id: number, cargarEtiquetas:boolean = false): void {
        this.visitService
            .oneDetail(id)
            .pipe(take(1))
            .subscribe(
                (account: any) => {
                    this.account = account;
                    this.obtenerCheckListInfo();
                    this.cargarAcreditado(id);
                    this.cargarHistorialPagosRecabados(false);
                    if (cargarEtiquetas) {
                        this.cargarHistorialEtiquetasCuenta();
                    }
                    this.clienteActivo = this.account.clienteActivo
                },
                (err: any) => {
                    this.app.showError(err);
                    //this.loadingAccountDetail = false;
                }
            );
    }

    private cargarDomicilioAcreditado(id: number, idDomicilio: any, idAcreditadoCuentaDomicilio: any): void {
        this.loadingClientAdressInformation = true;
        //let cargando = this.app.showLoading('Cargando Domicilio...');
        this.cargandoHistoriaDomiclios = true;
        let subscription: Subscription = this.visitService
            .obtenerDomicilioAcreditado(this.account.idCliente,
                this.account.idAcreditadoPG,
                this.account.idCuentaPG,
                idDomicilio,
                idAcreditadoCuentaDomicilio
            )
            .subscribe(
                (clientAddress: any) => {

                    this.clientAddress = clientAddress;
                    /*if(clientAddress.lat && clientAddress.lng){
                        this.coordenadas.push({
                            lat: clientAddress.lat,
                            lng: clientAddress.lng
                        });
                    }*/
                    this.clientAddress.idCuenta = this.account.id;
                    this.clientAddress.idFinanciera = this.client.idFinanciera;
                    this.clientAddress.idExterno =  this.client.externalID;
                    this.clientAddress.idAcreditado = this.account.idAcreditado;
                    this.clientAddress.idEstado = clientAddress.data[0].idEstado;
                    this.clientAddress.idMunicipio = clientAddress.data[0].idMunicipio;
                    this.clientAddress.idColonia = clientAddress.data[0].idColonia;
                    this.clientAddress.idCodigoPostal = clientAddress.data[0].idCodigoPostal;
                    this.clientAddress.zipCode = clientAddress.data[0].codigoPostal;
                    this.clientAddress.entreCalles = clientAddress.data[0].entreCalles;
                    this.clientAddress.calle = clientAddress.data[0].calle;
                    this.clientAddress.numeroInt = clientAddress.data[0].numeroInt;
                    this.clientAddress.numeroExt = clientAddress.data[0].numeroExt;
                    this.clientAddress.suburb = clientAddress.data[0].colonia;
                    this.clientAddress.municipality = clientAddress.data[0].municipio;
                    this.clientAddress.state = clientAddress.data[0].estado;
                    this.clientAddress.idDomicilioAcreditado = clientAddress.data[0].idDomicilioAcreditado;
                    //this.clientAddress.idDomicilioAcreditado = idDomicilio;
                    this.openDialog();
                },
                (err) => {
                    this.app.showError(err);
                    this.loadingClientAdressInformation = false;
                    subscription.unsubscribe();
                    //this.app.hideLoading(cargando)
                    this.cargandoHistoriaDomiclios = false;
                },
                () => {
                    this.loadingClientAdressInformation = false;
                    subscription.unsubscribe();
                    //this.app.hideLoading(cargando)
                    this.cargandoHistoriaDomiclios = false;
                }
            );
    }

    private cargarCuentaVisitas(id: number, idFinanciera: number): void {
        this.loadingLastVisit = true;
        let subscription: Subscription = this.visitService.visits(id, idFinanciera).subscribe(
            visits => {
                this.visitas = visits;
            }, err => {
                this.app.showError(err);
                this.loadingLastVisit = false;
                subscription.unsubscribe();
            },
            () => {
                this.loadingLastVisit = false;
                subscription.unsubscribe();
            }
        );
    }

    private loadMotivos(): void {
        this.visitService.obtenerEtiquetasOperaciones()
            .subscribe(
                motivos => {
                    this.motivos = motivos;
                },
                error => this.app.showError(error)

            );
    }

    public readParams(): void {
        this.route.queryParams.subscribe(params => {
            this.idExterno = params['idExterno'];
            this.subIdExterno = params['subIdExterno'];


            if (this.sessionData.isSuperAdmin) {
                if (params['idExterno'] && params['idCliente']) {
                    this.obtenerIdCuentaPorIdExterno(encodeURIComponent(params['idExterno']), params['idCliente'], params['subIdExterno'] ? encodeURIComponent(params['subIdExterno']) : params['subIdExterno']);
                } else {
                    this.router.navigate(['']);
                }
            } else {
                if (params['idExterno']) {
                    this.obtenerIdCuentaPorIdExterno(encodeURIComponent(params['idExterno']), this.sessionData.idCliente, params['subIdExterno'] ? encodeURIComponent(params['subIdExterno']) : params['subIdExterno']);
                } else {
                    this.router.navigate(['']);
                }
            }

        });
    }

    public obtenerIdCuentaPorIdExterno(idExterno, idCliente, subIdExterno) {
        this.visitService.obtenerIdCuentaPorIdExterno(idExterno, idCliente, subIdExterno).pipe(take(1))
            .subscribe(
                (respuesta: any) => {
                    if (respuesta.data) {
                        this.idCuenta = respuesta.data.idCuenta;
                        if (!this.sessionData.isSuperAdmin) {
                            if (this.sessionData.idCliente !== respuesta.data.idCliente) {
                                this.router.navigate(['']);
                            } else {
                                this.cargarCuenta(this.idCuenta, true);
                            }
                        } else {
                            this.cargarCuenta(this.idCuenta, true);
                        }
                    }else{
                        this.router.navigate(['']);
                    }

                },
                (err: any) => {
                    this.app.showError(err);
                }
            );
    }

    public savePersonalInformation(client: Client): void {
        this.client = client;
        this.editingPersonalInfo = false;
        //aqui va el llamado

        if (this.idCuenta) {
            // this.loadAccount(+params.id);
            this.cargarAcreditado(this.idCuenta);
        }
    }

    public editPersonalInfo(): void {
        this.editingPersonalInfo = true;
    }
    public editarCuentaDetalleInfo(listaDetalle: Array<any>): void {
        this.cuentaDetalles = listaDetalle;
        this.editarCuentaInfo = false;
    }

    public verificarEstatus() {
        if(this.totalCuentaDetalle === 0){

            this.editarCuentaInfo = false;

        }
        if (this.domicilios && this.domicilios.length === 0) {
            this.banderaBtnEstatus = false;
        } else {
            this.banderaBtnEstatus = this.domicilios && Array.isArray(this.domicilios) && this.domicilios.some(domicilio => [1, 2, 3].includes(domicilio.idEstatus));
        }
        return this.banderaBtnEstatus;
    }


    public habilitarBtn(opcion: boolean) {
        if (this.verificarEstatus()) {
            if (opcion) {
                this.editingPersonalInfo = !this.editingPersonalInfo
            } else {
                this.editarCuentaInfo = !this.editarCuentaInfo;
            }
        } else {
            this.app.showSnackbar(
                "Aviso",
                "El domicilio se encuentra inactivo, no es posible editar.",
                3000,
                "error"
            );
        }
    }

    public loadEditAddress(clientAddress: ClientAddress): void {
        this.clientAddress = clientAddress;
        this.editAddress = !this.editAddress;
    }

    public asginacionVaribles(): void {
        this.pais = this.environment.pais;
        this.labelMunicipio = this.environment.labelMunicipio;
        this.labelEstado = this.environment.labelEstado;
        this.labelColonia = this.environment.labelMunicipio;
        this.labelCurp = this.environment.labelCURP;
        this.labelRFC = this.environment.labelRFC;
        this.kobraDashboardUrl = this.environment.kobraDashboard;
    }

    public cargarHistorialMensajes(idCliente, idAcreditadoPG, idCuentaPG: number) {
        this.cargandoMensajes = true;
        this.visitService
            .obtenerHistorialMensajes(idCliente, idAcreditadoPG, idCuentaPG)
            .pipe(take(1))
            .subscribe(
                (respuesta: any) => {
                    this.cargandoMensajes = false;
                    this.historialMensajes = respuesta.data;
                    this.totalMensajes =  Object.keys(this.historialMensajes).length;
                },
                (err: any) => {
                    this.cargandoMensajes = false;
                    this.app.showError(err);
                }
            );
    }

    public obtenerUltimaVisita(idCliente: number, idAcreditado: number, idCuenta: number) {
        this.coordenadasUltimaVisita = [];
        this.visitService.obtenerUltimaVisita(idCliente, idAcreditado, idCuenta)
            .pipe(take(1))
            .subscribe((respuesta: any) => {

                let response = respuesta.data[0];

                if (response) {
                  let folio = response?.folioVisita ?? '';
                  let agente = response?.idAgente && response?.nombreAgente ? `${response.idAgente}-${response.nombreAgente}` : '';

                  let domicilio = response.domicilioCompleto ||
                      (response.calle ? response.calle + ' ' : '') +
                      (response.numero ? '#' + response.numero + ', ' : '') +
                      (response.colonia ? response.colonia + ' ' : '') +
                      (response.codigoPostal ? response.codigoPostal + ', ' : '') +
                      (response.municipio ? response.municipio + ', ' : '') +
                      (response.estado ? response.estado + '.' : '');

                  let latitud = response.latitud || '';
                  let longitud = response.longitud || '';
                  let comentario = response.comentario || '';

                  this.coordenadasUltimaVisita.push({
                    folio: folio,
                    agente: agente,
                    domicilio: domicilio,
                    latitud: latitud,
                    longitud: longitud,
                    comentario: comentario
                  });
                }


            },
                (error: any) => {
                    this.app.showError(error);
                }
            );
    }

    public calcularDias(fechaRegistro: string, fechaVencimiento: string): { diasApartada: number, diasRestantes: number } {
        const hoy = new Date();
        const registro = new Date(fechaRegistro);
        const vencimiento = new Date(fechaVencimiento);
        hoy.setHours(0, 0, 0, 0);
        registro.setHours(0, 0, 0, 0);
        vencimiento.setHours(0, 0, 0, 0);

        // Calcular días desde la fecha de registro hasta hoy
        const diferenciaTiempoRegistro = hoy.getTime() - registro.getTime();
        const diasDesdeRegistro = Math.ceil(diferenciaTiempoRegistro / (1000 * 60 * 60 * 24));

        // Calcular días desde hoy hasta la fecha de vencimiento
        const diferenciaTiempoVencimiento = vencimiento.getTime() - hoy.getTime();
        const diasHastaVencimiento = Math.ceil(diferenciaTiempoVencimiento / (1000 * 60 * 60 * 24)) + 1;

        return {
            diasApartada: Math.max(diasDesdeRegistro, 0),
            diasRestantes: Math.max(diasHastaVencimiento, 0)
        };
    }

    public cargarDomicilios(idCliente: number, idAcreditado: number, idCuenta: number) {
        this.cargandoHistoriaDomiclios = true;
        //this.coordenadasMapa.length = 0;
        this.coordenadasMapa = [];
        let coordenadasTemp:any = [];
        this.visitService.obtenerDomicilios(idCliente, idAcreditado, idCuenta, true)
            .pipe(take(1))
            .subscribe((respuesta: any) => {
                this.cargandoHistoriaDomiclios = false;
                for (let i = 0; i < respuesta.data.length; i++) {
                    const diasCalculados = this.calcularDias(respuesta.data[i].fechaRegistro, respuesta.data[i].fechaVencimiento);

                    respuesta.data[i].idTipoVisibilidad = respuesta.data[i].idTipoVisibilidad === 1 ? 'Red Kobra' : 'Red Interna';
                    respuesta.data[i].textoClick = respuesta.data[i].primary ? 'Domicilio principal' : 'Establecer como principal';
                    respuesta.data[i].coordenadas = respuesta.data[i].latitud ? `${respuesta.data[i].latitud} , ${respuesta.data[i].longuitud}` : 'No disponible';
                    respuesta.data[i].domicilio = respuesta.data[i].domicilioCompleto ? respuesta.data[i].domicilioCompleto : (respuesta.data[i].calle ? respuesta.data[i].calle +' ' : '') +
                                                (respuesta.data[i].numeroExt ? respuesta.data[i].numeroExt +', ' : '') +
                                                (respuesta.data[i].numeroInt ? respuesta.data[i].numeroInt +', ' : '') +
                                                (respuesta.data[i].codigoPostal ? respuesta.data[i].codigoPostal +', ' : '') +
                                                (respuesta.data[i].colonia ? respuesta.data[i].colonia +' ' : '') +
                                                (respuesta.data[i].municipio ? respuesta.data[i].municipio+', ' : '') +
                                                (respuesta.data[i].estado ? respuesta.data[i].estado +'.' : '') ;
                    respuesta.data[i].selectDomicilio = respuesta.data[i].folioDomicilio + ' - ' + respuesta.data[i].domicilio;
                    respuesta.data[i].fechaVencimiento = respuesta.data[i].fechaVencimiento ? this.momentVista(respuesta.data[i].fechaVencimiento).format('DD/MM/YYYY') : '';
                    respuesta.data[i].diasApartada = diasCalculados.diasApartada;
                    respuesta.data[i].diasRestantes = diasCalculados.diasRestantes;

                    if(respuesta.data[i].idCobradorAsignado){
                        respuesta.data[i].agente = respuesta.data[i].idCobradorAsignado ? respuesta.data[i].idCobradorAsignado + ' - ' + respuesta.data[i].nomGestorAsignado: '';
                    }

                    if(respuesta.data[i].idCobradorAparto ){
                        respuesta.data[i].agente = respuesta.data[i].idCobradorAparto ? respuesta.data[i].idCobradorAparto + ' - ' + respuesta.data[i].nomGestorAparto: '';
                    }

                    if(respuesta.data[i].latitud && respuesta.data[i].longuitud){
                        if(respuesta.data[i].latitud !=0 && respuesta.data[i].longuitud !=0){

                            coordenadasTemp.push({
                                lat: respuesta.data[i].latitud,
                                lng: respuesta.data[i].longuitud
                              });
                        }
                    }
                }
                this.coordenadasMapa = coordenadasTemp;

                setTimeout(() => {

                    if(this.coordenadasMapa.length > 0){
                        this.mapa = true;
                    }
                }, 200);

                this.domicilios = respuesta.data;
            },
                (error: any) => {
                    this.cargandoHistoriaDomiclios = false;
                    this.app.showError(error);
                }
            );
    }

    public cargarDomiciliosBorrados(idCliente: number, idAcreditado: number, idCuenta: number) {
        this.cargandoHistoriaDomiclios = true;
        this.visitService.obtenerDomicilios(idCliente, idAcreditado, idCuenta, false)
            .pipe(take(1))
            .subscribe((respuesta: any) => {
                this.cargandoHistoriaDomiclios = false;
                for (let i = 0; i < respuesta.data.length; i++) {
                    respuesta.data[i].coordenadas = respuesta.data[i].latitud ? `${respuesta.data[i].latitud} , ${respuesta.data[i].longuitud}` : 'No disponible';
                    respuesta.data[i].domicilio = (respuesta.data[i].calle ? respuesta.data[i].calle +' ' : '') +
                                                (respuesta.data[i].numeroExt ? respuesta.data[i].numeroExt +', ' : '') +
                                                (respuesta.data[i].numeroInt ? respuesta.data[i].numeroInt +', ' : '') +
                                                (respuesta.data[i].colonia ? respuesta.data[i].colonia +' ' : '') +
                                                (respuesta.data[i].codigoPostal ? respuesta.data[i].codigoPostal +', ' : '') +
                                                (respuesta.data[i].municipio ? respuesta.data[i].municipio+', ' : '') +
                                                (respuesta.data[i].estado ? respuesta.data[i].estado +'.' : '') ;
                    respuesta.data[i].selectDomicilio = respuesta.data[i].folioDomicilio + ' - ' + respuesta.data[i].domicilio;
                }

                /*if(this.coordenadasMapa.length > 0){
                    this.mapa = true;
                }*/

                this.domiciliosBorrados = respuesta.data;
            },
                (error: any) => {
                    this.cargandoHistoriaDomiclios = false;
                    this.app.showError(error);
                }
            );
    }

    onChangeFile(file) {
        this.file = file;
    }

    onAsignarAgenteFilterSelected(agente: any) {
        this.asignarAgenteFilterFlag = true;
        this.formAsignacion.get("idCobradorAsignado").patchValue(agente.idCobrador);
    }

    onLinkRow({ row, columnPressed }) {
        if (columnPressed === 'folioVisita') {
            this.LinkVisitas(row);
        }
    }

    onDoubleClick({ row }) {
        this.LinkVisitas(row);
    }

    LinkVisitas(visita: any) {
        this.router.navigate(['/visits/visit/' + visita.folioVisita]);
    }

    onDomicilioClick({ row, columnPressed }) {
        if (columnPressed == 'coordenadas') {
            if (row.coordenadas != 'No disponible') {
                window.open(`https://www.google.com/maps/search/?api=1&query=${row.coordenadas}`, '_blank');
            }
        }
    }


    public onSelected( rows ){
        this.itemsSelected = rows.slice();
    }

    onTabChange(event: MatTabChangeEvent): void {
        const tabIndex = event.index;

        if (tabIndex === 0) {
          this.itemsSelected = [];
        } else if (tabIndex === 1) {
          this.itemsSelected = [];
        }

      }

    public changeMotivo(): void {
        if (this.selectedTag) {
            this.nonSelectedTagWarning = false;
        } else {
            this.nonSelectedTagWarning = true;
        }
    }

    public obtenerCheckListInfo() {
        this.visitService.checklistInfo(this.account.idCliente, this.account.product.id, this.account.idAcreditado, this.account.idAcreditadoPG, this.account.idCuentaPG)
            .pipe(take(1))
            .subscribe((respuesta) => {
                this.subirContratoAcreditado = respuesta.data.clienteSubeArchivo;
                this.tieneContratos = respuesta.data.tieneContratos;
                this.idCheckList = respuesta.data.idChecklist;
                this.numVersion = respuesta.data.numVersion;
                this.obtenerArchivosFormatos();
            }, error => {
                this.app.showError(error);
            })
    }


    public obtenerDiasVencimientoCuentas(): void {
        let queryString: string = '?idCliente=' + this.account.idCliente;

        this.accountService.getDiasVencimiento(queryString)
            .subscribe(
                data => {
                    this.diasVencimiento = data.diasVencimientoCuenta;
                    //this.sumarDias(this.fechaAsignacionMaxDate,  this.diasVencimiento);
                }, error => {
                    this.app.showError(error);
                })
    }

    loadProducts() {
        //this.loadingProducts = true;
        this.lenderService.obtenerProductosCliente(this.account.idCliente)
            .pipe(
                take(1)
            ).subscribe((res: any) => {
                this.loadingProducts = false;
                this.productos = res.data;
            }, (err: any) => {
                //this.loadingProducts = false;
                this.app.showError(err);
            });
    }

    cargarEtiquetasCuenta() {
        //this.loadingEtiquetasCuenta = true;
        this.visitService.obtenerEtiquetasCuenta(this.account.idCliente)
            .pipe(
                take(1)
            ).subscribe((respuesta: any) => {
                this.loadingEtiquetasCuenta = false;
                this.etiquetasCuenta = respuesta.data;
            }, (err: any) => {
                //this.loadingEtiquetasCuenta = false;
                this.app.showError(err);
            });
    }

    cargarHistorialEtiquetasCuenta() {
        this.loadingHistorialEtiquetasCuenta = true;
        this.visitService.obtenerHistorialEtiquetasCuenta(this.account.idCliente, this.account.id)
            .pipe(
                take(1)
            ).subscribe((respuesta: any) => {
                this.loadingHistorialEtiquetasCuenta = false;
                for (let i = 0; i < respuesta.data.length; i++) {
                    const etiqueta = respuesta.data[i];
                    respuesta.data[i].nombreAdministradorFullName = `${etiqueta.nombreAdministrador}
                            ${(etiqueta.apellidoPaternoAdministrador ? etiqueta.apellidoPaternoAdministrador + ' ' : '')}
                            ${(etiqueta.apellidoMaternoAdministrador ? etiqueta.apellidoMaternoAdministrador : '')}`;

                }
                this.etiquetasCuentaHistorial = respuesta.data;

                if(respuesta.data.length > 0){
                    this.nombreEtiquetasCuenta = respuesta.data[0].nombreEtiquetaCuenta;
                }

            }, (err: any) => {
                this.loadingHistorialEtiquetasCuenta = true;
                this.app.showError(err);
            });
    }

    cargarHistorialPagosRecabados(validaFecha: boolean) {
        let fechaInicio;
        let fechaFin;
        let queryString: string = `?idCliente=${this.account.idCliente}&idAcreditado=${this.account.idAcreditadoPG}&idCuenta=${this.account.idCuentaPG}`;

        /*if (!validaFecha) {
            this.formRecabadoPagos.patchValue({
                fechaInicio: null,
                fechaFin: null
            });
        } else {
            fechaInicio = this.formRecabadoPagos.value.fechaInicio ? this.formRecabadoPagos.value.fechaInicio : null;
            fechaFin = this.formRecabadoPagos.value.fechaFin ? this.formRecabadoPagos.value.fechaFin : null;

            if (fechaInicio) {
                if (parseInt(this.momentVista(fechaInicio).format('YYYYMMDD')) > parseInt(this.momentVista(fechaFin).format('YYYYMMDD'))) {
                    this.app.showSnackbar(
                        "Aviso",
                        "La fecha inicio no debe ser mayor a la fecha fin",
                        3000,
                        "warning"
                    );
                    return;
                } else {
                    queryString += `&fechaInicio=${this.momentVista(fechaInicio).format('YYYY-MM-DD')}`;
                }
            } else {
                this.app.showSnackbar(
                    "Aviso",
                    "Favor de seleccionar fecha inicio",
                    3000,
                    "warning"
                );
                return;
            }

            if (fechaFin) {
                if (parseInt(this.momentVista(fechaFin).format('YYYYMMDD')) < parseInt(this.momentVista(fechaInicio).format('YYYYMMDD'))) {
                    this.app.showSnackbar(
                        "Aviso",
                        "La fecha fin no debe ser menor a la fecha inicio",
                        3000,
                        "warning"
                    );
                    return;
                } else {
                    queryString += `&fechaFin=${this.momentVista(fechaFin).format('YYYY-MM-DD')}`;
                }
            } else {
                this.app.showSnackbar(
                    "Aviso",
                    "Favor de seleccionar fecha fin",
                    3000,
                    "warning"
                );
                return;
            }
        }*/



        /*if(!validaFecha) {
            this.contadorPagos = 0;
        }*/
        this.montoTotal = 0;
        this.loadingDineroRecuperado = true;
        this.visitService.cargarHistorialPagosRecabados(queryString)
            .pipe(
                take(1)
            ).subscribe((respuesta: any) => {
                this.loadingDineroRecuperado = false;
                for (let i = 0; i < respuesta.data.length; i++) {
                    const datos = respuesta.data[i];
                    let idAgente = '';
                    let nombreCompletoAgente;
                    let nombreCompletoAgente2;

                    if (datos.idAgente) {
                        idAgente = `${datos.idAgente} -`;
                    }

                    nombreCompletoAgente = `${datos.nombreAgente ? datos.nombreAgente : ''} ${datos.apellidoPaternoAgente ? datos.apellidoPaternoAgente : ''} ${datos.apellidoMaternoAgente ? datos.apellidoMaternoAgente : ''}`;
                    nombreCompletoAgente2 = `${datos.nombreAgente2 ? datos.nombreAgente2 : ''} ${datos.apellidoPaternoAgente2 ? datos.apellidoPaternoAgente2 : ''} ${datos.apellidoMaternoAgente2 ? datos.apellidoMaternoAgente2 : ''}`;

                    if (nombreCompletoAgente) {
                        respuesta.data[i].nombreCompletoAgente = `${idAgente} ${nombreCompletoAgente}`;
                    } else {
                        respuesta.data[i].nombreCompletoAgente = `${idAgente} ${nombreCompletoAgente2}`;
                    }

                    this.montoTotal = this.montoTotal + parseInt(datos.montoPago);
                    this.contadorPagos++;
                    respuesta.data[i].montoPago = '$' + datos.montoPago;
                    respuesta.data[i].fechaPago = this.momentVista(datos.fechaPago).format('DD/MM/YYYY');
                    respuesta.data[i].fechaRegistro = this.momentVista(datos.fechaRegistro).format('DD/MM/YYYY');
                }
                this.dineroRecuperado = respuesta.data;
            }, (err: any) => {
                this.loadingDineroRecuperado = true;
                this.app.showError(err);
            });
    }

    public filtrarFecha(filtro: boolean) {
        if (filtro) {
            this.filtroFechaPago = false;
        } else {
            this.filtroFechaPago = true;
        }

        this.formRecabadoPagos.patchValue({
            fechaInicio: null,
            fechaFin: null
        });
    }

    public onChangeDomicilio() {

        if(!this.idDomicilio) {
            this.domicilioWarning =  true;
        }else {
            this.domicilioWarning =  false;
        }
    }

    public onChangeDomicilioAsignar() {

        if(!this.idDomicilioAsignar) {
            this.domicilioAsignarWarning =  true;
        }else {
            this.domicilioAsignarWarning =  false;
        }
    }

    openDialog(): void {

        const dialogRef = this.dialog.open(ModalDomicilioComponent, {
            data: this.clientAddress,
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result) {
                this.cargarDomicilios(this.account.idCliente, this.account.idAcreditadoPG, this.account.idCuentaPG);
            }
        });
    }

    openDialogActivar(): void {

        if (this.itemsSelected.length != 1 || !this.itemsSelected[0] ) {
            return;
        }

        const selectedItem = this.itemsSelected[0];

        if (!('idDomicilioAcreditado' in selectedItem)) {
            return;
        }

        this.cargandoHistoriaDomiclios = true;
        this.accountService.actualizarDomicilio(this.client.externalID, this.client.idFinanciera, this.account.clientID, selectedItem.idDomicilioAcreditado, this.account.id)
            .pipe(take(1))
            .subscribe((respuesta) => {
                if(respuesta.success){
                    this.app.showSnackbar(
                        "Aviso",
                        "El domicilio se actualizó correctamente.",
                        3000,
                        "success"
                    );
                    this.cargarDomicilios(this.account.idCliente, this.account.idAcreditadoPG, this.account.idCuentaPG);
                    this.cargarDomiciliosBorrados(this.account.idCliente, this.account.idAcreditadoPG, this.account.idCuentaPG);
                }else{
                    this.app.showSnackbar(
                        "Aviso",
                        "Ocurrio un error al actualizar el domicilio.",
                        3000,
                        "error"
                    );
                }
                this.cargandoHistoriaDomiclios = false;

            },
            (error: any) => {
                this.cargandoHistoriaDomiclios = false;
                this.app.showError(error);
            });


    }

    openDialogEditar(): void {

        if (this.itemsSelected.length != 1  || !this.itemsSelected[0]) {
            return;
        }

        const selectedItem = this.itemsSelected[0];

        if (!('idDomicilioAcreditado' in selectedItem)) {
            return;
          }


        if (!('idAcreditadoCuentaDomicilio' in selectedItem)) {
            return;
        }

      this.cargarDomicilioAcreditado(
        this.account.id,
        selectedItem.idDomicilioAcreditado,
        selectedItem.idAcreditadoCuentaDomicilio
      );

    }

    openDialogAsignar(): void {
        const dialogRef = this.dialog.open(ModalAsignarCuentaComponent, {
            //height: '400px',
            //width: '800px',
            //disableClose: true,
            data:{
                    opcion: 1,
                    account:this.account,
                    domiciliosSeleccionados: this.itemsSelected,
                    domicilios: this.domicilios,
                    idCobrador: null,
                    nombreCobrador: null,
                    idAgente: null,
                    nombreAcreditado: null,
                    folioVisita: null,
                    idVisitaPsg: null
                }
        });

        dialogRef.afterClosed().subscribe(result => {

            if(result) {
                //this.cargarCuenta(this.account.id);
                this.cargarDomicilios(this.account.idCliente, this.account.idAcreditadoPG, this.account.idCuentaPG);
            }
        });
    }

    openDialogEtiqueta(): void {

        const dialogRef = this.dialog.open(ModalEtiquetaCuentaComponent, {
            width: '500px',
            //disableClose: true,
            data:{
                    etiquetasCuenta:this.etiquetasCuenta,
                    productos: this.productos,
                    idFinanciera: this.idFinanciera,
                    idCliente: this.account.idCliente,
                    idAcreditadoPG: this.account.idAcreditadoPG,
                    idCuentaPG: this.account.idCuentaPG,
                    idCuenta: this.idCuenta,
                    idVisita: null,
                    idAgente: null,
                    idEtiquetaOperacion: null
                }
        });

        dialogRef.afterClosed().subscribe(result => {

            if(result) {
              if(this.etiquetasCuenta){
                this.cargarCuenta(this.account.id, true);
              }else{
                this.cargarCuenta(this.account.id, false);
              }

            }
        });
    }

    openDialogEstatus(): void {

        const dialogRef = this.dialog.open(ModalEstatusCuentaComponent, {
            //height: '400px',
            width: '500px',
            //disableClose: true,
            data:{
                    opcion: 1,
                    domicilios: this.domicilios,
                    motivos: this.motivos,
                    domiciliosSeleccionados: this.itemsSelected,
                    idFinanciera: this.idFinanciera,
                    idCliente: this.account.idCliente,
                    idAcreditadoPG: this.account.idAcreditadoPG,
                    idCuentaPG: this.account.idCuentaPG,
                    idCuenta: this.idCuenta
                }
        });

        dialogRef.afterClosed().subscribe(result => {

            if(result) {
                this.cargarDomicilios(this.account.idCliente, this.account.idAcreditadoPG, this.account.idCuentaPG);
                this.cargarHistorialCuenta(this.idFinanciera, this.idExterno, this.idCuenta);
                //this.cargarCuenta(this.account.id);

            }
        });
    }

    onclikDomicilioBorrados({ row, columnPressed }) {

        if (columnPressed == 'coordenadas') {
            if (row.coordenadas != 'No disponible') {
                window.open(`https://www.google.com/maps/search/?api=1&query=${row.coordenadas}`, '_blank');
            }
        }

    }

    openDialogContracto(): void {

        const dialogRef = this.dialog.open(ModalContratosComponent, {
            data:{
                    idAcreditado: this.account.idAcreditadoPG,
                    idCuenta: this.account.idCuentaPG,
                    idCliente: this.account.idCliente,
                    idFinanciera: this.idFinanciera,
                    tieneContratos: this.tieneContratos,
                    archivosContratos: this.archivosContratos,
                    isConsultar: this.isConsultar
                }
        });

        dialogRef.afterClosed().subscribe(result => {
            if(result){
                this.isConsultar = result.isConsultar;
                this.archivosContratos = result.data;
                this.tieneContratos = result.tieneContratos;
            }
        });
    }

    openDialogFormatos(): void {
        const dialogRef = this.dialog.open(ModalDescargaFormatoCuentaComponent, {
            data:{
                idCliente: this.account.idCliente,
                formatosCuentas: this.formatosCuentas,
                idFinanciera: this.idFinanciera,
                idCuentaMS: this.account.id,
                idExterno: this.client.externalID,
                idAcreditado: this.account.idAcreditadoPG,
                idCuenta: this.account.idCuentaPG,
                domicilios: this.domicilios
            }
        });
        dialogRef.afterClosed().subscribe(result => {
        });
    }

    public obtenerArchivosFormatos() {
        this.visitService.obtenerArchivos(this.account.idCliente, this.idCheckList, this.numVersion, 2)
            .pipe(take(1))
            .subscribe((respuesta: any) => {
                this.formatosCuentas = respuesta.data;
                if(this.formatosCuentas.length > 0){
                    this.isFormatos = true;
                }

        },(error: any) => {
            this.app.showError(error);
        });
    }
}
