import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

declare const jQuery: any;

@Injectable({
  providedIn: 'root'
})
export class FocusService {
  private focusSubject = new Subject<{ id: string, position: number | null }>();
  private insertTextSubject = new Subject<{ id: string, text: string }>();

  focus$ = this.focusSubject.asObservable();
  insertText$ = this.insertTextSubject.asObservable();

  /**
   * Se encarga de colocar el focus en el control que se requiere
   * @param {string} id Es el id del elemento que se le pondrá el foco.
   * @param {number|null} position Posición en la que se quiere colocar el cursor.
   * @Auth Uriel Yair Gámez Rosales
   */
  triggerFocus(id: string, position: number | null = null) {
    this.focusSubject.next({ id, position });
  }

  /**
   * Se encarga de añadir texto insertado en el input
   * @param {string} id Es el id del elemento que se le pondrá el foco.
   * @param {string} text Es el texto que se va a insertar.
   * @Auth Uriel Yair Gámez Rosales
   */
  triggerInsertText(id: string, text: string) {
    this.insertTextSubject.next({ id, text });
  }
}
