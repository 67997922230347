import { Injectable } from '@angular/core';
import { environmentSelector } from '../../../../environments/environment.selector';
import { ServicesGeneralService } from '../servicio-general/servicio-general.service';

@Injectable({
  providedIn: 'root'
})

export class ServiciosBiService extends ServicesGeneralService{
  protected override baseURL: string = environmentSelector().kobraServices.bi;
  protected override propertie: string = 'bi';

}
