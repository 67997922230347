<h1 mat-dialog-title>{{data.isAfectado ? 'Variables con error en la plantilla' : 'Catálogo de variables'}}</h1>
<mat-dialog-content>
  <alerta-informativa 
    *ngIf="data.isAfectado"
    clase="danger"
    titulo="¡Aviso!" 
    [texto] = "data.mensaje">
  </alerta-informativa>
  <div class="contenido" *ngIf="!loadVariables">
    <mat-card>
      <mat-card-content>
        <mat-accordion>
          <mat-expansion-panel *ngIf="cuenta.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Cuenta
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaCuenta.columnas" [items]="cuenta" [headers]="tablaCuenta.headers"
              [tableHeaders]="tablaCuenta.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaCuenta.multiSelect"
              (onLinkRow)="onClick($event)"></app-table>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="cuentaDetalle.length > 0 || !data.isAfectado">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Detalle de la cuenta
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaCuentaDetalle.columnas" [items]="cuentaDetalle"
              [headers]="tablaCuentaDetalle.headers" [tableHeaders]="tablaCuentaDetalle.tableHeaders"
              [ignoreSortChange]="true" [multiSelect]="tablaCuentaDetalle.multiSelect">
            </app-table>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="agente.length > 0" >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="title-container">
                  Agente
                  <button *ngIf="data.tipoFormato == 2"
                          class="icono-warning"
                          mat-icon-button
                          matTooltip="Esta categoria no pertenece a plantillas de cuentas"
                          mat-tooltip-panel-above>
                    <mat-icon>warning</mat-icon>
                  </button>
                </div>
              </mat-panel-title>
                           
            </mat-expansion-panel-header>
            <app-table  [classContainer]="'tabla-sin-scoll'" [columns]="tablaAgente.columnas" [items]="agente" [headers]="tablaAgente.headers"
              [tableHeaders]="tablaAgente.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaAgente.multiSelect"
            ></app-table>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="visita.length > 0" >
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="title-container">
                  Visita
                  <button *ngIf="data.tipoFormato == 2"
                          class="icono-warning"
                          mat-icon-button
                          matTooltip="Esta categoria no pertenece a plantillas de cuentas"
                          mat-tooltip-panel-above>
                    <mat-icon>warning</mat-icon>
                  </button>
                </div>
              </mat-panel-title>
                           
            </mat-expansion-panel-header>
            <app-table  [classContainer]="'tabla-sin-scoll'" [columns]="tablaVisita.columnas" [items]="visita" [headers]="tablaVisita.headers"
              [tableHeaders]="tablaVisita.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaVisita.multiSelect"
            ></app-table>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="checkLists.length > 0 || (!data.isAfectado && data.tipoFormato == 1)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="title-container">
                  CheckLists
                  <button *ngIf="data.tipoFormato == 2"
                          class="icono-warning"
                          mat-icon-button
                          matTooltip="Esta categoria no pertenece a plantillas de cuentas"
                          mat-tooltip-panel-above>
                    <mat-icon>warning</mat-icon>
                  </button>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaCheckList.columnas" [items]="checkLists" [headers]="tablaCheckList.headers"
              [tableHeaders]="tablaCheckList.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaCheckList.multiSelect"
            ></app-table>
          </mat-expansion-panel>
          
          <mat-expansion-panel *ngIf="condicionales.length > 0 || (!data.isAfectado && data.tipoFormato == 1)">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <div class="title-container">
                  Bloques
                  <button *ngIf="data.tipoFormato == 2"
                          class="icono-warning"
                          mat-icon-button
                          matTooltip="Esta categoria no pertenece a plantillas de cuentas"
                          mat-tooltip-panel-above>
                    <mat-icon>warning</mat-icon>
                  </button>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaCondicionales.columnas" [items]="condicionales" [headers]="tablaCondicionales.headers"
              [tableHeaders]="tablaCondicionales.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaCondicionales.multiSelect"
            ></app-table>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="generales.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Generales
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaGenerales.columnas" [items]="generales" [headers]="tablaGenerales.headers"
              [tableHeaders]="tablaGenerales.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaGenerales.multiSelect"
            ></app-table>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="sinCategoria.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                Sin categoria
              </mat-panel-title>
            </mat-expansion-panel-header>
            <app-table [classContainer]="'tabla-sin-scoll'" [columns]="tablaSinCategoria.columnas" [items]="sinCategoria" [headers]="tablaSinCategoria.headers"
              [tableHeaders]="tablaSinCategoria.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tablaSinCategoria.multiSelect"
            ></app-table>
          </mat-expansion-panel>
        </mat-accordion>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="boton-cancelar" mat-flat-button (click)="salir()">
    <mat-icon class="kobra-icon">close</mat-icon>Cancelar
  </button>
</mat-dialog-actions>