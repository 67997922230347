export class ConceptosEtiquetasVisitas{
    idConcepto: number;
    idEtiquetaVisita: number;
    idTipoConcepto: number;
    cargo: boolean;
    nombre: string;
    nombreTipoConcepto: string;

    constructor(){
    }

    public static map(data: any): ConceptosEtiquetasVisitas {
        let instance: ConceptosEtiquetasVisitas = new ConceptosEtiquetasVisitas();
        
        instance.idConcepto = data.idConcepto ?? null;
        instance.idEtiquetaVisita = data.idEtiquetaVisita ?? null;
        instance.idTipoConcepto = data.idTipoConcepto ?? null;
        instance.cargo = data.cargo ?? null;
        instance.nombre = data.nombre ?? null;
        instance.nombreTipoConcepto = data.nombreTipoConcepto ?? null;
    
        return instance;
    }
    

    public static mapArray(data: any[]): ConceptosEtiquetasVisitas[]{
        let instance:ConceptosEtiquetasVisitas[] = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            instance.push(ConceptosEtiquetasVisitas.map(element));
        }
        return instance;
    }
}