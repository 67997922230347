<router-outlet (activate)="activarHijo()" (deactivate)="hijosActivos = false;setFilterCorreos();setFilterTelefonos()"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
  <mat-tab-group>

    <mat-tab label="Correos electrónicos">
      <div class="button-container">
        <div class="button-toggle-group-container">
          <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
            <mat-button-toggle value="filtros"
              (click)="filtrosSideNavCorreos.toggle(); filtrosCorreosAbiertos = !filtrosCorreosAbiertos;"
              matTooltip="Filtrar" mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">filter_alt</mat-icon>
              <span *ngIf="totalFiltrosCorreos >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltrosCorreos }}</span>

            </mat-button-toggle>
            <mat-button-toggle value="actualizarCorreos" (click)="obtenerCorreosElectronicos(filters.page || 1);"
              matTooltip="Refrescar" mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">sync</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="subMenu" matTooltip="Descargar listado" mat-tooltip-panel-above
              [disabled]="correosElectronicos.length == 0" (click)="descargarListado(1)">
              <mat-icon class="kobra-icon">cloud_download</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="ayuda" matTooltip="Ayuda" mat-tooltip-panel-above
              (click)="ayudaCorreos = !ayudaCorreos;">
              <mat-icon color="primary">help</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <app-table [loading]="loadings.correos" [columns]="tablaCorreosElectronicos.columnas"
        [items]="correosElectronicos" [headers]="tablaCorreosElectronicos.headers"
        [classContainer]="'table-container-recopilada'" [tableHeaders]="tablaCorreosElectronicos.tableHeaders"
        [multiSelect]="tablaCorreosElectronicos.multiSelect" (onLinkRow)="onClick($event)"
        (onCtrlClicLinkRow)="onCtrlClick($event)" (sortChange)="setSortFieldCorreos($event)">

        <ng-container t-paginator>
          <mat-paginator showFirstLastButtons [length]="pagination?.totalRows" [pageIndex]="pagination?.page-1"
            [pageSize]="datosConsultaCorreos.rows" (page)="onPage($event)"
            aria-label="Select page of periodic elements">
          </mat-paginator>
        </ng-container>
        <div t-header>
          <mat-card *ngIf="ayudaCorreos" class="cardAyuda">

            <mat-card-content>
              <p><mat-icon color="primary">help</mat-icon>
                El módulo "Información Recopilada" muestra un listado histórico que recoge todos los correos recopilados
                por los agentes en cada una de sus visitas, proporcionando un registro detallado y organizado.Los
                usuarios pueden aplicar filtros basados en criterios específicos como la cuenta del cliente o la fecha
                en que se recopiló la información
              </p>
              Acciones:
              <p>
                <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de las
                información a mostrar.
              </p>
              <p>
                <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los
                criterios de filtros aplicados.
              <p>
                <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar
                la información previamente filtrada para su análisis o uso fuera de línea.
              </p>

            </mat-card-content>
          </mat-card>
        </div>
      </app-table>
    </mat-tab>

    <mat-tab label="Teléfonos">
      <div class="button-container">
        <div class="button-toggle-group-container">
          <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
            <mat-button-toggle value="filtros"
              (click)="filtrosSideNavTelefonos.toggle(); filtrosTelefonosAbiertos = !filtrosTelefonosAbiertos;"
              matTooltip="Filtrar" mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">filter_alt</mat-icon>
              <span *ngIf="totalFiltrosTelefonos >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltrosTelefonos }}</span>

            </mat-button-toggle>
            <mat-button-toggle value="actualizarTelefonos" (click)="obtenerTelefonos(filters.page || 1);"
              matTooltip="Refrescar" mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">sync</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="subMenu" matTooltip="Descargar listado" mat-tooltip-panel-above
              [disabled]="telefonos.length  == 0" (click)="descargarListado(3)">
              <mat-icon class="kobra-icon">cloud_download</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="ayuda" matTooltip="Ayuda" mat-tooltip-panel-above
              (click)="ayudaTelefonos = !ayudaTelefonos;">
              <mat-icon color="primary">help</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
      </div>

      <app-table [loading]="loadings.telefonos" [columns]="tablaTelefonos.columnas" [items]="telefonos"
        [classContainer]="'table-container-recopilada'" [headers]="tablaTelefonos.headers"
        [tableHeaders]="tablaTelefonos.tableHeaders" [multiSelect]="tablaTelefonos.multiSelect"
        (onLinkRow)="onClick($event)" (onCtrlClicLinkRow)="onCtrlClick($event)"
        (sortChange)="setSortFieldTelefonos($event)">

        <ng-container t-paginator>
          <mat-paginator showFirstLastButtons [length]="paginationTelefonos?.totalRows"
            [pageIndex]="paginationTelefonos?.page-1" [pageSize]="datosConsultaTelefonos.rows"
            (page)="onPageTelefono($event)" aria-label="Select page of periodic elements">
          </mat-paginator>
        </ng-container>
        <div t-header>
          <mat-card *ngIf="ayudaTelefonos" class="cardAyuda">

            <mat-card-content>
              <p><mat-icon color="primary">help</mat-icon>
                El módulo "Información Recopilada" muestra un listado histórico que recoge todos los correos recopilados
                por los agentes en cada una de sus visitas, proporcionando un registro detallado y organizado.Los
                usuarios pueden aplicar filtros basados en criterios específicos como la cuenta del cliente o la fecha
                en que se recopiló la información
              </p>
              Acciones:
              <p>
                <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de las
                información a mostrar.
              </p>
              <p>
                <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los
                criterios de filtros aplicados.
              <p>
                <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar
                la información previamente filtrada para su análisis o uso fuera de línea.
              </p>

            </mat-card-content>
          </mat-card>
        </div>
      </app-table>
    </mat-tab>
  </mat-tab-group>


</div>

<mat-sidenav #filtrosSideNavCorreos fixedInViewport autosize class="sidenav-right" mode="side" position="start"
  [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button matTooltip="Ocultar filtros" mat-tooltip-panel-above
      (click)="filtrosSideNavCorreos.toggle()">
      <mat-icon>cancel</mat-icon>
    </button>
    Filtros Correos
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          Súper Administrador
          <span *ngIf="contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('superAdmin') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search class="id-card-field" [label]="'Cliente'" [placeHolder]="'Selecciona un cliente'"
              [value]="'idFinanciera'" [labelField]="'name'" [icon]="'account_balance'" [options]="lenders"
              [(ngModel)]="idLender" (ngModelChange)="onChangeLender($event);" >
            </mat-select-search>

          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Generales
          <span *ngIf="contabilizadorDeFiltros('generalesC') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('generalesC') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>ID Cuenta</mat-label>
              <input matInput textSanitizer  [(ngModel)]="filters.idAcreditado" (ngModelChange)="this.contabilizadorDeFiltros('generalesC');" placeholder="Capture el id de la cuenta">
              <mat-icon matSuffix  class="icono-limpiar"
              *ngIf="filters.idAcreditado"
              (click)="filters.idAcreditado = null">clear</mat-icon>
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>

          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Fechas
          <span *ngIf="contabilizadorDeFiltros('fechasC') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('fechasC') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-date-picker-formater [label]="'Fecha inicio'" [placeHolder]="'Capture la fecha inicio'"
              [max]="filters.fechaFin ? filters.fechaFin : today" [(ngModel)]="filters.fechaInicio" (ngModelChange)="this.contabilizadorDeFiltros('fechasC');"/>

          </li>
          <li class="filtro-side">
            <mat-date-picker-formater [label]="'Fecha fin'" [placeHolder]="'Capture la fecha fin'"
              [min]="filters.fechaInicio" [max]="today" [(ngModel)]="filters.fechaFin" (ngModelChange)="this.contabilizadorDeFiltros('fechasC');"/>

          </li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <ol class="lista-filtros-buttons">
    <button style="width: 100%;" mat-stroked-button class="boton-accion-front"
      (click)="filtrosSideNavCorreos.toggle(); filtrosCorreosAbiertos=!filtrosCorreosAbiertos; setFilterCorreos();">
      <mat-icon>filter_alt</mat-icon>
      Filtrar
    </button>
    <button style="width: 100%; margin-top: 10px;" mat-stroked-button (click)="clearFilterCorreos();">
      <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
      Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
<mat-sidenav #filtrosSideNavTelefonos fixedInViewport autosize class="sidenav-right" mode="side" position="start"
  [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button matTooltip="Ocultar filtros" mat-tooltip-panel-above
      (click)="filtrosSideNavTelefonos.toggle()">
      <mat-icon>cancel</mat-icon>
    </button>
    Filtros Telefonos
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          Súper Administrador
          <span *ngIf="contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('superAdmin') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search class="id-card-field" [label]="'Cliente'" [placeHolder]="'Selecciona un cliente'"
              [value]="'idFinanciera'" [labelField]="'name'" [icon]="'account_balance'" [options]="lenders"
              [(ngModel)]="idLender" (ngModelChange)="onChangeLender($event); ">
            </mat-select-search>

          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Generales
          <span *ngIf="contabilizadorDeFiltros('generalesT') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('generalesT') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>ID Cuenta</mat-label>
              <input matInput textSanitizer  [(ngModel)]="filters.idAcreditadoTelefonos" (ngModelChange)="this.contabilizadorDeFiltros('generalesT');" placeholder="Capture el id de la cuenta">
              <mat-icon matSuffix  class="icono-limpiar"
              *ngIf="filters.idAcreditadoTelefonos"
              (click)="filters.idAcreditadoTelefonos = null;this.contabilizadorDeFiltros('generalesT')">clear</mat-icon>
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>

          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Fechas
          <span *ngIf="contabilizadorDeFiltros('fechasT') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('fechasT') }}</span>

        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-date-picker-formater [label]="'Fecha inicio'" [placeHolder]="'Capture la fecha inicio'"
              [max]="filters.fechaFinTelefonos ? filters.fechaFinTelefonos : today" [(ngModel)]="filters.fechaInicioTelefonos" (ngModelChange)="this.contabilizadorDeFiltros('fechasT');" />

          </li>
          <li class="filtro-side">
            <mat-date-picker-formater [label]="'Fecha fin'" [placeHolder]="'Capture la fecha fin'"
              [min]="filters.fechaInicioTelefonos" [max]="today" [(ngModel)]="filters.fechaFinTelefonos" (ngModelChange)="this.contabilizadorDeFiltros('fechasT');" />

          </li>
        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>

  <ol class="lista-filtros-buttons">
    <button style="width: 100%;" mat-stroked-button class="boton-accion-front"
      (click)="filtrosSideNavTelefonos.toggle(); filtrosCorreosAbiertos=!filtrosCorreosAbiertos; setFilterTelefonos();">
      <mat-icon>filter_alt</mat-icon>
      Filtrar
    </button>
    <button style="width: 100%; margin-top: 10px;" mat-stroked-button (click)="clearFilterTelefonos();">
      <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
      Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
