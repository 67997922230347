import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { RouterModule, Routes  } from '@angular/router';
//import { NgxFilesizeModule } from 'ngx-filesize';
import { CoreModule } from './core/core.module';
//import { ApplicationModule } from './application/application.module';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgChartsModule } from 'ng2-charts';
import { CubejsClientModule } from '@cubejs-client/ngx';
import cubejsOptions from './cubejsOptions';

/**MATERIAL */
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatListModule  } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatGridListResponsiveComponent } from './shared/components/material-responsive/mat-grid-list-responsive.component';
import { MatSelectSearch } from './shared/components/material-responsive/mat-select-search.component';
import { CustomSnackbarComponent} from './shared/components/snackbar/snackbar.component';
import { MatDatePickerFormater } from './shared/components/material-responsive/mat-date-picker-formater.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatExpansionModule } from '@angular/material/expansion';
import {MatRadioModule} from '@angular/material/radio';
import { MatChipsModule } from '@angular/material/chips';
import { MatTreeModule } from '@angular/material/tree';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { DateAdapter, MAT_DATE_LOCALE } from '@angular/material/core';

/**MATERIAL */

/** DIRECTIVAS **/
import { SanitizerDirective } from './directives/sanitizer.directive';
import { DecimalValidatorDirective } from './directives/decimal.directive';
import { MatSelectRemoveAriaOwnsDirective } from './directives/matselect.directive';
import { ValidateNumericInputDirective } from './directives/numeric-input.directive';

/**SERVICIOS */
import { NotificationService } from './dashboard-main/notification.service';
import { AuthService } from './authentication/auth.service';
import { HttpErrorService } from "./shared/http-error/http-error.service";
import { LoaderService } from "./shared/loader/loader.service";
import { MessageService } from "./shared/message/message.service";
import { ApplicationService } from './application/shared/application.service';
//import { ClientPickerService } from './client-picker/client-picker.service';
import { ApiKobraService } from './core/http/api-kobra/api-kobra.service';
import { MobileKobraService } from './core/http/mobile-kobra/mobile-kobra.service';
import { ServicesKobraService } from './core/http/services-kobra/services-kobra.service';
import { ServiciosKobraService } from './core/http/servicios-kobra/servicios-kobra.service';
import { ClientService } from './core/http/client/client.service';
import { ServiciosSaldosKobraService } from './core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { StorageKobraService } from './core/http/storage-kobra/storage-kobra.service';
import { ServiciosBiService } from './core/http/servicios-bi/servicios-bi.service';
import { TooltipOverlayService } from './shared/components/tooltip/tooltip-overlay.service';

/**OPCIONES */
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { LenderListComponent } from './lender/list/lender-list.component';
import { AuthComponent } from './authentication/auth.component';
import { LayoutComponent } from "./layout/layout.component";
import { ProductosNvoListComponent } from './catalogos/productos/lista/productos-nvo-list.component';
import { ProductosNvoEditarComponent } from './catalogos/productos/editar/productos-editar.component';
import { ConceptosProductosComponent } from './catalogos/conceptos-productos/lista/conceptos-productos-list.component';
import { ConceptosProductosNvoComponent } from './catalogos/conceptos-productos/nuevo/conceptos-productos.component';
import { SucursalesComponent } from './catalogos/sucursales/list/sucursales-list.component';
import { SucursalesModificarComponent } from './catalogos/sucursales/modificar/sucursales-modificar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashboardUserComponent } from './dashboard-user/dashboard-user.component';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { CalificadasVisitasComponent } from './calificadas-visitas/calificadas-visitas.component';
import { DescargasComponent } from './descargas/descargas.component';
import { ChecklistsComponent } from './catalogos/checklists/lista/checklists.component';
import { ChecklistsEditarComponent } from './catalogos/checklists/edicion/checklists-editar.component';
import { ConfigCuestionarioComponent } from './catalogos/checklists/modales/configuracion/config-cuestionario.component';
import { ChecklistsFormatoComponent } from './catalogos/checklists/formato/checklists-formato.component';
import { ListadoBloquesComponent } from './catalogos/checklists/formato/bloques/lista/listado-bloques.component';
import { FormatoCuentaComponent } from './catalogos/checklists/formato-cuenta/formato-cuenta.component';
import { ProductComponent as ImportProductComponent } from './import/product/product.component';
import { HomeComponent as ImportHomeComponent } from './import/home/home.component';
import { WizardComponent as ImportWizardComponent } from './import/wizard/wizard.component';
import { TypeComponent as ImportTypeComponent } from './import/type/type.component';
import { UploadComponent as ImportUploadComponent } from './import/upload/upload.component';
import { PreviewComponent as ImportPreviewComponent } from './import/preview/preview.component';
import { FinishComponent as ImportFinishComponent } from './import/finish/finish.component';
import { UsuariosComponent } from './catalogos/usuarios/usuarios.component';
import { TarifaProductosComponent } from './catalogos/tarifas-agentes/editar-tarifa/editar-tarifa.component';
import { UsuariosFormComponent } from './catalogos/usuarios/usuarios-form/usuarios-form.component';
import { AccountListComponent } from './account/account-list.component';
import { AccountDetailComponent } from './account/account-detail.component';
import { CatalogoEtiquetasVisitasComponent } from './catalogos/etiquetas-visitas/lista/etiquetas-visitas.component';
import { EtiquetasVisitasFormComponent } from './catalogos/etiquetas-visitas/etiquetas-visitas-form/etiquetas-visitas-form.component';
import { ClientesMovimientosComponent } from './saldos/clientes-movimientos/clientes-movimientos.component';
import { ClientesMovimientosFormComponent } from './saldos/clientes-movimientos/clientes-movimientos-form/clientes-movimientos-form.component';
import {ProductosComponent} from './catalogos/permisos-producto/lista/productos-list.component';
import {ProductosModificarComponent} from './catalogos/permisos-producto/editar/productos-modificar.component';
import { PaquetesSaldosComponent } from './catalogos/paquetes-saldos/paquetes-saldos.component';
import { PaquetesSaldosConceptosComponent } from './catalogos/paquetes-saldos-conceptos/paquetes-saldos-conceptos.component';
import { ConceptosSaldosComponent } from './saldos/conceptos-saldos/conceptos-saldos.component';
import { ConceptosSaldosFormComponent } from './saldos/conceptos-saldos/conceptos-saldos-form/conceptos-saldos-form.component';
import { CobradoresFacturacionesPeriodosComponent } from './saldos/cobradores-facturaciones-periodos/cobradores-facturaciones-periodos.component';
import { CobradoresFacturacionesPeriodosFormComponent } from './saldos/cobradores-facturaciones-periodos/cobradores-facturaciones-periodos-form/cobradores-facturaciones-periodos-form.component';
import { PaquetesFinancieraComponent } from './saldos/paquetes-financiera/paquetes-financiera.component';
import { LenderDetailComponent } from './lender/detail/detail.component';
import { PaquetesFinancieraDetalleComponent } from './saldos/paquetes-financiera/paquetes-financiera-detalle/paquetes-financiera-detalle.component';
import { PaqueteFinancieraFormComponent } from './paquete-financiera/form/form.component';
import { VisitListComponent } from "./visit/visit-list.component";
import { VisitDetailComponent } from "./visit/visit-detail.component";
import { VisitaMasivaDetailComponent } from "./visit/visita-masiva-detail.component";
import { ClientesEtiquetasVisitasComponent } from "./catalogos/clientes-etiquetas-visitas/lista/clientes-etiquetas-visitas.component";
import { ClientesEtiquetasVisitasFormComponent } from "./catalogos/clientes-etiquetas-visitas/form/clientes-etiquetas-visitas-form.component";
import { AgentListComponent } from './agent/agent-list/agent-list.component';
import { AgentDetailComponent } from './agent/agent-detail/agent-detail.component';
import { IndicatorsComponent } from './indicators/indicators.component';
import { CobradoresMovimientosComponent } from './saldos/cobradores-movimientos/cobradores-movimientos.component';
import { CobradoresMovimientosFormComponent } from './saldos/cobradores-movimientos/cobradores-movimientos-form/cobradores-movimientos-form.component';
import { GeoCercaCrearComponent } from './geo-cercas/geo-cerca-create/geo-cerca-create.component';
import { ConfigurationComponent } from 'src/app/lender/configuration/configuration.component';
import { LayoutFieldsComponent } from 'src/app/lender/layout-fields/layout-fields.component';
import { LayoutFieldNuevo } from 'src/app/lender/layout-fields/nuevo/layout-field-nuevo.component';
import { LenderDetailDialogComponent } from 'src/app/lender/detail/detail-dialog.component';
import { LenderNewComponent } from 'src/app/lender/new/new.component';
import { CalificarObligatoriosComponent } from './calificar-obligatorios/calificar-obligatorios.component';
import { AgenteNuevoComponent } from './agent/agente-nuevo/agente-nuevo.component';
import { VisitCreateComponent } from './visit-create/visit-create.component';
import { AgenteRecorridoComponent } from './agent/agente-recorridos/agente-recorrido.component';
import { PromesasPagoComponent } from './promesas-pago/promesas-pago.component';
import { RequestListComponent } from './credit-request/request-list.component';
import { CreditRequestComponent } from './credit-request/credit-request.component';
import { CobradoresFacturacionesComponent } from './saldos/cobradores-facturaciones/cobradores-facturaciones.component';
import { CuentasAgrupadasListComponent } from './cuentas/cuentas-agrupadas/cuentas-agrupadas-list.component';
import { ListaVisibilidadComponent } from './agent/visibilidad-cuentas/lista/lista-visibilidad.component';
import { EncargadosFinancieraComponent } from './lender/encargados-financiera/encargados-financiera.component';
import { AdmistradorSucursalesModificarComponent } from './catalogos/usuarios/sucursales/sucursales-modificar.component';
import { AdmistradorProductosModificarComponent } from './catalogos/usuarios/productos/productos-modificar.component';
import { BloqueNuevoComponent } from './catalogos/checklists/formato/bloques/nuevo/bloque-nuevo.component';

/**COMPONENTES */
import { UserEditComponent } from './user/edit/user-edit.component';
import {GestoresNoVinculadosDialog} from "./agent/visibilidad-cuentas/modales/gestores-no-vinculados/gestores-no-vinculados-dialog.component";
import { ModalVisitaDetalleMasivaComponent } from './visit/detalle-masiva-dialog/detalle-masiva-dialog.component';
import { TimelineCuentaComponent } from './account/timeline/timeline-cuenta.component';
import { TablaExpandibleComponent } from './shared/components/table/tabla-expandible/tabla-expandible.component';
import { TableComponent } from './shared/components/table/table.component';
import { TablaComponent } from './shared/components/table/tabla.component';
import { ConfirmacionDialog } from "./modales-genericos/confirmacion/confirmacion-dialog.component";
import { LoaderComponent } from './shared/components/loader/loader.component';
import { LoadingComponent } from './shared/components/loading/loading.component';
import { LoadingBarComponent } from './shared/components/loading-bar/loading-bar.component';
import { ModalCargarArchivoWordComponent } from './catalogos/checklists/modales/cargar-archivo-word/cargar-archivo-word.component';
import {VariablesDialog} from "./catalogos/checklists/modales/catalogo-variables/variables-dialog.component";
import { FileUploadComponent } from './shared/components/file-upload/file-upload.component';
import { ConfirmComponent } from './shared/components/confirm/confirm.component';
import { AnswerComponent as LenderProductChecklistAnswerComponent } from './lender/product-checklist/answer/answer.component';
import { QuestionComponent as LenderProductChecklistQuestionComponent } from './lender/product-checklist/question/question.component';
import { ModalComponent } from './shared/components/modal/modal.component';
import {ModalImportarComponent} from './saldos/modales/importarxsl/importar.component';
import {ModalClienteImportarComponent} from './saldos/modales/cliente-importar/cliente-importar.component';
import {ModalAsignarPeriodoComponent} from './saldos/modales/asignar-periodo/asignar-periodo.component';
import { FieldsetComponent } from './shared/components/fieldset/fieldset.component';
import { FieldComponent } from './shared/components/form/field/field.component';
import { FilterModalComponent } from './import/type/filter-modal/filter-modal.component';
import { FilesizePipe } from './import/upload/filesize.pipe';
import { AccountsComponent } from './import/accounts/accounts.component';
import { ModalEstatusCuentaComponent } from './account/modales/estatus/estatus-cuenta.component';
import { ChartComponent } from './reportes/business-intelligence/chart/chart/chart.component';
import { ModalGeocalizacionCuentasComponent } from './import/modales/geocalizacion-cuentas.component';
import { ListaTarifaComponent } from './catalogos/tarifas-agentes/lista-tarifa/lista-tarifa.component';
//import { IndicatorsComponent } from './indicators/indicators.component';
import { MapboxCuentasComponent } from './mapbox/cuentas/mapbox-cuentas.component';
import { ModalAsignarCuentaComponent } from './account/modales/asignar/asignar-cuenta.component';
import { ModalContratosComponent } from './account/modales/contratos/contratos.component';
import { ModalEtiquetaCuentaComponent } from './account/modales/etiqueta/etiqueta-cuenta.component';
import { ModalEtiquetaOperacionesComponent } from './account/modales/etiqueta-operaciones/etiqueta-operaciones.component';
import { ModalListadoContratosComponent } from './account/modales/listado-contratos/listado-contratos.component';
import { ModalDomicilioComponent } from './account/modales/modificar-domicilio/form-adress.component';
import { CargarArchivoComponent } from './account/cargar-archivo-pdf/cargar-archivo-pdf.component';
import {ClientFormComponent} from './account/forms/detail/form-detail.component';
import { FormAccountDetailComponent } from './account/forms/account-detail/form-account-detail.component';
import { MapboxComponent } from "./mapbox/mapbox.component";
import { ContainerComponent } from './shared/components/container/container.component';
import { GridComponent } from './shared/components/grid/grid.component';
import { MapboxVisitasComponent } from './mapbox/visitas/mapbox-visitas.component';
import { VisitClarificationComponent } from './visit-clarification/visit-clarification.component';
import { CalificacionVisitaEstrellasComponent } from './calificaciones-visita/calificacion-visita-estrellas/calificacion-visita-estrellas.component';
import { CalificacionVisitasEstrellasComponent } from './calificaciones-visita/calificacion-visitas-estrellas/calificacion-visitas-estrellas.component';
import { ModalDescargaFormatoComponent } from './visit/modales/descarga-formato/descarga-formato.component';
import { ModalEtiquetaComponent } from './visit/modales/etiqueta/etiqueta.component';
import { FotoComponent } from './componentes/fotos/foto.component';
import { EmptyComponent } from './shared/components/empty/empty.component'
import { MapaDetalleComponent } from './visit/mapa/mapa-detalle.component';
import { ModalEstatusAgenteComponent } from './agent/modales/estatus/estatus-agente.component';
import { ModalVinculacionProductosComponent } from './agent/modales/vinculacion/vinculacion-productos.component';
import { ModalDesvinculacionComponent } from './agent/modales/desvinculacion/desvinculacion.component';
import { ByteFormatPipe } from './shared/components/upload-file-v2/byte-format.pipe';
import { UploadFileComponent } from './shared/components/upload-file-v2/upload-file.component';
import { ModalVisitaDetalleComponent } from './visit/detalle-dialog/visit-detail-dialog.component';
import { FotoModalComponent } from './componentes/fotos/modal/foto-modal.component';
import { AgentStateComponent } from '@agent/agent-state/agent-state.component';
import { CuentasDesasignadasDialog } from '@agent/modales/cuentas-desasignadas/cuentas-desasignadas-dialog.component';
import { AgentFormComponent } from './agent/forms/agent-form/agent-form.component';
import { AgentAddressFormComponent } from './agent/forms/agent-address-form/agent-address-form.component';
import { WorkExperienceEditableListComponent } from './agent/agent-detail/work-experience/work-experience-editable-list/work-experience-editable-list.component';
import { WorkExperienceModalFormComponent } from './agent/agent-detail/work-experience/work-experience-modal-form/work-experience-modal-form.component';
import { WorkReferenceEditableListComponent } from './agent/agent-detail/work-reference/work-reference-editable-list/work-reference-editable-list.component';
import { WorkReferenceModalFormComponent } from './agent/agent-detail/work-reference/work-reference-modal-form/work-reference-modal-form.component';
import { PersonalReferenceEditableListComponent } from './agent/agent-detail/personal-reference/personal-reference-editable-list/personal-reference-editable-list.component';
import { PersonalReferenceModalFormComponent } from './agent/agent-detail/personal-reference/personal-reference-modal-form/personal-reference-modal-form.component';
import { OnboardingDistribuidorEditableComponent } from './agent/agent-detail/onboarding-distribuidor/onboarding-distribuidor-editable/onboarding-distribuidor-editable.component';
import { AgentBankFormComponent } from './agent/forms/agent-bank/agent-bank-form.component';
import { ChecklistDynamicQuestionComponent } from './visit-create/checklist-dynamic-question/checklist-dynamic-question.component';
import {AcreditadoDialog} from "./visit/acreditado-dialog/acreditado-dialog.component";
import {ModalEstadoCuentaComponent} from "./lender/modales/estado-cuenta/estado-cuenta.component";
import { InformacionRecopiladaComponent } from "./visit/informacion-recopilada/informacion-recopilada.component";
import { RevisitListComponent } from "./visit/revisita/revisita-lista.component";
import { ModalLimiteCuentasComponent } from './agent/modales/limite-cuentas/limite-cuentas.component';
import {PosicionPreguntaDialog} from './catalogos/checklists/modales/posicion-pregunta/posicion-pregunta-dialog.component';
import {AlertaInformativaComponent} from './shared/components/alerta-informativa/alerta-informativa.component'
//import { TimelineAgenteComponent } from './agent/agent-detail/timeline/timeline-agente.component'
import { HistorialAltaBajaComponent } from './agent/agent-detail/historial-alta-baja/historial-alta-baja.component'
import { HistorialNoDisponibleComponent } from './agent/agent-detail/historial-no-disponible/historial-no-disponible.component'
import { VisualizarCuentasDialog } from './account/visualizar-cuentas/visualizar-cuentas-dialog.component'
import { ModalDescargaFormatoCuentaComponent } from './account/modales/descarga-formato/descarga-formato-cuenta.component';
import { ModalSeleccionDomicilioComponent } from './account/modales/seleccionar-domicilio/domicilio.component';
import { ChecklistRespuestaTablaComponent } from './visit-create/checklist-respuestas-tablas/checklist-respuestas-tablas.component';
import { TableDialogComponent } from './shared/components/table/dialog/table-dialog.component';
import { TextAreaDialogComponent } from './shared/components/dialog/text-area-dialog/text-area-dialog.component';
import { HtmlTooltipComponent } from './shared/components/tooltip/html-tooltip.component';
import { KobraTooltipComponent } from './shared/components/tooltip/kobra-tooltip.component';
import { ConceptosEtiquetasVisitasComponent } from './saldos/conceptos-etiquetas-visitas/conceptos-etiquetas-visitas.component';
import { ModalAsignarComponent } from './catalogos/etiquetas-visitas/modales/modal-asignar/modal-asignar.component';
import { ModalEstatusComponent } from './catalogos/etiquetas-visitas/modales/modal-estatus/modal-estatus.component';
import { DynamicInputComponent } from './componentes/campos-dinamicos/dynamic-input.component'
import { TipoCondicionalDialog } from './catalogos/checklists/modales/tipo-condicional/tipo-condicional.component'
import {ModalFormatosAfectadosComponent} from './catalogos/checklists/modales/formatos-afectados/formatos-afectados.component';
import { VariablesComponent } from './catalogos/checklists/variables/variables.component';
import { CamposExtrasAgentesFormComponent } from '@agent/forms/campos-extras-agentes/campos-extras-agentes-form.component';

/**MODULOS EXTERNOS */
import { ClipboardModule } from 'ngx-clipboard';
import { MapboxUsuariosComponent } from './mapbox-dashboard/mapbox-dashboard.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatNativeDateModule } from '@angular-material-components/datetime-picker';
import { NgxStarsModule } from 'ngx-stars';
import { ConceptosEtiquetasVisitasFormComponent } from './saldos/conceptos-etiquetas-visitas/conceptos-etiquetas-visitas-form/conceptos-etiquetas-visitas-form.component';
import { TokenExpirationModalComponent } from './authentication/token-expiration-modal/token-expiration-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    LayoutComponent,
    ProductosNvoListComponent,
    ProductosNvoEditarComponent,
    ConceptosProductosComponent,
    ConceptosProductosNvoComponent,
    SucursalesComponent,
    SucursalesModificarComponent,
    MatGridListResponsiveComponent,
    MatSelectSearch,
    CustomSnackbarComponent,
    MatDatePickerFormater,
    TableComponent,
    TablaExpandibleComponent,
    TimelineCuentaComponent,
    TablaComponent,
    ConfirmacionDialog,
    BreadcrumbComponent,
    UserEditComponent,
    GestoresNoVinculadosDialog,
    LenderListComponent,
    LoaderComponent,
    LoadingComponent,
    LoadingBarComponent,
    DashboardMainComponent,
    DashboardComponent,
    DashboardUserComponent,
    MatGridListResponsiveComponent,
    MapboxUsuariosComponent,
    ChecklistsComponent,
    DescargasComponent,
    ChecklistsEditarComponent,
    ConfigCuestionarioComponent,
    ChecklistsFormatoComponent,
    FormatoCuentaComponent,
    UsuariosComponent,
    UsuariosFormComponent,
    VariablesDialog,
    FileUploadComponent,
    ConfirmComponent,
    LenderProductChecklistAnswerComponent,
    LenderProductChecklistQuestionComponent,
    ModalComponent,
    ModalImportarComponent,
    ModalClienteImportarComponent,
    ModalAsignarPeriodoComponent,
    FieldsetComponent,
    FieldComponent,
    ImportHomeComponent,
    ImportProductComponent,
    ImportWizardComponent,
    ImportTypeComponent,
    FilterModalComponent,
    ImportUploadComponent,
    FilesizePipe,
    AccountsComponent,
    ModalEstatusCuentaComponent,
    ImportPreviewComponent,
    ImportFinishComponent,
    ChartComponent,
    ModalGeocalizacionCuentasComponent,
    //IndicatorsComponent,
    GeoCercaCrearComponent,
    MapboxCuentasComponent,
    ModalAsignarCuentaComponent,
    AccountListComponent,
    AccountDetailComponent,
    CatalogoEtiquetasVisitasComponent,
    EtiquetasVisitasFormComponent,
    ModalContratosComponent,
    ModalEtiquetaCuentaComponent,
    ModalListadoContratosComponent,
    ModalDomicilioComponent,
    CargarArchivoComponent,
    ClientFormComponent,
    FormAccountDetailComponent,
    MapboxComponent,
    ContainerComponent,
    GridComponent,
    ModalEtiquetaOperacionesComponent,
    ClientesMovimientosComponent,
    ClientesMovimientosFormComponent,
    ProductosComponent,
    ProductosModificarComponent,
    PaquetesSaldosComponent,
    PaquetesSaldosConceptosComponent,
    ConceptosSaldosComponent,
    ConceptosSaldosFormComponent,
    CobradoresFacturacionesPeriodosComponent,
    CobradoresFacturacionesPeriodosFormComponent,
    PaquetesFinancieraComponent,
    LenderDetailComponent,
    PaquetesFinancieraDetalleComponent,
    PaqueteFinancieraFormComponent,
    VisitaMasivaDetailComponent,
    VisitListComponent,
    MapboxVisitasComponent,
    VisitDetailComponent,
    ClientesEtiquetasVisitasComponent,
    ClientesEtiquetasVisitasFormComponent,
    CalificadasVisitasComponent,
    VisitClarificationComponent,
    CalificacionVisitaEstrellasComponent,
    CalificacionVisitasEstrellasComponent,
    ModalDescargaFormatoComponent,
    ModalEtiquetaComponent,
    FotoComponent,
    EmptyComponent,
    MapaDetalleComponent,
    AgentListComponent,
    AgentDetailComponent,
    ModalEstatusAgenteComponent,
    ModalVinculacionProductosComponent,
    ModalDesvinculacionComponent,
    IndicatorsComponent,
    ByteFormatPipe,
    UploadFileComponent,
    ConfigurationComponent,
    LayoutFieldsComponent,
    LayoutFieldNuevo,
    LenderDetailDialogComponent,
    LenderNewComponent,
    ListaTarifaComponent,
    TarifaProductosComponent,
    ModalCargarArchivoWordComponent,
    CobradoresMovimientosComponent,
    CobradoresMovimientosFormComponent,
    CalificarObligatoriosComponent,
    AgenteNuevoComponent,
    ModalVisitaDetalleComponent,
    FotoModalComponent,
    AgentStateComponent,
    CuentasDesasignadasDialog,
    AgentFormComponent,
    AgentAddressFormComponent,
    WorkExperienceEditableListComponent,
    WorkExperienceModalFormComponent,
    WorkReferenceEditableListComponent,
    WorkReferenceModalFormComponent,
    PersonalReferenceEditableListComponent,
    PersonalReferenceModalFormComponent,
    OnboardingDistribuidorEditableComponent,
    AgentBankFormComponent,
    ModalVisitaDetalleComponent,
    ModalVisitaDetalleMasivaComponent,
    VisitCreateComponent,
    ChecklistDynamicQuestionComponent,
    AcreditadoDialog,
    ModalEstadoCuentaComponent,
    AgenteRecorridoComponent,
    PromesasPagoComponent,
    InformacionRecopiladaComponent,
    RevisitListComponent,
    RequestListComponent,
    CreditRequestComponent,
    CobradoresFacturacionesComponent,
    CuentasAgrupadasListComponent,
    ListaVisibilidadComponent,
    EncargadosFinancieraComponent,
    ModalLimiteCuentasComponent,
    ModalLimiteCuentasComponent,
    AlertaInformativaComponent,
    PosicionPreguntaDialog,
    //TimelineAgenteComponent,
    HistorialAltaBajaComponent,
    HistorialNoDisponibleComponent,
    VisualizarCuentasDialog,
    SanitizerDirective,
    ValidateNumericInputDirective,
    DecimalValidatorDirective,
    MatSelectRemoveAriaOwnsDirective,
    ModalDescargaFormatoCuentaComponent,
    ModalSeleccionDomicilioComponent,
    ChecklistRespuestaTablaComponent,
    TableDialogComponent,
    TextAreaDialogComponent,
    HtmlTooltipComponent,
    KobraTooltipComponent,
    AdmistradorSucursalesModificarComponent,
    AdmistradorProductosModificarComponent,
    ConceptosEtiquetasVisitasComponent,
    ModalAsignarComponent,
    ModalEstatusComponent,
    ConceptosEtiquetasVisitasFormComponent,
    ListadoBloquesComponent,
    BloqueNuevoComponent,
    DynamicInputComponent,
    TipoCondicionalDialog,
    ModalFormatosAfectadosComponent,
    VariablesComponent,
    CamposExtrasAgentesFormComponent,
    TokenExpirationModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    /*NgxFilesizeModule,*/
    CoreModule,
    //ApplicationModule,
    MatCardModule,
    MatTabsModule,
    MatIconModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatListModule,
    MatGridListModule,
    MatAutocompleteModule,
    DragDropModule,
    RouterModule,
    MatSidenavModule,
    MatTooltipModule,
    MatBadgeModule,
    MatDialogModule,
    MatStepperModule,
    MatSortModule,
    MatTableModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatToolbarModule,
    MatSelectModule,
    MatMenuModule,
    MatPaginatorModule,
    MatCheckboxModule,
    ClipboardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatExpansionModule,
    MatRadioModule,
    MatChipsModule,
    MatTreeModule,
    MatSlideToggleModule,
    NgxChartsModule,
    NgChartsModule,
    CubejsClientModule.forRoot(cubejsOptions),
    MatButtonToggleModule,
    NgxMaterialTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    NgxStarsModule,

  ],
  exports: [
    SanitizerDirective,
    ValidateNumericInputDirective,
    DecimalValidatorDirective,
    MatSelectRemoveAriaOwnsDirective
  ],
  providers: [
    NotificationService,
    AuthService,
    HttpErrorService,
    // HttpErrorServiceKobra,
    LoaderService,
    MessageService,
    ApplicationService,
    //ClientPickerService,

    // Servicios Kobra
    ApiKobraService,
    MobileKobraService,
    ServicesKobraService,
    ServiciosKobraService,
    ClientService,
    ServiciosSaldosKobraService,
    StorageKobraService,
    ServiciosBiService,
    TooltipOverlayService,
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
