import {Component} from '@angular/core';
import { MatDialogRef} from '@angular/material/dialog';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Inject } from '@angular/core';



@Component({
    selector: 'variables-dialog',
    templateUrl: './variables-dialog.component.html',
    styleUrls: ['./variables-dialog.component.css']
  })
  export class VariablesDialog {
    public cuenta: any[] = [];
    public cuentaDetalle: any[] = [];
    public visita: any[] = [];
    public agente: any[] = [];
    public checkLists: any[] = [];
    public generales: any[] = [];
    public condicionales: any[] = [];
    public loadVariables = false;
    sinCategoria: any[] = [];

    public tablaCuenta: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    } 

    public tablaCuentaDetalle: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    }
  
    public tablaVisita: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    }

    public tablaCheckList: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    }

    public tablaGenerales: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    }

    public tablaCondicionales: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    }

    public tablaSinCategoria: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    };

    public tablaAgente: any = {
      headers: false,
      tableHeaders: false,
      multiSelect: false,
      columnas: this.getVariableColumns()
    };

    constructor(
      public dialogRef: MatDialogRef<VariablesDialog>,
      @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        //this.agentes = data;
    }

    ngOnInit() {

      this.obtienerVariable();
    }

    getVariableColumns() {
      return [
        {
          name: 'ID Variable',
          key: 'claveInterna',
          hide: true
        },
        {
          name: ' ',
          key: 'claveUsuario',
          type: 'copy',
          hide: false
        },
        {
          name: 'Variable',
          key: 'claveUsuario',
          hide: false
        },
        {
          name: 'Descripción',
          key: 'descripcion',
          hide: false
        }
      ];
    }

    onNoClick(): void {
      this.dialogRef.close(false);
    }

    salir(){
      this.dialogRef.close();
    }

    processVarsAfectadas() {
      // Verificamos que exista el objeto varsAfectadas y el tipoFormato sea igual a 1
      if (this.data.variablesAfectadas) {

        if (this.data.variablesAfectadas.cuenta && Array.isArray(this.data.variablesAfectadas.cuenta)) {
          this.cuenta = this.data.variablesAfectadas.cuenta;
        }
  
        if (this.data.variablesAfectadas.cuentaDetalle && Array.isArray(this.data.variablesAfectadas.cuentaDetalle)) {
          this.cuentaDetalle = this.data.variablesAfectadas.cuentaDetalle;
        }

        if (this.data.variablesAfectadas.generales && Array.isArray(this.data.variablesAfectadas.generales)) {
          this.generales = this.data.variablesAfectadas.generales;
        }

        if (this.data.variablesAfectadas.sinCategoria && Array.isArray(this.data.variablesAfectadas.sinCategoria)) {
          this.sinCategoria = this.data.variablesAfectadas.sinCategoria;
        }

        if (this.data.variablesAfectadas.block && Array.isArray(this.data.variablesAfectadas.block)) {
          this.condicionales = this.data.variablesAfectadas.block;
        }
  
        // Procesamos el checklist solo si existe
        if (this.data.variablesAfectadas.checklist && Array.isArray(this.data.variablesAfectadas.checklist)) {
          this.checkLists = this.data.variablesAfectadas.checklist;
        }

        if (this.data.variablesAfectadas.visita && Array.isArray(this.data.variablesAfectadas.visita)) {
          this.visita = this.data.variablesAfectadas.visita;
        }

        if (this.data.variablesAfectadas.agente && Array.isArray(this.data.variablesAfectadas.agente)) {
          this.agente = this.data.variablesAfectadas.agente;
        }
        
      }
      
    }

    private obtienerVariable(): void {
      this.loadVariables = true;
      if(this.data.isAfectado) {
        this.processVarsAfectadas(); 
      } else {
        this.data.variables.forEach(subArray => {
                    
          if(subArray.nombre === 'cuenta'){
            this.cuenta = subArray.vars;
          }
  
          if(subArray.nombre === 'cuentaDetalle'){
            this.cuentaDetalle = subArray.vars;
          }
          //console.log(this.data.tipoFormato);
          if(this.data.tipoFormato == 1){

            if(subArray.nombre === 'agente'){
              this.agente = subArray.vars;
            }
  
            if(subArray.nombre === 'visita'){
              this.visita = subArray.vars;
            }
    
            if(subArray.nombre === 'checkLists'){
              this.checkLists = subArray.vars;
            }
    
            if(subArray.nombre === 'condicionales'){
              this.condicionales = subArray.vars;
            }
            
          }
  
          if(subArray.nombre === 'generales'){
            this.generales = subArray.vars;
          }
          
        });
      }
      
      this.loadVariables = false;
    }

    onClick({ row, columnPressed }) {
     // console.log(row);
    }

  }
