import { Component } from '@angular/core';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { ConceptosEtiquetasVisitasService } from '../conceptos-etiquetas-visitas.service';
import { map, switchMap, take } from 'rxjs';
import { Router } from '@angular/router';
import { ConceptosEtiquetasVisitas } from '../conceptos-etiquetas-visitas.model';


@Component({
  selector: 'app-conceptos-etiquetas-visitas-form',
  templateUrl: './conceptos-etiquetas-visitas-form.component.html',
  styleUrls: ['./conceptos-etiquetas-visitas-form.component.css']
})
export class ConceptosEtiquetasVisitasFormComponent {
  
  conceptos: any;
  sessionData: SessionData;
  etiquetas: any[] = [];
  etiquetasFiltradas: any[] = []; // Etiquetas filtradas para no mostrar las seleccionadas
  formConceptos: any[] = [{ idEtiquetaVisita: 0, pagoAlGestor: null }];  // Array inicializado con un concepto vacío

  loadings: any = {
    etiquetas: true,
    guardando: false
  };

  constructor(
    private app: ApplicationService,
    public conceptosEtiquetasVisitas: ConceptosEtiquetasVisitasService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loadEtiquetas();
  }

  // Cargar etiquetas desde el servicio y restar los resultados de obtenerConceptosEtiquetasSaldos
  public loadEtiquetas() {
    this.loadings.etiquetas = true;

    // Obtener conceptos etiquetas (saldos)
    this.conceptosEtiquetasVisitas.obtenerConceptosEtiquetas()
      .pipe(
        take(1),
        map((res: any) => {
          // Guardamos los conceptos obtenidos
          this.conceptos = ConceptosEtiquetasVisitas.mapArray(res.data);
          return this.conceptos;
        }),
        switchMap((conceptos: any[]) => {
          // Después de obtener los conceptos, cargamos las etiquetas
          return this.conceptosEtiquetasVisitas.obtenerEtiquetas().pipe(
            take(1),
            map((res: any) => {
              // Modificar etiquetas para incluir nombre completo
              const etiquetasModificadas = res.data.map((etiqueta: any) => ({
                ...etiqueta,
                nombreEtiqueta: `${etiqueta.idEtiquetaVisita} - ${etiqueta.etiquetaVisita}`
              }));

              // Ordenar etiquetas numéricamente por idEtiquetaVisita
              const etiquetasOrdenadas = etiquetasModificadas.sort((a, b) => a.idEtiquetaVisita - b.idEtiquetaVisita);

              // Realizar la resta: quedarnos con las etiquetas que no están en los conceptos
              const etiquetasRestantes = etiquetasOrdenadas.filter(etiqueta =>
                !conceptos.some(concepto => concepto.idEtiquetaVisita === etiqueta.idEtiquetaVisita)
              );

              return etiquetasRestantes;
            })
          );
        })
      )
      .subscribe((etiquetasRestantes: any) => {
        this.etiquetas = etiquetasRestantes;
        this.etiquetasFiltradas = [...this.etiquetas]; // Copia de las etiquetas restantes
        this.loadings.etiquetas = false;
      }, (err: any) => {
        this.loadings.etiquetas = false;
        this.app.showError(err);
      });
  }

  onSelectEtiqueta(index: number) {
    const concepto = this.formConceptos[index];

    if (concepto.idEtiquetaVisita !== 0) {
      // Buscar si ya hay un concepto vacío existente
      const conceptoVacio = this.formConceptos.find(c => c.idEtiquetaVisita === 0);

      if (!conceptoVacio) {
        // Si no hay un concepto vacío, agregamos uno nuevo AL FINAL usando push()
        this.formConceptos.push({ idEtiquetaVisita: 0, pagoAlGestor: null });
      }

      // Remover la etiqueta seleccionada de la lista filtrada
      this.etiquetasFiltradas = this.etiquetasFiltradas.filter(etiqueta => etiqueta.idEtiquetaVisita !== concepto.idEtiquetaVisita);
    }

    // NO reordenar los conceptos ya existentes.
    // El concepto vacío debe seguir al final, pero no cambiar el orden de los otros conceptos.
  }

  removeConcepto(index: number) {
    const concepto = this.formConceptos[index];

    if (this.formConceptos.length > 1) {
      // Restaurar la etiqueta eliminada al listado de etiquetas disponibles
      const etiquetaRestaurada = this.etiquetas.find(etiqueta => etiqueta.idEtiquetaVisita === concepto.idEtiquetaVisita);

      if (etiquetaRestaurada) {
        // Verificar si la etiqueta ya está en la lista filtrada antes de agregarla
        const existeEnFiltradas = this.etiquetasFiltradas.some(etiqueta => etiqueta.idEtiquetaVisita === etiquetaRestaurada.idEtiquetaVisita);

        if (!existeEnFiltradas) {
          this.etiquetasFiltradas.push(etiquetaRestaurada); // Restaurar la etiqueta al listado disponible

          // Ordenar las etiquetas filtradas por idEtiquetaVisita (numéricamente)
          this.etiquetasFiltradas.sort((a, b) => a.idEtiquetaVisita - b.idEtiquetaVisita);
        }
      }

      // Eliminar el concepto
      this.formConceptos.splice(index, 1);

      // Eliminar el concepto vacío si existe
      const conceptoVacioIndex = this.formConceptos.findIndex(c => c.idEtiquetaVisita === 0);
      if (conceptoVacioIndex > -1) {
        this.formConceptos.splice(conceptoVacioIndex, 1); // Eliminar concepto vacío
      }

      // Actualizar la lista de conceptos manteniendo los existentes y agregando el nuevo concepto vacío al final
      const conceptosExistentes = this.formConceptos.filter(c => c.idEtiquetaVisita > 0);

      // Agregar un nuevo concepto vacío al final
      this.formConceptos = [...conceptosExistentes, { idEtiquetaVisita: 0, pagoAlGestor: null }];
    } else {
      this.app.showSnackbar('¡Aviso!', 'Debe haber al menos un concepto.', 3000, 'warning');
    }
  }




  // Guardar los conceptos seleccionados
  save() {
    // Eliminar el concepto vacío si existe
    const conceptoVacioIndex = this.formConceptos.findIndex(c => c.idEtiquetaVisita === 0);
    if (conceptoVacioIndex > -1) {
      this.formConceptos.splice(conceptoVacioIndex, 1); // Eliminar concepto vacío
    }

    // Eliminar conceptos en blanco (donde no se haya seleccionado una etiqueta)
    this.formConceptos = this.formConceptos.filter(concepto => concepto.idEtiquetaVisita !== 0 || concepto.pagoAlGestor !== null);

    // Filtrar los conceptos que tengan una etiqueta y un pago asignado y válido
    const conceptosValidos = this.formConceptos.filter(concepto =>
      concepto.idEtiquetaVisita !== 0 && concepto.pagoAlGestor !== null && concepto.pagoAlGestor > 0
    );

    // Si no hay conceptos válidos, mostramos un error
    if (conceptosValidos.length === 0) {
      this.app.showSnackbar('¡Aviso!', 'Todos los conceptos deben tener una etiqueta seleccionada y un pago válido mayor a 0.', 3000, 'warning');
      return;
    }

    // Mapeamos los conceptos válidos para enviarlos
    let parametros = conceptosValidos.map(concepto => {
      const etiquetaSeleccionada = this.etiquetas.find(etiqueta => etiqueta.idEtiquetaVisita === concepto.idEtiquetaVisita);
      return {
        idEtiquetaVisita: concepto.idEtiquetaVisita,
        nombreConcepto: etiquetaSeleccionada ? etiquetaSeleccionada.etiquetaVisita : '', // Usamos etiquetaVisita para el nombre sin números
        pagoGestor: concepto.pagoAlGestor
      };
    });

    let data = {
      conceptos: parametros
    };


    this.app.showLoading("Guardando conceptos");
    this.conceptosEtiquetasVisitas.agregarConceptoEtiqueta(data)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.app.hideLoading();
        this.app.showSnackbar('¡Aviso!', 'Se han guardado los conceptos correctamente.', 1000, 'success');
        this.router.navigate([`catalogos/conceptos-etiquetas-visitas`], {
          queryParamsHandling: 'preserve'
        });
      }, (err: any) => {
        this.app.hideLoading();
        this.app.showError(err);
      });
  }


  // Cancelar y volver a la página anterior
  cancel() {
    this.router.navigate([`catalogos/conceptos-etiquetas-visitas`], {
      queryParamsHandling: 'preserve'
    });
  }

  onPagoAlGestorChange(value: any, index: number): void {
    const maxPagoAlGestor = 99999.99;
    if (value > maxPagoAlGestor) {
      // Limitar el valor a 99999.99 si excede el límite
      this.formConceptos[index].pagoAlGestor = maxPagoAlGestor;
    }
  }

  validarPagoAlGestor(event: any, index: number): void {
    let inputValue = event.target.value;
  
    // Eliminar cualquier carácter que no sea un número o punto decimal
    inputValue = inputValue.replace(/[^0-9.]/g, '');
  
    // Dividir la parte antes y después del punto
    const parts = inputValue.split('.');
  
    // Limitar la parte entera a un máximo de 5 dígitos (99999)
    if (parts[0].length > 5) {
      parts[0] = parts[0].substring(0, 5);  // Limitar a 5 dígitos en la parte entera
    }
  
    // Limitar la parte decimal a 2 dígitos si existe
    if (parts[1] && parts[1].length > 2) {
      parts[1] = parts[1].substring(0, 2);  // Limitar a 2 dígitos en la parte decimal
    }
  
    // Volver a unir las partes con el punto decimal (si existe la parte decimal)
    inputValue = parts.length > 1 ? `${parts[0]}.${parts[1]}` : parts[0];
  
    // Permitir que el usuario introduzca el punto sin autocompletar
    if (inputValue.endsWith('.')) {
      this.formConceptos[index].pagoAlGestor = inputValue;  // Dejar el punto sin tocar
      return;
    }
  
    // Convertir el valor a número solo si no es un punto suelto (ej: '.')
    let value = parseFloat(inputValue);
  
    // Validar si el valor está dentro de los límites permitidos solo si es un número válido
    const minValue = 1.00;
    const maxValue = 99999.99;
  
    if (!isNaN(value)) {
      if (value < minValue) {
        value = minValue;
      } else if (value > maxValue) {
        value = maxValue;
      }
      // Solo formatear a dos decimales después de que se haya introducido un número completo
      if (!inputValue.includes('.')) {
        inputValue = value.toFixed(2);  // Solo si no se está ingresando un decimal actualmente
      }
    }
  
    // Actualizar el valor del campo de entrada
    event.target.value = inputValue;
  
    // Actualizar el valor en el array de conceptos
    this.formConceptos[index].pagoAlGestor = isNaN(value) ? null : value;
  }
  
  
}
