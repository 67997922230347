import { ChecklistRespuesta } from './../../../catalogos/checklists/modelos/checklist-respuesta.model';
import { Component, ElementRef, ViewChild, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApplicationService } from '../../../application/shared/application.service';
import { ChecklistService } from '../../../checklist/checklist.service';
import { EtiquetasVisitasService } from '../../../catalogos/etiquetas-visitas/etiquetas-visitas.service';
import { Observable } from 'rxjs';
import { AccionesEspecificas } from '../enums/acciones.enum';
import { debounceTime, distinctUntilChanged, switchMap, startWith } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { ChecklistPregunta } from '../../../catalogos/checklists/modelos/checklist-pregunta.model';
import { CondicionRespuesta } from '../../../catalogos/checklists/modelos/condiciones/condicion-respuesta.model';
import { Condicion } from '../../../catalogos/checklists/modelos/condiciones/condicion.model';
import { MostrarPreguntas } from '../../../catalogos/checklists/modelos/condiciones/mostrar-preguntas.model';
import { of } from 'rxjs';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';
import { PosicionPreguntaDialog } from 'src/app/catalogos/checklists/modales/posicion-pregunta/posicion-pregunta-dialog.component';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { ChecklistRespuestaOpcion } from 'src/app/catalogos/checklists/modelos/checklist-respuesta-opcion.model';
import { GrupoVariable } from 'src/app/catalogos/checklists/interfaces/variables/variables.interface';
import { FocusService } from 'src/app/application/shared/focus.service';

@Component({
  selector: 'app-product-checklist-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.css'],
  providers: [ChecklistService]
})
export class AnswerComponent implements OnInit {
  formGroup: FormGroup;
  tiposRespuestas = [];
  tiposRespuestasColumnas = [];
  acciones = [];
  etiquetasAsignadas: ChecklistRespuestaOpcion[] = [];
  loading: boolean = true;
  showWhen = [];
  cargandoMetodosPago: boolean = false;
  answerDisabled:boolean = false;
  desplegarBlaster: boolean = false;
  mostrarNuevaEtiqueta: boolean = false;
  etiquetasVisitasCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];
  filteredEtiquetasVisitas$: Observable<any[]>;
  etiquetasVisitas = [];
  accionEtiquetaVisita: boolean = false;
  preguntas: ChecklistPregunta[];
  preguntaActual: ChecklistPregunta;
  validarCambios: boolean = true;
  ayudaAcciones: boolean = false;
  answerAqui: any;
  nombreRequerido: boolean = false;
  selectedTabIndex: number = -1;
  variablesCuentas: GrupoVariable[] = [null];
  esCredito: boolean = false;
  @ViewChild('optionsInput') optionsInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;

  constructor(
    private appService: ApplicationService,
    private checklistService: ChecklistService,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<AnswerComponent>,
    public etiquetasVisitasService: EtiquetasVisitasService,
    private focusService: FocusService,
    private cdr: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
   }

  ngOnInit() {
    //RN: Quitamos el tipo columna debido a que este será automatico en base a donde se encuentre dicha respuesta el usuario no lo verá
    this.tiposRespuestas = this.data.tiposRespuestas ? this.data.tiposRespuestas.filter(item => item.id != 13) : [];
    //RN: Quitamos el tipo tabla y foto de las columnas para los tipos tablas.
    this.tiposRespuestasColumnas = this.tiposRespuestas.filter(item => item.id != 12 && item.id != 11);
    this.acciones = this.data.acciones;
    this.etiquetasAsignadas = this.data.etiquetasAsignadas;
    this.variablesCuentas = this.data.variablesCuentas;
    this.esCredito = this.data.esCredito;
    this.answerAqui = this.data.answer || {
      idTipoRespuesta: this.data.soloCheckBox ? 6 : 1,
      accion: '',
      requerida: this.data.soloCheckBox ? false : (this.data.answer ? this.data.answer.requerida : false),
      condiciones: [],
      blasters: [],
      valorPreLlenado: null,
      preLlenarRespuesta: false
    };
    if(!this.answerAqui.blasters){
      this.answerAqui.blasters = [];
    }

    this.preguntas = this.data.preguntas || [];
    this.preguntaActual = this.data.preguntaActual || null;
    this.accionEtiquetaVisita = (this.answerAqui.accion === 'etiqueta-visita') ? true : false;
    this.nombreRequerido = this.data.nameAnswerRequired;

    let valueShowWhen = null;

    if (this.answerAqui.idTipoRespuesta === 8) {
      this.showWhen = [{
        name: 'Apagado',
        value: 0
      }, {
        name: 'Encendido',
        value: 1
      }];
      valueShowWhen = 0;
    }

    this.formGroup = this.formBuilder.group({
      idTipoRespuesta: new FormControl({
        value: this.data.soloCheckBox ? 6 : this.answerAqui.idTipoRespuesta,
        disabled: this.data.soloCheckBox || Boolean(this.answerAqui.accion)
      }, Validators.required),
      nombreRespuesta: new FormControl({
        value: this.answerAqui.nombreRespuesta,
        disabled: !this.data.nameAnswerRequired
      }, this.data.nameAnswerRequired ? [Validators.required, Validators.maxLength(100)] : null),
      requerida: new FormControl({
        value: this.answerAqui.requerida || false,
        disabled: this.data.soloCheckBox || this.answerAqui.idTipoRespuesta == 6 || false || this.answerAqui.idAccionRespuesta
      }),
      accion: new FormControl({
        value: this.answerAqui.accion,
        disabled: false
      }),
      idChecklistRespuesta: new FormControl({
        value: this.answerAqui.idChecklistRespuesta,
        disabled: true
      }),
      idAccionRespuesta: new FormControl({
        value: this.answerAqui.idAccionRespuesta,
        disabled: true
      }),
      minCaracteres: new FormControl(this.answerAqui.minCaracteres || 0),
      maxCaracteres: new FormControl(this.answerAqui.maxCaracteres || 0),
      mostrarPreguntasCuando: new FormControl(null),
      options: new FormControl(this.answerAqui.options || []),
      valorPreLlenado: new FormControl(this.answerAqui.valorPreLlenado || null),
      preLlenarRespuesta: new FormControl({
        value: this.answerAqui.valorPreLlenado ? true : false,
        disabled: this.answerAqui.idTipoRespuesta != 1 && this.answerAqui.idTipoRespuesta != 2
      })
    });

    if(this.answerAqui.idAccionRespuesta == AccionesEspecificas.PROMESA_PAGO_METODO){
      this.answerDisabled = true;
    }

    if (this.answerAqui.options && this.answerAqui.options.length > 0) {
      this.showWhen = [];
      for (let i = 0; i < this.answerAqui.options.length; i++) {
        const opcion = this.answerAqui.options[i];
        this.showWhen.push({
          name: opcion.label,
          value: opcion.id || (i + 1)
        });
      }
      //this.formGroup.get('mostrarPreguntasCuando').setValue(this.answerAqui.options[0]);
    }

    if (this.accionEtiquetaVisita) {
      this.etiquetasVisitas = this.answerAqui.options;
      this.activarEtiquetasVisitas();
    }

    this.formGroup.get('options').valueChanges.subscribe((value) => {
      if (value.length > this.showWhen.length) {
        this.showWhen.push({
          name: value[value.length - 1].label,
          value: value.length
        });
      } else {
        this.showWhen = [];
        this.limpiarCondiciones();
        for (let i = 0; i < value.length; i++) {
          const valorActual = value[i];
          this.showWhen.push({
            name: valorActual.label,
            value: valorActual.id || (i + 1)
          });
        }
      }

      if(this.showWhen.length == 1){
        this.formGroup.get('mostrarPreguntasCuando').setValue(this.showWhen[0].value);
      }
    });

    this.formGroup.get('idTipoRespuesta').valueChanges.subscribe((value) => {
      if (value === 9 && !this.accionEtiquetaVisita) {
        this.activarEtiquetasVisitas();
      }
    });
    this.formGroup.get('mostrarPreguntasCuando').valueChanges.subscribe((value) => {
      this.validarCambios = false;
      this.limpiarPreguntasSeleccionadas();
      this.seleccionarPreguntasCondicion();
      this.validarCambios = true;
    });
    this.formGroup.get('preLlenarRespuesta').valueChanges.subscribe((value) => {
      this.answerAqui.preLlenarRespuesta = value;
    });

    this.formGroup.get('valorPreLlenado').valueChanges.subscribe((value) => {
      this.answerAqui.valorPreLlenado = value;
    });

    if(this.showWhen.length > 0){
      this.formGroup.get('mostrarPreguntasCuando').setValue(this.showWhen[0].value);
    }

    for (let index = 0; index < this.answerAqui.blasters.length; index++) {
      const blaster = this.answerAqui.blasters[index];
      blaster.respuestas = [];
      for (let bc = 0; bc < blaster.condiciones.length; bc++) {
        const condicion = blaster.condiciones[bc];
        let etiquetas = this.showWhen.filter(etiqueta => etiqueta.value == condicion.conditions[0].value.eq);
        if(etiquetas){
          blaster.respuestas.push(etiquetas[0]);
        }

        //blaster.respuestas.push(condicion.conditions[0].value.eq);
      }
      if(!blaster.envio.id){
        blaster.envio.id = null;
      }
      if(!blaster.envio.message){
        blaster.envio.message = null;
      }
    }
    this.loading = false;
  }

  activarEtiquetasVisitas(){
    this.filteredEtiquetasVisitas$ = this.etiquetasVisitasCtrl.valueChanges.pipe(
      startWith(''),
      distinctUntilChanged(),
      debounceTime(150),
      switchMap(name => {
        if (typeof name === 'string' && (name || '').trim()) {
          return (async () => {
            const etiquetasVisitas: any = await this.etiquetasVisitasService
              .obtenerEtiquetasAutocomplete(name)
              .toPromise()
              .catch((error) => this.appService.showError(error));
            return etiquetasVisitas.map(a => ({ id: a.idEtiquetaVisita, label: a.etiquetaVisita })) as any;
          })();
        } else {
          return of([]); // Agregar un valor de retorno para este caso
        }
      })
    );
  }

  comboAccionIdTipoRespuesta(){
    let control = this.formGroup.get('idTipoRespuesta');
    control.setValue(this.answerAqui.idTipoRespuesta);
    if(Boolean(this.answerAqui.accion)){
      control.disable();
    }
    else{
      control.enable();
    }
  }

  limpiarAccion(){
    this.answerAqui.idAccionRespuesta = null;
    this.answerAqui.accion = null;
    this.accionEtiquetaVisita = false;
    this.comboAccionIdTipoRespuesta();;
    this.answerAqui.options = [];
    this.formGroup.get('options').setValue(this.answerAqui.options);
    this.formGroup.get('requerida').enable();
    this.answerDisabled = false;
  }

  asignarAccion(accion){
    this.answerAqui.idAccionRespuesta = accion.id;
    this.answerAqui.accion = accion.name;
    this.answerAqui.requerida = true;
    this.formGroup.get('requerida').setValue(true);
    this.formGroup.get('requerida').disable();
    this.answerAqui.options = [];

    //colocamos el tipo de respuesta requerido para cada accion por defecto
    switch(this.answerAqui.idAccionRespuesta){
      case 4:
      case 7:
      case 18:
        this.answerAqui.idTipoRespuesta = 9;
        break;
      case 1:
      case 5:
      case 9:
      case 13:
      case 14:
      case 17:
        this.answerAqui.idTipoRespuesta = 8;
        break;
      case 6:
      case 10:
        this.answerAqui.idTipoRespuesta = 5;
        break;
      case 8:
      case 16:
        this.answerAqui.idTipoRespuesta = 4;
        break;
      case 11:
      case 12:
        this.answerAqui.idTipoRespuesta = 10;
        break;
      default:
        this.answerAqui.idTipoRespuesta = 1;
        break;
    }
    this.comboAccionIdTipoRespuesta();
    if(accion.id != AccionesEspecificas.ETIQUETA_VISITA){
      this.accionEtiquetaVisita = false;
    }
    if(accion.id == AccionesEspecificas.ETIQUETA_VISITA){
      this.answerAqui.options = [];
      this.accionEtiquetaVisita = true;
      this.answerAqui.idTipoRespuesta = 9;
      if(!this.answerAqui.options || this.answerAqui.options.length == 0){
        this.answerAqui.options = this.etiquetasAsignadas;
      }
      this.etiquetasVisitas = this.answerAqui.options;
      this.activarEtiquetasVisitas();

      this.formGroup.get('options').setValue(this.answerAqui.options);
    }
    else if(accion.id == AccionesEspecificas.PROMESA_PAGO_METODO){
      this.cargandoMetodosPago = true;
      let subscribe = this.checklistService.obtenerMetodosPago().subscribe(
        (res) => {
          res.map(metodoPago => {
            this.answerAqui.options.push({
              label: metodoPago.name
            });
          });
          this.formGroup.get('options').setValue(this.answerAqui.options);
          this.answerDisabled = true;
          this.cargandoMetodosPago = false;
        },
        (error) => {
          this.appService.showError(error);
          this.cargandoMetodosPago = false;
        },
        () => {
          this.cargandoMetodosPago = false;
        }
      );
    }
  }

  validarNoAccion(accion){
    let respuesta: boolean = true;
    for (let index = 0; index < this.preguntas.length; index++) {
      const preguntaActual: ChecklistPregunta = this.preguntas[index];
      if(preguntaActual.respuestas.some((respuesta)=>respuesta.idAccionRespuesta == accion.id && this.preguntaActual != preguntaActual)){
        respuesta = false;
        index = this.preguntas.length;
      }
    }
    return respuesta;
  }

  agregarColumnas(index: number = 0, antes: boolean = false){

    if(!this.answerAqui.columnas){
      this.answerAqui.columnas = [];
    }
    else{
      if(!this.validarNombresColumnas()){
        this.appService.showSnackbar('¡Aviso!', 'Es necesario añadir nombre a todas las columnas pendientes antes de añadir una nueva.', 3000, 'warning');
        return;
      }
    }

    if(antes){
      this.answerAqui.columnas.splice(index, 0, {
        nombre: '',
        idTipoRespuesta: 1,
        minCaracteres: 0,
        maxCaracteres: 0,
        options: [],
        columnas: [],
        requerida: true
      });
    }
    else{
      if(index > 0){
        this.answerAqui.columnas.splice(index+1, 0, {
          nombre: '',
          idTipoRespuesta: 1,
          minCaracteres: 0,
          maxCaracteres: 0,
          options: [],
          columnas: [],
          requerida: true
        });
      }
      else{
        this.answerAqui.columnas.push({
          nombre: '',
          idTipoRespuesta: 1,
          minCaracteres: 0,
          maxCaracteres: 0,
          options: [],
          columnas: [],
          requerida: true
        });
      }
    }

    setTimeout(() => {
      if(antes){
        this.selectedTabIndex--;
        setTimeout(() => {
          this.selectedTabIndex++;
        },0);
      }
      else if(index>0){
        this.selectedTabIndex++;
      }
      else{
        this.selectedTabIndex = this.answerAqui.columnas.length - 1;
      }
    }, 0);
  }

  validarNombresColumnas(): boolean{
    let avanzar: boolean = true;
    for (let i = 0; i < this.answerAqui.columnas.length && avanzar; i++) {
      const columna = this.answerAqui.columnas[i];
      if(!columna.nombre){
        avanzar = false;
      }
    }
    return avanzar;
  }

  eliminarColumna(index: number){
    let columna: ChecklistRespuesta = this.answerAqui.columnas[index];
    const dialogRef = this.dialog.open(ConfirmacionDialog, {
        data:{
          titulo: "Columna",
          mensaje: `¿Estas seguro de eliminar la columna "${columna.nombre}"?`,
          icono: "delete_forever",
          colorIcono: "warn",
          boton1: "No",
          boton2: "Sí",
          texto: null,
          claseAccion: "boton-accion-eliminar"
        }
    });

    dialogRef.afterClosed().subscribe(result => {
        if(result) {
          this.answerAqui.columnas.splice(index,1);
        }
    });
  }

  save() {
    const values = this.formGroup.getRawValue();

    values.mostrarPreguntasCuando = Boolean(values.mostrarPreguntasCuando);
    values.condiciones = this.answerAqui.condiciones;
    values.blasters = this.answerAqui.blasters;

    let indices: number[] = [];
    for (let index = 0; index < values.blasters.length; index++) {
      const blaster = values.blasters[index];
      if(!blaster.envio.id || blaster.envio.id == null){
        delete blaster.envio.id;
      }
      if(!blaster.envio.message || blaster.envio.message == null){
        delete blaster.envio.message;
      }

      if((!blaster.envio.id && !blaster.envio.message) || (!blaster.condiciones || blaster.condiciones.length == 0)){
        indices.push(index);
      }
    }
    for (let ib = 0; ib < indices.length; ib++) {
      const indexBorrar = indices[ib];
      values.blasters.splice(indexBorrar, 1);
    }

    if(this.answerAqui.accion === 'etiqueta-visita'){
      this.accionEtiquetaVisita = true;
      this.answerAqui.idTipoRespuesta = 9;
      values.idTipoRespuesta = 9;
      values.options = this.etiquetasVisitas;
    }

    values.idAccionRespuesta = this.answerAqui.idAccionRespuesta;
    values.accion = this.answerAqui.accion;
    if(this.answerAqui.idTipoRespuesta == 12){
      if(!this.answerAqui.columnas){
        this.appService.showSnackbar('¡Aviso!', 'Es necesario capture al menos una columna para la tabla.', 3000, 'warning');
        return;
      }

      if(!this.validarNombresColumnas()){
        this.appService.showSnackbar('¡Aviso!', 'Es necesario añadir nombre a todas las columnas pendientes antes de añadir una nueva.', 3000, 'warning');
        return;
      }
      values.columnas = this.answerAqui.columnas;
    }


    this.validarCambios = false;
    this.limpiarPreguntasSeleccionadas();
    this.validarCambios = true;
    if(values.minCaracteres > values.maxCaracteres){
      this.appService.showSnackbar('¡Aviso!', 'El minimo de caracteres no puede ser mayor al máximo de caracteres.', 3000, 'warning');
      return;
    }

    if(values.minCaracteres < 0){
      this.appService.showSnackbar('¡Aviso!', 'El minimo de caracteres no puede ser menor a cero.', 3000, 'warning');
      return;
    }

    if(values.maxCaracters < 0){
      this.appService.showSnackbar('¡Aviso!', 'El máximo de caracteres no puede ser menor a cero.', 3000, 'warning');
      return;
    }

    if(this.answerAqui.preLlenarRespuesta && !this.answerAqui.valorPreLlenado){
      this.appService.showSnackbar('¡Aviso!', 'Haz indicado que quieres utilizar una respuesta pre llenada, es necesario captures el valor que llevará por defecto', 3000, 'warning');
      return;
    }

    if(!this.answerAqui.preLlenarRespuesta && this.answerAqui.valorPreLlenado){
      this.answerAqui.valorPreLlenado = null;
      values.valorPreLlenado = null;
    }

    if(this.answerAqui.preLlenarRespuesta){
      let variablesNoExisten: string = this.validarVariablesExistentes();
      if(variablesNoExisten.length > 0){
        this.appService.showSnackbar('¡Aviso!', `Las siguientes variables no existen "${variablesNoExisten}", por favor verifique`, 3000, 'warning');
        return;
      }
    }

    if (this.answerAqui && this.answerAqui.idTipoRespuesta) {
      this.dialogRef.close(values);
    } else {
      this.appService.showSnackbar('¡Aviso!', 'Es necesario escoger el tipo de respuesta para continuar.', 3000, 'warning');
    }
  }

  validarVariablesExistentes(): string{
    const matches = [...this.answerAqui.valorPreLlenado.matchAll(/\$\{([a-zA-Z_]+)\}/g)];

    const clavesDetectadas = [...new Set(matches.map(match => match[0]))];

    let respuesta:string = '';
    for (let cdi = 0; cdi < clavesDetectadas.length; cdi++) {
      const clave: string = clavesDetectadas[cdi];
      let detenerBusqueda: boolean = false
      for (let index = 0; index < this.variablesCuentas.length && !detenerBusqueda; index++) {
        const grupo: GrupoVariable = this.variablesCuentas[index];
        for (let vi = 0; vi < grupo.vars.length && !detenerBusqueda; vi++) {
          const variable = grupo.vars[vi];
          if(variable.claveUsuario == clave){
            detenerBusqueda = true;
          }
        }
      }

      if(!detenerBusqueda){
        respuesta=`${respuesta ? respuesta + ', ' : ''}${clave}`;
      }
    }
    return respuesta;
  }

  addEtiquetaVisita(event: MatChipInputEvent): void {
    const input = event.input;
    if (input) {
      input.value = '';
    }

    this.etiquetasVisitasCtrl.setValue(null);
  }

  removeEtiquetaVisita(etiquetaVisita: any): void {
    const index = this.etiquetasVisitas.indexOf(etiquetaVisita);

    if (index >= 0) {
      this.etiquetasVisitas.splice(index, 1);
    }
  }

  selectedEtiquetaVisita(event: MatAutocompleteSelectedEvent): void {
    let existeEtiqueta = this.etiquetasVisitas.map(function(item) { return item.id; }).indexOf(event.option.value.id);

    if (existeEtiqueta === -1) {
      this.etiquetasVisitas.push(event.option.value);
      this.showWhen.push({value: event.option.value.id, name: event.option.value.label});
      this.optionsInput.nativeElement.value = '';
      this.etiquetasVisitasCtrl.setValue(null);
    }
    /*this.etiquetasVisitas.push(event.option.value);
    this.optionsInput.nativeElement.value = '';
    this.etiquetasVisitasCtrl.setValue(null);*/
  }

  get title() {
    let title: string = 'Nueva Pregunta';

    if (this.preguntaActual && !this.preguntaActual.nombrePregunta) {
      title = `${this.preguntaActual.orden} - Nueva Pregunta`;
    }

    if (this.preguntaActual && this.preguntaActual.nombrePregunta) {
      title = `${this.preguntaActual.orden} - ${this.preguntaActual.nombrePregunta}`;
    }

    return title;

  }

  get subtitle(){
    let title: string = 'Nueva Respuesta';

    if (this.answerAqui && !this.answerAqui.nombreRespuesta) {
      title = 'Sin nombre de respuesta';
    }

    if (this.formGroup.get('nombreRespuesta').value) {
      title = this.formGroup.get('nombreRespuesta').value;
    }

    return title;
  }

  onClose(): void {
    if (this.validarCambios && this.formGroup.dirty) {
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
          data:{
            titulo: "No se han guardado los cambios",
            mensaje: "¿Desea cerrar sin guardar los cambios realizados?",
            icono: "check",
            colorIcono: "warn",
            boton1: "No",
            boton2: "Sí",
            texto: null,
            claseAccion: "boton-accion-front"
          }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          this.dialogRef.close(null);
        }
      });
    } else {
      this.dialogRef.close(null);
    }
  }

  agregarCondicionQuitarCondicion(pregunta: ChecklistPregunta, index: number) {
    if (this.validarCambios) {
      var preguntaCondicion: ChecklistPregunta = null;
      var idPregunta = pregunta.idChecklistPreguntaCondicion;
      var valorCondicion = this.formGroup.get('mostrarPreguntasCuando').value;
      var continuaCondicion = false;
      const condicionActual: any = this.obtenerCondicionActual(this.formGroup.get('mostrarPreguntasCuando').value);

      if(condicionActual){
        preguntaCondicion = this.obtenerPreguntaCondicion(condicionActual.when.then.show, pregunta.idChecklistPreguntaCondicion);
        if (pregunta.seleccionada && !preguntaCondicion) {
          this.agregarPreguntaACondicion(condicionActual, pregunta.idChecklistPreguntaCondicion);
          pregunta.condicionMostrar = `Esta pregunta depende de la pregunta "${this.preguntaActual.orden} - ${this.preguntaActual.nombrePregunta || 'Nueva pregunta'}"`;
          pregunta.idChecklistPreguntaPadre = this.preguntaActual.idChecklistPreguntaCondicion;
          pregunta.margen = this.preguntaActual.margen + 10;
        } else {
          if (preguntaCondicion) {
            var indice = this.quitarPreguntaDeCondicion(condicionActual.when.then.show, idPregunta);
            if(indice || indice === 0){
              condicionActual.when.then.show.splice(indice, 1);
              this.answerAqui.condiciones.forEach(condicion => {
                if(!continuaCondicion){
                  continuaCondicion = condicion.when.then.show.find(elemento => elemento == idPregunta);
                }
              });
              if(!continuaCondicion){
                pregunta.condicionMostrar = null;
                pregunta.idChecklistPreguntaPadre = null;
                pregunta.margen=0;
              }

              if(condicionActual.when.then.show.length == 0){
                this.answerAqui.condiciones = this.answerAqui.condiciones.filter(condicion => condicion != condicionActual);
              }
            }
          }
        }
      }
      else{
        this.answerAqui.condiciones.push(CondicionRespuesta.map({
          when: Condicion.map({
            response: valorCondicion,
            then: MostrarPreguntas.map({
              show: [
                idPregunta
              ]
            })
          })
        }));
        pregunta.condicionMostrar = `Esta pregunta depende de la pregunta "${this.preguntaActual.orden} - ${this.preguntaActual.nombrePregunta || 'Nueva pregunta'}"`;
        pregunta.idChecklistPreguntaPadre = this.preguntaActual.idChecklistPreguntaCondicion;
        pregunta.margen = this.preguntaActual.margen + 10;
      }
    }
  }



  limpiarCondiciones() {
    const condicionesEliminadas = this.answerAqui.condiciones.filter(c => c.idChecklistPreguntaCondicion === this.preguntaActual.idChecklistPregunta);
    condicionesEliminadas.forEach(condicionEliminada => {
      const indexCondicionEliminada = this.answerAqui.condiciones.indexOf(condicionEliminada);
      if (indexCondicionEliminada > -1) {
        this.answerAqui.condiciones.splice(indexCondicionEliminada, 1);
      }
    });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  obtenerCondicionActual(valorCondicion): CondicionRespuesta {
    return this.answerAqui.condiciones.find(condicion => condicion.when.response === valorCondicion);
  }

  obtenerPreguntaCondicion(ids: number[], idPregunta: number): ChecklistPregunta {
    var pregunta: ChecklistPregunta = null;
    for (let index = 0; index < ids.length; index++) {
      const id = ids[index];
      if(idPregunta==id){
        pregunta = this.preguntas.find(pregunta =>
          pregunta.idChecklistPreguntaCondicion === id
        );
        if(pregunta){
          index = ids.length;
        }
      }
    }
    return pregunta;
  }

  respuestaTieneCondicion(idPregunta: number): boolean {
    return this.answerAqui.condiciones.some(condicion => condicion.when.then.show.includes(idPregunta));
  }

  agregarPreguntaACondicion(condicion: CondicionRespuesta, idPregunta: number) {
    condicion.when.then.show.push(idPregunta);
  }

  private quitarPreguntaDeCondicion(ids: number[] ,id: number): number{
    var indiceDevolver: number = null;
    for (let index = 0; index < ids.length; index++) {
      if(id == ids[index]){
        indiceDevolver = index;
        index = ids.length;
      }
    }
    return indiceDevolver;
  }

  quitarEtiquetaNueva(){
    this.mostrarNuevaEtiqueta = false;
  }

  private limpiarPreguntasSeleccionadas(){
    for (let i = 0; i < this.preguntas.length; i++) {
      this.preguntas[i].seleccionada = false;
    }
  }

  private seleccionarPreguntasCondicion(){
    var valorCondicion = this.formGroup.get('mostrarPreguntasCuando').value;
    var condicionActual: CondicionRespuesta = this.obtenerCondicionActual(valorCondicion);
    var preguntaCondicion: ChecklistPregunta = null;

    if(condicionActual){
      for (let i = 0; i < condicionActual.when.then.show.length; i++) {
        const id = condicionActual.when.then.show[i];
        for (let j = 0; j < this.preguntas.length; j++) {
          var pregunta = this.preguntas[j];
          if(pregunta.idChecklistPreguntaCondicion == id && pregunta.condicionMostrar){
            pregunta.seleccionada = true;
          }
        }
      }
    }
  }

  public desplegar(){
    if(this.answerAqui.blasters.length > 0 || this.desplegarBlaster){
      this.desplegarBlaster = !this.desplegarBlaster;
    }
  }

  changeBlasterRequest(event: any, blaster: any){
    blaster.condiciones = [];
    for (let index = 0; index < blaster.respuestas.length; index++) {
      const respuesta = blaster.respuestas[index];
      blaster.condiciones.push({
        conditions: [
          {
            value: {eq: respuesta.value}
          }
        ]
      });
    }
  }

  resetBlaster(blaster: any){
    blaster.envio.message = null;
    blaster.envio.id = null;
  }

  eliminarBlaster(index: number, blaster: any){
    this.answerAqui.blasters.splice(index, 1);
    if(this.answerAqui.blasters.length == 0){
      this.desplegar();
    }
  }

  agregarConfig(): void{

    this.answerAqui.blasters.push({
      descripcion: null,
      idTipoDifusion: 1,
      idChecklistRespuesta: this.answerAqui.idChecklistRespuesta,
      idChecklistRespuestaDifusion: null,
      respuestasPrecargadas: [],
      respuestas: [],
      condiciones: [],
      envio: {
        id: null,
        message: null
      }
    });
    if(!this.desplegarBlaster){
      this.desplegar();
    }
  }

  abrirDialogoCambiarIndice(index: number){
    let respuestaDialogo = function(nuevoIndice: number){
      this.moverIndiceColumna(index, nuevoIndice);
    }
    const dialogRef = this.dialog.open(PosicionPreguntaDialog, {
      disableClose: false,
      /*width: '50%',*/
      data: {
        title: '¿A qué posición deseas cambiar la columna?',
        currentValue: index+1,
        maxValue: this.answerAqui.columnas.length,
        respuesta: respuestaDialogo.bind(this)
      }

    });

    dialogRef.afterClosed().subscribe(data => {});
  }

  moverIndiceColumna(indexAnterior: number, indexNvo: number){
    let indexAnteriorUtilizar: number = indexAnterior;
    moveItemInArray(this.answerAqui.columnas, indexAnteriorUtilizar, indexNvo);
  }

  onSelectVariable({ variable, columnPressed }){
    this.focusService.triggerInsertText('preLlenado', variable[columnPressed]);
  }

}
