<div class="app content kobra">
  <mat-card>
    <mat-card-header>
      <mat-card-title> </mat-card-title>
    </mat-card-header>
    <mat-card-content class="container">

      <!-- Primera Columna - General -->
      <mat-card class="tile">
        <mat-card-header>
          <mat-card-title>
            General
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="personalInfoForm">
            <mat-form-field class="input-conf">
              <mat-label>Nombre</mat-label>
              <input matInput textSanitizer formControlName="nombre" placeholder="Capture el nombre del administrador">
              <mat-icon matSuffix>shield_person</mat-icon>

            </mat-form-field>

            <mat-form-field class="input-conf">
              <mat-label>Primer apellido</mat-label>
              <input matInput textSanitizer formControlName="apellidoPaterno"
                placeholder="Capture el apellido del administrador">
              <mat-icon matSuffix>shield_person</mat-icon>
              <mat-error>
              </mat-error>
            </mat-form-field>

            <mat-form-field class="input-conf">
              <mat-label>Segundo apellido</mat-label>
              <input matInput textSanitizer formControlName="apellidoMaterno"
                placeholder="Capture el apellido del administrador">
              <mat-icon matSuffix>shield_person</mat-icon>
            </mat-form-field>

            <mat-form-field class="input-conf">
              <mat-label>Correo</mat-label>
              <input matInput textSanitizer formControlName="email" placeholder="Capture el correo del administrador">
              <mat-icon matSuffix>mail</mat-icon>
              <mat-error>
              </mat-error>
            </mat-form-field>
          </form>
        </mat-card-content>
        <mat-card-footer>
          <div mat-card-actions align="right" style="margin: 10px;  ">
            <button mat-stroked-button class="boton-guardar" (click)="guardarDatos()" m-actions>
              <mat-icon>
                save
              </mat-icon>Guardar</button>
          </div>
          <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
        </mat-card-footer>
      </mat-card>

      <!-- Segunda Columna - Saldos -->
      <mat-card class="tile">
        <mat-card-header>
          <mat-card-title>
            Cambiar Contraseña
          </mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <form [formGroup]="passwordForm">

            <mat-form-field class="input-conf">
              <mat-label>Contraseña actual</mat-label>
              <input matInput textSanitizer formControlName="contrasenaActual" placeholder="Introduce la contraseña"
                [type]="'password'">
              <mat-icon class="ver-password" matSuffix></mat-icon>

            </mat-form-field>

            <mat-form-field class="input-conf">
              <mat-label>Nueva contraseña</mat-label>
              <input matInput textSanitizer placeholder="Confirma la contraseña"
                [type]="hideContra ? 'password' : 'text'" formControlName="contrasenaNueva"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$">
              <mat-icon class="ver-password" matSuffix (click)="hideContra = !hideContra">
                {{hideContra ? 'visibility_off': 'visibility'}}
              </mat-icon>

            </mat-form-field>

            <mat-form-field class="input-conf">
              <mat-label>Confirmar nueva contraseña</mat-label>
              <input matInput textSanitizer placeholder="Confirma la contraseña"
                [type]="hideConfirm ? 'password' : 'text'" formControlName="contrasenaRepetida"
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$">
              <mat-icon class="ver-password" matSuffix (click)="hideConfirm = !hideConfirm">
                {{hideConfirm ? 'visibility_off' : 'visibility'}}
              </mat-icon>

            </mat-form-field>
          </form>
        </mat-card-content>
        <mat-card-footer class="kobra-card-footer">

          <div mat-card-actions align="right" style="margin: 10px; ">
            <button mat-stroked-button class="boton-guardar" (click)="cambiarContrasenia()" m-actions>
              <mat-icon>
                save
              </mat-icon>Guardar</button>
          </div>
        </mat-card-footer>
      </mat-card>
      <mat-card class="authenticator-card">
        <mat-card-header>
          <mat-card-title>Factor de Autenticación</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div class="button-container" *ngIf="!mostrarQR">
            <div class="button-toggle-group-container">
              <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
                <mat-button-toggle value="actualizar" matTooltip="Refrescar" mat-tooltip-panel-above
                  (click)="cargarDatos2FA()">
                  <mat-icon class="kobra-icon">sync</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="ayuda" matTooltip="Ayuda" (click)="ayuda = !ayuda" mat-tooltip-panel-above>
                  <mat-icon color="primary">help</mat-icon>
                </mat-button-toggle>
                <mat-button-toggle value="subMenu" matTooltip="Acciones" mat-tooltip-panel-above
                  [matMenuTriggerFor]="menu">
                  <mat-icon class="kobra-icon">more_vert</mat-icon>
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
          <mat-menu #menu="matMenu">
            <button mat-menu-item [disabled]="!activarBoton" (click)="clickItemSelected(itemsSelected[0])">
              <mat-icon class="kobra-icon">swap_vert</mat-icon>
              <span>Activar</span>
            </button>
          </mat-menu>
          <app-table *ngIf="!mostrarQR" [classContainer]="'tabla-completa'" [loading]="cargandoDatos"
            [columns]="tabla2FA.columnas" [items]="items" [headers]="tabla2FA.headers"
            [tableHeaders]="tabla2FA.tableHeaders" [ignoreSortChange]="true" [multiSelect]="tabla2FA.multiSelect"
            (onSelectedRows)="onSelected($event)">
            <div t-header>
             
              <mat-card *ngIf="ayuda" class="cardAyuda" style="text-align: left;">
                <mat-card-content>
                  <p><mat-icon color="primary">help</mat-icon>
                    El Factor de Autenticación agrega una capa de seguridad adicional a la cuenta
                     mediante autenticación de múltiples factores. Aquí, puedes administrar la vinculación
                      de aplicaciones de autenticación o recibir códigos por correo para verificar tu identidad.
                    </p>
                  <b>Acciones disponibles: </b>
           
                  <p>
                    <mat-icon class="kobra-icon">refresh</mat-icon><b> Refrescar: </b> Actualiza la lista de factores de autenticación configurados para el usuario.
                  </p>
                  <p>
                    <mat-icon class="kobra-icon">swap_vert</mat-icon><b> Activar: </b> Cambia el estado de los factores de autenticación activando o desactivando la autenticación en dos pasos (2FA) según sea necesario.
                  </p>
                 
                  
                </mat-card-content>
              </mat-card>
              </div> 
          </app-table>
          <!-- Contenedor para mostrar QR y campo OTP al iniciar vinculación -->
          <div *ngIf="mostrarQR" class="otp-container">
            <p>Escanea este código QR en tu aplicación de Autenticación:</p>
            <img [src]="qrCodeUrl" alt="QR Code " style="margin: 0 auto;" />
            <mat-form-field class="input-conf" style="width: 20%; margin-top: 16px;">
              <mat-label>Código OTP</mat-label>
              <input 
                matInput 
                maxlength="6" 
                type="text" 
                inputmode="numeric" 
                [(ngModel)]="otpCode" 
                placeholder="Ingrese el código OTP" 
                validateNumericInput>
            </mat-form-field>
            
            <div style="display: flex; gap: 10px;">
              <button mat-flat-button class="boton-cancelar"   (click)="cancelarVinculacion()">
                <mat-icon class="kobra-icon">close</mat-icon>Cancelar
              </button>
              <button mat-stroked-button class="boton-accion-guardar"  (click)="confirmarOTP(otpCode)">
                <mat-icon>
                  save
                </mat-icon>Guardar
              </button>
            </div>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-card-content>
  </mat-card>
</div>