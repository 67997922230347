import { Injectable } from '@angular/core';
import { Observable } from "rxjs";

import { Lender } from "./lender.model";
import { PaqueteFinanciera } from "../paquete-financiera/paquete-financiera.model";
import { GenericReservedActiveResponse } from "@reservedActive/generic-reserved-active";
import { GenericReservedActive } from "@reservedActive/generic-reserved-active";
import { ServicesKobraService } from "../core/http/services-kobra/services-kobra.service";
import { ServiciosSaldosKobraService } from "../core/http/servicios-saldos-kobra/servicios-saldos-kobra.service";
import { ApiKobraService } from "../core/http/api-kobra/api-kobra.service";
import { ServiciosKobraService } from "@servicios/servicios-kobra.service";
import { map } from "rxjs/operators";
import { Tag } from '@lender/tag.model';
import { PaymentMethod } from '@lender/payment-method.model';
import { ServicesVisitasService } from '@servicesVisitas/services-visitas.service';
import { ClientService } from 'src/app/core/http/client/client.service';
import { StorageKobraService } from 'src/app/core/http/storage-kobra/storage-kobra.service';
import { environmentSelector } from '../../environments/environment.selector';
import { IServicesTypeKobraResponse } from '../core/http/servicio-general/servicio-general-response';

@Injectable()
export class LenderService {
  private serviceURL: string = '/financieras';
  private serviciosSaldosURL: string = '/financieras';
  private resourceAppsServices: string = '/clientes';
  private resourceVisitsServices: string = '/clientes';

  constructor(
    private apiKobra: ApiKobraService,
    private servicesKobra: ServicesKobraService,
    private serviciosSaldosKobra: ServiciosSaldosKobraService,
    private serviciosKobraService: ServiciosKobraService,
    private visitasServ: ServicesVisitasService,
    private clientService: ClientService,
    private storageService: StorageKobraService
  ) { }

  public getAll(status = ''): Observable<Lender[]> {
    return this.getLenders(status).pipe(map(res => res.data.map(l => ({
      id: l.idFinanciera,
      idFinanciera: l.idFinanciera,
      idCliente: l.idCliente,
      name: l.nombre + (Boolean(l.deleted) ? ' (inactiva)' : ''),
      nombre: l.nombre + (Boolean(l.deleted) ? ' (inactiva)' : ''),
      razonSocial: l.razonSocial,
      email: l.email,
      sitioWeb: l.sitioWeb,
      nombreEncargado: l.nombreEncargado,
      financieraDemo:l.financieraDemo,
      activa: !l.deleted
    }))));
  }

  public one( id: number ): Observable<Lender> {
    return this.serviciosKobraService.get(`${this.resourceAppsServices}?idFinanciera=${id}&status=all`).pipe(
      map((res) => <Lender> res.data[0])
    );
  }

  public paqueteContratado( lenderID: number): Observable<PaqueteFinanciera> {
    return this.servicesKobra
      .get(`${this.serviceURL}/${lenderID}/paquetes-contratados?actual=1`).pipe(
        map((res) => <PaqueteFinanciera> res.data)
      );
  }

  public obtenerPaquetes( lenderID: number, activo: number): Observable<PaqueteFinanciera[]> {
    let uri = `${this.serviciosSaldosURL}/${lenderID}/paquetes`;

    if( activo != null ) {
      uri += '?activo=' + activo;
    }

    return this.serviciosSaldosKobra
      .get(uri).pipe(
        map((res) => <PaqueteFinanciera[]> res.data)
      );
  }

  public agregarPaquete( paqueteFinanciera: PaqueteFinanciera ): Observable<PaqueteFinanciera> {
    return this.serviciosSaldosKobra.post(this.serviceURL + '/' + paqueteFinanciera.idFinanciera + '/financieras-paquetes', paqueteFinanciera).pipe(
      map((res) => <PaqueteFinanciera> res.data)
    )
  }

  public cancelarPaquete( idFinanciera: number, idFinancieraPaquete: number ): Observable<any> {
    return this.serviciosSaldosKobra.delete(this.serviceURL + '/' + idFinanciera + '/financieras-paquetes/' + idFinancieraPaquete).pipe(
      map((res) => <PaqueteFinanciera> res.data)
    )
  }

  public paquetesContratados( lenderID: number): Observable<PaqueteFinanciera[]> {
    return this.servicesKobra.get(`${this.serviceURL}/${lenderID}/paquetes-contratados`).pipe(
      map((res) => <PaqueteFinanciera[]> res.data)
    );
  }

  public getLinkedAgents( lenderID: number): Observable<GenericReservedActive> {
    return this.visitasServ.get(`${this.resourceVisitsServices}/${lenderID}/agentes-cuentas-apartadas-activas`).pipe(
      map(res => {
        let sumActivas = 0;
        let sumApartadas = 0;
        let genericsReservedActiveResponse: GenericReservedActiveResponse[] = [];

        for (let i = 0; i < res.data.length; i++) {
            sumActivas += +res.data[i].totalCuentasActivas;
            sumApartadas += +res.data[i].totalCuentasApartadas;

            let agentReservedActiveResponse: GenericReservedActiveResponse = {
              idCobrador: res.data[i].idCobrador,
              nombre: res.data[i].nombreAgente,
              totalActivas: +res.data[i].totalCuentasActivas,
              totalApartadas: +res.data[i].totalCuentasApartadas
            };

            genericsReservedActiveResponse.push(agentReservedActiveResponse);
        }

        let stateReservedActive: GenericReservedActive = {
          genericsReservedActiveResponse: genericsReservedActiveResponse,
          sumActivas: sumActivas,
          sumApartadas: sumApartadas,
        };

        return stateReservedActive;
      })
    );
  }

  public getTags( lenderID: number, idProduct: number): Observable<Tag[]> {
    return this.serviciosKobraService.get(`${this.resourceAppsServices}/${lenderID}/tags?idProduct=${idProduct}`).pipe(
      map(res => {
        let tags: Tag[] = [];
        for (let i = 0; i < res.data.length; i++) {
          let tag : Tag = {
            id: +res.data[i].idTag,
            name: res.data[i].name
          }
          tags.push(tag);
        }
        return tags;
      })
    );
  }

  public getUsers(lenderID: number): Observable<any> {
    return this.serviciosKobraService.get(`${this.resourceAppsServices}/${lenderID}/usuarios`).pipe(
      map(res => res.data)
    );
  }

  //obtiene los metodos de pago
  public getPaymentMethods( lenderID: number): Observable<Tag[]> {
    return this.serviciosKobraService.get(`${this.resourceAppsServices}/${lenderID}/metodos-pago`).pipe(
      map(res => {
        let paymentMethods: PaymentMethod[] = [];
        for (let i = 0; i < res.data.length; i++) {
          let paymentMethod : PaymentMethod = {
            id: +res.data[i].idMetodoPago,
            name: res.data[i].nombre
          }
          paymentMethods.push(paymentMethod);
        }
        return paymentMethods;
      })
    );
  }


  //obtiene las cuentas apartadas activas por Cliente
  public getAccountReservedActive(idCliente: any): Observable<GenericReservedActive> {
    let query: string = ''

    if (idCliente) {
      query += '?idCliente=' + idCliente;
    }

    return this.visitasServ.get(this.resourceVisitsServices + '/cuentas-apartadas-activas' + query).pipe(
      map(res => {

        let sumActivas = 0;
        let sumApartadas = 0;
        let genericsReservedActiveResponse: GenericReservedActiveResponse[] = [];

        for (let i = 0; i < res.data.length; i++) {
            sumActivas += +res.data[i].totalCuentasActivas;
            sumApartadas += +res.data[i].totalCuentasApartadas;

            let porcentage = 0;

            if ( +res.data[i].totalCuentasActivas && +res.data[i].totalCuentasApartadas) {
              porcentage = (+res.data[i].totalCuentasApartadas * 100 /(+res.data[i].totalCuentasActivas + +res.data[i].totalCuentasApartadas))
            }

            let genericReservedActiveResponse: GenericReservedActiveResponse = {
              id: res.data[i].idCliente,
              nombre: res.data[i].nombreFinanciera,
              totalActivas: +res.data[i].totalCuentasActivas,
              totalApartadas: +res.data[i].totalCuentasApartadas,
              porcentajeApartadas: porcentage
            };

            genericsReservedActiveResponse.push(genericReservedActiveResponse);
        }

        let sumPorcentage = 0;

        if ( +sumApartadas && sumActivas) {
          sumPorcentage = (+sumApartadas * 100 /(+sumApartadas + sumActivas))
        }

        let lenderReservedActive: GenericReservedActive = {
          genericsReservedActiveResponse: genericsReservedActiveResponse,
          sumActivas: sumActivas,
          sumApartadas: sumApartadas,
          sumPorcentajeApartadas: sumPorcentage
        };

        return lenderReservedActive;
      })
    );
  }

  getLenders(status: string = '') {
    return this.serviciosKobraService.get(`${this.resourceAppsServices}?status=${status}`);
  }

  changeLender(lender: Lender) {
    return this.serviciosKobraService.post('/cambiar-cliente', {
      idFinanciera: lender.idFinanciera
    }).pipe(
      map(res => res.data)
    );
  }

  obtenerConfigCliente(idClientes: Array<number>) {
    return this.visitasServ.post(`/clientes/configuraciones/dia-vencimiento`,
    {
      'idClientes': idClientes
    });
  }

  obtenerProductos() {
    return this.visitasServ.get(`/clientes/productos`);
  }

  obtenerProductosCliente(idCliente: number = 0, query:any = '') {
    return this.visitasServ.get(`/clientes/${idCliente}/productos${query}`);
  }

  obtenerTiposCredito() {
    return this.visitasServ.get('/clientes/creditos/tipos');
  }

  obtenerTiposCreditoCliente(idCliente) {
    return this.visitasServ.get('/clientes/'+idCliente+'/creditos/tipos');
  }

  obtenerFinanciera( idFinanciera: number ) {
    return this.serviciosKobraService.get('/clientes?idFinanciera=' + idFinanciera);
  }

  obtenerMovimientosPdf(idFinanciera, fechaInicio, fechaFin, columnas){
    let queryParams = `?token=${localStorage.getItem('access-token')}&idFinanciera=${idFinanciera}&fechaInicio=${fechaInicio}&fechaFin=${fechaFin}&columnas=${columnas}`;

    let country = "&Country=";
    country+= environmentSelector().country;
    window.open(`${this.serviciosSaldosKobra.baseURL}/estado-cuenta-pdf${queryParams}${country}`);
  }

  getClientGruop(params: string) {
    return this.clientService.get(`/clientes-grupos-grid${params}`);
  }

  addClient(data: any) {
    return this.clientService.post('/nuevo', data).pipe(map(res => res.data));
  }

  activateClient(idLender: number) {
    return this.serviciosKobraService.patch(`/clientes/${idLender}/activar-financiera`, {}).pipe(map(res => res.data));
  }

  inactivateClient(idLender: number) {
    return this.serviciosKobraService.delete(`/clientes/${idLender}`).pipe(map(res => res.data));
  }

  uploadLogo(file: File, idLender: number) {
    const formData = new FormData();

    formData.append('logo', file);

    return this.storageService.uploadFile(`/clientes/${idLender}/logo`, formData).pipe(map(res => res.data));
  }

  descargarEtiquetasVisitas(pParams: string){
    return this.visitasServ.getFile(`/clientes/clientes-etiquetas-visitas-download${pParams}`)
  }

  getConfiguration(idLender: number) {
    return this.serviciosKobraService.get(`/clientes/${idLender}/configuraciones-alta`).pipe(map(res => res.data));
  }

  getConfiguracionSaldos(idLender: number){
    return this.serviciosSaldosKobra.get(`/financieras/${idLender}/configuraciones-saldos`).pipe(map(res => res.data))
  }

  getConfiguracionChecklist(idCliente: number, idChecklist: number){
    return this.visitasServ.get(`/clientes/${idCliente}/checklists/${idChecklist}/configuraciones-cuestionario`).pipe(map(res=> res.data))
  }

  getProducts(idLender: number) {
    return this.serviciosKobraService.get(`/clientes/${idLender}/productos`).pipe(
      map((res) => res.data.map(d => ({
        id: d.idProducto,
        name: d.nomProducto
      })))
    );
  }

  getAdministradoresEncargados(idFinanciera: number){
    return this.serviciosKobraService.get(`/clientes/${idFinanciera}/encargados`).pipe(map(res => res.data))
  }

  getEjecutivos360(){
    return this.serviciosKobraService.get(`/ejecutivos-360`).pipe(map(res => res.data))
  }

  guardarEncargadosCliente(idFinanciera: number, encargados: any){
    return this.serviciosKobraService.post(`/cliente/${idFinanciera}/encargados`, encargados)
      .pipe(map(res => res.data));
  }

  saveConfiguration(idLender: number, configuration: any) {
    return this.serviciosKobraService.patch(`/clientes/${idLender}/configuraciones-alta`, {
      configuraciones: configuration
    }).pipe(map(res => res.data));
  }

  saveConfiguracionSaldos(idLender: number, configuration: any) {
    return this.serviciosSaldosKobra.put(`/financieras/${idLender}/configuraciones-saldos`, {
      configuraciones: configuration
    }).pipe(map(res => res.data));
  }

  saveConfiguracionChecklist(idCliente: number, idChecklist: number, configuraciones: any) {
    return this.visitasServ.put(`/clientes/${idCliente}/checklists/${idChecklist}/configuraciones-cuestionario`, {
      configuraciones
    }).pipe(map(res => res.data));
  }



  getLayoutFieldsByProduct(idCliente: number, idProducto: number) {
    return this.serviciosKobraService.get(`/clientes/${idCliente}/campos-productos/${idProducto}`).pipe(map(res => res.data.map(field => ({
      id: field.idCuentaCampo,
      key: field.clave,
      name: field.descripcion,
      required: field.requerido,
      idFieldType: field.idCuentaCampoTipoDato,
      showOnApp: field.mostrarEnApp,
      customized: field.personalizado
    }))));
  }

  getLayoutFieldsByProductDefault(idCliente: number, idProducto: number) {
    return this.serviciosKobraService.get(`/clientes/${idCliente}/campos-productos-default/${idProducto}`).pipe(map(res => res.data.map(field => ({
      id: field.idCuentaCampo,
      key: field.clave,
      name: field.descripcion + ' (' + field.clave + ')' ,
      nameValor: field.descripcion,
      required: field.requerido,
      idFieldType: field.idCuentaCampoTipoDato,
      showOnApp: field.mostrarEnApp,
      customized: field.personalizado
    }))));
  }

  saveLayoutFields(idFinanciera: number, idProducto: number, fields: Array<any>) {
    return this.serviciosKobraService
    .patch(`/clientes/${idFinanciera}/producto/${idProducto}/layout`, {
      campos: fields
    }).pipe(map(res => res.data));
  }

  deleteLayoutFields(idFinanciera: number, idProducto: number, idCuentaCampo: number): Observable<any> {
    return this.serviciosKobraService
      .delete(`/clientes/${idFinanciera}/productos/${idProducto}/layout/${idCuentaCampo}`).pipe(map(res => res.data));
  }

  getAllChecklistsByProduct(idFinanciera: number, idProducto: number) {
    return this.serviciosKobraService.get(`/clientes/${idFinanciera}/checklists-producto/${idProducto}`).pipe(map(res => res.data.map(checklist => ({
      id: checklist.idChecklist,
      client: {
        id: checklist.idCliente
      },
      product: {
        id: checklist.idProducto
      },
      name: checklist.nombre,
      version: checklist.numVersion,
      active: checklist.activo,
      date: checklist.fecha
    }))));
  }

  getChecklistByProduct(idFinanciera: number, idProducto: number, idTipoCredito: number = null) {
    let url = `/clientes/${idFinanciera}/producto/${idProducto}/checklist`;
    if(idTipoCredito){
      url += `?idTipoCredito=${idTipoCredito}`;
    }
    return this.visitasServ.get(url)
      .pipe(map(res => res.data));
  }

  saveChecklist(idCliente: number, checklist: any) {
    return this.visitasServ.post(`/clientes/${idCliente}/checklists`, checklist)
      .pipe(map(res => res.data));
  }

  obtenerClienteRedInterna(idCliente: number) {
    return this.visitasServ.get(`/clientes/${idCliente}/verificar-red-interna`);
  }

  public obtenerCamposExtras<T = any>( idCliente: number ): Observable<T> {
    return this.visitasServ.get<T>(`${this.resourceAppsServices}/${idCliente}/campos-extras-agentes`).pipe(
      map((res) => <T> res.data)
    );
  }
}
