import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environmentSelector } from '../../../../environments/environment.selector';
import { IServicesTypeKobraResponse } from './servicio-general-response';
@Injectable({
  providedIn: 'root'
})
export class ServicesGeneralService {
  protected propertie: string = 'servicios'
  protected baseURL: string = environmentSelector().kobraServices[this.propertie];
  constructor(
    private httpClient: HttpClient,
  ) { }

  public reloadBaseUrl(archivo:string = ""){
    this.baseURL = environmentSelector(archivo).kobraServices[this.propertie];
  }

  private headers(login: boolean = false): HttpHeaders {
    let header: any = { 'Content-Type': 'application/json' };
    let token = localStorage.getItem('access-token');
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');
    let ipReal = localStorage.getItem("ipPublica");

    if(!login && (!token && paisSeleccionado)){
      localStorage.clear();
      sessionStorage.clear();
      location.href = '/auth';
    }

    if (token) {
      header['Authorization'] = token;
    }

    header['Msk-Real-Ipv4'] = ipReal || '';
    header['Country'] = paisSeleccionado ? JSON.parse(paisSeleccionado).abreviacion : 'mx';


    return new HttpHeaders(header);
  }

   private headersFiles(): HttpHeaders {
    let header: any = {};
    let token = localStorage.getItem('access-token');
    let paisSeleccionado = localStorage.getItem('paisSeleccionado');

    if(!token && paisSeleccionado){
      localStorage.clear();
      sessionStorage.clear();
      location.href = '/auth';
    }

    if (token) {
      header['Authorization'] = token;
    }

    header['Country'] = JSON.parse(paisSeleccionado).abreviacion;

    return new HttpHeaders(header);
  }


  private request<T>(req: Observable<any>, login: boolean = false): Observable<IServicesTypeKobraResponse<T>> {

    return req.pipe(

      map(data => {
        let res = <IServicesTypeKobraResponse<T>>data;
        return res;
      }),
      catchError((err: HttpErrorResponse | string) => {
        var message;
        if (err instanceof HttpErrorResponse) {
          if (err.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', err.error.message);
            message = err.error.message;
          } else {
            if (err.status == 401) { // Session has expired
              localStorage.clear();
              sessionStorage.clear();
              location.href = '/auth';
            }
            else if (err.error && err.error.error && err.error.error.message) {
              message = err.error.error.message;
            }
            else if (err.status == 404) {
              message = 'Error 404: not found.';
            }
            else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              message = err.error.error.message || 'Refresca la página e intentalo de nuevo.';
            }
          }
        } else {
          message = err;
        }
        return throwError(message);
      })
    );
  }

  public delete<T = any>(path: string): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.delete(this.baseURL + path, { headers: this.headers() }));
  }

  public get<T = any>(path: string): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.get(this.baseURL + path, { headers: this.headers() }));
  }

  public patch<T = any>(path: string, data: any): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.patch(this.baseURL + path, data, { headers: this.headers() }));
  }

  public put<T = any>(path: string, data: any): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.put(this.baseURL + path, data, { headers: this.headers() }));
  }

  public post<T = any>(path: string, data: any, login: boolean = false): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.post(this.baseURL + path, data, { headers: this.headers(login) }), login);
  }

  public getFile( path: string ): Observable<HttpEvent<Blob>> {
    return this.httpClient.get(this.baseURL + path, {headers: this.headersFiles(),  responseType: 'blob',
    reportProgress: true,
    observe: 'events'});
  }

  public uploadFile<T = any>(path: string, formData: FormData): Observable<IServicesTypeKobraResponse<T>> {
    return this.request<T>(this.httpClient.post(this.baseURL + path, formData, { headers: this.headersFiles() }));
  }

  public download(path: string): Observable<Blob> {
    return this.httpClient.get(this.baseURL + path, {
      headers: this.headersFiles(),
      responseType: 'blob'
    });
  }



}
