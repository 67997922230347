import { Component, Input, ElementRef, AfterViewInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { TooltipOverlayService } from './tooltip-overlay.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'kobra-tooltip',
  template: `<!-- No content needed here -->`
})
export class KobraTooltipComponent implements AfterViewInit {
  @Input() component: string | ElementRef;
  @Input() tooltipContent: string | SafeHtml;
  overlayRef = null;
  element: HTMLElement;

  constructor(
    private tooltipService: TooltipOverlayService,
    private sanitizer: DomSanitizer,
    private viewContainerRef: ViewContainerRef ) {}

  ngAfterViewInit() {
    this.createElement();
    const safeContent = this.sanitizer.bypassSecurityTrustHtml(this.tooltipContent as string);
    this.element.addEventListener('mouseenter', () => {
      this.overlayRef = this.tooltipService.showTooltip(this.element, safeContent, this.viewContainerRef);
    });
    this.element.addEventListener('mouseleave', () => {
      if(this.overlayRef){
        this.overlayRef.dispose();
        { once: true };
        this.overlayRef = null;
      }
    });
  }

  // Determina si el input es un id o un ElementRef y obtiene el elemento correspondiente
  createElement(){
    if (typeof this.component === 'string') {
      this.element = document.getElementById(this.component);
      if (!this.element) {
        console.error(`Element not found with id: ${this.component}`);
        return;
      }
    } else {
      this.element = (this.component as ElementRef).nativeElement;
    }
  }
}
