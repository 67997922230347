import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { ConceptoSaldoPagination } from './conceptos-saldo.interface';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { ConceptosEtiquetasVisitasService } from './conceptos-etiquetas-visitas.service';
import { take } from 'rxjs';
import { ConceptosEtiquetasVisitas } from './conceptos-etiquetas-visitas.model';

@Component({
  selector: 'app-conceptos-etiquetas-visitas',
  templateUrl: './conceptos-etiquetas-visitas.component.html',
  styleUrls: ['./conceptos-etiquetas-visitas.component.css']
})
export class ConceptosEtiquetasVisitasComponent implements OnInit {
  private routerSubscription: Subscription;

  constructor(
    private app: ApplicationService,
    private filtrosService: FiltrosService,
    public conceptosEtiquetasVisitasService: ConceptosEtiquetasVisitasService,
    private router: Router
  ) {
    this.crearTabla();
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();
  }


  conceptos: any;
  public pageSize = 50;
  hijosActivos: boolean = false;
  cargandoLista: boolean = false;
  filtrosAbiertos: boolean = false;
  ayuda: boolean = false;
  public pagination: ConceptoSaldoPagination;
  public totalFiltros: number = 0;
  public contabilizadorFiltros: FiltersContabilizer;
  private slt: string = '*';


  tabla: any;

  public filters: any = {
    financiera: null,
    nombreConcepto: null,
    activa: true,
    excluirDemo: false,
    filtered: false,
    page: 1
  }

  public datosConsulta: any = {
    pageSize: 50,
    rows: 50,
    sortField: 'idEtiquetaVisita',
    sortAsc: true,
    query: "",
    fromRowPage: 0,
    toRowPage: 50
  }

  public loadings: any = {
    conceptosProductos: true,
    borrando: false,
    financieras: true
  }



  private agregarContabilizadoresDeFiltros() {
    this.contabilizadorFiltros.add('Generales', 'generales', [
      'nombreConcepto'
    ]);
  }

  public contabilizadorDeFiltros(filtroName: string) {
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
  }

  public contabilizadorDeFiltrosTotales(): void {
    let totalFiltrosArray: number[] = [];
    totalFiltrosArray.push(this.contabilizadorDeFiltros('generales'));
    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  }


  crearTabla() {
    this.tabla = {
      headers: false,
      multiSelect: false,
      columns: [
        {
          name: 'ID Etiqueta Visita',
          key: 'idEtiquetaVisita',
          hide: false
        },
        {
          name: 'ID Concepto',
          key: 'idConcepto',
          hide: false
        },
        {
          name: 'Concepto',
          key: 'nombre',
          hide: false
        }
      ]
    }
  }

  ngOnInit(): void {
  
        this.obtenerConceptosEtiquetasSaldos(this.filters.page ? this.filters.page : 1);
  
  }

  private crearURLQuery(page: number): string {
    let queryParams: string = '';
    let guardarFiltros: boolean = false;
    if (page != this.filters.page) {
      this.filters.page = page;
      guardarFiltros = true;
    }

    queryParams += `?slt=${this.slt}&paginate=1&page=${page}&rows=${this.datosConsulta.rows}`;

    if (this.datosConsulta.sortField) {
      queryParams += '&orderBy=' + this.datosConsulta.sortField + (this.datosConsulta.sortAsc ? ' asc' : ' desc');
    }

    if (this.filters.nombreConcepto) {
      queryParams += `&nombreConcepto=${this.filters.nombreConcepto}`;
      this.filters.filtered = true;
      guardarFiltros = true
    }

    if (guardarFiltros) {
      this.filtrosService.guardarFiltros(this.filters);
    }

    return queryParams;
  }


  public obtenerConceptosEtiquetasSaldos(page: number) {
    this.cargandoLista = true;
    let query: string = this.crearURLQuery(page);
    this.conceptosEtiquetasVisitasService.obtenerConceptosEtiquetasPaginado(query)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.loadings.conceptosProductos = false;
        this.pagination = res.data;
        this.conceptos = ConceptosEtiquetasVisitas.mapArray(res.data.pageData);
        this.calcFromToRowPage();
        this.cargandoLista = false;
        this.contabilizadorDeFiltrosTotales();
      }, (err: any) => {
        this.loadings.conceptosProductos = false;
        this.app.showError(err);
        this.cargandoLista = false;

      });
    this.contabilizadorDeFiltrosTotales();
  }

  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.datosConsulta.fromRowPage = 0;
      this.datosConsulta.toRowPage = 0;
    } else {
      this.datosConsulta.fromRowPage = (this.datosConsulta.rows * this.pagination.page) - (this.datosConsulta.rows - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.datosConsulta.toRowPage = this.pagination.totalRows;
      } else {
        this.datosConsulta.toRowPage = this.pagination.page * this.datosConsulta.rows;
      }
    }
  }

  inactivarHijo() {
    this.obtenerConceptosEtiquetasSaldos(this.filters.page ? this.filters.page : 1);

  }

  setSortField(sort: any) {

    if (sort.active == this.datosConsulta.sortField) {
      this.datosConsulta.sortAsc = !this.datosConsulta.sortAsc;
    } else {
      this.datosConsulta.sortField = sort.active;
      this.datosConsulta.sortAsc = true;
    }
    this.obtenerConceptosEtiquetasSaldos(1);
  }

  onPage(newPagina: any): void {
    if (newPagina.pageIndex + 1 !== this.pagination.page || newPagina.pageSize !== this.datosConsulta.rows) {
      this.datosConsulta.rows = newPagina.pageSize;
      this.obtenerConceptosEtiquetasSaldos(newPagina.pageIndex + 1);
    }
  }

  public filtrar(page: number) {
    this.obtenerConceptosEtiquetasSaldos(page);
  }

  public limpiarFiltros() {
    this.filters = {
      financiera: null,
      nombreConcepto: null,
      activa: true,
      excluirDemo: false,
      filtered: false,
      page: 1
    }

  }
}
