<form  [formGroup]="form" (ngSubmit)="submit()">
  <app-loader *ngIf="cargando"></app-loader>
  <div style="margin-bottom: 15px;">
    <mat-form-field  style="width: 40%;" *ngIf="!cargando" >
      <mat-label>Paquete</mat-label>
      <mat-select formControlName="idPaquete" (ngModelChange)="actualizarSaldo($event)" >
        <mat-option *ngFor="let paquete of paquetes" [value]="paquete.idPaquete" >
          {{paquete.nombre}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="idPaqueteValidation.hasError() && form.get('saldoInicial').value != 0 && mostrarSaldo">
        El paquete es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div style="margin-bottom: 15px;" *ngIf="mostrarSaldo">
    <mat-form-field floatLabel="always">
      <mat-label>Saldo</mat-label>
      <input formControlName="saldoInicial" matInput textSanitizer  type="number" class="example-right-align" placeholder="0">
      <span matTextPrefix>$&nbsp;</span>
      <mat-error *ngIf="saldoInicialValidation.hasError()">
        El saldo es <strong>requerido</strong>
      </mat-error>
    </mat-form-field>
  </div>
  <div>
    <app-loader *ngIf="cargandoConcepto"></app-loader>
  </div>
  <div *ngIf="!cargandoConcepto" formArrayName="items" class="kobra three columns" >
    <!-- <div  class="field" *ngFor="let item of form.get('items')['controls']; let i = index;" [ngClass]="{error: form.controls.items.controls[i].controls.precio.errors}"> -->
    <div  class="kobra column" *ngFor="let item of form.get('items')['controls']; let i = index;" [ngClass]="{error: form.get('items')['controls'][i].get('precio').errors}">
      <mat-form-field  [formGroupName]="i">
        <mat-label>{{ form.get('items')['controls'][i].get('nombre').value }}</mat-label>
        <input matInput textSanitizer  type="number"
          class="no-border example-right-align"
          min="0"
          max="99999"
          (input)="onInputLimite(i)"
          [id]="form.get('items')['controls'][i].get('idConcepto').value"
          formControlName="precio"
          placeholder="Capture el costo del concepto">
        <span matTextPrefix>$&nbsp;</span>
        <mat-icon matSuffix color="warn" class="delete-icon"
          matTooltip="Borrar concepto"
          mat-tooltip-panel-above
          (click)="borrarConcepto(item, i)"
        >
          delete
        </mat-icon>
        <mat-error>El monto debe ser un número mayor a cero y menor a 99999.</mat-error>
      </mat-form-field>
    </div>

    <div class="kobra column" *ngIf="items && form.get('idPaquete').value">

      <mat-form-field appearance="fill">
        <input matInput placeholder="Buscar concepto" [formControl]="searchControl" [matAutocomplete]="auto">
        
        <mat-icon matSuffix class="icono-limpiar-solo"
        *ngIf="searchControl.value"
        (click)="limpiarBusqueda()">clear</mat-icon>

        <mat-autocomplete #auto="matAutocomplete" 
          (optionSelected)="onConceptoSelected($event)">
          <!-- Sección colapsable para conceptosProducto -->
          <mat-expansion-panel *ngIf="filteredConceptosProducto.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title><strong>Conceptos Producto</strong></mat-panel-title>
            </mat-expansion-panel-header>
            <mat-option *ngFor="let producto of filteredConceptosProducto" [value]="producto">
              {{ producto.nombreConcepto }}
            </mat-option>
          </mat-expansion-panel>
    
          <!-- Sección colapsable para conceptosEtiquetaVisita -->
          <mat-expansion-panel *ngIf="filteredConceptosEtiquetaVisita.length > 0">
            <mat-expansion-panel-header>
              <mat-panel-title><strong>Conceptos Etiqueta Visita</strong></mat-panel-title>
            </mat-expansion-panel-header>
            <mat-option *ngFor="let etiqueta of filteredConceptosEtiquetaVisita" [value]="etiqueta">
              {{ etiqueta.nombreConcepto }}
            </mat-option>
          </mat-expansion-panel>
        </mat-autocomplete>
      </mat-form-field>

    </div>
    
  </div>

  <div *ngIf="showAddButton" class="field" align="right">
    <!-- <button type="button" class="ui button">Cancelar</button> -->
    <button mat-stroked-button [ngClass]="{'boton-guardar': activarBoton && form.get('idPaquete').value , 'buton-disabled': form.invalid || form.get('items').invalid}" [disabled]="form.invalid || form.get('items').invalid "><mat-icon>save</mat-icon> Agregar</button>
  </div>
</form>
