<mat-form-field *ngIf="type === 'string'" class="field" [formGroup]="fieldForm">
  <mat-label>{{ label || placeholder }}</mat-label>

  <input
    #textInput
  	matInput textSanitizer
    [matTooltip]="placeholder"
    mat-tooltip-panel-above
  	formControlName="value"
    ngDefaultControl
    [maxLength]="maxLenghtOptions"
  	[required]="required"
  />

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">
    Este campo es requerido
  </mat-error>

  <mat-error *ngIf="fieldForm.get('value').hasError('maxlength')">
    El campo acepta máximo {{fieldForm.get('value').errors['maxlength'].requiredLength}} caracteres
  </mat-error>

</mat-form-field>

<mat-form-field *ngIf="type === 'choice'" class="field" [formGroup]="fieldForm">
  <mat-label>{{ label || placeholder }}</mat-label>

  <mat-select
    formControlName="value"
    ngDefaultControl
    [matTooltip]="placeholder"
    mat-tooltip-panel-above
    name="item"
    [required]="required"

  >
    <mat-option [value]="null">
      Seleccione
    </mat-option>
    <mat-option *ngFor="let item of items" [value]="item['value']">
      {{item['name']}}
    </mat-option>
  </mat-select>

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">Este campo es requerido</mat-error>
</mat-form-field>

<mat-form-field *ngIf="type === 'number'" class="field" [formGroup]="fieldForm">
  <mat-label>{{ label || placeholder }}</mat-label>

  <input
    matInput decimalValidator
    [decimalPlaces]="0"
    type="number"
    formControlName="value"
    ngDefaultControl
    [matTooltip]="placeholder"
    mat-tooltip-panel-above
     max="maxValue"
     #textInput
    (input)="onInputLimite()"
    [required]="required"
    [disabled]="disabled"
  />

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">Este campo es requerido</mat-error>
</mat-form-field>

<mat-form-field *ngIf="type === 'decimal'" class="field" [formGroup]="fieldForm">
  <mat-label>{{ label || placeholder }}</mat-label>

  <input
    matInput decimalValidator
    [decimalPlaces]="6"
    type="number"
    formControlName="value"
    ngDefaultControl
    [matTooltip]="placeholder"
    mat-tooltip-panel-above
     max="maxValue"
     #textInput
    (input)="onInputLimite()"
    [required]="required"
    [disabled]="disabled"
  />

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">Este campo es requerido</mat-error>
</mat-form-field>

<ng-container *ngIf="type === 'boolean'" [formGroup]="fieldForm">
  <mat-checkbox formControlName="value"
  [matTooltip]="placeholder"
  mat-tooltip-panel-above
  ngDefaultControl class="kobra-checkbox">{{ label || placeholder }}</mat-checkbox>
</ng-container>

<mat-form-field *ngIf="type === 'upload'" class="field" [formGroup]="fieldForm" (click)="fileInput.click()" appFileDragDrop (drop)="onDrop($event)">
  <mat-label>{{ label || placeholder }}</mat-label>

  <button *ngIf="fieldForm.get('value').value" mat-icon-button matPrefix (click)="removeFile($event); $event.stopPropagation();">
    <mat-icon>close</mat-icon>
  </button>

  <input hidden type="file" [accept]="fileAccept.join(',')" [multiple]="false" (change)="onFileSelected($event)" #fileInput [required]="required" />

  <input matInput textSanitizer  disabled [value]="fieldForm.get('value').value?.name" [required]="required" />

  <button mat-icon-button matSuffix [matTooltip]="placeholder"
  mat-tooltip-panel-above (click)="fileInput.click(); $event.stopPropagation();">
    <mat-icon >attach_file</mat-icon>
  </button>

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">Este campo es requerido</mat-error>

  <mat-error *ngIf="fieldForm.get('value').hasError('upload')"> {{ fieldForm.get('value').getError('upload') }} </mat-error>
</mat-form-field>

<ng-container *ngIf="type === 'toggle'" [formGroup]="fieldForm">
  <mat-slide-toggle [matTooltip]="placeholder"
  mat-tooltip-panel-above formControlName="value" ngDefaultControl class="kobra-slide-toggle">
    <ng-content></ng-content>
  </mat-slide-toggle>
</ng-container>

<mat-form-field *ngIf="type === 'list'" [formGroup]="fieldForm" style="width: 100%;">
  <mat-label>{{ label || placeholder }}</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row *ngFor="let v of fieldForm.get('value').value"
                  (removed)="removeFromList(v)">
      {{v.label}}
      <button matChipRemove *ngIf="!disabledCombo">
        <mat-icon>cancel</mat-icon>
      </button>
    </mat-chip-row>
    <input [placeholder]="placeholder"
          #textInput
          [maxLength]="maxLenghtOptions"
          [matChipInputFor]="chipGrid"
          [matAutocomplete]="auto"
          [disabled]="disabledCombo"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
          [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addToList($event)"/>
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let item of items" [value]="item">
      {{item['label']}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="fieldForm.get('value').hasError('required')">Este campo es requerido</mat-error>
</mat-form-field>

<div *ngIf="type === 'radio'" [formGroup]="fieldForm" style="width: 100%; display: flex; flex-direction: column; margin-bottom: 8px;">
  <mat-label>{{ label || placeholder }}</mat-label>

  <mat-radio-group style="margin: 4px;" [disabled]="disabled" [matTooltip]="placeholder"
  mat-tooltip-panel-above>
    <mat-radio-button
      class="kobra-radio"
      *ngFor="let item of items"
      [checked]="fieldForm.get('value').value === item['value']"
      [value]="item['value']"
      (change)="onRadioGroupChange(item['value'])"
      style="margin-right: 8px;"
    >
      {{item['name']}}
    </mat-radio-button>
  </mat-radio-group>
</div>

<div *ngIf="type === 'checkbox'" [formGroup]="fieldForm" style="width: 100%; margin-bottom: 8px;">
  <mat-label>{{ label || placeholder }}</mat-label>
  <br>
  <ng-container *ngFor="let item of items">
    <mat-checkbox style="margin-left: 15px;" class="kobra-checkbox" [checked]="selectedItemsChecked.includes(item.value)"
    (change)="onCheckBoxChange(item.value)">
      {{ item.name }}
    </mat-checkbox>
  </ng-container>
</div>

<mat-form-field *ngIf="type === 'date'" class="field" [formGroup]="fieldForm">
  <mat-label>{{ label || placeholder }}</mat-label>
  <div class="icon-container">
    <input matInput textSanitizer readonly [matTooltip]="placeholder"
      mat-tooltip-panel-above [matDatepicker]="datePicker" [required]="required" formControlName="value">
    <mat-icon class="clear-icon" *ngIf="hasValue()" (click)="clearValue()">clear</mat-icon>
  </div>
  <mat-datepicker-toggle matSuffix [for]="datePicker"></mat-datepicker-toggle>
  <mat-datepicker #datePicker></mat-datepicker>

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">Este campo es requerido</mat-error>
</mat-form-field>

<mat-form-field *ngIf="type === 'datetime'" class="field" [formGroup]="fieldForm">
  <mat-label>{{ label || placeholder }}</mat-label>
  <div class="icon-container">
    <input matInput textSanitizer [matTooltip]="placeholder"
    mat-tooltip-panel-above [ngxMatDatetimePicker]="dateTimePicker" [required]="required" readonly formControlName="value">
    <mat-icon class="clear-icon" *ngIf="hasValue()" (click)="clearValue()">clear</mat-icon>
  </div>
  <mat-datepicker-toggle matSuffix [for]="$any(dateTimePicker)"></mat-datepicker-toggle>
  <ngx-mat-datetime-picker #dateTimePicker>
    <ngx-mat-datepicker-actions>
        <button class="boton-cancelar" mat-flat-button ngxMatDatepickerCancel>Cancelar</button>
        <button mat-stroked-button color="primary" ngxMatDatepickerApply>Aceptar</button>
    </ngx-mat-datepicker-actions>
  </ngx-mat-datetime-picker>

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">Este campo es requerido</mat-error>
</mat-form-field>

<mat-form-field *ngIf="type === 'time'" class="field" [formGroup]="fieldForm">
  <mat-label>{{ label || placeholder }}</mat-label>
  <div class="icon-container">
    <input matInput textSanitizer readonly [matTooltip]="placeholder"
    mat-tooltip-panel-above [ngxTimepicker]="timePicker" [required]="required" formControlName="value">
    <mat-icon class="clear-icon" *ngIf="hasValue()" (click)="clearValue()">clear</mat-icon>
  </div>

  <ngx-material-timepicker #timePicker></ngx-material-timepicker>

  <mat-error *ngIf="fieldForm.get('value').hasError('required')">Este campo es requerido</mat-error>
</mat-form-field>
