import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { take } from 'rxjs';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { ClientesEtiquetasVisitasService } from 'src/app/catalogos/clientes-etiquetas-visitas/clientes-etiquetas-visitas.service';

@Component({
  selector: 'app-modal-estatus',
  templateUrl: './modal-estatus.component.html',
  styleUrls: ['./modal-estatus.component.css']
})
export class ModalEstatusComponent implements OnInit {
  etiquetasSeleccionadas: any;
  accion: boolean;
  opcion: number;

  constructor(
    public dialogRef: MatDialogRef<ModalEstatusComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private app: ApplicationService,
    private fb: FormBuilder,
    public clientesEtiquetasVisitasService: ClientesEtiquetasVisitasService,

  ) {
  }

  ngOnInit() {
    this.etiquetasSeleccionadas = this.data.itemsSelected
    this.accion = this.data.accion;
    this.opcion = this.data.opcion;

    // Verificar si está vacío y cerrar el modal si es necesario
    if (this.etiquetasSeleccionadas.length === 0) {
      this.cerrarModal(false);
    }
  }

  getClientes(items: any[], activo: boolean): string[] {
    const clientes = items
      .filter(item => item.activo === activo)
      .map(item => item.cliente);
    return [...new Set(clientes)]; // Eliminar duplicados
  }

  getEtiquetasPorCliente(cliente: string, activo: boolean): any[] {
    return this.etiquetasSeleccionadas
      .filter(item => item.cliente === cliente && item.activo === activo);
  }

  cerrarModal(resultado: boolean) {
    this.etiquetasSeleccionadas = []; // Limpia las etiquetas seleccionadas
    this.data.itemsSelected = []; // Limpia las etiquetas seleccionadas
    this.dialogRef.close(resultado); // Cierra el modal
  }

  removerEtiqueta(idEtiquetaVisita: number, idCliente: number, idProducto: number) {
    this.etiquetasSeleccionadas = this.etiquetasSeleccionadas.filter(et =>
      et.idEtiquetaVisita !== idEtiquetaVisita ||
      et.idCliente !== idCliente ||
      et.idProducto !== idProducto
    );

    if (this.etiquetasSeleccionadas.length === 0) {
      this.cerrarModal(false);
    }
  }


  onSubmitEtiquetasSeleccionadas() {
    if (this.etiquetasSeleccionadas && this.etiquetasSeleccionadas.length > 0) {
      this.app.showLoading("Actualizando estatus de etiquetas...");

      // Filtrar las etiquetas según la acción seleccionada (activar o inactivar)
      // Si la acción es activar (this.accion = true), se filtran las etiquetas inactivas (item.activo = false)
      // Si la acción es inactivar (this.accion = false), se filtran las etiquetas activas (item.activo = true)
      const etiquetasFiltradas = this.etiquetasSeleccionadas.filter(et => et.activo !== this.accion);

      if (etiquetasFiltradas.length === 0) {
        this.app.showSnackbar('¡Aviso!', 'No hay etiquetas seleccionadas para actualizar', 3000, 'warning');
        this.app.hideLoading();
        return;
      }

      // Construir el payload para enviar solo las etiquetas filtradas
      const payload = {
        etiquetas: etiquetasFiltradas.map(et => ({
          idEtiquetaVisita: et.idEtiquetaVisita,
          idCliente: et.idCliente,
          idProducto: et.idProducto,
          activo: this.accion ? false : true // Ajustar estatus basado en la acción
        }))
      };

      let servicioLlamada;
      if (this.opcion === 1) {
        servicioLlamada = this.clientesEtiquetasVisitasService.actualizarEstatusEtiquetas(payload);
      } else if (this.opcion === 2) {
        servicioLlamada = this.clientesEtiquetasVisitasService.actualizarEstatusEtiquetasClientes(payload);
      } else {
        this.app.hideLoading();
        this.app.showError('Opción inválida.');
        return;
      }

      // Envía el payload
      servicioLlamada
        .pipe(take(1))
        .subscribe((res: any) => {
          this.app.hideLoading();
          this.app.showSnackbar('¡Aviso!', 'El estatus de las etiquetas se actualizó correctamente', 3000, 'success');
          this.cerrarModal(true); // Cierra el modal después de enviar el payload
        }, (error: any) => {
          this.app.hideLoading();
          this.app.showError(error);
        });
    } else {
      this.app.showSnackbar('¡Aviso!', 'No hay etiquetas seleccionadas para actualizar', 3000, 'warning');
    }
  }


}
