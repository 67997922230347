<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container-wrapper" [ngClass]="{'button-container': !filtros.mostrarMapa, 'button-container-mapa': filtros.mostrarMapa}">

      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy" class="right-align">
          <mat-button-toggle value="filtros"
            (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
            matTooltip="Filtrar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">filter_alt</mat-icon>
            <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>
          </mat-button-toggle>
          <mat-button-toggle value="descargas"
            [disabled]="!listFiltered"
            *ngIf="!filtros.mostrarMapa"
            [matMenuTriggerFor]="menuDescarga"
            matTooltip="Descargar listado"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">cloud_download</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="verMapa"
            *ngIf="!filtros.mostrarMapa"
            (click)="showMap(true)"
            matTooltip="Mostrar en mapa"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">map</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ocultarMapa"
            *ngIf="filtros.mostrarMapa"
            (click)="consultarVisitas()"
            matTooltip="Ocultar mapa"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">map</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            [disabled]="!listFiltered"
            *ngIf="!filtros.mostrarMapa"
            (click)="loadVisits(filtros.page)"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="actualizarMapa"
            *ngIf="filtros.mostrarMapa"
            (click)="consultarVisitasMapa(0, 1); visits = []"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"
            *ngIf="!filtros.mostrarMapa"
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda"
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda">
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>

    <mat-menu #menu="matMenu">
      <button mat-menu-item
        [disabled]="itemsSelected.length == 0"
        *ngIf="!filtros.mostrarMapa"
        (click)="onCalificar()">
        <mat-icon class="kobra-icon">star</mat-icon>
        <span>Calificar</span>
      </button>
      <button mat-menu-item
        [disabled]="itemsSelected.length < 2 "
        *ngIf="!filtros.mostrarMapa"
        (click)="onCalificarMasivas()">
        <mat-icon class="kobra-icon">stars_6</mat-icon>
        <span>Calificar Masiva</span>
      </button>
      <button mat-menu-item
        (click)="LinkCrearVisita()">
        <mat-icon class="kobra-icon">add</mat-icon>
        <span>Nueva visita</span>
      </button>
    </mat-menu>

    <mat-menu #menuDescarga="matMenu">
      <button mat-menu-item
        *ngIf="!filtros.mostrarMapa"
        (click)="descargarListado(0)">
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Sin cuestionario</span>
      </button>
      <button mat-menu-item
        *ngIf="!filtros.mostrarMapa"
        (click)="descargarListado(1)">
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Con cuestionario (Vertical)</span>
      </button>
      <button mat-menu-item
        *ngIf="!filtros.mostrarMapa"
        (click)="descargarListado(2)">
        <mat-icon class="kobra-icon">swap_vert</mat-icon>
        <span>Con cuestionario (Horizontal)</span>
      </button>
    </mat-menu>

    <div class="contenido" [ngStyle]="{'max-height': altoVentana+'px'}" >

      <mat-card *ngIf="ayuda && filtros.mostrarMapa" class="cardAyuda">

        <mat-card-content>
          <p><mat-icon color="primary">help</mat-icon>
            El módulo "Todas las visitas" permite a los usuarios visualizar el resultado completo de todas las gestiones realizadas a las cuentas.
  Esta vista es particularmente valiosa porque ofrece una perspectiva amplia y detallada de las actividades de gestión, asegurando que los usuarios tengan acceso a toda la información relevante en un solo lugar. Los usuarios pueden ver datos críticos de cada visita o gestión, incluyendo las fechas en que se realizaron, comentarios detallados, el agente que estuvo a cargo de la gestión, el domicilio específico donde se llevó a cabo, el tipo de producto que tenía la cuenta al momento de la gestión, si es de cobranza mostrará si la gestión resultó en una promesa de pago, etc.

          </p>
          Acciones:
          <p>
            <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de las visitas realizadas.
          </p>
          <p>
            <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de filtros aplicados.
          </p>
          <p>
            <mat-icon class="kobra-icon">map</mat-icon><b> Mapa: </b> Puedes visualizar las visitas geolocalizadas en un mapa a nivel global.
          </p>
        </mat-card-content>
      </mat-card>

      <app-loader *ngIf="isLoadVisitasMapa" [message]="'Cargando mapa...'"></app-loader>
      <div style="margin-top: 15px" *ngIf="filtros.mostrarMapa && visits && !isLoadVisitasMapa">
        <div style="height: calc(100vh - 314px); overflow-y: hidden;">
          <mapbox-visitas [visitas]="visits" #mapaVisitas></mapbox-visitas>
        </div>
      </div>
    </div>
    <div *ngIf="filtros.mostrarMapa && !loadingVisitas">
      <div>
        <span><b>Mostrando {{visits.length}} visitas</b></span>
      </div>
    </div>

    <app-grid
      *ngIf="!filtros.mostrarMapa"
      [loading]="loadingVisitas"
      [columns]="tablaVisitas.columns"
      [items]="visits"
      [headers]="tablaVisitas.headers"
      [multiSelect]="tablaVisitas.multiSelect"
      (onSelectedRows)="onSelected($event)"
      (onLinkRow)="onClick($event)"
      (sortChange)="setSortField($event)"
      (onCtrlClicLinkRow)="onCtrlClick($event)">

      <div t-header>

     <mat-card *ngIf="ayuda && !filtros.mostrarMapa" class="cardAyuda">
      <mat-card-content>
        <p><mat-icon color="primary">help</mat-icon>
          El módulo "Todas las visitas" permite a los usuarios visualizar el resultado completo de todas las gestiones
          realizadas a las cuentas.
          Esta vista es particularmente valiosa porque ofrece una perspectiva amplia y detallada de las actividades de gestión,
          asegurando que los usuarios tengan acceso a toda la información relevante en un solo lugar. Los usuarios pueden ver
          datos críticos de cada visita o gestión, incluyendo las fechas en que se realizaron, comentarios detallados, el agente
          que estuvo a cargo de la gestión, el domicilio específico donde se llevó a cabo, el tipo de producto que tenía la
          cuenta al momento de la gestión, si es de cobranza mostrará si la gestión resultó en una promesa de pago, etc.

        </p>
        Acciones:
        <p>
          <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda de las visitas realizadas.
        </p>
        <p>
          <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de
          filtros aplicados.
        </p>
        <p>
          <mat-icon class="kobra-icon">cloud_download</mat-icon><b> Descargar: </b> Ofrece la opción de descargar los datos de
          las visitas para su análisis o uso fuera de línea.
        </p>
        <p>
          <mat-icon class="kobra-icon">map</mat-icon><b> Mapa: </b> Puedes visualizar las visitas geolocalizadas en un mapa a
          nivel global.
        </p>
        <p>
          <mat-icon class="kobra-icon">star</mat-icon><b> Calificar: </b> Te permite revisar cada visita y darle una
          calificación del 1 al 5.
        </p>
        <p>
          <mat-icon class="kobra-icon">stars_6</mat-icon><b> Calificar masiva: </b> Puedes asignar un agente a los elementos
          seleccionados en el mapa.
        </p>
      </mat-card-content>
      </mat-card>
      </div>

      <ng-container t-paginator>
        <mat-paginator *ngIf="!filtros.mostrarMapa"
          showFirstLastButtons
          [length]="visitPaginator.pagination?.totalCount"
          [pageIndex]="visitPaginator.pagination?.currentPage-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-grid>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button>
    Filtros
  </mat-toolbar>
  <div class="accordion-filtros">
    <mat-accordion [multi]="false">
      <mat-expansion-panel *ngIf="sessionData.isSuperAdmin" [expanded]="true">
        <mat-expansion-panel-header>
          Súper Administrador
          <span *ngIf="contabilizadorDeFiltros('superAdmin') >= 1"class="notification-dot-filtros">{{ contabilizadorDeFiltros('superAdmin') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Cliente'"
              [placeHolder]="'Selecciona un cliente'"
              [value]="'idFinanciera'"
              [labelField]="'name'"
              [icon]="'account_balance'"
              [options]="lenders"
              [(ngModel)]="idLender"
              (ngModelChange)="onChangeLender($event);"
            >
            </mat-select-search>

          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Excluir Demo</mat-label>
              <mat-select [(ngModel)]="filtros.excludeFinancierasDemo" (selectionChange)="this.contabilizadorDeFiltros('superAdmin');">
                <mat-option [value]="true">Sí</mat-option>
                <mat-option [value]="false">No</mat-option>
              </mat-select>
            </mat-form-field>
          </li>
          <li class="filtro-side">
            <mat-form-field  style="width: 100%;">
              <mat-label>Etiqueta Kobra</mat-label>
              <mat-select  [(ngModel)]="filtros.idEtiquetaOperacion" (ngModelChange)="this.contabilizadorDeFiltros('superAdmin');">
                <mat-option *ngFor="let etiqueta of tags" [value]="etiqueta.id" >
                  {{etiqueta.name}}
                </mat-option>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar-solo"
                  *ngIf="filtros.idEtiquetaOperacion != null"
                  (click)="filtros.idEtiquetaOperacion = null;$event.stopPropagation();this.contabilizadorDeFiltros('superAdmin');">clear</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side">
            <mat-form-field  style="width: 100%;">
              <mat-label>Calif. Súper Admin.</mat-label>
              <mat-select [(ngModel)]="filtros.calificacionSuperAdmin" (ngModelChange)="this.contabilizadorDeFiltros('superAdmin');">
                <mat-option [value]="1" >
                  Sí
                </mat-option>
                <mat-option [value]="0" >
                  No
                </mat-option>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar-solo"
                  *ngIf="filtros.calificacionSuperAdmin != null"
                  (click)="filtros.calificacionSuperAdmin = null;$event.stopPropagation();this.contabilizadorDeFiltros('superAdmin');">clear</mat-icon>
            </mat-form-field>

          </li>

        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel [expanded]="!sessionData.isSuperAdmin">
        <mat-expansion-panel-header>
          Generales
          <span *ngIf="contabilizadorDeFiltros('generales') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('generales') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Folio Visita</mat-label>
              <input matInput textSanitizer
              [(ngModel)]="filtros.folioVisita"
              (ngModelChange)="this.contabilizadorDeFiltros('generales');"
              placeholder="Capture el folio de la visita">
              <mat-icon matSuffix class="icono-limpiar"
              *ngIf="filtros.folioVisita != null"
              (click)="filtros.folioVisita = null;this.contabilizadorDeFiltros('generales');">clear</mat-icon>
              <mat-icon matSuffix>account_circle</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Visibilidad</mat-label>
              <mat-select
              [(ngModel)]="filtros.visibilidad"
              placeholder="Seleccione..."
              (ngModelChange)="this.contabilizadorDeFiltros('generales');"
              >
                <mat-option [value]="0">Red Kobra</mat-option>
                <mat-option [value]="1">Red Interna</mat-option>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar"
                  *ngIf="filtros.visibilidad != null"
                  (click)="filtros.visibilidad = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
              <mat-icon matSuffix>visibility</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Promesa de Pago</mat-label>
              <mat-select [(ngModel)]="filtros.promesaPago" (ngModelChange)="this.contabilizadorDeFiltros('generales');">
                <mat-option [value]="1">
                  Sí
                </mat-option>
                <mat-option [value]="0">
                  No
                </mat-option>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar"
                  *ngIf="filtros.promesaPago != null"
                  (click)="filtros.promesaPago = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
              <mat-icon matSuffix>azm</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>{{loadingProducts ? 'Cargando productos...' : 'Producto'}}</mat-label>
              <mat-select
                [(ngModel)]="filtros.idProducto"
                placeholder="Seleccione..."
                (ngModelChange)="onChangeProduct();this.contabilizadorDeFiltros('generales');"

              >
                <ng-container *ngFor="let product of products; index as ti">
                  <mat-option [value]="product.idProducto">{{product.nombre || product.nomProducto}}</mat-option>
                </ng-container>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar"
                  *ngIf="filtros.idProducto	 != null"
                  (click)="filtros.idProducto = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
              <mat-icon matSuffix>conveyor_belt</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side" *ngIf="idLender">
            <mat-form-field style="width: 100%;">
              <mat-label>Sucursal</mat-label>
              <mat-select
                [(ngModel)]="filtros.idSucursal"
                placeholder="Seleccione..."
                (ngModelChange)="this.contabilizadorDeFiltros('generales');"
              >
                <ng-container *ngFor="let sucursal of sucursales; index as ti">
                  <mat-option [value]="sucursal.idSucursal">{{sucursal.nombreSucursal}}</mat-option>
                </ng-container>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar"
                  *ngIf="filtros.idSucursal != null"
                  (click)="filtros.idSucursal = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
              <mat-icon matSuffix>apartment</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Repunteo</mat-label>
              <mat-select
              [(ngModel)]="filtros.repunteo"
              (ngModelChange)="this.contabilizadorDeFiltros('generales');"
              >
                <mat-option [value]="1">Sí</mat-option>
                <mat-option [value]="0">No</mat-option>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar"
                  *ngIf="filtros.repunteo != null"
                  (click)="filtros.repunteo = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
              <mat-icon matSuffix>tag</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side" *ngIf="idLender">
            <mat-form-field style="width: 100%;">
              <mat-label>{{loadingEtiquetasCuenta ? 'Cargando etiquetas...' : 'Etiqueta Cuenta'}}</mat-label>
              <mat-select
                [(ngModel)]="filtros.idEtiquetaCuenta"
                (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                placeholder="Seleccione..."
              >
                <ng-container *ngFor="let etiqueta of etiquetasCuenta; index as ti">
                  <mat-option [value]="etiqueta.idEtiquetaCuenta">{{etiqueta.nombre}}</mat-option>
                </ng-container>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar"
                  *ngIf="filtros.idEtiquetaCuenta != null"
                  (click)="filtros.idEtiquetaCuenta = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
              <mat-icon matSuffix>tag</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side" *ngIf="idLender">
            <mat-form-field style="width: 100%;">
              <mat-label>{{loadingEtiquetas ? 'Cargando etiquetas...' : 'Etiqueta Visita'}}</mat-label>
              <mat-select
                [(ngModel)]="filtros.idEtiqueta"
                (ngModelChange)="this.contabilizadorDeFiltros('generales');"
                placeholder="Seleccione..."
              >
                <ng-container *ngFor="let etiqueta of etiquetas; index as ti">
                  <mat-option [value]="etiqueta.idEtiqueta">{{etiqueta.nombreEtiqueta}}</mat-option>
                </ng-container>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar"
                  *ngIf="filtros.idEtiqueta != null"
                  (click)="filtros.idEtiqueta = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
              <mat-icon matSuffix>tag</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Mock Location</mat-label>
              <mat-select
              [(ngModel)]="filtros.mock"
              (ngModelChange)="this.contabilizadorDeFiltros('generales');"
              >
                <mat-option [value]="1">Sí</mat-option>
                <mat-option [value]="0">No</mat-option>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar"
                  *ngIf="filtros.mock != null"
                  (click)="filtros.mock = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
              <mat-icon matSuffix>tag</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>Visita Offline</mat-label>
              <mat-select
              [(ngModel)]="filtros.offline"
              (ngModelChange)="this.contabilizadorDeFiltros('generales');"
              >
                <mat-option [value]="1">Sí</mat-option>
                <mat-option [value]="0">No</mat-option>
              </mat-select>
              <mat-icon matSuffix class="icono-limpiar"
                  *ngIf="filtros.offline != null"
                  (click)="filtros.offline = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
              <mat-icon matSuffix>tag</mat-icon>
            </mat-form-field>

          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label for="">Aclaraciones</mat-label>
            <mat-select [(ngModel)]="filtros.estatusAclaracion" (ngModelChange)="this.contabilizadorDeFiltros('generales');">
              <mat-option [value]="1">En revisión</mat-option>
              <mat-option [value]="2">Aprobada</mat-option>
              <mat-option [value]="3">Rechazada</mat-option>
              <mat-option [value]="4">Cancelada</mat-option>
            </mat-select>
            <mat-icon matSuffix class="icono-limpiar-solo"
                  *ngIf="filtros.estatusAclaracion != null"
                  (click)="filtros.estatusAclaracion = null;$event.stopPropagation();this.contabilizadorDeFiltros('generales');">clear</mat-icon>
            </mat-form-field>

          </li>

        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Cuenta
          <span *ngIf="contabilizadorDeFiltros('cuenta') >= 1" class="notification-dot-filtros">{{ contabilizadorDeFiltros('cuenta') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Cuenta'"
              [placeHolder]="'Busca por id o nombre'"
              [value]="'option'"
              [labelField]="'nombre'"
              [icon]="'account_circle'"
              [realizarBusqueda]="acreditadoFilterSearch"
              [(ngModel)]="filtros.acreditado"
              (ngModelChange)="this.contabilizadorDeFiltros('cuenta');"
            >
            </mat-select-search>

          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Grupo
          <span *ngIf="this.contabilizadorDeFiltros('grupo') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('grupo') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Grupo'"
              [placeHolder]="'Busca por id o nombre'"
              [value]="'id'"
              [labelField]="'nombre'"
              [icon]="'groups'"
              [realizarBusqueda]="grupoFilterSearch"
              [(ngModel)]="filtros.idGrupo"
              (ngModelChange)="this.contabilizadorDeFiltros('grupo');"
            >
            </mat-select-search>

          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Ubicación
          <span *ngIf="contabilizadorDeFiltros('ubicacion') >= 1 && pais != 'MX'" class="notification-dot-filtros">{{ contabilizadorDeFiltros('ubicacion') }}</span>
          <span *ngIf="contabilizadorDeFiltros('ubicacionMX') >= 1 && pais == 'MX'" class="notification-dot-filtros">{{ contabilizadorDeFiltros('ubicacionMX') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side" *ngIf="pais != 'MX'">
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>{{labelEstado}}</mat-label>
              <input matInput textSanitizer
              [(ngModel)]="filtros.estado"
              (ngModelChange)="this.contabilizadorDeFiltros('ubicacion');"
              placeholder="Capture el {{labelEstado}}">
              <mat-icon matSuffix>location_city</mat-icon>
            </mat-form-field>
            <div align="left" *ngIf="filtros.estado" class="limpiar-seleccion-kobra">
              <a (click)="filtros.estado = null"><i>Limpiar</i></a>
            </div>
          </li>
          <li class="filtro-side">
            <mat-form-field style="width: 100%;">
              <mat-label>{{labelMunicipio}}</mat-label>
              <input matInput textSanitizer
              [(ngModel)]="filtros.municipio"
              (ngModelChange)="this.contabilizadorDeFiltros('ubicacion');"
              placeholder="Capture el {{labelMunicipio}}">
              <mat-icon matSuffix>location_city</mat-icon>
            </mat-form-field>
            <div align="left" *ngIf="filtros.municipio" class="limpiar-seleccion-kobra">
              <a (click)="filtros.municipio = null"><i>Limpiar</i></a>
            </div>
          </li>
        </ol>

        <ol class="lista-filtros-side" *ngIf="pais == 'MX'">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="labelEstado"
              [placeHolder]="'Seleccione...'"
              [value]="'idEstado'"
              [labelField]="'nombre'"
              [icon]="'location_city'"
              [options]="estados"
              [(ngModel)]="filtros.idEstado"
              (ngModelChange)="onChangeEstate($event);this.contabilizadorDeFiltros('ubicacionMX');"
            >
            </mat-select-search>

          </li>
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="this.loadings.municipios ? 'Cargando municipios...' : labelMunicipio"
              [placeHolder]="'Seleccione...'"
              [value]="'idMunicipio'"
              [labelField]="'nombre'"
              [icon]="'location_city'"
              [options]="municipios"
              [(ngModel)]="filtros.idMunicipio"
              (ngModelChange)="this.contabilizadorDeFiltros('ubicacionMX');"

            >
            </mat-select-search>

          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Fechas
          <span *ngIf="this.contabilizadorDeFiltros('fechas') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechas') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-accordion [multi]="true">
              <mat-expansion-panel  [expanded]="true">
                <mat-expansion-panel-header>
                  Fechas visita
                  <span *ngIf="this.contabilizadorDeFiltros('fechas') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('fechas') }}</span>
                </mat-expansion-panel-header>
                <ol class="lista-filtros-side">
                  <li class="filtro-side">
                    <mat-date-picker-formater
                      [label]="'Fecha inicio'"
                      [placeHolder]="'Capture la fecha inicio'"
                      [max]="filtros.checklistFechaFin ? filtros.checklistFechaFin : today"
                      [(ngModel)]="filtros.checklistFechaInicio"
                      (ngModelChange)="this.contabilizadorDeFiltros('fechas');"
                    />

                  </li>
                  <li class="filtro-side">
                    <mat-date-picker-formater
                      [label]="'Fecha fin'"
                      [placeHolder]="'Capture la fecha fin'"
                      [min]="filtros.checklistFechaInicio"
                      [max]="today"
                      [(ngModel)]="filtros.checklistFechaFin"
                      (ngModelChange)="this.contabilizadorDeFiltros('fechas');"
                    />

                  </li>
                </ol>
              </mat-expansion-panel>
            </mat-accordion>
          </li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          Agente
          <span *ngIf="this.contabilizadorDeFiltros('agente') >= 1" class="notification-dot-filtros">{{ this.contabilizadorDeFiltros('agente') }}</span>
        </mat-expansion-panel-header>
        <ol class="lista-filtros-side">
          <li class="filtro-side">
            <mat-select-search
              class="id-card-field"
              [label]="'Agente'"
              [placeHolder]="'Busca por id o nombre'"
              [value]="'option'"
              [labelField]="'nombre'"
              [icon]="'account_circle'"
              [realizarBusqueda]="agenteFilterSearch"
              [(ngModel)]="filtros.agente"
              (ngModelChange)="this.contabilizadorDeFiltros('agente');"
              [minBusqueda]="1"
            >
            </mat-select-search>

          </li>

        </ol>
      </mat-expansion-panel>
    </mat-accordion>
  </div>


  <ol class="lista-filtros-buttons">
    <button
      style="width: 100%;"
      mat-stroked-button
      class="boton-accion-front"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; setFilter();">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="clearFilter();">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
