<mat-card *ngIf="batch">
  <mat-card-content class="scroll">
    <!-- <div class="ui active inverted dimmer" *ngIf="loading">
      <div class="ui text loader">{{loadingText}}</div>
    </div> -->
  
    <div class="finish-item" style="height: auto;">
      <h3 *ngIf="batch.idStatus !== IMPORT_STATUS.FINISHED && batch.idStatus !== IMPORT_STATUS.PROCESSED_ERRORS && batch.idStatus !== IMPORT_STATUS.ERROR" class="titulo import-title">Realizando la importación</h3>
  
      <h5 *ngIf="batch.idStatus !== IMPORT_STATUS.FINISHED && batch.idStatus !== IMPORT_STATUS.PROCESSED_ERRORS && batch.idStatus !== IMPORT_STATUS.ERROR" class="titulo-sub import-title" style="margin: 0;">
        Al finalizar enviaremos un correo electrónico a <u *ngIf="!batch.emails">{{ batch.email }}</u><u *ngIf="batch.emails">{{ batch.emails }}</u> o puedes esperar a que termine en esta pagina.
      </h5>
  
      <h3 *ngIf="batch.idStatus === IMPORT_STATUS.FINISHED" class="titulo import-title">Importación terminada</h3>
  
      <h3 *ngIf="batch.idStatus === IMPORT_STATUS.ERROR || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS" class="titulo import-title">Importación terminada con errores</h3>
  
      <h5 *ngIf="batch.idStatus === IMPORT_STATUS.FINISHED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS" class="titulo-sub import-title" style="margin: 0;">
        Hemos enviado un correo electrónico a <u *ngIf="!batch.emails">{{ batch.email }}</u><u *ngIf="batch.emails">{{ batch.emails }}</u> con los resultados de la importación.
      </h5>
    </div>

    <div mat-card-actions align="right" style="margin-top: 20px;margin-right: 25px;">
      <button  
        *ngIf="batch.idStatus >= IMPORT_STATUS.GEOLOCATED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR"
        mat-stroked-button 
        (click)="openDialogGeocalizarCuentas()" >
        <mat-icon class="kobra-icon">place</mat-icon>
        Cambiar geolocalización de cuentas con visitas
      </button>
    </div>

    <mat-grid-list-responsive [colsMd]="2" [colsSm]="1" rowHeight="470px">
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-card class="finish-item-card ancho-card">
          <mat-card-header>
            <mat-card-title>
              <mat-icon [ngClass]="{'finish-success': batch.idStatus >= IMPORT_STATUS.PROCESSED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR }">check_circle</mat-icon>
              Cuentas
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="card-content">
            <ng-container *ngIf="batch.idStatus < IMPORT_STATUS.PROCESSING && batch.idStatus !== IMPORT_STATUS.PROCESSED_ERRORS && batch.idStatus !== IMPORT_STATUS.ERROR">
              <div class="finish-wait">
                <h4 class="titulo-sub import-title">Esperando para procesar</h4>
                <img
                  width="40px"
                  height="40px"
                  srcset="
                    /assets/images/wait_clock@3x.png 3x,
                    /assets/images/wait_clock@2x.png 2x
                  "
                  src="/assets/images/wait_clock.png"
                >
              </div>
            </ng-container>
    
            <ng-container *ngIf="batch.idStatus === IMPORT_STATUS.PROCESSING">
              <div class="finish-wait">
                <h4 class="import-title">Procesando...</h4>
                <app-loader></app-loader>
              </div>
            </ng-container>
    
            <div *ngIf="batch.idStatus >= IMPORT_STATUS.PROCESSED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR" style="text-align: center;">
              <img
                width="32px"
                height="32px"
                srcset="
                  /assets/import/types/2@3x.png 3x,
                  /assets/import/types/2@2x.png 2x
                "
                src="/assets/import/types/2.png"
              >
              <h4 class="titulo-sub-2 import-title" style="margin: 0;">Cuentas en el archivo</h4>
              <a mat-button class="active" (click)="openAccounts('cuentas-archivo')">{{ batch.total || 0 }}</a>
            </div>
    
            <div *ngIf="batch.idStatus >= IMPORT_STATUS.PROCESSED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR" style="text-align: center;">
              <h4 class="titulo-sub-2 import-title" style="margin: 0;">Errores encontrados</h4>
              <a mat-button class="active" (click)="openAccounts('errors')">{{ batch.totalErrors || 0 }}</a>
            </div>
    
            <div *ngIf="batch.idStatus >= IMPORT_STATUS.PROCESSED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR" style="text-align: center;">
              <h4 class="titulo-sub-2 import-title" style="margin: 0;">Cuentas importadas</h4>
              <a mat-button class="active" (click)="openAccounts('imported')">{{ batch.cuentasImportadas || 0 }}</a>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile colspan="1" rowspan="1">
        <mat-card class="finish-item-card ancho-card">
          <mat-card-header>
            <mat-card-title>
              <mat-icon [ngClass]="{'finish-success': batch.idStatus >= IMPORT_STATUS.GEOLOCATED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR }">check_circle</mat-icon>
              Geolocalización
            </mat-card-title>
            <a 
              routerLink="{{urlHelp}}/conocimiento/que-es-la-geolocalizacion"
              target="_blank"
              mat-icon-button
              color="primary"
              matTooltip="¿Qué es?"
              mat-tooltip-panel-above
              class="ayuda"
            >
              <mat-icon>help</mat-icon>
            </a>
          </mat-card-header>
          <mat-card-content class="card-content">
            <ng-container *ngIf="batch.idStatus < IMPORT_STATUS.GEOLOCATING && batch.idStatus !== IMPORT_STATUS.PROCESSED_ERRORS && batch.idStatus !== IMPORT_STATUS.ERROR">
              <ng-container *ngTemplateOutlet="waitTemplate"></ng-container>
            </ng-container>
    
            <ng-container *ngIf="batch.idStatus === IMPORT_STATUS.GEOLOCATING">
              <ng-container *ngTemplateOutlet="calculatingTemplate"></ng-container>
            </ng-container>
    
            <div *ngIf="batch.idStatus >= IMPORT_STATUS.GEOLOCATED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR" style="text-align: center;">
              <img
                srcset="
                  /assets/geo/green_pin@3x.png 3x,
                  /assets/geo/green_pin@2x.png 2x
                "
                src="/assets/geo/green_pin.png"
              >
              <h4 class="titulo-sub import-title" style="margin: 0;">Geolocalización exacta</h4>
              <a mat-button class="active" (click)="openAccounts('geo-exact')">{{ batch.totalGeoExact }}</a>
            </div>
    
            <div *ngIf="batch.idStatus >= IMPORT_STATUS.GEOLOCATED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR" style="text-align: center; margin-top: 10px;">
              <img
                srcset="
                  /assets/geo/red_pin@3x.png 3x,
                  /assets/geo/red_pin@2x.png 2x
                "
                src="/assets/geo/red_pin.png"
              >
              <h4 class="titulo-sub import-title" style="margin: 0;">Geolocalización inexacta</h4>
              <a mat-button class="active" (click)="openAccounts('geo-inexact')">{{ batch.totalGeoInexact }}</a>
            </div>
    
            <div *ngIf="batch.idStatus >= IMPORT_STATUS.GEOLOCATED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR" style="text-align: center; margin-top: 10px;">
              <img
                srcset="
                  /assets/geo/red_pin@3x.png 3x,
                  /assets/geo/red_pin@2x.png 2x
                "
                src="/assets/geo/red_pin.png"
              >
              <h4 class="titulo-sub import-title" style="margin: 0;">Sin geolocalización</h4>
              <a mat-button class="active" (click)="openAccounts('geo-no')">{{ batch.totalNoGeoloc }}</a>
            </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    
    </mat-grid-list-responsive>

    <!-- <div class="finish-container">
      <div class="finish-item">
        <mat-card class="finish-item-card">
          <mat-card-header>
            <mat-card-title>
              <mat-icon [ngClass]="{'finish-success': batch.idStatus >= IMPORT_STATUS.CALCULATED_COVERAGE || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR }">check_circle</mat-icon>
              Cobertura
            </mat-card-title>
            <mat-card-subtitle *ngIf="batch.idStatus >= IMPORT_STATUS.CALCULATED_COVERAGE || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR">Probabilidad de visita</mat-card-subtitle>
            <a 
              routerLink="{{urlHelp}}/conocimiento/como-se-determina-la-probabilidad-de-visita"
              target="_blank"
              mat-icon-button
              color="primary"
              matTooltip="¿Como se determina la probabilidad de visita?"
              mat-tooltip-panel-above
              class="ayuda"
              >
              <mat-icon>help</mat-icon>
            </a>
          </mat-card-header>
          <mat-card-content>
            <ng-container *ngIf="batch.idStatus < IMPORT_STATUS.CALCULATING_COVERAGE && batch.idStatus !== IMPORT_STATUS.PROCESSED_ERRORS && batch.idStatus !== IMPORT_STATUS.ERROR">
              <ng-container *ngTemplateOutlet="waitTemplate"></ng-container>
            </ng-container>
  
            <ng-container *ngIf="batch.idStatus === IMPORT_STATUS.CALCULATING_COVERAGE">
              <ng-container *ngTemplateOutlet="calculatingTemplate"></ng-container>
            </ng-container>

            <div class="finish-probability-container">
              <div *ngIf="batch.idStatus >= IMPORT_STATUS.CALCULATED_COVERAGE || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR" class="finish-probability">
                <div>
                  <h3 class="titulo-sub-tabla import-title">Muy alta</h3>
                  <a mat-button class="active" (click)="openAccounts('probability-very-high')">{{ batch.totalVeryHigh }}</a>
                </div>
                <div>
                  <h3 class="titulo-sub-tabla import-title">Alta</h3>
                  <a mat-button class="active" (click)="openAccounts('probability-high')">{{ batch.totalHigh }}</a>
                </div>
                <div>
                  <h3 class="titulo-sub-tabla import-title">Poca</h3>
                  <a mat-button class="active" (click)="openAccounts('probability-low')">{{ batch.totalLow }}</a>
                </div>
                <div>
                  <h3 class="titulo-sub-tabla import-title">Muy poca</h3>
                  <a mat-button class="active" (click)="openAccounts('probability-very-low')">{{ batch.totalVeryLow }}</a>
                </div>
                <div>
                  <h3 class="titulo-sub-tabla import-title">Ninguna</h3>
                  <a mat-button class="active" (click)="openAccounts('probability-without-coverage')">{{ batch.totalWithoutCoverage }}</a>
                </div>
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </div> -->

    <!-- <div class="finish-container">
      <div class="finish-item">
        <mat-card class="finish-item-card" style="height: auto;">
          <mat-card-header>
            <mat-card-title>
              <mat-icon [ngClass]="{ 'finish-success': batch.idStatus === IMPORT_STATUS.FINISHED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR }">check_circle</mat-icon>
              SLA (Service Level Agreement)
            </mat-card-title>
            <a
              href="{{urlHelp}}/conocimiento/que-es-el-sla" 
              target="_blank"
              mat-icon-button
              color="primary"
              matTooltip="¿Qué es el SLA?"
              mat-tooltip-panel-above
              class="ayuda">
              <mat-icon>help</mat-icon>
            </a>
          </mat-card-header>
          <mat-card-content>
            <ng-container *ngIf="batch.idStatus < IMPORT_STATUS.CALCULATING_SLA && batch.idStatus !== IMPORT_STATUS.PROCESSED_ERRORS && batch.idStatus !== IMPORT_STATUS.ERROR">
              <ng-container *ngTemplateOutlet="waitTemplate"></ng-container>
            </ng-container>
  
            <ng-container *ngIf="batch.idStatus === IMPORT_STATUS.CALCULATING_SLA">
              <ng-container *ngTemplateOutlet="calculatingTemplate"></ng-container>
            </ng-container>
  
            <app-chart
              *ngIf="batch.idStatus === IMPORT_STATUS.FINISHED || batch.idStatus === IMPORT_STATUS.PROCESSED_ERRORS || batch.idStatus === IMPORT_STATUS.ERROR"
              chartType="table"
              [query]="query"
              [visibility]="visibility"
              style="width: 100%; height: 100%;"
            ></app-chart>
          </mat-card-content>
        </mat-card>
      </div>
    </div> -->

    <ng-template #waitTemplate>
      <div class="finish-wait">
        <h4 class="titulo-sub import-title">Esperando calcular</h4>
        <img
          width="40px"
          height="40px"
          srcset="
            /assets/images/wait_clock@3x.png 3x,
            /assets/images/wait_clock@2x.png 2x
          "
          src="/assets/images/wait_clock.png"
        >
      </div>
    </ng-template>
    
    <ng-template #calculatingTemplate>
      <div class="finish-wait">
        <h4 class="import-title">Calculando...</h4>
        <app-loader></app-loader>
      </div>
    </ng-template>
  </mat-card-content>
</mat-card>


