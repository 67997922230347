import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from "@angular/router";
import { LenderService } from "../../../lender/lender.service";
import { map, startWith, take } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { FormGroup, FormBuilder, Validators, FormControl, FormArray } from '@angular/forms';
import { PaquetesFinancieraDetalleService } from './paquetes-financiera-detalle.service';

@Component({
  selector: 'app-paquetes-financiera-detalle',
  templateUrl: './paquetes-financiera-detalle.component.html',
  styleUrls: ['./paquetes-financiera-detalle.component.css'],
  providers: [
    LenderService
  ]
})
export class PaquetesFinancieraDetalleComponent implements OnInit {
  conceptoActual: any = null;
  public title: string;
  public idFinanciera: number;
  public idFinancieraPaquete: number;
  public financieraName: string;
  public detalleForm: FormGroup;
  public conceptosProducto: any[] = [];
  public conceptosEtiquetaVisita: any[] = [];
  public form: FormGroup;
  public items: FormArray;
  public loaderText: string | null;
  public conceptosLoader: boolean = false;
  public saveButton: boolean = false;
  private financieraPaqueteConceptos: any[] = [];
  private conceptosCompletos: any[] = [];
  private itemsBorrados: any[] = [];
  searchControl = new FormControl();
  filteredConceptosProducto = [];
  filteredConceptosEtiquetaVisita = [];
  public conceptoSeleccionado: boolean = false;
  conceptos: any[] = [];

  constructor(
    private acRoute: ActivatedRoute,
    private lenderService: LenderService,
    private app: ApplicationService,
    private paquetesFinancieraDetalleService: PaquetesFinancieraDetalleService,
    private formBuilder: FormBuilder,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.init();
    this.searchControl.valueChanges
    .pipe(
      startWith(''),
      map(value => this._filter(value))
    )
    .subscribe();
  }

  private _filter(value: any) {
    // Verificar si value es una cadena de texto y normalizarlo
    const filterValue = typeof value === 'string' ? this._normalize(value) : '';
  
    // Filtrar conceptosProducto
    this.filteredConceptosProducto = this.conceptosProducto.filter(concepto =>
      this._normalize(concepto.nombreConcepto).includes(filterValue)
    );
  
    // Filtrar conceptosEtiquetaVisita
    this.filteredConceptosEtiquetaVisita = this.conceptosEtiquetaVisita.filter(concepto =>
      this._normalize(concepto.nombreConcepto).includes(filterValue)
    );
  }

  private _normalize(text: string): string {
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
  }

  private init(): void {
    this.title = 'Editar precios por tipo de visitas';
    this.detalleForm = this.formBuilder.group({
      items: this.formBuilder.array([])
    });
    this.idFinanciera = +this.acRoute.parent?.snapshot.params['idFinanciera'];
    this.idFinancieraPaquete = this.acRoute.snapshot.params['idFinancieraPaquete'];
    if (!this.financieraName) {
      this.cargarFinanciera(this.idFinanciera);
    }

    this.paquetesFinancieraDetalleService.getConceptos(this.idFinanciera, this.idFinancieraPaquete)
      .pipe(take(1))
      .subscribe((res) => {
        this.items = this.detalleForm.get('items') as FormArray;
        this.financieraPaqueteConceptos = res;
        this.getConceptosFinanciera();
        for( let i = 0; i < res.length; i++ ) {
          this.items.push(this.createItem( res[i].idConcepto, res[i].nombreConcepto, res[i].precio ));
        }
      }, (err) => {
        this.app.showError(err);
      });
  }

  limpiarBusqueda() {
    this.searchControl.setValue('');
  }

  getConceptosFinanciera() {
    this.loaderText = 'Obteniendo conceptos...';
    this.conceptosLoader = true;
    this.paquetesFinancieraDetalleService.getConceptosEtiquetasProductos(this.idFinanciera)
      .pipe(take(1))
      .subscribe((res) => {
        this.loaderText = null;
        this.conceptosCompletos = res;

        let conceptosTemp = this.conceptosCompletos.filter(concepto => {
          return !this.financieraPaqueteConceptos.some(item => item.idConcepto === concepto.idConcepto);
        });

        // Agrupar conceptos por tipo
        this.conceptosProducto = conceptosTemp.filter(concepto => concepto.tipoTarifa === 'producto' );
        this.conceptosEtiquetaVisita = conceptosTemp.filter(concepto => concepto.tipoTarifa === 'etiquetaVisita');
        this.filteredConceptosProducto = this.conceptosProducto;
        this.filteredConceptosEtiquetaVisita = this.conceptosEtiquetaVisita;
        this.conceptosLoader = false;
      }, (err) => {
        this.loaderText = null;
        this.app.showError(err);
        this.conceptosLoader = false;
      });
  }

  onConceptoSelected(event: any) {
    // Asignar el concepto seleccionado al conceptoActual
    this.conceptoActual = event.option.value;
  
    // Llamar al método para agregar el concepto seleccionado
    this.agregarConcepto();
  }

  agregarConcepto() {
    if (!this.conceptoActual) return;
  
    // Agregar el concepto seleccionado a los items
    this.items.push(this.createItem(
      this.conceptoActual.idConcepto,
      this.conceptoActual.nombreConcepto,
      0,  // Precio inicial
      true  // Este campo indica si es un concepto nuevo o no
    ));
  
    // Obtener tipoTarifa desde conceptosCompletos
    const tipoTarifa = this.conceptosCompletos.find(concepto => concepto.idConcepto === this.conceptoActual.idConcepto)?.tipoTarifa;
  
    // Eliminar el concepto seleccionado de la lista de disponibles
    if (tipoTarifa === 'producto') {
      this.conceptosProducto = this.conceptosProducto.filter(concepto => concepto.idConcepto !== this.conceptoActual.idConcepto);
      this.filteredConceptosProducto = [...this.conceptosProducto];  // Actualizamos la lista filtrada
    } else if (tipoTarifa === 'etiquetaVisita') {
      this.conceptosEtiquetaVisita = this.conceptosEtiquetaVisita.filter(concepto => concepto.idConcepto !== this.conceptoActual.idConcepto);
      this.filteredConceptosEtiquetaVisita = [...this.conceptosEtiquetaVisita];  // Actualizamos la lista filtrada
    }
  
    // Reiniciar los valores y limpiar el campo de búsqueda
    this.conceptoActual = null;
    this.searchControl.setValue('');
  }
  
  borrarConcepto(item: any, index: number) {
    let conceptoBorrado = {
      idConcepto: item.controls['idConcepto'].value,
      nombreConcepto: item.controls['nombreConcepto'].value,
      precio: item.controls['precio'].value,
      nuevo: item.controls['nuevo'].value
    };
  
    // Obtener tipoTarifa desde conceptosCompletos
    const tipoTarifa = this.conceptosCompletos.find(concepto => concepto.idConcepto === conceptoBorrado.idConcepto)?.tipoTarifa;
  
    // Remover de items
    this.itemsBorrados.push(conceptoBorrado);
    this.items.removeAt(index);
  
    // Volver a agregar el concepto a la lista correspondiente
    if (tipoTarifa === 'producto') {
      this.conceptosProducto.push(conceptoBorrado);
      this.filteredConceptosProducto = [...this.conceptosProducto];  // Actualizamos la lista filtrada
    } else if (tipoTarifa === 'etiquetaVisita') {
      this.conceptosEtiquetaVisita.push(conceptoBorrado);
      this.filteredConceptosEtiquetaVisita = [...this.conceptosEtiquetaVisita];  // Actualizamos la lista filtrada
    }
  }

  createItem(idConcepto: number, nombreConcepto: string, precio: number, nuevo: boolean = false): FormGroup {
    return this.formBuilder.group({
      idConcepto: idConcepto,
      nombreConcepto: nombreConcepto,
      precio: [precio, [
        Validators.required,
        Validators.pattern(/^(?:[1-9]\d*|0)?(?:\.\d+)?$/),
        Validators.min(1)
      ]],
      nuevo: nuevo
    });
  }

  private cargarFinanciera(idFinanciera: number): void {
    this.lenderService.obtenerFinanciera(idFinanciera)
    .pipe(take(1))
    .subscribe((res: any) => {
      this.financieraName = res.data[0].nombre;
    }, (error: any) => {
      this.app.showError(error);
    });
  }

  onSubmit() {
    let conceptos: any[] = [];
    let conceptosBorrados: any[] = [];

    for (let i = 0; i < this.items.length; i++) {
      conceptos.push({
        idConcepto: this.items.controls[i].value.idConcepto,
        precio: this.items.controls[i].value.precio,
        nuevo: this.items.controls[i].value.nuevo
      });
    }

    for (let index = 0; index < this.itemsBorrados.length; index++) {
      const item = this.itemsBorrados[index];
      conceptosBorrados.push({
        idConcepto: item.idConcepto,
        nombreConcepto: item.nombreConcepto,
        precio: item.precio
      });
    }

    let data = { conceptos: conceptos, conceptosBorrados: conceptosBorrados };

    let loading = this.app.showLoading('Guardando información...');
    this.paquetesFinancieraDetalleService.editarConceptos(this.idFinanciera, this.idFinancieraPaquete, data)
      .pipe(take(1))
      .subscribe((res) => {
        this.app.hideLoading(loading);
        this.app.showSnackbar('¡Aviso!', 'Precios actualizados correctamente.', 3000, 'success');
        this.router.navigate([`paquetes-cliente/${this.idFinanciera}`], { queryParamsHandling: 'preserve' });
      }, (err) => {
        this.app.hideLoading(loading);
        this.app.showError(err);
      });
  }

  public onInputLimite(index: number): void{

    if(this.items.controls[index].value.precio > 99999){
        setTimeout(()=>{
          this.items.controls[index].value.precio = 99999;
          this.items.controls[index].get('precio').patchValue(99999);
        }, 10);
    }
  }

  public prohibirCaracteres(event: KeyboardEvent): void {
    const invalidKeys = ['e', 'E', '+', '-'];
  
    // Si la tecla presionada está en la lista de teclas prohibidas, prevenimos su comportamiento
    if (invalidKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
  
  
}
