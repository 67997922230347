import { Injectable } from '@angular/core';
import { environmentSelector } from '../../../../environments/environment.selector';
import { ServicesGeneralService } from '../servicio-general/servicio-general.service';


@Injectable()
export class ServicesKobraService extends ServicesGeneralService {
  protected override baseURL: string = environmentSelector().kobraServices.services;
  protected override propertie: string = 'services';
}
