import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[decimalValidator]'
})
export class DecimalValidatorDirective {
  @Input() decimalPlaces: number;

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  @HostListener('keyup', ['$event'])
  onInput(event: any) {
    const value = event.target.value;
    const regex = this.decimalPlaces === 0 ? /^\d+$/ : /^\d+(?:\.\d{0,${this.decimalPlaces}})?$/;

    // Si el valor no cumple con el patrón, cortamos hasta el sexto decimal
    if (!regex.test(value)) {
      const parts = value.split('.');
      const integerPart = parts[0];
      const decimalPart = parts[1]?.slice(0, this.decimalPlaces) || ''; // Cortamos la parte decimal
      const newValue = this.decimalPlaces == 0 ? integerPart : `${integerPart}.${decimalPart}`;
      event.target.value = newValue;
    }
  }
}
