
  <div class="app content kobra">

    <mat-card class="kobra-card">
      <mat-card-header>
        <mat-card-title>Nuevo</mat-card-title>
      </mat-card-header>
      <mat-card-content class="kobra-card-content">
        <mat-grid-list-responsive [colsMd]="4" [colsSm]="2" [colsXs]="1" rowHeight="80px">
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-select-search
              [label]="'Producto'"
              [placeHolder]="'Selecciona un producto'"
              [value]="'idProducto'"
              [labelField]="'idProductoYNombre'"
              [icon]="'inventory_2'"
              [options]="productos"
              [(ngModel)]="idProducto"
              (ngModelChange)="onSelectProduct();"
              class="pago-gestor"
            >
            </mat-select-search>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field class="pago-gestor">
              <mat-label>Pago al gestor</mat-label>
              <input matInput textSanitizer  type="number"
                min="1.00"
                max="99999.99"
                (input)="onInputLimite($event)"
                [(ngModel)]="pagoAlGestor"
                placeholder="Capture monto pago al gestor">
              <mat-icon matSuffix>attach_money</mat-icon>
            </mat-form-field>
          </mat-grid-tile>

        </mat-grid-list-responsive>

        <mat-grid-list-responsive [colsMd]="4" [colsSm]="2" [colsXs]="1" rowHeight="80px" *ngIf="idTipoProducto == 1">
          <mat-grid-tile colspan="1" rowspan="1">

            <mat-form-field class="pago-gestor">
              <mat-label></mat-label>
              <input matInput textSanitizer  class="upper"
                maxlength="100"
                [disabled]="true"
                (ngModelChange)="idProductoYNombre"
                [(ngModel)]="idProductoYNombre">
              <mat-icon matSuffix>person</mat-icon>
            </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1" rowspan="1">
            <mat-form-field class="pago-gestor">
              <mat-label>Pago al gestor</mat-label>
              <input matInput textSanitizer  type="number"
                min="1.00"
                max="99999.99"
                (input)="onInputLimite($event)"
                [(ngModel)]="pagoAlGestorPromesa"
                placeholder="Capture cuanto se le pagará al gestor">
              <mat-icon matSuffix>attach_money</mat-icon>
            </mat-form-field>
          </mat-grid-tile>
        </mat-grid-list-responsive>

        <div class="button-center">
          <button class="boton-cancelar" mat-flat-button type="submit" style="margin-right: 10px;" (click)="cancel()" [disabled]="loadings.productos || loadings.guardando">
            <mat-icon class="kobra-icon">
              close
            </mat-icon> Cancelar</button>
          <button mat-stroked-button class="kobra-button" type="submit" (click)="save()" [disabled]="loadings.productos || loadings.guardando">
            <mat-icon>
              save
            </mat-icon> Guardar</button>
        </div>
      </mat-card-content>
      <mat-card-footer style="margin-top: 3px !important;" *ngIf="loadings.productos || loadings.guardando">
        <i *ngIf="loadings.guardando">Guardando concepto...</i>
        <i *ngIf="loadings.productos">Obteniendo productos...</i>
        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
      </mat-card-footer>
    </mat-card>
  
  </div>
