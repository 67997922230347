import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountsComponent } from 'src/app/import/accounts/accounts.component';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { ImportService } from '../import.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { Product } from '../models/product.model';
import { Type } from '../models/type.model';
import { Batch } from '../models/batch.model';
import { take } from 'rxjs/internal/operators/take';
import { FiltrosService } from 'src/app/application/shared/filtros.service';


@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.css'],
  providers: [
    ImportService
  ]
})
export class PreviewComponent implements OnInit, OnDestroy {
	@Input() product: Product;
	@Input() type: Type;
	@Input() file: File;
  @Input() batch: Batch;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() idCliente: number;
  @Input() idFinanciera: number;

  currentBatch: Batch;

  IMPORT_STATUS: any = {
    PROCESSED_ERRORS: 1,
    ERROR: 2,
    QUEUE: 3,
    LOADING: 4,
    LOADED: 5,
    PROCESSING: 6,
    PROCESSED: 7,
    GEOLOCATING: 8,
    GEOLOCATED: 9,
    CALCULATING_COVERAGE: 10,
    CALCULATED_COVERAGE: 11,
    CALCULATING_SLA: 12,
    FINISHED: 13
  };

  checkState: boolean = true;
  //public cuentasImportadas: number;
  private erroresGenerales: Array<any>;
  private importacion: any;
  sessionData: SessionData;
  public filters: any = {
    financiera: null,
    nombre: null,
    activa: true,
    excluirDemo: false,
    filtered: false,
    page: 1
  };
  

  constructor(
    private importService: ImportService,
    private appService: ApplicationService,
    private localStorageService: LocalStorageService,
    private dialog: MatDialog,
    private filtrosService: FiltrosService,
  ) {
      this.sessionData = this.localStorageService.getSessionData();
   }

  ngOnInit() {
    setTimeout(() => {
      if(localStorage.getItem('importacion-temp')){
        this.currentBatch  = this.batch;
        localStorage.removeItem('importacion-temp');
  
        this.checkBatchState();
      }
      else{
        this.importService.uploadLayout(this.file, this.batch, this.product, this.type, this.idCliente, this.idFinanciera).subscribe((batch: Batch) => {
          batch.idProduct = this.product.id;
          this.currentBatch = batch;
  
          this.checkBatchState();
        }, (error) => {
          this.appService.showError(error);
        });
      }
    }, 200);
  }

  resultadoFiltros(filtrosNuevos: any) {
    if (filtrosNuevos) {
        this.filters = filtrosNuevos;
        this.filters.filter = true;
    }
    this.idCliente = this.filters.financiera ? this.filters.financiera.idCliente : this.sessionData.idCliente;
    this.idFinanciera = this.filters.financiera ? this.filters.financiera.idFinanciera : this.sessionData.idFinanciera;
  }

  ngOnDestroy() {
    this.checkState = false;
  }

  checkBatchState() {

    this.importService.getBatchState(this.idCliente, this.idFinanciera, this.currentBatch).pipe(take(1)).subscribe((batch: Batch) => {
      this.erroresGenerales = batch.resultado ? batch.resultado.split('|') : null;
      /*let cuentasImportadas = ( batch.total || 0 ) - ( batch.totalErrors || 0 );
      this.cuentasImportadas = cuentasImportadas < 0 ? 0 : cuentasImportadas;*/

      /*if( this.erroresGenerales ) {
        batch.totalErrors += this.erroresGenerales.length;
      }*/

      this.currentBatch = batch;

      if( batch.idStatus < this.IMPORT_STATUS.LOADED && batch.idStatus !== this.IMPORT_STATUS.ERROR && batch.idStatus !== this.IMPORT_STATUS.PROCESSED_ERRORS ) {
        setTimeout(() => {
          if (this.checkState) {
            this.checkBatchState();
          }
        }, 2000);
      } else {
        this.onChange.emit(batch);
      }
    }, (error) => {
      this.appService.showError(error);
    });
  }

  openAccounts(filter) {
    const dialogRef = this.dialog.open(AccountsComponent, {
      //width: '80%',
      //height: '90%',
      data: {
        id: this.currentBatch.id,
        idTipoImportacion: this.type.id,
        erroresGenerales: this.erroresGenerales,
        filter,
        idCliente: this.idCliente,
        idFinanciera: this.idFinanciera
      }
    });

    dialogRef.afterClosed().subscribe(() => {});
  }
}
