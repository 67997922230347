<router-outlet (activate)="hijosActivos = true ;" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
      <div class="button-toggle-group-container">
        <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
          <mat-button-toggle value="filtros" 
          (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
          matTooltip="Filtrar administradores"
          mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">filter_alt</mat-icon>
          <span *ngIf="totalFiltros  >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros  }}</span>
          </mat-button-toggle>
          <mat-button-toggle value="actualizar"
            (click)="obtenerConceptosEtiquetasSaldos(filters.page ? filters.page : 1);"
            matTooltip="Refrescar"
            mat-tooltip-panel-above>
            <mat-icon class="kobra-icon">sync</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="subMenu"  
            matTooltip="Acciones"
            mat-tooltip-panel-above
            [matMenuTriggerFor]="menu">
            <mat-icon class="kobra-icon">more_vert</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle value="ayuda" 
            matTooltip="Ayuda"
            mat-tooltip-panel-above
            (click)="ayuda = !ayuda;"
         >
            <mat-icon color="primary">help</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
    </div>
  
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['/catalogos/conceptos-etiquetas-visitas/nuevo']" queryParamsHandling="preserve">
        <mat-icon class="kobra-icon">add</mat-icon>
        <span>Nuevo</span>
      </button>
      <!-- <button mat-menu-item   >
        <mat-icon class="kobra-icon">edit</mat-icon>
        <span>Editar</span>
      </button>
      <button mat-menu-item  >
        <mat-icon color="warn" >delete_forever</mat-icon>
        <span>Eliminar</span>
      </button> -->
    </mat-menu>
  
    <app-table
      [loading]="cargandoLista"
      [columns]="tabla.columns"
      [items]="conceptos"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      [classContainer]="'tabla-completa'"
      (sortChange)="setSortField($event)"
      >

      <div t-header>  
        <mat-card *ngIf="ayuda" class="cardAyuda">
         
          <mat-card-content>
            <p><mat-icon color="primary">help</mat-icon>
              El módulo "Conceptos de etiquetas visitas" es un componente importante del módulo general de "Saldos", desempeñando un papel fundamental en
              la gestión financiera tanto para clientes como para agentes.
              Este módulo permite a los usuarios crear nuevos conceptos que serán aplicados en cargos o abonos, una
              funcionalidad esencial para el manejo eficiente de las transacciones financieras.
            </p>
            Acciones:
            <p>
              <mat-icon class="kobra-icon">filter_alt</mat-icon><b> Filtrar: </b> Mejora la búsqueda y el acceso a los conceptos,
              permitiendo aplicar filtros basados en diversos criterios.
            </p>
            <p>
              <mat-icon class="kobra-icon">sync</mat-icon><b> Refrescar: </b> Te permite recargar tu listado con los criterios de
              filtros aplicados.
            </p>
            <p>
              <mat-icon class="kobra-icon">add</mat-icon><b> Nuevo: </b> Los usuarios pueden añadir nuevos conceptos al sistema.
            </p>
          
          
          </mat-card-content>
        </mat-card>
      </div>
  
      <ng-container t-paginator>
        <mat-paginator
          showFirstLastButtons
          [length]="pagination?.totalRows"
          [pageIndex]="pagination?.page-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <div class="div-filtros-side">
      <li class="filtro-side">
        <mat-form-field class="pago-gestor" style="width: 100%;">
          <mat-label>Nombre concepto</mat-label>
          <input matInput textSanitizer 
            [(ngModel)]="filters.nombreConcepto"
            (ngModelChange)="this.contabilizadorDeFiltros('generales') ;"
            placeholder="Capture el concepto a buscar" [maxlength]="100">
            <mat-icon matSuffix  class="icono-limpiar"
            *ngIf="filters.nombreConcepto" 
            (click)="filters.nombreConcepto = null; this.contabilizadorDeFiltros('generales')">clear</mat-icon>
          <mat-icon matSuffix>lightbulb_circle</mat-icon>
        </mat-form-field>
    
      </li>
    </div>
    
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; filtrar(1)">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="limpiarFiltros()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>