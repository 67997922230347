import { Component, Input, TemplateRef } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'html-tooltip',
  template: `
    <div [innerHTML]="content"></div>
  `,
  styles: [`
    div {
      background: #616161;
      padding: 10px;
      border: 1px solid black;
      border-radius: 4px;
      color: white;
      max-width: 200px;
    }
  `]
})
export class HtmlTooltipComponent {
  @Input() content: string | SafeHtml;
}
