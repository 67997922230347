import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmacionDialog } from "../../modales-genericos/confirmacion/confirmacion-dialog.component";
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { UserService } from '../user.service';
import { User } from '../user.model';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Admin } from 'src/app/administrator/administrator.model';
import {  Tipo2fa } from '../user.interface';

@Component({
  selector: 'user-edit',
  templateUrl: 'user-edit.component.html',
  styleUrls: ['user-edit.component.css'],
  providers: [UserService],
})
export class UserEditComponent implements OnInit {
  public personalInfoForm: FormGroup;
  public passwordForm: FormGroup;
  hideContra = true;
  hideConfirm = true;
  sessionData: SessionData;
  idUsuario: number;
  user: User;
  loading: boolean = true;
  hasSecret: boolean = false; // Inicialmente asume que no tiene vinculado GA
  otpSecret: string | null = null; // Clave secreta de Google Authenticator generada
  qrCodeUrl: string | null = null; // URL del código QR para escanear con Google Authenticator
  otpCode: string = ''; // Código OTP ingresado por el usuario
  isVinculado: boolean = false; // Indica si la vinculación fue exitosa
  mostrarQR: boolean = false;
  ayuda: boolean = false;
  public cargandoDatos = false;
  public items: Tipo2fa[] = [];
  public itemsSelected: any[] = [];
  activarBoton: boolean = false;

  public tabla2FA: any = {
    headers: false,
    tableHeaders: false,
    multiSelect: false,
    columnas: [
      {
        name: 'idTipo2fa',
        key: 'idTipo2fa',
        hide: true
      },{
        name: 'Tipo de Autenticación',	
        key: 'txtNomTipo2fa',
        hide: false
      },{
        name: 'Estatus',
        key:  'estatus',
        type: 'estatus',
        hide: false
      },{
        name: 'Fecha Registro',
        key: 'fecRegistro',
        type: 'dateTime',
        hide: false
      },{
        name: 'Fecha Inactivo',
        key: 'fecActualizado',
        type: 'dateTime',
        hide: false
      }
    ]
  }


  constructor(
    private fb: FormBuilder,
    private app: ApplicationService,
    private localStorageService: LocalStorageService,
    private userService: UserService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.user = new User();
    this.user.admin = new Admin();

    this.personalInfoForm = this.fb.group({
      nombre: ['', [Validators.required]],
      apellidoPaterno: ['', [Validators.required]],
      apellidoMaterno: '',
      email: [{ value: '', disabled: true }, [Validators.required, Validators.email]],
    });

    this.passwordForm = this.fb.group({
      contrasenaActual: ['', [Validators.pattern]],
      contrasenaNueva: ['', [Validators.pattern]],
      contrasenaRepetida: ['', [Validators.pattern]],
    });
  }

  ngOnInit() {
    this.cargarDatos();
    this.obtenerSecret(); // Verifica si ya tiene vinculado el un secret
    this.cargarDatos2FA();

  }

  obtenerSecret(): void {
    this.userService.obtenerSecret(this.idUsuario).subscribe(
      (response) => {
        // Asigna el resultado del estado de vinculación y el secret
        this.hasSecret = response !== null;
       //this.secret = response || null; // Asigna el secret si está en `data`
      },
      (error) => {
        this.hasSecret = false; // En caso de error, se asume que no tiene GA
        this.otpSecret = null;
        console.error('Error al verificar el estado de Google Authenticator:', error);
      }
    );
  }

  vincularSecret(): void {
    this.mostrarQR = true;
    this.userService.vinculacionSecret(this.idUsuario).subscribe(
      (response) => {
        this.activarBoton = false;
        this.otpSecret = response.otpSecret;
        this.qrCodeUrl = response.qrCodeUrl; // URL del código QR devuelta por el backend
        this.hasSecret = response.alreadyLinked;
        this.cargarDatos2FA();
      },
      (err: any) => {
        this.app.showError(err);
      }
    );
  }

  confirmarOTP(otpCode: string): void {
    this.userService.confirmarOTP(this.otpSecret, otpCode).subscribe(
      (response) => {
        if (response.success && response.data) {
          this.hasSecret = true;
          this.mostrarQR = false;
          this.otpCode = '';
          this.otpSecret = null;
          this.qrCodeUrl = null;
          this.cargarDatos2FA();
          this.app.showSnackbar('¡Éxito!', 'Vinculado correctamente.', 3000, 'success');
        } else {
          this.hasSecret = false;
          this.app.showSnackbar('¡Aviso!', 'Código incorrecto.', 3000, 'warning');
        }
        // Fuerza la detección de cambios
        this.cdr.detectChanges();
      },
      (err: any) => {
        this.app.showError(err);
      }
    );
  }
  
  


  desvincularSecret(): void {
    const dialogRef = this.dialog.open(ConfirmacionDialog,{
      data: {
        titulo: "Confirmar",
        mensaje: "¿Estás seguro de que deseas desvincular tu app de autenticación?",
        texto: "Cuando desees volver a vincular la app de autenticación será necesario registrar un nuevo código QR.",
        icono: "save",
        boton1: "No",
        boton2: "Sí",
        claseAccion: "boton-accion-guardar"
    }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // Solo desvincula si el usuario confirmó en el modal
        this.userService.desvincularSecret(this.idUsuario).subscribe(
          () => {
            this.hasSecret = false; // Actualiza el estado
            this.activarBoton = false;
            this.cargarDatos2FA();
            //console.log('Google Authenticator desvinculado con éxito');
          },
          (error) => {
            console.error('Error al desvincular la cuenta de Google Authenticator:', error);
          }
        );
      }
    });
  }

  onSelected(rows) {
    this.itemsSelected = rows.slice();
  
    if (this.itemsSelected.length > 0 && !this.itemsSelected[0].banActivo) {
      this.activarBoton = true;
    } else {
      this.activarBoton = false;
    }
  }
  

  cancelarVinculacion(): void {
    this.itemsSelected = [];
    this.mostrarQR = false;
    this.otpCode = ''; // Limpia el código OTP ingresado, opcional
    this.qrCodeUrl = null; // Limpia la URL del QR, opcional
  }

  clickItemSelected(row) {
   if(row.banActivo && row.idTipo2fa == 2){
     this.desvincularSecret();
   }else if(!row.banActivo && row.idTipo2fa == 2){
     this.vincularSecret();
     this.mostrarQR = true;
   }else if (row.banActivo && row.idTipo2fa == 1){
     this.vincularSecret();
     this.mostrarQR = true;
   }else if(!row.banActivo && row.idTipo2fa == 1){
    this.desvincularSecret();
   }
  }

  cargarDatos() {
    let query = '?expand=admin';
    this.idUsuario = +localStorage.getItem('userID');

    this.userService.getOne(this.idUsuario, query).subscribe(
      (user) => {
        this.user = user;
        this.personalInfoForm.patchValue({
          nombre: this.user.admin.nombre,
          apellidoPaterno: this.user.admin.apellidoPaterno,
          apellidoMaterno: this.user.admin.apellidoMaterno,
          email: this.user.admin.email,
        });
        this.loading = false;
      }
    );

   
  }

  cargarDatos2FA() {
    this.cargandoDatos = true;
    this.userService.get2FA(this.idUsuario).subscribe(
      (res) => {
        // Mapear los datos para agregar la clave `estatus`
        this.items = res.map((item) => ({
          ...item,
          estatus: item.banActivo ? 'Activo' : 'Inactivo', // Agregar clave `estatus`
        }));
        this.cargandoDatos = false;
        this.activarBoton = false;
      },
      (err: any) => {
        this.cargandoDatos = false;
        this.app.showError(err);
      }
    );
  }
  

  guardarDatos() {
    this.actualizarPerfil('Se ha actualizado tu perfil correctamente');
  }

  cambiarContrasenia() {
    this.actualizarPerfil('Se ha actualizado tu contraseña.', true);
  }

  private actualizarPerfil(message: string, cambiarContrasena: boolean = false) {
    if (cambiarContrasena && !this.validarContrasena()) {
      return; // No continuar si la validación de contraseña falla
    }

    if (!cambiarContrasena && !this.validarNombreApellido()) {
      return; // No continuar si la validación de nombre y apellido falla
    }

    const loadingMessage = cambiarContrasena ? 'Cambiando contraseña.' : 'Actualizando información.';
    const loading = this.app.showLoading(loadingMessage);

    const requestObservable = cambiarContrasena
      ? this.userService.cambiarContrasena(this.idUsuario, {
        password: this.passwordForm.get('contrasenaActual').value,
        newPassword: this.passwordForm.get('contrasenaNueva').value
      })
      : this.userService.actualizarPerfil(this.idUsuario, this.user.admin);

    requestObservable.subscribe(
      () => {
        this.app.hideLoading(loading);
        this.app.showSnackbar('Aviso', message, 3000, 'success');
        this.cargarDatos();

      },
      (error) => {
        this.app.hideLoading(loading);
        if (cambiarContrasena && error === 'La contraseña es incorrecta') {
          this.app.showSnackbar('Aviso', 'La contraseña es incorrecta.', 3000, 'error');
        } else {
          this.app.showError(error);
        }
      }
    );
  }

  private validarContrasena(): boolean {
    const actual = this.passwordForm.get('contrasenaActual').value;
    const nueva = this.passwordForm.get('contrasenaNueva').value;
    const repetida = this.passwordForm.get('contrasenaRepetida').value;

    if (actual == '' || nueva == '' || repetida == '') {
      this.app.showSnackbar('¡Aviso!', 'Debe de capturar todos los campos.', 3000, 'warning');
      return false;
    }

    if (nueva.length < 4) {
      this.app.showSnackbar('¡Aviso!', 'La contraseña debe tener mínimo 4 caracteres.', 3000, 'warning');
      return false;
    }

    if (nueva !== repetida) {
      this.app.showSnackbar('¡Aviso!', 'Las contraseñas ingresadas no coinciden.', 3000, 'warning');
      return false;
    }

    if (nueva === actual) {
      this.app.showSnackbar('¡Aviso!', 'La contraseña no puede ser la misma.', 3000, 'warning');
      return false;
    }

    return true;
  }

  private validarNombreApellido(): boolean {
    //console.log(this.user.admin)
    const nombre = this.personalInfoForm.get('nombre').value;
    const apellidoPaterno = this.personalInfoForm.get('apellidoPaterno').value;
    const apellidoMaterno = this.personalInfoForm.get('apellidoMaterno').value;

    if (!nombre || nombre.trim() === '' || !apellidoPaterno || apellidoPaterno.trim() === '') {
      this.app.showSnackbar('¡Aviso!', 'Es necesario capturar Nombre y/o Apellido.', 3000, 'warning');
      return false;
    }
    this.user.admin.nombre = nombre;
    this.user.admin.apellidoPaterno = apellidoPaterno;
    this.user.admin.apellidoMaterno = apellidoMaterno;

    return true;
  }

}
