import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';

import { LenderService } from "../lender.service";
import { MatDialog } from '@angular/material/dialog';
import { take } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { ServiciosSaldosKobraService } from 'src/app/core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Lender } from '@lender/lender.model';
import { ConfigurationComponent } from 'src/app/lender/configuration/configuration.component';
import { environmentSelector} from 'src/environments/environment.selector';
import {ConfirmacionDialog} from "src/app/modales-genericos/confirmacion/confirmacion-dialog.component";
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { LenderDetailDialogComponent } from '@lender/detail/detail-dialog.component';
import {ModalEstadoCuentaComponent} from "../modales/estado-cuenta/estado-cuenta.component";
import { relative } from 'path';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';
import { LoadingService } from 'src/app/shared/components/loading-bar/loading-bar.service';
import { HttpEventType } from '@angular/common/http';
import moment from 'moment';
import { saveAs } from 'file-saver';



@Component({
  selector: 'lender-list',
  templateUrl: './lender-list.component.html',
  styleUrls: ['./lender-list.component.css'],
  providers: [
    LenderService
  ]
})
export class LenderListComponent implements OnInit, OnDestroy {
  private environment = environmentSelector();
  public totalFiltros: number = 0;

  hijosActivos: boolean = false;
  filtrosAbiertos: boolean = false;
  public lenders: any;
  public spinnerLenders: Lender[];
  //@ViewChild('modalTemplate') public modalTemplate: ModalTemplate<IContext, string, string>;
  public lenderData: any;
  public resError: string | null;
  private querySub: Subscription;
  public contabilizadorFiltros: FiltersContabilizer;
  public pagination: IUsuariosPagination;
  public pageSize = 50;
  public fromRowPage: number;
  public toRowPage: number;
  ayuda: boolean = false;
  itemsSelected: any[] = [];

  //@ViewChild('modalMovimientos') public modalMovimientos: ModalTemplate<{}, string, string>;
  movimientosForm: FormGroup;

  public queryParams: URLSearchParams;
  public fields = ['idFinanciera', 'nombreFinanciera', 'saldoDisponible', 'estatus'];

  public haySaldo = false;

  loading: boolean = true;

  tabla: any = {
    headers: false,
    multiSelect: false,
    columns: [
      {
        name: 'ID',
        key: 'idFinanciera',
        hide: false
      }, {
        name: 'Nombre',
        key: 'nombreFinanciera',
        type: 'link',
        hide: false
      }, {
        name: 'Saldo',
        key: 'saldoDisponible',
        type: 'money',
        link: true,
        hide: false
      }, {
        name: 'Estatus',
        key: 'estatus',
        hide: false
      }
    ]
  };

  filters = {
    nombre: null,
    activo: null,
    page: 1,
    pageSize: 50
  };
  /*filters = [{
    name: 'Estatus',
    keyAlter: 'activa',
    key: 'active',
    type: 'choice',
    value: '1',
    items: [{
      name: 'Activo',
      value: '1'
    }, {
      name: 'Inactivo',
      value: '0'
    }]
  }];*/

  constructor(
    private lenderService: LenderService,
    //private modalService: SuiModalService,
    private app: ApplicationService,
    private saldosServ: ServiciosSaldosKobraService,
    private route: ActivatedRoute,
    private filtrosService: FiltrosService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private loadingService: LoadingService

  ) {
    //localizationService.load('es', es);
    //localizationService.setLanguage('es');

    this.movimientosForm = this.formBuilder.group({
      fecha: ["", Validators.required],
      idFinanciera: ["", Validators.required],
      colFecha: [true, false],
      colPaquete: [true, false],
      colConcepto: [true, false],
      colImporte: [true, false],
      colFolioVisita: [false, false],
      colIdExterno: [false, false],
      colMunicipio: [false, false],
      colEstado: [false, false],
      colCP: [false, false]
    });
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();



  }

  ngOnInit() {
   // this.createQueryFilter(1);

    //this.loadSpinnerLenders();
    //this.readQueryParams();
    this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
  }

  private agregarContabilizadoresDeFiltros(){
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'nombre',
        'activo'

    ]);
  }

  public contabilizadorDeFiltrosTotales():void{


    let totalFiltrosArray: number[] = [];

    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );

    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

public contabilizadorDeFiltros(filtroName: string){
  return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
 }

  resultadoFiltros(filtrosNuevos: any) {
    if (filtrosNuevos) {
        this.filters = filtrosNuevos;
    }
    this.loadLenders(this.filters.page || 1);
}

  ngOnDestroy() {
    if(this.querySub){
      this.querySub.unsubscribe();
    }
  }

  readQueryParams() {
    this.querySub = this.route.queryParams.subscribe((params: Params) => {
      const page: number = params['_page'] || 1;

      this.loadLenders(page);
    });
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !== this.pagination.page || newPagina.pageSize !== this.filters.pageSize){
      this.filters.pageSize = newPagina.pageSize;
      this.loadLenders(newPagina.pageIndex+1);
    }
  }

  setQueryParam(page: number) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: {
          _page: page
        },
        //queryParamsHandling: 'merge'
      }
    );
  }

  private compare(a, b) {
    const nombreA = a.nombre.toUpperCase();
    const nombreB = b.nombre.toUpperCase();

    let comparison = 0;
    if (nombreA > nombreB) {
      comparison = 1;
    } else if (nombreA < nombreB) {
      comparison = -1;
    }
    return comparison;
  }

  limpiarFiltros(){
    this.filters = {
      nombre: null,
      activo: null,
      page: 1,
      pageSize: 20
    };


    //filtrosService.guardarFiltros(this.filters);
    //this.loadLenders(1);
  }

  public createQueryFilter(page: number): void {

    this.queryParams = new URLSearchParams();
    this.queryParams.set('_paginate', '1');
    this.queryParams.set('_page', page.toString());
    this.queryParams.set('_rows', String(this.pageSize));
    this.queryParams.set('_slt', this.fields.map(f => `"${f}"`).join(','));
    this.queryParams.set('_orderBy', 'nombreFinanciera');
    if (this.filters.nombre) this.queryParams.set('nombre', this.filters.nombre);
    if (this.filters.activo || this.filters.activo === false) this.queryParams.set('activa', this.filters.activo ? '1': '0');

    this.filtrosService.guardarFiltros(this.filters);

};

  public loadLenders(page: number): void {
    this.loading = true;

    this.createQueryFilter(page);

    this.saldosServ.get(`/financieras-grid?${this.queryParams.toString()}`)
      .pipe(
        take(1)
      ).subscribe((res: any) => {
        this.lenders = res.data.pageData;

        this.pagination = res.data;
        this.calcFromToRowPage();

        this.loading = false;
      }, (error: any) => {
        this.app.showError(error);

        this.loading = false;
      });
      this.contabilizadorDeFiltrosTotales();

  }

  public download(): void {

    this.loadingService.setLoading(true);


   this.saldosServ.descargarClientes(this.queryParams.toString()).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        this.loadingService.setLoading(false);
        const blob = new Blob([event.body], { type: event.body.type });
        const fechaActual = moment().format('YYYYMMDD'); // Formato de fecha, ajustable según necesidad
        saveAs(blob, `Listado-clientes-${fechaActual}`);
      }
    }, _error => {
      this.loadingService.setLoading(false);
      this.app.showSnackbar(
        "Aviso",
        "Ocurrio un problema al intentar descargar el archivo.",
        3000,
        "error"
      );
      console.log(_error)
    });
  }

  public showLenderInfo(idLender: number) {

    this.lenderData = undefined;
    this.resError = null;

    //const loading = this.app.showLoading('Estamos obteniendo la información del cliente.');

    this.lenderService.one(idLender)
      .pipe(
        take(1)
      ).subscribe((lender: any) => {
        this.lenderData = lender;
        //this.app.hideLoading(loading);
        const dialogRef = this.dialog.open(LenderDetailDialogComponent, {
          disableClose: false,
          width: '50%',
          data: this.lenderData
        });

        dialogRef.afterClosed().subscribe(data => {});
      }, (error: any) => {
        if (error.showMessage) {
          this.resError = error.message;
        } else {
          this.resError = 'Ocurrio un error al tratar de obtener los datos de la financiera.';
        }

        this.app.showError(error);
      });

    //const config = new TemplateModalConfig<IContext, string, string>(this.modalTemplate);
//
    //config.closeResult = 'closed!';
    //config.context = { data: '' };
    //config.size = ModalSize.Small;
//
    //this.modalService.open(config);
  }

  calcFromToRowPage() {
    if (this.pagination.pageData.length <= 0) {
      this.fromRowPage = 0;
      this.toRowPage = 0;
    } else {
      this.fromRowPage = (this.pageSize * this.pagination.page) - (this.pageSize - 1);

      if (this.pagination.page === this.pagination.pages) {
        this.toRowPage = this.pagination.totalRows;
      } else {
        this.toRowPage = this.pagination.page * this.pageSize;
      }
    }
  }

  openDescargaModal(){
    const dialogRef = this.dialog.open(ModalEstadoCuentaComponent, {
        data: this.itemsSelected,
    });

    dialogRef.afterClosed().subscribe(result => {});

  }

  ayudaComentario(bool: boolean){
    this.ayuda = bool;
  }

  onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  onLinkRow({ row, columnPressed }) {
    if (columnPressed === 'nombreFinanciera') {
      this.showLenderInfo(row.idFinanciera);
    } else if (columnPressed === 'saldoDisponible' && this.hasSaldosAccess()) {
      this.router.navigate([`/paquetes-cliente/${row.idFinanciera}`]);
    }
  }

  hasSaldosAccess() {
    if (this.environment.saldosAccess[0] === 'all') {
      return true;
    }

    const IDFounded = this.environment.saldosAccess.find(x => x === localStorage.getItem('userID'));

    if (IDFounded) {
      return true;
    }

    return false;
  }


  activarDesactivarClient() {
    const lender: any = this.itemsSelected[0];
    let icono: string = 'check';
    var actionLabel = 'activar';
    var actionLabelSuccess = 'activó';
    var actionCliente = this.lenderService.activateClient(lender.idFinanciera);

    if (lender.estatus === 'Activa') {
      actionLabel = 'inactivar';
      actionLabelSuccess = 'inactivó';
      icono = 'delete_forever';
      actionCliente = this.lenderService.inactivateClient(lender.idFinanciera);
    }

    const dialogRef = this.dialog.open(ConfirmacionDialog, {
      data:{
              titulo: "Confirmar",
              mensaje: `¿Estas seguro de ${actionLabel} a "${lender.nombreFinanciera}"?`,
              icono: icono,
              colorIcono: "warn",
              boton1: "No",
              boton2: "Sí",
              claseAccion: "boton-accion-eliminar"
          }
    });

    dialogRef.afterClosed().subscribe(response => {
      if (response) {
        actionCliente.subscribe(() => {
          this.app.showSnackbar('Aviso', `Se ${actionLabelSuccess} ${lender.nombreFinanciera.toLowerCase()} con exito`, 3000, 'success');

          if (+this.queryParams.get('_page') > 1) {
            this.setQueryParam(1);
          } else {
            this.loadLenders(this.pagination.page);
          }
        }, (error) => {
          this.app.showError(error);
        });
      }
    });
  }

  // openConfiguration() {

  //   const dialogRef = this.dialog.open(ConfigurationComponent, {
  //     disableClose: false,
  //     data: this.itemsSelected[0]
  //   });

  //   dialogRef.afterClosed().subscribe(data => {});
  // }

  openConfiguration() {
    const data = this.itemsSelected[0];

    this.router.navigate(['configuracion'], {
      relativeTo: this.route,
      queryParams:  data
    });

  }

  openEncargados(){
    const data = this.itemsSelected[0];
    localStorage.setItem('lenderTemp', JSON.stringify(data));

    this.router.navigate(['encargados'], {
      relativeTo: this.route
    });
  }

  inactivarHijo(){
    if(localStorage.getItem('lenderTemp')){
      localStorage.removeItem('lenderTemp');
    }

    this.filtrosService.asignarOpcion();
    if(this.filters){
      this.filtrosService.guardarFiltros(this.filters, true);
    }
    this.loadLenders(1);
  }

  sortChange(sort: any) {
    this.queryParams.set('_orderBy', `${sort.active} ${sort.direction.toUpperCase()}`);

    if (+this.queryParams.get('_page') > 1) {
      this.setQueryParam(1);
    } else {
      this.loadLenders(1);
    }
  }

  /*applyFilters(values = {}) {
    return this.filters.map(f => {
      const value = values[f.key];

      if (value) {
        this.queryParams.set(f.keyAlter, value);
      } else {
        this.queryParams.delete(f.keyAlter);
      }

      return { ...f, value };
    });
  }*/

  /*filtersChange(values) {
    this.filters = this.applyFilters(values);

    if (+this.queryParams.get('_page') > 1) {
      this.setQueryParam(1);
    } else {
      this.loadLenders(1);
    }
  }*/

  searchChange(text) {
    if (Boolean(text)) {
      this.queryParams.set('nombre', text);
    } else {
      this.queryParams.delete('nombre');
    }

    if (+this.queryParams.get('_page') > 1) {
      this.setQueryParam(1);
    } else {
      this.loadLenders(1);
    }
  }
}

export interface IContext {
  data: string;
}

export interface IClientesSaldos {
  idFinanciera: number;
  nombre: string;
  saldoDisponible: any;
  activa: boolean;
}

export interface IUsuariosPagination {
  page: number;
  pageData: [IClientesSaldos];
  pages: number;
  totalRows: number;
}
