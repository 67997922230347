<mat-accordion>
  <mat-expansion-panel class="acordeon" *ngIf="agente && agente.length > 0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4 style="margin-bottom: 0px;">Agente</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-chip-listbox aria-label="Fish selection">
        <mat-chip-option *ngFor="let variable of agente" (click)="onClick(variable, 'claveUsuario');"
          matTooltip="{{variable.descripcion}}"
          mat-tooltip-panel-above
          [selectable]="false"
        >{{variable.claveUsuario}}</mat-chip-option>
    </mat-chip-listbox>
  </mat-expansion-panel>

  <mat-expansion-panel class="acordeon" *ngIf="!esCredito">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4 style="margin-bottom: 0px;">Cuenta</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-chip-listbox aria-label="Fish selection">
        <mat-chip-option *ngFor="let variable of cuenta" (click)="onClick(variable, 'claveUsuario');"
          matTooltip="{{variable.descripcion}}"
          mat-tooltip-panel-above
          [selectable]="false"
        >{{variable.claveUsuario}}</mat-chip-option>
    </mat-chip-listbox>
  </mat-expansion-panel>

  <mat-expansion-panel class="acordeon" *ngIf="!esCredito">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h4 style="margin-bottom: 0px;">Detalle de la cuenta</h4>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <mat-chip-listbox aria-label="Fish selection">
        <mat-chip-option *ngFor="let variable of cuentaDetalle" (click)="onClick(variable, 'claveUsuario');"
          matTooltip="{{variable.descripcion}}"
          mat-tooltip-panel-above
          [selectable]="false"
        >{{variable.claveUsuario}}</mat-chip-option>
        <mat-chip-option *ngIf="cuentaDetalle.length == 0"
          mat-tooltip-panel-above
          [selectable]="false"
        >No hay variables para mostrar</mat-chip-option>
    </mat-chip-listbox>
  </mat-expansion-panel>
</mat-accordion>
