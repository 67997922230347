import { Component } from '@angular/core';
import { NotificationService } from './dashboard-main/notification.service';
import { TokenExpirationService } from './authentication/token-expiration.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private notificationService: NotificationService,
    private tokenExpirationService: TokenExpirationService,

  ) {}
  title = 'kobra-admin-15';
}
