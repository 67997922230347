import { Injectable, TemplateRef, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Overlay, OverlayRef, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { HtmlTooltipComponent } from './html-tooltip.component'; // Suponiendo que tienes un componente Tooltip
import { SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class TooltipOverlayService {
  constructor(private overlay: Overlay, private resolver: ComponentFactoryResolver) {}

  showTooltip(target: HTMLElement, content: string | SafeHtml, viewContainerRef: ViewContainerRef) {
    const strategy = this.overlay.position().flexibleConnectedTo(target).withPositions([
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 8
      }
    ]);

    const overlayRef = this.overlay.create(new OverlayConfig({
      positionStrategy: strategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    }));

    const tooltipPortal = new ComponentPortal(HtmlTooltipComponent, viewContainerRef);
    const componentRef = overlayRef.attach(tooltipPortal);
    componentRef.instance.content = content;

    return overlayRef;
  }
}
