<!--div class="kobra four columns">
  <div class="kobra column" style="margin-top: 10px;" *ngFor="let campoExtra of camposExtrasAgentes; let i = index">
    <ng-container [ngSwitch]="campoExtra.idTipoDato">
      <ng-container *ngSwitchCase="tipoDato.ENTERO">
        <app-field
          class="campo-extra"
          ngDefaultControl
          [maxValue]="99999999"
          type="number"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.DECIMAL">
        <app-field
          class="campo-extra"
          ngDefaultControl
          [maxValue]="99999999.999999"
          type="decimal"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.ALFANUMERICO">
        <app-field
          class="campo-extra"
          ngDefaultControl
          [maxLenghtOptions]="3000"
          type="string"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.FECHA">
        <app-field
          class="campo-extra"
          ngDefaultControl
          type="date"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.FECHA_HORA">
        <app-field
          class="campo-extra"
          ngDefaultControl
          type="datetime"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.LOGICO">
        <app-field
          class="campo-extra"
          ngDefaultControl
          type="boolean"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.HORA">
        <app-field
          class="campo-extra"
          ngDefaultControl
          type="time"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>
    </ng-container>
  </div>
</div-->
<mat-grid-list-responsive [colsXl]="colsXl" [colsMd]="colsMd" [colsSm]="colsSm" [rowHeight]="rowHeight">
  <mat-grid-tile colspan="1" rowspan="1" *ngFor="let campoExtra of camposExtrasAgentes; let i = index">
    <ng-container [ngSwitch]="campoExtra.idTipoDato">
      <ng-container *ngSwitchCase="tipoDato.ENTERO">
        <app-field
          class="campo-extra"
          ngDefaultControl
          [maxValue]="99999999"
          type="number"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.DECIMAL">
        <app-field
          class="campo-extra"
          ngDefaultControl
          [maxValue]="99999999.999999"
          type="decimal"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.ALFANUMERICO">
        <app-field
          class="campo-extra"
          ngDefaultControl
          [maxLenghtOptions]="3000"
          type="string"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.FECHA">
        <app-field
          class="campo-extra"
          ngDefaultControl
          type="date"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.FECHA_HORA">
        <app-field
          class="campo-extra"
          ngDefaultControl
          type="datetime"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.LOGICO">
        <app-field
          class="campo-extra"
          ngDefaultControl
          type="boolean"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>

      <ng-container *ngSwitchCase="tipoDato.HORA">
        <app-field
          class="campo-extra"
          ngDefaultControl
          type="time"
          [(ngModel)]="campoExtra.valor"
          [ngModelOptions]="{standalone: true}"
          [label]="campoExtra.nombre"
          placeholder="{{campoExtra.descripcion}}"
          [required]="campoExtra.requerido"
        ></app-field>
      </ng-container>
    </ng-container>
  </mat-grid-tile>
</mat-grid-list-responsive>
