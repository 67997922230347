import { StorageKobraService } from 'src/app/core/http/storage-kobra/storage-kobra.service';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Account } from './account.model';
import { AccountPaginator } from './account.paginator';
import { Pagination } from '../pagination/pagination.model';
import { ApiKobraService } from '../core/http/api-kobra/api-kobra.service';
import { ServiciosKobraService } from "@servicios/servicios-kobra.service";
import { SessionData } from '../shared/interfaces/session-data';
import { LocalStorageService } from '../shared/services/local-storage.service';
import { CuentaAgrupadaPaginator } from '../cuentas/cuentas-agrupadas/modelos/cuenta-agrupada.paginator';
import { CuentaAgrupada } from '../cuentas/cuentas-agrupadas/modelos/cuenta-agrupada.model';


@Injectable()
export class AccountService {
    private resource: string = '/v2/accounts';
    private leanServicesURL = '/cuentas';
    sessionData: SessionData;

    constructor(
        private apiKobraService: ApiKobraService,
        private serviciosKobra: ServiciosKobraService,
        private storageKobraService: StorageKobraService,
        private localStorageService: LocalStorageService
    ) {
        this.sessionData = this.localStorageService.getSessionData();
     }

    public assignAgent(accounts: Account[], agentID: number, comment: string = '', horarios: object, fechaVisita: string, horarioAgenteConfirmado: boolean, fechaLimite: string): Observable<any> {
        let data: any = {
            accounts: accounts,
            agentID: agentID,
            comment: comment,
            horarios: horarios,
            fecVisita: fechaVisita,
            horarioAgenteConfirmado: horarioAgenteConfirmado,
            fechaLimite: fechaLimite,
        };

        return this.serviciosKobra.post(this.leanServicesURL + '/asignar', data).pipe(
            map(res => {
                let response: any = {
                    success: res.success,
                    accounts: res.data.accounts,
                    message: res.data.message,
                    idAgent: agentID,
                    isAsignacion: res.data.isAsignacion,
                }
                return response;
            })
        );
    }

    public asignarAgente(idCuenta: number, idAgente: number, comentario: string = '', horarios: object, fechaVisita: string, horarioAgenteConfirmado: boolean, fechaLimite: string,
                        idCliente: number, idAcreditadoPG: number, idCuentaPG: number, domicilios: any): Observable<any> {
        let data: any = {
            idCuenta: idCuenta,
            idAgente: idAgente,
            comentario: comentario,
            horarios: horarios,
            fecVisita: fechaVisita,
            horarioAgenteConfirmado: horarioAgenteConfirmado,
            fechaLimite: fechaLimite, 
            idCliente: idCliente,
            idAcreditadoPG: idAcreditadoPG, 
            idCuentaPG: idCuentaPG,
            domicilios: domicilios
        };

        return this.serviciosKobra.post(this.leanServicesURL + `/${idCuenta}/agente/${idAgente}/asignar`, data).pipe(
            map(res => {
                let response: any = {
                    success: res.success,
                    accounts: res.data.cuentas,
                    message: res.data.message,
                    idAgent: idAgente,
                    isAsignacion: res.data.isAsignacion,
                }
                return response;
            })
        );
    }

    public revisita(idFinanciera: number, idCuenta: number, idVisita: number, idCobrador: number, idAcreditado: number, fullname: string, comentario: string = '', idAgente: number, idVisitaPSG: number,
        horarios: object, fechaVisita: string, horarioAgenteConfirmado: boolean, idAgenteRevisita: number, 
        idCliente: number, idAcreditadoPG: number, idCuentaPG: number, domicilios: any): Observable<any> {
        let data: any = {
            idFinanciera: idFinanciera,
            idVisita: idVisita,
            idCobrador: idCobrador,
            idAcreditado: idAcreditado,
            comentario: comentario,
            nombreAcreditado: fullname,
            idAgente: idAgente,
            idVisitaPSG: idVisitaPSG,
            horarios: horarios,
            fecVisita: fechaVisita,
            horarioAgenteConfirmado: horarioAgenteConfirmado,
            idAgenteRevisita: idAgenteRevisita,
            idCliente: idCliente, 
            idAcreditadoPG: idAcreditadoPG,
            idCuentaPG: idCuentaPG,
            domicilios :  domicilios
        };

        return this.serviciosKobra.post(this.leanServicesURL + `/${idCuenta}/revisita`, data).pipe(
            map(res => {
                let response: any = {
                    success: res.success,
                    message: res.data.message,
                    isAsignacion: res.data.isAsignacion
                }
                return response;
            })
        );
    }

    public getAll(query: string): Observable<Account[]> {
        return this.apiKobraService.get(this.resource + query).pipe(
            map(res => <Account[]>res.data)
        );
    }

    public getOne(id: number, query: string = ''): Observable<Account> {
        return this.apiKobraService.get(`${this.resource}/${id}${query}`).pipe(
            map(res => <Account>res.data)
        );
    }

    public getPaginated(pagination: Pagination, extraParams: string): Observable<AccountPaginator> {
        let query: string = '?paginated=true' + extraParams;
        query += '&page=' + pagination.nextPage;

        return this.apiKobraService.get(this.resource + query).pipe(
            map(res => <AccountPaginator>res)
        );
    }

    public obtenerAgrupadas(pagination: Pagination, extraParams: string, rows: number): Observable<CuentaAgrupadaPaginator> {
        let start = 0;
        if (pagination.nextPage > 1) {
            start = ((pagination.nextPage - 1) * rows) + 1;
        }
        let query: string = '/cuentas-agrupadas-grid?start=' + start + extraParams;

        return this.serviciosKobra.get(query).pipe(
            map(res => {
                let response: any = res.data;
                let accountPaginator: CuentaAgrupadaPaginator = new CuentaAgrupadaPaginator();
                accountPaginator.pagination = new Pagination();
                accountPaginator.pagination.currentPage = response.page ? response.page : 1;
                accountPaginator.pagination.pageCount = response.pages;
                accountPaginator.pagination.perPage = rows;
                accountPaginator.pagination.totalCount = response.totalRows;
                accountPaginator.data = [];
                for (let i = 0; i < response.pageData.length; i++) {
                    let account: CuentaAgrupada = CuentaAgrupada.map(response.pageData[i]);
                    accountPaginator.data.push(account);
                }
                return accountPaginator;
            })
        );
    }

    public obtenerListado(pagination: Pagination, extraParams: string, rows: number): Observable<AccountPaginator> {
        let start = 0;
        if (pagination.nextPage > 1) {
            start = ((pagination.nextPage - 1) * rows) + 1;
        }
        let query: string = '/cuentas-todas-grid?start=' + start + extraParams;

        return this.serviciosKobra.get(query).pipe(
            map(res => {
                let response: any = res.data;
                let accountPaginator: AccountPaginator = new AccountPaginator();
                accountPaginator.pagination = new Pagination();
                accountPaginator.pagination.currentPage = response.page ? response.page : 1;
                accountPaginator.pagination.pageCount = response.pages;
                accountPaginator.pagination.perPage = rows;
                accountPaginator.pagination.totalCount = response.totalRows;
                accountPaginator.data = [];
                for (let i = 0; i < response.pageData.length; i++) {
                    let account: Account = new Account();
                    let queryString = '';
                    account.id = response.pageData[i].accountID;
                    account.createdAt = response.pageData[i].accountCreatedAt;
                    account.daysInPlatform = response.pageData[i].accountDaysInPlatform;
                    account.daysUntilFirstVisit = response.pageData[i].daysUntilFirstVisit;
                    account.daysTaken = response.pageData[i].accountDaysTaken;
                    account.daysPastDue = response.pageData[i].diasMoratorios;
                    account.telefonoCelular = response.pageData[i].telefonoCelular;
                    account.clienteActivo = response.pageData[i].clienteActivo;
                    account.status = response.pageData[i].accountStatus;
                    account.assigned.assignedDate = response.pageData[i].accountAssignedDate;
                    account.client.idAcreditado = response.pageData[i].clientID;
                    account.client.externalID = response.pageData[i].externalID;
                    account.client.subExternalID = response.pageData[i].subExternalID;
                    account.client.fullName = response.pageData[i].clientFullName;
                    account.client.address.city = response.pageData[i].city;
                    account.client.address.municipality = response.pageData[i].municipality;
                    account.client.address.state = response.pageData[i].state;
                    account.client.address.suburb = response.pageData[i].suburb || "";
                    account.client.address.zipCode = response.pageData[i].cp || "";
                    account.client.address.calle = response.pageData[i].calle || "";
                    account.client.address.numeroExt = response.pageData[i].numeroExterior || "";
                    account.client.address.numeroInt = response.pageData[i].numeroInterior || "";
                    account.client.address.geoloc = response.pageData[i].clientAddressGeoloc == '1' ? true : false;
                    account.lender.id = response.pageData[i].lenderID;
                    account.lender.name = response.pageData[i].lenderName;
                    account.product.name = response.pageData[i].productName;
                    account.product.id = response.pageData[i].idProducto;
                    account.taken.takenDate = response.pageData[i].takenAccountTakenDate;
                    account.taken.expirationDate = response.pageData[i].takenAccountExpirationDate;
                    account.taken.agent.id = response.pageData[i].agentID;
                    account.taken.agent.fullName = (response.pageData[i].agentID || "") + " - " + (response.pageData[i].agentFullName || "");
                    account.tag.id = response.pageData[i].operationTagID;
                    account.tag.name = response.pageData[i].operationTagName;
                    account.visibility = response.pageData[i].visibility;
                    account.grupo = response.pageData[i].grupo;
                    account.sucursal = response.pageData[i].sucursal;
                    account.totalVisitas = response.pageData[i].totalVisitas;
                    account.lat = response.pageData[i].lat;
                    account.lng = response.pageData[i].lng;
                    account.nombreEtiquetaCuenta = response.pageData[i].nombreEtiquetaCuenta;
                    account.idCliente = response.pageData[i].idCliente;
                    account.idAcreditadoPG = response.pageData[i].idAcreditadoPG;
                    account.idCuentaPG = response.pageData[i].idCuentaPG;
                    account.client.address.folioDomicilio = response.pageData[i].folioDomicilio;
                    account.client.address.idDomicilioAcreditado = response.pageData[i].idDomicilioAcreditado;
                    account.tipoCobertura = response.pageData[i].tipoCobertura;
                    account.coordenadas = response.pageData[i].lat ? `${response.pageData[i].lat} , ${response.pageData[i].lng}` : 'No disponible';
                    account.lenderID = response.pageData[i].lenderID;
                    account.domicilios = [{idDomicilioAcreditado:response.pageData[i].idDomicilioAcreditado}];
                    if(response.pageData[i].subExternalID){
                        queryString = "&subIdExterno="  + response.pageData[i].subExternalID;
                    };
        
                    if(this.sessionData.isSuperAdmin){
                        queryString += "&idCliente="  + response.pageData[i].idCliente;
                    };
                    account.daysUntilExpiration = this.calcularDiasRestantes(response.pageData[i].takenAccountExpirationDate);
                    account.urlDetalleCuenta = `?idExterno=${response.pageData[i].externalID}${queryString}`;
                    account.idAcreditadoCuentaDomicilio = response.pageData[i].idAcreditadoCuentaDomicilio;
                    account.client.address.fullAddress = response.pageData[i].domicilioCompleto ? response.pageData[i].domicilioCompleto : `${account.client.address.calle ? account.client.address.calle + ' ' : ''} 
                        ${account.client.address.numeroExt ? account.client.address.numeroExt + ', ' : ''}
                        ${account.client.address.numeroInt ? account.client.address.numeroInt + ', ' : ''}
                        ${account.client.address.suburb ? account.client.address.suburb + ' ' : ''}
                        ${account.client.address.zipCode ? account.client.address.zipCode + ', ' :''}
                        ${account.client.address.municipality?account.client.address.municipality + ' ': ''}
                        ${account.client.address.state? account.client.address.state : ''}`;

                    
                    accountPaginator.data.push(account);
                }

                return accountPaginator;
            })
        );
    }

    public calcularDiasRestantes(fechaVencimiento: string): number {
        const hoy = new Date();
        hoy.setHours(0, 0, 0, 0);
        const vencimiento = new Date(fechaVencimiento);
        vencimiento.setHours(0, 0, 0, 0);
        const diferenciaTiempo = vencimiento.getTime() - hoy.getTime();
        const diferenciaDias = Math.ceil(diferenciaTiempo / (1000 * 60 * 60 * 24)) + 1;
        return Math.max(diferenciaDias, 0);
    }
    
    

    public obtenerCuentasMapa(start: number, rows: number, page: number, query = '') {
        query+= `&start=${start}&rows=${rows}&page=${page}`;
        let uri: string = '/cuentas-todas-grid?' + query;

        return this.serviciosKobra.get(uri).pipe(
            map(res => {return res.data;})
        );
        
    }

    public setStatus(accounts: any, status: string, idOperationTag: number): Observable<any> {
        const id: any = accounts.id;

        let data = {
            idFinanciera: accounts.lenderID,
            estatus: status,
            idEtiquetaOperacion: idOperationTag,
            idCliente:  accounts.idCliente,
            idAcreditadoPG: accounts.idAcreditadoPG,
            idCuentaPG: accounts.idCuentaPG,
            domicilios: accounts.domicilios
        };

        return this.serviciosKobra.patch(`/cuentas/${id}/estatus`, data);
    }

    public setTag(id: number, idCliente: number, idAcreditadoPG: number, idCuentaPG: number, tag: number): Observable<any> {
        const data = {
            idTag: tag,
            idCliente: idCliente,
            idAcreditado: idAcreditadoPG,
            idCuenta: idCuentaPG
        };
        return this.serviciosKobra.patch(`/cuentas/${id}/tag`, data);
    }

    public getCodigoPostal(idMunicipio: number): Observable<any> {
        return this.serviciosKobra.get(`/municipios/${idMunicipio}/codigos-postales`)
            .pipe(
                map((res) => {
                    return res.data as any;
                })
            );
    }

    public obtenerDomicilios(idExterno: any, idFinanciera: any, idCuenta: number) {
        const slt = 'calle, numeroExt, colonia, lat, ciudad, municipio, estado, primary, lng, idDomicilioAcreditado, estatus';

        return this.serviciosKobra.get(`/acreditados/address-history-grid?idExterno=${idExterno}&idFinanciera=${idFinanciera}&idCuenta=${idCuenta}&slt=${slt}`);
    }

    public actualizarDomicilio(idExterno: any, idFinanciera: any, idAcreditado: any, idDomicilioAcreditado: any, idCuenta: number) {
        return this.serviciosKobra.patch(`/acreditados/${idAcreditado}/domicilio-acreditados/${idDomicilioAcreditado}?idExterno=${idExterno}&idFinanciera=${idFinanciera}&idCuenta=${idCuenta}`, null);
    }

    public subirContrato(uploadFile: File[], idCita: number, idAcreditado: number, idCuenta: number, idCliente: number, idFinanciera: number, idTipoArchivoAcreditado: number) {
        const formData = new FormData();

        for (let i = 0; i < uploadFile.length; i++) {
            formData.append('uploadFile[]', uploadFile[i]);
        }

        if( idCita !== null ) {
            formData.append('idCita', idCita.toString());
        }

        formData.append('idAcreditado', idAcreditado.toString());
        formData.append('idCuenta', idCuenta.toString());
        formData.append('idCliente', idCliente.toString());
        formData.append('idFinanciera', idFinanciera.toString());
        formData.append('idTipoArchivoAcreditado', idTipoArchivoAcreditado.toString());

        return this.storageKobraService.uploadFile(`/acreditados/archivos`, formData);
    }

    public getDiasVencimiento(query: string): Observable<any> {
        return this.serviciosKobra.get(`/configuracion/dias-vencimiento-cuentas${query}`)
            .pipe(
                map((res) => {
                    return res.data as any;
                })
            );
    }

    public descargarExcelCuentas(params:string){
        return this.serviciosKobra.get( `/cuentas-grid-download${params}`);
    }

    public descargarExcelCuentasAgrupadas(params:string){
        return this.serviciosKobra.get( `/cuentas-agrupadas-grid-download${params}`);
    }
   
}
