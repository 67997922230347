<app-modal [title]="title" [onlyClose]="true" (onClose)="salir();">
  <div class="contenido">
    <app-field
      ngDefaultControl
      [maxValue]="maxValue"
      type="number"
      [(ngModel)]="posicion"
      placeholder="Nueva posición"
      [required]="true"
      class="posicion"
    ></app-field>
  </div>
  <button mat-stroked-button m-actions class="boton-accion-front" (click)="guardar()"
    >
      <mat-icon>check</mat-icon>
      Aceptar
    </button>
</app-modal>
