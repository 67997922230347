<router-outlet (activate)="hijosActivos = true" (deactivate)="hijosActivos = false; inactivarHijo();"></router-outlet>
<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <div class="div-filtros-side">
      <li class="filtro-side">
        <mat-form-field class="pago-gestor" style="width: 100%;">
          <mat-label>Nombre de etiqueta</mat-label>
          <input matInput textSanitizer 
            [(ngModel)]="filters.nombre"
            maxlength="100"
            placeholder="Capture la etiqueta a buscar">
            <mat-icon matSuffix  class="icono-limpiar"
                *ngIf="filters.nombre" 
                (click)="filters.nombre = null">clear</mat-icon>
          <mat-icon matSuffix>sell</mat-icon>
        </mat-form-field>
        <!-- <div align="left" *ngIf="filters.nombre" class="limpiar-seleccion-kobra">
          <a (click)="filters.nombre = null"><i>Limpiar</i></a>
        </div> -->
      </li>
      <li class="filtro-side">
        <mat-form-field style="width: 100%;">
            <mat-label>Estatus</mat-label>
            <mat-select
              [(ngModel)]="filters.estatus"
              placeholder="Seleccione..."
            >
            <mat-option [value]="true">Activa</mat-option>
            <mat-option [value]="false">Inactiva</mat-option>
            </mat-select>
            <mat-icon matSuffix class="icono-limpiar" 
                  *ngIf="filters.estatus != null" 
                  (click)="filters.estatus = null;$event.stopPropagation();">clear</mat-icon> 
            <mat-icon matSuffix>visibility</mat-icon>
        </mat-form-field>
        <!-- <div align="left" *ngIf="filters.estatus != null" class="limpiar-seleccion-kobra">
          <a (click)="filters.estatus = null"><i>Limpiar selección</i></a>
        </div> -->
      </li>
    </div>
    
    <button
    class="boton-accion-front"
      style="width: 100%;"
      mat-stroked-button
      color="primary"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos; setFilter();">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="limpiarFiltros()">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>
  
  <div class="app content tabla kobra" *ngIf="!hijosActivos">
    <div class="button-container">
        <div class="button-toggle-group-container">
          <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
            <mat-button-toggle value="filtros" 
                (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
                matTooltip="Filtrar"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">filter_alt</mat-icon>
                <span *ngIf="totalFiltros >= 1" class="notification-dot-filtros notification-dot-accion">{{ totalFiltros }}</span>

            </mat-button-toggle>
            <mat-button-toggle value="descargas"  
                (click)="descargarListado()" 
                matTooltip="Descargar etiquetas"
                mat-tooltip-panel-above>
                <mat-icon class="kobra-icon">cloud_download</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="actualizar"
              (click)="etiquetasVisitasService.obtenerEtiquetas(filters.page ? filters.page : 1, filters);"
              matTooltip="Refrescar"
              mat-tooltip-panel-above>
              <mat-icon class="kobra-icon">sync</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="subMenu"  
              matTooltip="Acciones"
              mat-tooltip-panel-above
              [matMenuTriggerFor]="menu">
              <mat-icon class="kobra-icon">more_vert</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle value="ayuda" 
              matTooltip="Ayuda"
              mat-tooltip-panel-above
              (click)="ayuda = !ayuda;">
              <mat-icon color="primary">help</mat-icon>
            </mat-button-toggle>
          </mat-button-toggle-group>
        </div>
    </div>
    
    <mat-menu #menu="matMenu">
      <button mat-menu-item [routerLink]="['form']" queryParamsHandling="preserve">
        <mat-icon class="kobra-icon">add</mat-icon>
        <span>Nueva etiqueta</span>
     </button>
      <button mat-menu-item [disabled]="bloqueoAsignar"
      (click)="openModalAsignar()">
        <mat-icon class="kobra-icon">done_all</mat-icon>
        <span>Asignación masiva</span>
      </button>
     <button mat-menu-item [disabled]="validarSeleccion(true)"
     (click)="openModalEstatus(true)">
          <mat-icon class="kobra-icon">check</mat-icon>
          <span>Activar</span>
      </button> 
     <button mat-menu-item [disabled]="validarSeleccion(false)"
     (click)="openModalEstatus(false)">
          <mat-icon class="kobra-delete-icon">do_not_disturb-on</mat-icon>
          <span>Inactivar</span>
      </button> 
    </mat-menu>
    
    <app-table class="table-margen"
      [classContainer]="'tabla-completa'"
      [loading]="etiquetasVisitasService.loader"
      [columns]="tabla.columns"
      [items]="etiquetasVisitasService.paginationInfo.pageData"
      [headers]="tabla.headers"
      [multiSelect]="tabla.multiSelect"
      (sortChange)="sortChange($event)"
      (onSelectedRows)="onSelected($event)"
    >
      <div t-header>
        <mat-card *ngIf="ayuda" class="cardAyuda">
          <mat-card-content>
           <p><mat-icon color="primary">help</mat-icon> 
            El módulo "Etiquetas de visita" es una herramienta esencial para la gestión y organización de las visitas. Funciona como un catálogo que alberga todas las etiquetas disponibles en el sistema, las cuales son fundamentales para la clasificación y seguimiento de las visitas. Las etiquetas que se dan de alta en este módulo pueden ser asociadas a clientes y productos específicos. Esta vinculación es crucial para personalizar y mejorar la eficacia de los cuestionarios de las visitas.
           </p> 
           Las acciones en este módulo son:
           <p>
             <mat-icon class="kobra-icon">filter_alt</mat-icon><b>Filtrar:</b>Mejora la búsqueda y el acceso a las etiquetas, permitiendo aplicar filtros basados en diversos criterios.
           </p>
           <p>
             <mat-icon class="kobra-icon">sync</mat-icon><b>Refrescar:</b> Te permite recargar tu listado con los criterios de filtros aplicados.
           </p>
           <p>
             <mat-icon class="kobra-icon">done_all</mat-icon><b>Asignación masiva:</b> Te permite asignar de manera masiva los elementos seleccionados en el listado para asignarlos a una producto de algun cliente.
           </p>
           <p>
             <mat-icon class="kobra-icon">check</mat-icon><b>Activar:</b> Te permite activar etiquetas de visitas.
           </p>
           <p>
             <mat-icon class="kobra-delete-icon">do_not_disturb-on</mat-icon><b>Inactivar:</b> Te permite inactivar etiquetas de visitas.
           </p>
         
           <p>En resumen, el módulo "Etiquetas de visita" es una función integral para la gestión eficiente de las visitas, ofreciendo a los usuarios la capacidad de clasificar y organizar las visitas de una manera estructurada y sistemática a través del uso de etiquetas personalizadas.
          </p>
         
         </mat-card-content>
       </mat-card> 
     </div>
      <ng-container t-paginator>
        <mat-paginator 
          showFirstLastButtons
          [length]="etiquetasVisitasService.pagination?.totalRows"
          [pageIndex]="etiquetasVisitasService.pagination?.page-1"
          [pageSize]="etiquetasVisitasService.pagination.pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
  </div>
  