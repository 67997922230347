import { Component, OnInit, Input, ViewChild, Inject, ElementRef, EventEmitter } from '@angular/core';
import { ApplicationService } from '../../application/shared/application.service';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';

@Component({
  selector: 'app-checklist-respuestas-tablas',
  templateUrl: './checklist-respuestas-tablas.component.html',
  styleUrls: ['./checklist-respuestas-tablas.component.css'],
  providers: [
  ]
})
export class ChecklistRespuestaTablaComponent implements OnInit {

  respuestas: any;
  respuestaActual: any;
  pregunta: any;
  title: string;
  subtitle: string;
  edicion: boolean;
  dynamicTableForm: FormGroup;
  valueAnterior: any;
  respuestasAgregadas: any[];
  @ViewChild('primerControl') nombreInput: ElementRef;

  constructor(
    private app: ApplicationService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ChecklistRespuestaTablaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.respuestas = this.data.respuestas;
    this.respuestaActual = this.data.respuestaActual;
    this.pregunta = this.data.pregunta;
    this.edicion = this.data.edicion ?? false;
    if(this.edicion){
      this.valueAnterior = this.data.value;
    }

    this.title = this.pregunta.nombrePregunta;
    this.subtitle = this.respuestaActual.nombre || this.respuestaActual.nombreRespuesta;
    this.respuestasAgregadas = [];
    this.crearFormGruop();
  }

  ngOnInit() {

  }

  crearFormGruop() {
    this.dynamicTableForm = this.fb.group({});

    // Recorre las respuestas y agrega controles al FormGroup
    for (const respuesta of this.respuestas) {
      const validators = [];
      if (respuesta.requerida) {
        validators.push(Validators.required);
      }
      /*if (respuesta.idTipoRespuesta === 4) { // Suponiendo que el tipo 4 es para números decimales
        validators.push(Validators.pattern(/^\d+(\.\d{1,6})?$/));
      }*/
      if(!this.edicion){
        respuesta.valor = null;
        respuesta.valorText = null;
        respuesta.valorTemp = null;
      }
      else{
        if(respuesta.idTipoRespuesta == 9){
          let option = respuesta.options.filter(opcion=>opcion.id == this.valueAnterior[respuesta.idChecklistRespuesta.toString()]);
          option = option ? option[0] : null;
          respuesta.valorTemp = option;
        }
        else{
          respuesta.valor = this.valueAnterior[respuesta.idChecklistRespuesta.toString()];
        }
      }
      this.dynamicTableForm.addControl(
        respuesta.idChecklistRespuesta.toString(),
        new FormControl(respuesta.valor || '', validators)
      );
    }
  }

  validarControl(control: string, esGuardar: boolean = false) {
    return esGuardar ? this.dynamicTableForm.controls[control].invalid : this.dynamicTableForm.controls[control].invalid && this.dynamicTableForm.controls[control].touched;
  }

  validarRespuestas() : boolean{
    let validas: boolean = true;
    let respuestasInvalidas: boolean[] = [];
    for (let index = 0; index < this.respuestas.length; index++) {
      const respuesta = this.respuestas[index];
      respuestasInvalidas.push(!respuesta.valor);
    }

    if(!respuestasInvalidas.includes(false)){
      validas = false;
    }
    return validas;
  }

  onSwitchValueChange(respuesta: any, respuestaSwitch: string = '')
  {
    if(respuestaSwitch == 'Si' && respuesta.switchSi){
      respuesta.switchNo = false;
      respuesta.valor = '1';
    }
    else if(respuestaSwitch == 'No' && respuesta.switchNo){
      respuesta.switchSi = false;
      respuesta.valor = "0";
    }
    else if(respuestaSwitch == 'No' || respuestaSwitch == 'Si'){
      respuesta.valor = '';
    }
    this.dynamicTableForm.controls[respuesta.idChecklistRespuesta.toString()].setValue(respuesta.valor);
    this.onChangeValue(respuesta);
  }

  onChangeValue(respuesta: any) {
    setTimeout(() => {
      if(respuesta.valorTemp){
        respuesta.valor = respuesta.valorTemp.id;
        respuesta.valorText = respuesta.valorTemp.label;
      }
    });
  }

  save(){
    let value: any = {};
    let continuar: boolean = true;
    for (let index = 0; index < this.respuestas.length && continuar; index++) {
      const respuesta = this.respuestas[index];
      if(this.validarControl(respuesta.idChecklistRespuesta.toString(), true)){
        continuar = false;
        this.app.showSnackbar("¡Aviso!", `Es necesario capturar valores a las columnas requeridas marcadas con un (*).`, 3000, "warning");
        this.dynamicTableForm.markAllAsTouched();
      }

      if(!this.validarRespuestas()){
        continuar = false;
        this.app.showSnackbar("¡Aviso!", `No es posible agregar registros completamente vacíos, es necesario agregar al menos un valor.`, 3000, "warning");
      }

      value[respuesta.idChecklistRespuesta.toString()] = respuesta.valor;

      if(respuesta.idTipoRespuesta == 9){
        value[respuesta.idChecklistRespuesta.toString() + "Text"] = respuesta.valorText;
      }

      if(this.edicion){
        value.index = this.valueAnterior.index;
      }
      else{
        value.index = this.respuestaActual.valor.length + this.respuestasAgregadas.length;
      }
      respuesta.switchSi = false;
      respuesta.switchNo = false;
    }
    if(continuar){
      if(this.edicion){
        this.dialogRef.close(value);
      }
      else{
        this.respuestasAgregadas.push(value);
        setTimeout(() => {
          this.crearFormGruop();
          this.respuestaActual.switchSi = false;
          this.respuestaActual.switchNo = false;
          this.nombreInput.nativeElement.focus();
        }, 100);
      }
    }
  }

  onClose(){
    if(!this.edicion && this.respuestasAgregadas.length > 0){
      const dialogRef = this.dialog.open(ConfirmacionDialog, {
        data:{
          titulo: "Columna",
          mensaje: `Hay ${this.respuestasAgregadas.length} registros pendientes de añadirse y esta información se perderá. ¿Estás seguro de salir?`,
          icono: "delete_forever",
          colorIcono: "warn",
          boton1: "No",
          boton2: "Sí",
          texto: null,
          claseAccion: "boton-accion-eliminar"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
          if(result) {
            this.dialogRef.close();
          }
      });
    }
    else{
      this.dialogRef.close();
    }
  }

  finalizar(){
    this.dialogRef.close(this.respuestasAgregadas);
  }
}

