import { Financiera } from './../../financieras/shared/financiera.model';
import { Page } from './../../shared/interfaces/page';
import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Importacion } from '../models/importacion.model';
import { ImportService } from '../import.service';
import { ClientPickerService } from 'src/app/client-picker/client-picker.service';
import { environmentSelector } from '../../../environments/environment.selector';
import { take } from 'rxjs/internal/operators/take';
import { SessionData } from 'src/app/shared/interfaces/session-data';
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { LenderService } from './../../lender/lender.service';
import { Lender } from '@lender/lender.model';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { tap } from 'rxjs/operators';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';



@Component({
  selector: 'app-import-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [LenderService]
})
export class HomeComponent implements OnInit {
  private environment = environmentSelector();

  displayedColumns: string[] = [
    'idImportacion',
    'nombreTipoLayout',
    'estatus',
    'numCuentasImportadas',
    'numCuentasNoImportadas',
    'lenderAdminFullname',
    'fecRegistro'
  ];
  public totalFiltros: number = 0;
  public contabilizadorFiltros: FiltersContabilizer;

  dataSource: any;
  page: Page;
  params = new URLSearchParams();
  subscription: Subscription;
  subscriptionChaneClientImport: Subscription;
  url = this.environment.kobraRed;
  sessionData: SessionData;
  clickEventsubscription: Subscription;
  public lenders: Lender[] = [];
  public idLender: number = 0;
  public filters: any = {
    financiera: [],
    nombre: null,
    activa: true,
    excluirDemo: false,
    filtered: false,
    page: 1
  };
  public loadings: any = {
    financieras: true
  };
  public ayuda: boolean = false;
  public ayudaSincro: boolean = false;
  public hijosActivos: boolean = false;
  public pageSize = 50;
  public tablaImportaciones: any;
  cargandoLista = false;
  filtrosAbiertos = false;
  public listFiltered: boolean;
  idCliente: number;

  @ViewChild(MatPaginatorModule) paginator: MatPaginatorModule;

  constructor(
    private lenderService: LenderService,
    private importService: ImportService,
    private clientPickerService: ClientPickerService,
    private appService: ApplicationService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private filtrosService: FiltrosService,
    private cdr: ChangeDetectorRef
  ) {
    this.params.set('paginate', '1');
    this.params.set('orderBy', 'fecRegistro DESC');
    this.params.set('page', '1');
    this.params.set('rows', '10');
    this.params.set('slt', 'idImportacion, idTipoImportacion, nombreTipoLayout, idEstatus, estatus, numCuentasImportadas, numCuentasNoImportadas, lenderAdminFullname, fecRegistro, nombreCliente');
    this.dataSource = [];
    this.crearTabla();
    this.sessionData = this.localStorageService.getSessionData();
    if(!this.sessionData.isSuperAdmin){
      this.filters.financiera.idCliente = this.sessionData.idCliente;
      this.filters.financiera.idFinanciera = this.sessionData.idFinanciera;
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    //this.contabilizadorDeFiltrosTotales(); 
  }

  ngOnInit() {
    if(localStorage.getItem('importacion-temp')){
      localStorage.removeItem('importacion-temp');
    }
    
    /*this.subscription = this.clientPickerService.currentClient.subscribe(lender => {
      this.loadImports(this.sessionData.idCliente);
    });*/
  }

  private agregarContabilizadoresDeFiltros(){
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'idCliente'
    ]);
  }

  public contabilizadorDeFiltrosTotales():void{

    let totalFiltrosArray: number[] = [];
 
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
  
    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

public contabilizadorDeFiltros(filtroName: string){
  return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
}

  crearTabla() {
    this.tablaImportaciones = {
        headers: false,
        multiSelect: false,
        columns: [{
          name: 'Cliente',
          key: 'nombreCliente',
          hide: false
        },{
            name: 'ID Lote',
            key: 'idImportacion',
            hide: false,
            type: 'link'
        },{
            name: 'Tipo layout',
            key: 'nombreTipoLayout',
            hide: false
        },{
            name: 'Estatus',
            key: 'estatus',
            hide: false,
            type: 'estatus'
        },{
            name: 'Cuentas Importadas',
            key: 'numCuentasImportadas',
            hide: false
        },{
            name: 'Errores',
            key: 'numCuentasNoImportadas',
            hide: false
        },{
            name: 'Usuario',
            key: 'lenderAdminFullname',
            hide: false
        },{
          name: 'Creada',
          key: 'fecRegistro',
          hide: false,
          type: 'dateTime'
        }]
    }
  }

  resultadoFiltros(filtrosNuevos: any) {
    if (filtrosNuevos) {
        this.filters = filtrosNuevos;
        this.filters.filter = true;
    }

    if(this.sessionData.isSuperAdmin){
      this.loadLenders();
    }
    console.log(this.sessionData.isSuperAdmin);
    if(!this.sessionData.isSuperAdmin){
      console.log("entro");
      this.loadImports(this.filters.page);
    }
  } 
   

  loadImports(pagina: number) {
    this.cargandoLista = true;
    this.listFiltered = true;
    //const loading = this.appService.showLoading('Cargando lotes...');
    this.params.set('page', pagina.toString());
    this.params.set('rows', this.pageSize.toString());

    this.importService.getHistory(null, this.params, this.filters.financiera.idCliente).pipe(take(1)).subscribe(
      (data: Page) => {
        this.page = {
          page: data.page,
          pages: data.pages,
          totalRows: data.totalRows,
          pageData: null,
          rows: Number(this.params.get('rows'))
        };

        this.dataSource = data.pageData;
        this.cargandoLista = false;
          //this.dataSource.paginator = this.paginator;
          //this.appService.hideLoading(loading);
      }, err => {
        this.cargandoLista = false;
        //this.appService.hideLoading(loading);
        this.appService.showError(err);
      });
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !==  this.page.page || newPagina.pageSize !== this.pageSize){
      this.pageSize = newPagina.pageSize;
      this.filters.page = newPagina.pageIndex+1;
      this.filtrosService.guardarFiltros(this.filters);
      this.loadImports(newPagina.pageIndex+1);
    }
  }


 /*ngOnDestroy() {
    this.subscription.unsubscribe();
    //this.subscriptionChaneClientImport.unsubscribe();
  }*/

  public iniciarImportacion(){
    if(!this.filters.financiera.idCliente){
      this.appService.showSnackbar('Aviso', 'Es necesario filtrar por cliente para continuar.', 3000, 'warning');

      return
    }
    this.router.navigate(['/importacion/asistente',   this.filters.financiera.idCliente, this.filters.financiera.idFinanciera]);
  }
  

  public loadLenders(): void {
    this.loadings.financieras = true;

    this.lenderService.getAll('active').subscribe(
        res => {
            this.lenders = res;
            this.onChangeLender(this.filters.financiera.idCliente);
            if(this.filters.financiera.idCliente){
              this.loadImports(this.filters.page);
            }
            
        },
        err => {
            this.appService.showError(err);
            this.loadings.financieras = false;
        },
        () => {
            this.loadings.financieras = false;
        }
    );
  }

public onChangeLender(idCliente: any) {
    this.lenders.forEach(financiera => {
      
      if (financiera.idCliente == idCliente) {
        this.filters.financiera = financiera;
        this.filtrosService.guardarFiltros(this.filters);
      }
    });

    
}

  ayudaImportacion(bool: boolean){
    this.ayuda = bool;
  }

  ayudaSincronizacion(bool: boolean){
    this.ayudaSincro = bool;
  }

  validarCliente(){
    if(!this.idCliente) {
      this.appService.showSnackbar(
          "Aviso",
          "Favor de seleccionar un cliente.",
          3000,
          "warning"
      );
      return;
    }
  }

  public setFilter(): void {
    if(!this.filters.financiera.idCliente) {
      this.appService.showSnackbar(
          "Aviso",
          "Favor de seleccionar un cliente.",
          3000,
          "warning"
      );
      return;
    }
    this.filters.page = 1;
    this.filtrosService.guardarFiltros(this.filters);
    this.loadImports(this.filters.page);
    this.contabilizadorDeFiltrosTotales();
  }

  public clearFilter(): void {
    Object.keys(this.filters).forEach(key => {
        if (this.sessionData.isSuperAdmin || (!this.sessionData.isSuperAdmin && key != 'financiera')) {
            this.filters[key] = [];
        }
    });
    this.filters.page = 1;
    this.filtrosService.removerFiltros();
    //this.loadImports(this.filters.page);
  }

  onClick({ row, columnPressed }) {
 
    if (columnPressed == 'idImportacion') {
      if(row.idEstatus != 7 && row.idEstatus != 5){
        this.abrirImportacion(row);
      }

      if(row.idEstatus == 7 || row.idEstatus == 5){
        this.getFinish(row);
      }

    }
  }

  onCtrlClick({ row, columnPressed }) {
 
    if (columnPressed == 'idImportacion') {
      if(row.idEstatus != 7 && row.idEstatus != 5){
        this.abrirImportacion(row, true);
      }

      if(row.idEstatus == 7 || row.idEstatus == 5){
        this.getFinish(row, true);
      }

    }
  }

  abrirImportacion(row: any, openInNewTab: boolean = false): void {
    // Construye la URL base
    const baseUrl = `/importacion/${row.idImportacion}`;
  
    // Construye los parámetros de consulta
    const queryParams = `?idCliente=${this.filters.financiera.idCliente}&idFinanciera=${this.filters.financiera.idFinanciera}`;
  
    if (openInNewTab) {
      // Abre en una nueva pestaña
      window.open(baseUrl + queryParams, '_blank');
    } else {
      // Navega en la misma pestaña, sin duplicar parámetros
      this.router.navigate([baseUrl], {
        queryParams: {
          idCliente: this.filters.financiera.idCliente,
          idFinanciera: this.filters.financiera.idFinanciera,
        },
      });
    }
  }
  
  public getFinish(element: any, openInNewTab: boolean = false): void {
    // Clona el elemento y añade propiedades necesarias
    const newElement = { ...element, stepperIndex: 0, financiera: this.filters.financiera };
  
    // Configura el stepperIndex según el idEstatus
    newElement.stepperIndex = 
      element.idEstatus === 7 ? 4 : 
      element.idEstatus === 5 ? 3 : 0;
  
    // Guarda los datos en localStorage
    localStorage.setItem('importacion-temp', JSON.stringify(newElement));
  
    // Construye la URL
    const url = `/importacion/asistente/${this.filters.financiera.idCliente}/${this.filters.financiera.idFinanciera}`;
  
    // Decide si navegar o abrir en una nueva pestaña
    if (openInNewTab) {
      window.open(url, '_blank'); // Abre en una nueva pestaña
    } else {
      this.router.navigate([url]); // Navega en la misma pestaña
    }
  }
  

  inactivarHijo(){

    this.filtrosService.asignarOpcion();
    if(this.filters && this.filters.financiera){
      this.filtrosService.guardarFiltros(this.filters, true);
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));    
  }
    
}
