<h1 mat-dialog-title>Cambiar Estatus</h1>

<mat-dialog-content class="modal-content">

    <div class="etiquetas-container">
        <mat-card class="etiquetas-card" *ngIf="!accion">
            <!-- <mat-card-header>
                <mat-card-title>Etiquetas Activas</mat-card-title>
            </mat-card-header> -->
            <mat-card-content>
                <div *ngFor="let cliente of getClientes(etiquetasSeleccionadas, true)" class="cliente-container">
                    <h3>{{ cliente }}</h3>
                    <div class="chip-list-container">
                        <mat-chip-listbox>
                            <mat-chip-option *ngFor="let etiqueta of getEtiquetasPorCliente(cliente, true)"
                            (click)="removerEtiqueta(etiqueta.idEtiquetaVisita, etiqueta.idCliente, etiqueta.idProducto)" 
                            class="accion-seleccionada">
                                {{ etiqueta.etiquetaVisita }}<span *ngIf="etiqueta.producto"> | {{ etiqueta.producto }}</span>
                                <mat-icon class="kobra-delete-icon" style="vertical-align: middle;">delete</mat-icon>
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>

        <mat-card class="etiquetas-card" *ngIf="accion">
            <!-- <mat-card-header>
                <mat-card-title>Etiquetas Inactivas</mat-card-title>
            </mat-card-header> -->
            <mat-card-content>
                <div *ngFor="let cliente of getClientes(etiquetasSeleccionadas, false)" class="cliente-container">
                    <h3>{{ cliente }}</h3>
                    <div class="chip-list-container">
                        <mat-chip-listbox>
                            <mat-chip-option *ngFor="let etiqueta of getEtiquetasPorCliente(cliente, false)"
                            (click)="removerEtiqueta(etiqueta.idEtiquetaVisita, etiqueta.idCliente, etiqueta.idProducto)" 
                            class="accion-seleccionada">
                                {{ etiqueta.etiquetaVisita }}<span *ngIf="etiqueta.producto"> | {{ etiqueta.producto }}</span>
                                <mat-icon class="kobra-delete-icon" style="vertical-align: middle;">delete</mat-icon>
                            </mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</mat-dialog-content>

<div *ngIf="!accion && opcion == 1" class="estado-mensaje mensaje-activo" >
    <mat-icon>info</mat-icon>
    Las etiquetas seleccionadas se encuentran actualmente activas, al cambiar el estatus pasarán a inactivas.<br>
    También se inactivaran las etiquetas asignadas a los clientes.
</div>
<div *ngIf="accion && opcion == 1" class="estado-mensaje mensaje-inactivo" >
    <mat-icon>info</mat-icon>
    Las etiquetas seleccionadas se encuentran actualmente inactivas, al cambiar el estatus pasarán a activas. <br>
</div>

<mat-dialog-actions align="end">
    <button mat-flat-button class="boton-cancelar" (click)="cerrarModal(false)">
        <mat-icon class="kobra-icon">close</mat-icon>Cancelar
    </button>
    <button mat-stroked-button class="boton-guardar" (click)="onSubmitEtiquetasSeleccionadas()">
        <mat-icon>save</mat-icon>Cambiar estatus
    </button>
</mat-dialog-actions>