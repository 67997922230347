import { ApplicationService } from 'src/app/application/shared/application.service';
import { Component, OnInit, Input, ViewChild, Inject, ElementRef, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmacionDialog } from 'src/app/modales-genericos/confirmacion/confirmacion-dialog.component';

@Component({
    selector: 'app-table-dialog',
    templateUrl: './table-dialog.component.html',
    styleUrls: ['./table-dialog.component.css']
})
export class TableDialogComponent implements OnInit {
  respuestas: any;
  respuestaActual: any;
  pregunta: any;
  title: string;
  subtitle: string;
  edicion: boolean;
  dynamicTableForm: FormGroup;
  valueAnterior: any;
  respuestasAgregadas: any[];
  @ViewChild('primerControl') nombreInput: ElementRef;

  constructor(
    private app: ApplicationService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<TableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

  }

  ngOnInit() {

  }

  onClose(){
    this.dialogRef.close();
  }
}
