<div class="app content kobra">
    <mat-card class="kobra-card">
      <mat-card-header>
        <mat-card-title>Nuevo</mat-card-title>
      </mat-card-header>
      
      <!-- Contenido desplazable (etiquetas y pagos) -->
      <mat-card-content class="kobra-card-content" style="max-height: 600px; overflow-y: auto;">
        <ng-container *ngFor="let concepto of formConceptos; let i = index">
          <mat-grid-list-responsive [colsMd]="4" [colsSm]="2" [colsXs]="1" rowHeight="80px">
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-select-search 
                [label]="'Etiqueta visita'" 
                [placeHolder]="'Selecciona una etiqueta'"
                [value]="'idEtiquetaVisita'" 
                [labelField]="'nombreEtiqueta'" 
                [icon]="'inventory_2'"
                [options]="etiquetasFiltradas" 
                [(ngModel)]="concepto.idEtiquetaVisita" 
                (ngModelChange)="onSelectEtiqueta(i)" 
                [maxlength]="100"
                class="pago-gestor">
              </mat-select-search>
            </mat-grid-tile>
            <mat-grid-tile colspan="1" rowspan="1">
              <mat-form-field class="pago-gestor">
                <mat-label>Pago al gestor</mat-label>
                <input matInput textSanitizer type="text" 
                [(ngModel)]="concepto.pagoAlGestor"
                placeholder="Capture monto pago al gestor"
                (input)="validarPagoAlGestor($event, i)">
         

                <mat-icon matSuffix>attach_money</mat-icon>
              </mat-form-field>
              <button style="margin-bottom: 5%;" mat-icon-button color="warn" (click)="removeConcepto(i)" [disabled]="formConceptos.length === 1">
                <mat-icon>delete</mat-icon>
              </button>
            </mat-grid-tile>
          </mat-grid-list-responsive>
        </ng-container>
      </mat-card-content>
      
      <!-- Botones separados del contenido desplazable -->
      <mat-card-actions class="button-center">
        <button class="boton-cancelar" mat-flat-button type="button" style="margin-right: 10px;"
            (click)="cancel()" [disabled]="loadings.guardando">
          <mat-icon class="kobra-icon">close</mat-icon> Cancelar
        </button>
        <button mat-stroked-button class="kobra-button" type="submit" (click)="save()" [disabled]="loadings.guardando">
          <mat-icon>save</mat-icon> Guardar
        </button>
      </mat-card-actions>
      
    </mat-card>
  </div>
  