<h1 mat-dialog-title>{{data.message}}</h1>

<mat-dialog-content class="modal-content">
    <alerta-informativa clase="info" titulo=""
        html="Tu sesión está próxima a expirar. Te recomendamos guardar cualquier trabajo o información pendiente para evitar la pérdida de datos.">
    </alerta-informativa>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-flat-button class="boton-accion-front" (click)="cerrarModal(false)">
        <mat-icon>check</mat-icon>Aceptar
    </button>

</mat-dialog-actions>