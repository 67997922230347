import { Observable } from 'rxjs';
import { ProductosSaldosService } from '../productos-saldos.service';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { Component,  OnInit } from '@angular/core';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import { ActivatedRoute, Router, Params } from '@angular/router';
import { FiltersContabilizer } from 'src/app/shared/models/filter-contabilizer.model';



declare var $: any;

@Component({
  selector: 'app-lista-tarifa',
  templateUrl: './lista-tarifa.component.html',
  styleUrls: ['./lista-tarifa.component.css']
})
export class ListaTarifaComponent implements OnInit {
  public paquetes$: Observable<any>;
  public selectedRow: any;
  public itemsSelected: any[] = [];
  filtrosAbiertos: boolean = false;
  hijosActivos: boolean = false;
  ayuda: boolean = false;
  public filters: any = {
    financiera: null,
    nombre: null,
    tipoTarifa: null,
    activa: true,
    excluirDemo: false,
    filtered: false,
    page: 1,
    sortField: 'c.nombre',
    sortAsc: false
  };
  public contabilizadorFiltros: FiltersContabilizer;
  public totalFiltros: number = 0;
  tabla: any = {
    headers: false,
    multiSelect: false,
    columns: [
        {
            name: 'Tipo Tarifa',
            key: 'tipoTarifa',
            hide: false,
            sortName: 'tipoTarifa'
        },
        {
            name: 'Concepto',
            key: 'nombre',
            hide: false,
            sortName: 'c.nombre'
        },
        {
          name: 'Precio',
          key: 'precio',
          hide: false,
          type:'money',
          sortName: 'tc.precio'
        }
    ]
  };

  constructor(
    public productosSaldosService: ProductosSaldosService,
    private router: Router,
    private app: ApplicationService,
    private filtrosService: FiltrosService,
    private route: ActivatedRoute,

  ) {
    this.contabilizadorFiltros = new FiltersContabilizer();
    this.agregarContabilizadoresDeFiltros();
    this.contabilizadorDeFiltrosTotales();
   }

  ngOnInit() {
    this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
  }

  private agregarContabilizadoresDeFiltros(){
    this.contabilizadorFiltros.add('Generales', 'generales', [
        'nombre',
        'tipoTarifa'
    ]);
  }

  public contabilizadorDeFiltros(filtroName: string){
    return this.contabilizadorFiltros.countActiveFilters(filtroName, this.filters);
  }

  public contabilizadorDeFiltrosTotales():void{
    let totalFiltrosArray: number[] = [];
    totalFiltrosArray.push( this.contabilizadorDeFiltros('generales') );
    this.totalFiltros = totalFiltrosArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
}

  resultadoFiltros(filtrosNuevos: any) {
    if (filtrosNuevos) {
      this.filters = filtrosNuevos;
      this.filters.filter = true;
      this.filters.filtered = true;
    }
   this.productosSaldosService.obtenerPaquetes(this.filters.page || 1, this.filters)
  }

  readQueryParams() {
      const page: number = this.filters.page ? this.filters.page : 1;
      this.crearURLQuery(page)
      this.productosSaldosService.obtenerPaquetes(this.filters.page || 1, this.filters)
      this.contabilizadorDeFiltrosTotales();
    }

  onEdit(registroSeleccionado: any) {

    if (registroSeleccionado) {

      // if( registroSeleccionado.idConcepto !== 0 && !(registroSeleccionado.idConcepto > 0)) {
      //     return this.app.showSnackbar('¡Aviso!', 'El concepto no es editable.', 3000, 'warning');
      // }

      this.router.navigate(['editar'], {
        relativeTo: this.route,
        queryParams: {
          idConcepto: registroSeleccionado.idConcepto,
          idTarifa: registroSeleccionado.idTarifa,
          nombre: registroSeleccionado.nombre,
          precio: registroSeleccionado.precio,
          tipoTarifa: registroSeleccionado.tipoTarifa,
        }
      }
      );
    }

  }

  onDoubleClick({ row }) {
    this.onEdit(row);
  }

  onSelected(rows) {
    this.itemsSelected = rows.slice();
  }

  public setFilter(): void {
    this.filters.filtered = true;
    this.filters.page = 1;
    this.filtrosService.guardarFiltros(this.filters);
    this.productosSaldosService.obtenerPaquetes(this.filters.page || 1, this.filters)
  }



  private crearURLQuery(page: number): string {
    let queryParams: string = '';
    if (page != this.filters.page) {
      this.filters.page = page;
    }

    queryParams += `_page=${page}`;
    this.filters.page = page;

    if (this.filters.nombre) {
      queryParams += `&nombreConcepto=${this.filters.nombre}`;
      this.filters.filtered = true;
    }
    if (this.filters.tipoTarifa) {
      queryParams += `&tipoTarifa=${this.filters.tipoTarifa}`;
      this.filters.filtered = true;
    }

    this.filtrosService.guardarFiltros(this.filters);


    return queryParams;
  }

  public limpiarFiltros() {
    this.filters = {
      financiera: this.filters.financiera,
      nombre: null,
      tipoTarifa: null,
      activa: true,
      excluirDemo: false,
      filtered: false,
      sortField: 'c.nombre',
      page: 1
    }
    this.productosSaldosService.filtrosService.guardarFiltros(this.filters);
    //this.productosSaldosService.obtenerPaquetes( 1, this.filters)
  }

  onPage(newPagina: any): void {
    if(newPagina.pageIndex+1 !== this.productosSaldosService.pagination.page || newPagina.pageSize !== this.productosSaldosService.pagination.pageSize){
      this.productosSaldosService.pagination.pageSize = newPagina.pageSize;
      this.productosSaldosService.obtenerPaquetes(newPagina.pageIndex+1, this.filters);
    }
  }

  sortChange(sort: any) {
    this.filters.sortAsc = sort.direction;
    if (sort.active != this.filters.sortField) {
      this.filters.sortField = sort.active;
    }
    this.productosSaldosService.obtenerPaquetes(1, this.filters);
  }

  inactivarHijo(){
    this.filtrosService.asignarOpcion();
    if(this.filters && this.filters.filtered){
      this.filtrosService.guardarFiltros(this.filters, true);
    }
    this.filtrosService.obtenerFiltrosIniciales(this.filters, this.resultadoFiltros.bind(this));
  }

}
