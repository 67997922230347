import { Component, OnInit, OnDestroy, ChangeDetectorRef, ViewContainerRef, ComponentFactoryResolver, ComponentRef, QueryList, ViewChildren, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '../../../../../application/shared/application.service';
import { VisitService } from '../../../../../visit/visit.service';
import { Subject } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ConfirmacionDialog } from "../../../../../modales-genericos/confirmacion/confirmacion-dialog.component";
import { MatDialog } from '@angular/material/dialog';
import { TipoCondicionalDialog } from '../../../modales/tipo-condicional/tipo-condicional.component';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { DynamicInputComponent } from 'src/app/componentes/campos-dinamicos/dynamic-input.component';
import { v4 as uuidv4 } from 'uuid';

const responseTypes = {
  1: 'Texto corto',
  2: 'Texto largo',
  3: 'Numérico',
  4: 'Decimal',
  5: 'Selector de fecha',
  6: 'Checkbox',
  7: 'Radio',
  8: 'Interruptor',
  9: 'Combo',
  10: 'Horario',
  11: 'Foto',
  12: 'Tabla'
};

interface Card {
  formGroup: FormGroup;
  cardId: string;
  pregunta: any;
  condicionalSeleccionada: { id: string, nombre: string }; 
  operadores: { display: string, value: string }[];
  operaradorSeleccionado: string;
  idTipoValor: number;
  preguntasCache?: any;
}

@Component({
  selector: 'app-bloque-nuevo',
  templateUrl: './bloque-nuevo.component.html',
  styleUrls: ['./bloque-nuevo.component.css'],
  providers: [VisitService]
})
export class BloqueNuevoComponent implements OnInit, OnDestroy, AfterViewInit {
  idCliente: number;
  idChecklist: number;
  idProducto: number;
  numVersion: number;
  idVariable: number;
  checklist: any[] = [];
  preguntasDisponibles: any[] = [];
  cards: Card[] = [];
  condicionTexto: string = '';
  guardarHabilitado: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  momentFecha;
  private componentRefs: { [key: string]: ComponentRef<DynamicInputComponent> } = {};
  private dynamicContainersMap: { [key: string]: ViewContainerRef } = {};
  condicionTextoCambiado: boolean = false;
  isLoadPregunta: boolean = true;
  editarPresente: boolean = false;
  cargandoCondicion: boolean = false;
  idFormatoVariable: number;
  claveVariable:string;
  copiarClave:string;
  cardsSinCambios:String;
  tipoCondicion:any;

  @ViewChildren('dynamicContainer', { read: ViewContainerRef }) dynamicContainers: QueryList<ViewContainerRef>;

  condicionalesSQL = [
    { id: '&&', nombre: 'Y' },
    { id: '||', nombre: 'O' }
  ];

  operators = {
    1: [ // Texto corto y Texto largo
      { display: 'Igual a', value: '==' },
      { display: 'Diferente de', value: '!=' }
    ],
    2: [ // Numérico y Decimal
      { display: 'Igual a', value: '==' },
      { display: 'Diferente de', value: '!=' },
      { display: 'Mayor que', value: '>' },
      { display: 'Menor que', value: '<' },
      { display: 'Mayor o igual que', value: '>=' },
      { display: 'Menor o igual que', value: '<=' },
      { display: 'Entre', value: 'between' }
    ],
    5: [ // Selector de fecha
      { display: 'Igual a', value: '==' },
      { display: 'Mayor que', value: '>' },
      { display: 'Menor que', value: '<' },
      { display: 'Mayor o igual que', value: '>=' },
      { display: 'Menor o igual que', value: '<=' },
      { display: 'Entre', value: 'between' }
    ],
    6: [ // Checkbox, Radio e Interruptor
      { display: 'Igual a', value: '==' }
    ]
  };

  constructor(
    public dialog: MatDialog,
    private router: Router,
    private appService: ApplicationService,
    private route: ActivatedRoute,
    private visitService: VisitService,
    private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {
    this.momentFecha = moment;
    
    this.route.url.subscribe(urlSegments => {
      const urlSegmentStrings = urlSegments.map(segment => segment.path);
      this.editarPresente = urlSegmentStrings.includes('editar');
    });
    
    this.idCliente = +this.route.snapshot.queryParamMap.get('idCliente');
    this.idChecklist = +this.route.snapshot.queryParamMap.get('idChecklist');
    this.idProducto = +this.route.snapshot.queryParamMap.get('idProducto');
    this.numVersion = +this.route.snapshot.queryParamMap.get('numVersion');

    if (!this.idCliente || !this.idChecklist || !this.idProducto || !this.numVersion) {
      this.mensajeParametros();
    }

    if(this.editarPresente){
      this.idVariable = +this.route.snapshot.queryParamMap.get('idVariable');
      
      if (!this.idVariable) {
        this.mensajeParametros();
      }
      
    }else{
      this.generaVariable();
    }

    this.loadChecklist();
    
  }

  generaVariable() {
    const randomNum = Math.floor(Math.random() * 1000);
    this.claveVariable = `e_${CryptoJS.MD5((this.momentFecha().unix() + randomNum).toString()).toString()}`;
    this.copiarClave = "${" + this.claveVariable + "}" + " ${/" + this.claveVariable + "}";
  }

  private mensajeParametros(){
    this.appService.showSnackbar('¡Aviso!', 'Faltan parámetros en la URL.', 2000, 'error');
    this.cancel();
    return;
  }

  ngAfterViewInit(): void {
    // Suscribirse a los cambios en los contenedores dinámicos
    this.dynamicContainers.changes.subscribe(() => {
      this.initializeDynamicComponents();
      this.cdr.detectChanges(); // Fuerza una verificación de cambios
    });
  
    // Crear componentes dinámicos después de que la vista esté completamente inicializada
    this.initializeDynamicComponents();
    this.cdr.detectChanges(); // Fuerza una verificación de cambios
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.destroyComponents();
  }

  cargarCardEditar() {
    this.cargandoCondicion = true;
    this.visitService.obtenerCondiciones(this.idCliente, this.idVariable)
      .pipe(take(1))
      .subscribe((respuesta: any) => {
        this.cargandoCondicion = false;
  
        // Limpiar las cards actuales (si es necesario)
        this.cards = [];
        this.destroyComponents();
        this.idFormatoVariable = respuesta.data.idFormatoVariable;
        this.claveVariable = respuesta.data.clave;
        this.copiarClave = "${" + this.claveVariable + "}" + " ${/" + this.claveVariable + "}";
       
        respuesta.data.condiciones.forEach((element, index) => {
          const preguntaOriginal = this.preguntasDisponibles.find(p => p.idChecklistPregunta === element.idChecklistPregunta && p.respuestas[0].idChecklistRespuesta === element.idChecklistRespuesta);

          if (preguntaOriginal) {
            // Clonar la pregunta para evitar la modificación del objeto original
            const preguntaClonada = {
              ...preguntaOriginal,
              respuestas: preguntaOriginal.respuestas.map(respuesta => ({ ...respuesta }))
            };
  
            // Asignar valor a la respuesta específica en la pregunta clonada
            preguntaClonada.respuestas[0].valor = element.valorRespuesta;
  
            const condicionSeleccionada = this.condicionalesSQL.find(cond => cond.id === element.condicion) || { id: '', nombre: '' };
            this.tipoCondicion = condicionSeleccionada;
            const newCard: Card = {
              formGroup: this.fb.group({
                pregunta: new FormControl(preguntaOriginal, Validators.required),  // Inicializar como null primero
                operador: new FormControl(element.operador)
              }),
              pregunta: preguntaOriginal,
              condicionalSeleccionada: condicionSeleccionada,
              operadores: this.getOperators(preguntaClonada.respuestas[0].idTipoRespuesta),
              idTipoValor: element.idTipoValor,
              operaradorSeleccionado: element.operador,
              preguntasCache: preguntaClonada,
              cardId: uuidv4()
            };
  
            this.inicializarControlesFormulario(newCard);
            this.cards.push(newCard);
  
          }

        });
  
        this.cdr.detectChanges();  // Forzar detección de cambios
  
        this.cardsSinCambios = this.generateCardsHash();
  
        // Inicializar componentes dinámicos
        //this.initializeDynamicComponents();
        this.actualizarCondicionTexto();
        this.validarCards();
      },
      (error: any) => {
        this.cargandoCondicion = false;
        this.appService.showError(error);
      });
  }
  
  private generateCardsHash(): string {
    const cardsData = this.cards.map(card => {
      const pregunta = card.preguntasCache;
      return {
        pregunta: pregunta ? pregunta.idChecklistPregunta : null,
        condicionalSeleccionada: card.condicionalSeleccionada,
        operadores: card.operadores,
        operaradorSeleccionado: card.operaradorSeleccionado,
        idTipoValor: card.idTipoValor,
        respuestas: pregunta?.respuestas ? pregunta.respuestas.map(respuesta => ({
          id: respuesta.idChecklistRespuesta,
          valor: respuesta.valor,
        })) : [] // Si no hay respuestas, se devuelve un array vacío
      };
    });
  
    // Convertir la data en un string JSON para generar el hash
    const cardsJson = JSON.stringify(cardsData);
    
    // Generar el hash MD5
    return CryptoJS.MD5(cardsJson).toString();
  }
  
  private initializeDynamicComponents(): void {
    if (this.dynamicContainers && this.dynamicContainers.length) {
      this.cards.forEach((card, index) => {
        if (!this.componentRefs[card.cardId] && card.preguntasCache) {
          this.crearComponente(card, index);
        }
      });
    }
  }

  cancel(): void {
    this.router.navigate(['catalogos/checklists/formato-visita/bloques'], {
      queryParamsHandling: 'merge'
    });
  }

  private loadChecklist(): void {
    this.visitService.getCheckList(this.idCliente, this.idProducto)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (res: any) => {
          const newChecklist = [];
          res.preguntas.forEach(pregunta => {
            // Filtrar respuestas con idTipoRespuesta 10, 11, 12
            const respuestasFiltradas = pregunta.respuestas.filter(
              respuesta => ![10, 11, 12].includes(respuesta.idTipoRespuesta)
            );
  
            if (respuestasFiltradas.length > 1) {
              respuestasFiltradas.forEach(respuesta => {
                const nuevaPregunta = {
                  ...pregunta,
                  nombrePregunta: `${pregunta.nombrePregunta} - ${respuesta.nombreRespuesta || 'Respuesta'}`,
                  respuestas: [respuesta]
                };
                newChecklist.push(nuevaPregunta);
              });
            } else if (respuestasFiltradas.length === 1) {
              const nuevaPregunta = {
                ...pregunta,
                respuestas: respuestasFiltradas
              };
              newChecklist.push(nuevaPregunta);
            }
          });
  
          this.preguntasDisponibles = [...newChecklist];
          this.isLoadPregunta = false;
  
          if (this.editarPresente) {
            this.cargarCardEditar();
          } else {
            this.agregarCard({ id: '', nombre: '' });
          }
        },
        error: (error) => {
          this.isLoadPregunta = false;
          this.appService.showSnackbar('Error', 'No se pudo cargar el checklist.', 2000, 'error');
        }
      });
  }
  

  agregarCard(tipoCondicional: any): void {
    const newCard: Card = {
      formGroup: this.fb.group({
        pregunta: new FormControl(null, Validators.required),
        operador: new FormControl(null, Validators.required)
      }),
      pregunta: null,
      condicionalSeleccionada: tipoCondicional,
      operadores: [],
      idTipoValor: 2,
      operaradorSeleccionado: '',
      cardId: uuidv4()
    };
    this.cards.push(newCard);
    

    //this.validarCards();
  }

  eliminarCard(card: Card): void {
    const cardIndex = this.cards.findIndex(c => c.cardId === card.cardId);
    const dialogRef = this.dialog.open(ConfirmacionDialog, {
      data: {
        titulo: "Confirmar",
        mensaje: `¿Deseas eliminar la condición ${cardIndex + 1}?`,
        icono: "delete_forever",
        colorIcono: "warn",
        boton1: "No",
        boton2: "Sí",
        claseAccion: "boton-accion-eliminar"
      }
    });
  
    dialogRef.afterClosed().subscribe(async result => {
      if (result) {
        if (cardIndex !== -1) {
          const id = card.cardId;
          
          // Destruye el componente dinámico asociado con la tarjeta
          await this.destroyComponentById(card.cardId);       
          this.cards.splice(cardIndex, 1);  // Elimina la tarjeta del array
  
          // Asegúrate de que la primera tarjeta restante no tenga condicional seleccionada
          if (this.cards.length >= 1 && cardIndex === 0) {
            this.cards[0].condicionalSeleccionada = { id: '', nombre: '' };
          }

          this.cdr.detectChanges();
          this.actualizarCondicionTexto();
        }
        this.validarCards();
      }
    });
  }
  

  procesarPregunta(event, card: Card): void {

    if (event) {
      const preguntaSeleccionada = event;
      // Verifica que la pregunta seleccionada y sus respuestas existan
      if (preguntaSeleccionada && preguntaSeleccionada.respuestas && preguntaSeleccionada.respuestas.length > 0) {
        if(card.preguntasCache && card.preguntasCache.idChecklistPregunta == preguntaSeleccionada.idChecklistPregunta
          && card.preguntasCache.respuestas[0].idChecklistRespuesta == preguntaSeleccionada.respuestas[0].idChecklistRespuesta
        ) {
          return;
        }
        
        // Clonación de la pregunta y su respuesta para asegurar independencia de datos
        const preguntaClonada = { 
          ...preguntaSeleccionada, 
          respuestas: preguntaSeleccionada.respuestas.map(respuesta => ({ ...respuesta }))
        };

        // Asigna la pregunta clonada a la card
        card.pregunta = preguntaSeleccionada;
        card.preguntasCache = preguntaClonada;
        card.operadores = this.getOperators(preguntaSeleccionada.respuestas[0].idTipoRespuesta);

        const tiposRespuesta = [6, 7, 8, 9];  // Array con los tipos de respuesta

        if (tiposRespuesta.includes(preguntaSeleccionada.respuestas[0].idTipoRespuesta)) {
          card.formGroup.get('operador')?.setValue('==');
        }
        // Inicializa los controles del formulario y otros procesos
        this.destroyComponentById(card.cardId);
        this.inicializarControlesFormulario(card);
        this.actualizarCondicionTexto();
        this.crearComponente(card, this.cards.indexOf(card));
        this.cdr.detectChanges();
      } 
    } else {
      this.limpiarTipoRespuesta(card.formGroup.get('operador'));
      card.preguntasCache = null;
      card.pregunta = null;
      card.idTipoValor = 0;
      
      this.destroyComponentById(card.cardId);
      this.actualizarCondicionTexto();

    }

    this.validarCards();
  }


  procesarCondicional(event): void {
    // Agregar una nueva tarjeta vacía primero
    this.agregarCard(event);
  
    // Encuentra el índice de la nueva tarjeta (última tarjeta)
    //const nuevaCard = this.cards[this.cards.length - 1];
    
    // Asigna la condición seleccionada a la nueva tarjeta
    //nuevaCard.condicionalSeleccionada = event;
  
    this.actualizarCondicionTexto();
    this.validarCards();
}


  actualizarValorRespuesta(nuevoValor: any, card: Card): void {
    card.operadores = this.getOperators(nuevoValor.idTipoRespuesta);
    card.preguntasCache.respuestas[0].valor = nuevoValor.value;
    card.idTipoValor = nuevoValor.idTipoValor;
    this.actualizarCondicionTexto();
    this.validarCards();
  }

  validarCard(card: Card): boolean {
    return card.formGroup.valid;
  }

  inicializarControlesFormulario(card: Card): void {
    card.preguntasCache.respuestas.forEach(respuesta => {
      const control = new FormControl(respuesta.valor, respuesta.requerida ? Validators.required : null);
      card.formGroup.addControl(respuesta.idChecklistRespuesta.toString(), control);
    });
  }

  getPreguntaControl(card: Card): FormControl {
    return card.formGroup.get('pregunta') as FormControl;
  }

  getCondicionalControl(card: Card): FormControl {
    return card.formGroup.get('condicionalSeleccionada') as FormControl;
  }

  private getOperators(idTipoRespuesta: number): { display: string, value: string }[] {
    if ([1, 2].includes(idTipoRespuesta)) {
      return this.operators[1];
    } else if ([3, 4].includes(idTipoRespuesta)) {
      return this.operators[2];
    } else if (idTipoRespuesta === 5) {
      return this.operators[5];
    } else if ([6, 7, 8, 9].includes(idTipoRespuesta)) {
      return this.operators[6];
    } else {
      return [];
    }
  }

  actualizarOperador(card: Card, index: number){
    const componentRef = this.componentRefs[index];
    if (componentRef) {
        componentRef.instance.operador = card.formGroup.get('operador')?.value;
        componentRef.instance.onOperadorChange(card.formGroup.get('operador')?.value);
    }
    
    this.actualizarCondicionTexto();
  }

  actualizarCondicionTexto(): void {
    let nuevoTexto = this.cards.map((card, index) => {

        if (card.preguntasCache && card.preguntasCache.respuestas[0].requerida) {
            card.idTipoValor = 2;
        }
        const preguntaNombre = card.preguntasCache?.nombrePregunta || '';
        const operador = card.operadores.find(op => op.value === card.formGroup.get('operador')?.value);
        const operadorDisplay = operador?.display || '';
        card.operaradorSeleccionado = operador?.value || '';
        let valorRespuesta = card.preguntasCache?.respuestas[0]?.valor;

        if (card.preguntasCache?.respuestas[0]?.idTipoRespuesta == 8) {
            valorRespuesta = (card.preguntasCache?.respuestas[0]?.valor == 1) ? "Sí" : "No";
        } else if (card.preguntasCache?.respuestas[0]?.idTipoRespuesta == 9) {
            let array = card.preguntasCache?.respuestas[0]?.options.filter((item) => item.id == valorRespuesta);
            valorRespuesta = array[0]?.label || valorRespuesta;
        } 

        let textoAux = '';
        
        if (card.operaradorSeleccionado === 'between') {
          
          const isValidBetween = typeof valorRespuesta === 'object' &&
                               'minimo' in valorRespuesta && valorRespuesta.minimo &&
                               'maximo' in valorRespuesta && valorRespuesta.maximo;

          if(isValidBetween) {
            let minimo = '';
            let maximo = '';
            if (card.preguntasCache?.respuestas[0]?.idTipoRespuesta == 5) {
              minimo = this.momentFecha(valorRespuesta.minimo).format('DD/MM/YYYY');
              maximo = this.momentFecha(valorRespuesta.maximo).format('DD/MM/YYYY');
            } else {
              minimo = valorRespuesta.minimo;
              maximo = valorRespuesta.maximo;
            } 

            textoAux = `"${minimo}" <strong>Y</strong> "${maximo}"`;
          }
            
        } else {

          if (card.preguntasCache?.respuestas[0]?.idTipoRespuesta == 5 && valorRespuesta) {
            valorRespuesta = this.momentFecha(valorRespuesta).format('DD/MM/YYYY');
          }

          if(card.preguntasCache?.respuestas[0]?.idTipoRespuesta == 9 && valorRespuesta) {
            valorRespuesta = valorRespuesta.label;
          }

          if (
            valorRespuesta || 
            (valorRespuesta === 0 && 
            (card.preguntasCache?.respuestas[0]?.idTipoRespuesta === 3 || card.preguntasCache?.respuestas[0]?.idTipoRespuesta === 4))
          ) {
            textoAux = `"${valorRespuesta}"`;
          }
          
        }

        let texto = '';
        if (card.idTipoValor == 1) {
            texto = `Si la pregunta "${preguntaNombre}" <strong>No se responde</strong>`;
        } else {
            texto = `Si la pregunta "${preguntaNombre}" es <strong>${operadorDisplay}</strong> ${textoAux}`;
        }

        const condicion = card.condicionalSeleccionada?.nombre || '';

        if (this.cards.length > 1 && index > 0 && condicion) {
            texto = `<strong>${condicion}</strong> ` + texto;
        }

        return texto;

    }).join(' ');

    if (this.cards.length > 0) {
        nuevoTexto += ', entonces se colocará el bloque de información.';
    }

    if (nuevoTexto !== this.condicionTexto) {
        this.condicionTexto = nuevoTexto;
        this.condicionTextoCambiado = true;
    }

    this.validarCards();  // Asegurar que se llama a validarCards después de actualizar el texto
  }

  validarCards(): void {
    let allValid = true;

    if(this.editarPresente) {
      const currentCardsHash = this.generateCardsHash();

      if(currentCardsHash == this.cardsSinCambios){
        allValid = false;
      }
  
    }
  
    this.cards.forEach((card, index) => {
      const conditionNumber = index + 1;
  
      if (!card.pregunta || !card.formGroup.get('pregunta').value) {
        allValid = false;
      }
  
      if (card.idTipoValor == 2 && !card.formGroup.get('operador').value) {
        allValid = false;
      }
  
      card.preguntasCache?.respuestas.forEach(respuesta => {
        const operador = card.formGroup.get('operador')?.value;
  
        if (respuesta.requerida && !respuesta.valor) {
          allValid = false;
        }
  
        if (!respuesta.requerida && !respuesta.valor && card.idTipoValor == 2) {
          allValid = false;
        }
  
        if (!respuesta.requerida && !card.idTipoValor) {
          allValid = false;
        }
  
        // Validación específica para operador 'between'
        if (operador === 'between') {
          const valorRespuesta = respuesta.valor;
  
          // Verificar si valorRespuesta es un objeto y tiene las propiedades 'minimo' y 'maximo'
          const isValidBetween = typeof valorRespuesta === 'object' &&
                               'minimo' in valorRespuesta && valorRespuesta.minimo &&
                               'maximo' in valorRespuesta && valorRespuesta.maximo;

          if (!isValidBetween) {
            allValid = false;
          } 
        }
      });
    });
  
    this.guardarHabilitado = allValid;
  }
  
  

  agregarTipoCondicion(): void {
    if (this.cards.length == 1) {
      const dialogRef = this.dialog.open(TipoCondicionalDialog);

      dialogRef.afterClosed().subscribe(result => {
        if (result && result.success) {
          this.tipoCondicion = result.tipoCondicion;
          this.procesarCondicional(result.tipoCondicion);
        }
      });
    } else {
      this.procesarCondicional(this.tipoCondicion);
    }
   
    
    
  }

  filtrarParametros(): any[] {
    // Inicializar obj como un array vacío
    let obj: any[] = [];
    
    // Iterar sobre cada card y construir el objeto data
    this.cards.forEach(card => {
      let valorRespuesta = card.preguntasCache?.respuestas[0].valor;
  
      // Verificar si el tipo de respuesta es 8 y ajustar valorRespuesta en consecuencia
      if (card.preguntasCache?.respuestas[0].idTipoRespuesta == 8) {
        valorRespuesta = (valorRespuesta == 1) ? true : (valorRespuesta == 2) ? false : null;
      }
      
      const data = {
        operador: card.idTipoValor == 1 ? 'isNull' : card.formGroup.get('operador')?.value, 
        idChecklistPregunta: card.preguntasCache?.idChecklistPregunta,
        idChecklistRespuesta: card.preguntasCache?.respuestas[0].idChecklistRespuesta,
        idTipoDato: card.preguntasCache?.respuestas[0].idTipoRespuesta,
        valorRespuesta: valorRespuesta, // Usar valorRespuesta ajustado
        condicion: card.condicionalSeleccionada?.id, // Acceso a la propiedad 'id'
        idTipoValor: card.idTipoValor
      };
    
      // Agregar data al array obj
      obj.push(data);
    });

    return obj;
  }
  
  guardarVariable(): void {

    if (!this.guardarHabilitado) {
      this.appService.showSnackbar('Aviso', 'Por favor, complete todas las condiciones antes de guardar.', 2000, 'warning');
      return;
    }
  
    const plainText = this.condicionTexto.replace(/<\/?strong>/g, '').replace(/\\/g, '').replace(/\s+/g, ' ').trim();
    const data = {
      idCheckList: this.idChecklist,
      numVersion: this.numVersion,
      clave: this.claveVariable,
      descripcion: plainText,
      condiciones: this.filtrarParametros(),
      idProducto: this.idProducto
    };
 
    const loading = this.appService.showLoading("Generando variable...");
  
    this.visitService.guardarVariable(this.idCliente, data)
      .pipe(take(1))
      .subscribe({
        next: (respuesta: any) => {
          this.appService.hideLoading(loading);
          if (respuesta.success) {
            this.appService.showSnackbar('Aviso', 'Variable guardada con éxito.', 2000, 'success');
            // Limpiar el array de cards y destruir componentes dinámicos
            this.generaVariable();
            this.cards = [];
            this.destroyComponents();
            this.tipoCondicion = null;
            this.agregarCard({ id: '', nombre: '' });
            this.condicionTextoCambiado = false;
            this.validarCards();
          } else {
            this.appService.showSnackbar('Aviso', 'Ocurrió un error al guardar.', 2000, 'error');
          }
        },
        error: (err: any) => {
          this.appService.hideLoading(loading);
          this.appService.showError(err);
        }
      });
  }  

  accionGuardar(){
    if(this.editarPresente) {
      this.actualizarVariable()
    } else {
      this.guardarVariable();
    }
  }

  actualizarVariable(): void {

    if (!this.guardarHabilitado) {
      this.appService.showSnackbar('Aviso', 'Por favor, complete todas las condiciones antes de guardar.', 2000, 'warning');
      return;
    }
  
    const plainText = this.condicionTexto.replace(/<\/?strong>/g, '').replace(/\\/g, '').replace(/\s+/g, ' ').trim();
    const data = {
      idCheckList: this.idChecklist,
      numVersion: this.numVersion,
      descripcion: plainText,
      condiciones: this.filtrarParametros(),
      idProducto: this.idProducto
    };
  
    const loading = this.appService.showLoading("Actualiando condiciones...");
  
    this.visitService.actualizarVariable(this.idCliente, this.idFormatoVariable, data)
      .pipe(take(1))
      .subscribe({
        next: (respuesta: any) => {
          this.appService.hideLoading(loading);
          if (respuesta.success) {
            this.appService.showSnackbar('Aviso', 'Variable actualizada con éxito.', 2000, 'success');
            // Limpiar el array de cards y destruir componentes dinámicos
            this.cards = [];
            this.destroyComponents();
            this.cancel();
          } else {
            this.appService.showSnackbar('Aviso', 'Ocurrió un error al actualizar.', 2000, 'error');
          }
        },
        error: (err: any) => {
          this.appService.hideLoading(loading);
          this.appService.showError(err);
        }
      });
  }  

  crearComponente(card: Card, index: number): void {
    if (this.dynamicContainers && this.dynamicContainers.length > index) {
        const dynamicContainer = this.dynamicContainers.toArray()[index];
        
        // Guardar el contenedor dinámico en el mapa con su `cardId`
        this.dynamicContainersMap[card.cardId] = dynamicContainer;
        
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(DynamicInputComponent);
        const componentRef = dynamicContainer.createComponent(componentFactory);
        this.componentRefs[card.cardId] = componentRef;
        
        if (this.editarPresente) {
            this.componentRefs[card.cardId].instance.tipoRespuesta = card.idTipoValor;
            
            if(card.preguntasCache.respuestas[0].idTipoRespuesta == 8) {
                card.preguntasCache.respuestas[0].valor = card.preguntasCache.respuestas[0].valor == true ? 1 : card.preguntasCache.respuestas[0].valor == false ? 2: null;
            }
        } 
        
        this.componentRefs[card.cardId].instance.idControl = card.cardId;
        this.componentRefs[card.cardId].instance.operador = card.operaradorSeleccionado;
        this.componentRefs[card.cardId].instance.respuesta = card.preguntasCache.respuestas[0];
        this.componentRefs[card.cardId].instance.valueChange.subscribe(value => this.actualizarValorRespuesta(value, card));
    } 
  }

  private destroyComponents(): void {
    Object.keys(this.componentRefs).forEach(key => {
        this.componentRefs[key].destroy();
    });
    this.componentRefs = {}; 
  }

  private async destroyComponentById(uniqueId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        // Verificar si existe la referencia del componente
        if (this.componentRefs[uniqueId]) {
          this.componentRefs[uniqueId].destroy();  // Destruye el componente dinámico
          delete this.componentRefs[uniqueId];     // Elimina la referencia al componente
        }
        // Limpiar el contenedor dinámico si existe
        if (this.dynamicContainersMap[uniqueId]) {
          this.dynamicContainersMap[uniqueId].clear(); // Limpia el contenedor
          delete this.dynamicContainersMap[uniqueId];  // Elimina la referencia del mapa
        }
  
        this.cdr.detectChanges(); // Forzar la detección de cambios para asegurarse de que se actualice la vista
        resolve();  // Resolver la promesa al finalizar
      } catch (error) {
        reject(error);  // Rechazar la promesa en caso de error
      }
    });
  }
  
  trackByFn(index: number, item: Card): string {
    return item.cardId; // Asegúrate de que Angular utilice el cardId único como referencia
  }   

  validarControl(control: AbstractControl | null): boolean {
    if (control instanceof FormControl) {
      return control.invalid && (control.touched || control.dirty);
    }
    return false;
  }
    
  limpiarTipoRespuesta(control: AbstractControl | null): void {
    if (control instanceof FormControl) {
      control.setValue(null); // Establece el valor del control en null
      control.markAsTouched(); // Marca el control como tocado para activar las validaciones si es necesario
    }
  }

  
}
