<div class="app content tabla kobra">
  <div class="button-container">
    <div class="button-toggle-group-container">
      <mat-button-toggle-group #group="matButtonToggleGroup" appearance="legacy">
        <mat-button-toggle value="filtros" 
          (click)="filtrosSideNav.toggle(); filtrosAbiertos = !filtrosAbiertos;"
          matTooltip="Filtrar"
          mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">filter_alt</mat-icon>
          <span  class="notification-dot-filtros notification-dot-accion">1</span>
        </mat-button-toggle>
        <mat-button-toggle value="actualizar"
          (click)="obtenerArchivos(filters.page);"
          matTooltip="Refrescar"
          mat-tooltip-panel-above>
          <mat-icon class="kobra-icon">sync</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="subMenu"  
          matTooltip="Acciones"
          mat-tooltip-panel-above
          [matMenuTriggerFor]="menu">
          <mat-icon class="kobra-icon">more_vert</mat-icon>
        </mat-button-toggle>
        <mat-button-toggle value="ayuda" 
          matTooltip="Ayuda"
          mat-tooltip-panel-above
          (click)="ayuda = !ayuda;">
          <mat-icon color="primary">help</mat-icon>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <mat-menu #menu="matMenu">
    <button mat-menu-item [disabled]="loadVariables" (click)="openDialogVariables(false)">
      <mat-icon class="kobra-icon">playlist_add_check</mat-icon>
      <span>Variables</span>
    </button>
    <button mat-menu-item (click)="openDialogCargarArchivo()" *ngIf="filters.idEstatus != 3">
      <mat-icon class="kobra-icon">cloud_upload</mat-icon>
      <span>Cargar nueva plantilla</span>
    </button>
    <button [disabled]="itemsSelected.length == 0" mat-menu-item (click)="descargarArchivo(itemsSelected[0])"> 
      <mat-icon class="kobra-icon">cloud_download</mat-icon>
      <span>Descargar plantilla</span>
    </button>
    <button [disabled]="itemsSelected.length == 0 || filters.idEstatus == 2" mat-menu-item (click)="openDialogConfirmacion(itemsSelected[0])">
      <mat-icon color="warn">delete_forever</mat-icon>
      <span>Eliminar</span>
    </button>
    
  </mat-menu>
    <app-table
      [classContainer]="'tabla-completa'"
      [loading]="cargandoArchivos"
      [columns]="tablaArchivos.columns"
      [items]="archivos"
      [headers]="tablaArchivos.headers"
      [multiSelect]="tablaArchivos.multiSelect"
      (onSelectedRows)="onSelected($event)"
      (sortChange)="setSortField($event)"
    >

      <div t-header>     
        <mat-card *ngIf="ayuda" class="cardAyuda">
          <mat-card-header>
            <mat-card-title>Ayuda</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <div style="margin-left: 10px; margin-right: 10px; margin-top: 10px;">
              <p>En esta opción podrás subir tus plantillas con extensión ".docx" para que se generen en automático con la información de la cuenta.</p>
              <p>¿Cómo le hacemos? A continuación se enlistan los pasos para que puedas configurar tus plantillas:</p>
              <ol>
                <li>
                  <p>Crea tu plantilla en Word y donde quieras que se llene la información dinámica la vas a reemplazar con las variables que te enlistamos en el catálogo de variables.</p>
                </li>
                <li>
                  <p>Sube tus plantillas ya listos con las variables correspondientes y listo, en cuanto guardes todas las cuentas con este producto podran generar estos documentos.</p>
                </li>
              </ol>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <ng-container t-paginator>
        <mat-paginator
          showFirstLastButtons
          [length]="pagination?.totalRows"
          [pageIndex]="pagination?.page-1"
          [pageSize]="pageSize"
          (page)="onPage($event)"
          aria-label="Select page of periodic elements">
        </mat-paginator>
      </ng-container>
    </app-table>
</div>

<mat-sidenav #filtrosSideNav fixedInViewport autosize class="sidenav-right" mode="side" position="start" [opened]="false">
  <mat-toolbar>
    <button class="filter-button-side" mat-flat-button
      matTooltip="Ocultar filtros"
      mat-tooltip-panel-above
      (click)="filtrosSideNav.toggle()"
    >
      <mat-icon>cancel</mat-icon>
    </button> 
    Filtros
  </mat-toolbar>
  <ol class="lista-filtros-side">
    <div class="div-filtros-side">
      <li class="filtro-side">
        <mat-form-field style="width: 100%;">
          <mat-label>Estatus</mat-label>
          <mat-select
          [(ngModel)]="filters.idEstatus"
          placeholder="Seleccione..."
          >
          <mat-option [value]="1">Activo</mat-option>
          <mat-option [value]="2">Inactivo</mat-option>
        </mat-select>
          <mat-icon matSuffix>visibility</mat-icon>
        </mat-form-field>
      </li>
    </div>
  </ol>
  <ol class="lista-filtros-buttons">
    <button
      style="width: 100%;"
      mat-stroked-button
      class="boton-accion-front"
      (click)="filtrosSideNav.toggle(); filtrosAbiertos=!filtrosAbiertos; filterModal(true);">
        <mat-icon>filter_alt</mat-icon>
        Filtrar
    </button>
    <button
      style="width: 100%; margin-top: 10px;"
      mat-stroked-button
      (click)="filterModal(false)">
        <mat-icon class="kobra-icon">filter_alt_off</mat-icon>
        Limpiar Filtros
    </button>
  </ol>
</mat-sidenav>