import { ServiciosSaldosKobraService } from '../core/http/servicios-saldos-kobra/servicios-saldos-kobra.service';
import { Injectable } from '@angular/core';
import { Observable, empty } from "rxjs";
import { map } from "rxjs/operators";

import { Agent } from "./agent.model";
import { AgentStatus } from "./agent-status.model";
import { PercentageDeallocation } from "../indicators/percentage-deallocation.model";
import { AccountPaginator } from "./agent.paginator";
import { AgentAddress } from "../agent-address/agent-address.model";
import { AgentBank } from "../agent/agent-bank/agent-bank.model";
import { GenericReservedActiveResponse } from "../account/reserved-active/generic-reserved-active";
import { GenericReservedActive } from "../account/reserved-active/generic-reserved-active";
import { Pagination } from "../pagination/pagination.model";
import { Visit } from "../visit/visit.model";
import { ApiKobraService } from "../core/http/api-kobra/api-kobra.service";
import { ServicesKobraService } from "../core/http/services-kobra/services-kobra.service";
import { ServicesGestoresService } from "../core/http/services-gestores/services-gestores.service";
import { ServiciosKobraService } from "../core/http/servicios-kobra/servicios-kobra.service";
import { ServicesVisitasService } from '../core/http/services-visitas/services-visitas.service';
import { AgenteProductosFinanciera } from './agente-productos-financiera.model';
import { ProductosFinanciera } from './productos-financiera.model';
import { AgenteNuevoModel } from './modelos/agente-nuevo.model';
import { IAgenteBitacora, IAgenteNvoComentario } from './interfaces/agente-bitacora.interface';
import { IAgenteCamposExtras, ICampoExtraAgente } from './interfaces/campo-extra-agentes.interface';
import { IServicesTypeKobraResponse } from '../core/http/servicio-general/servicio-general-response';

@Injectable()
export class AgentService {
  public apiService = '/v2/agents';
  private servicesURL = '/cobradores';
  private adminServices = '/admin/cobradores';
  private visitServices = '/agentes';

  constructor(
    private http: ApiKobraService,
    private servicesKobra: ServicesKobraService,
    private servicesGestores: ServicesGestoresService,
    private serviciosKobra: ServiciosKobraService,
    private serviciosVisitas: ServicesVisitasService,
    private serviciosSaldos: ServiciosSaldosKobraService,
  ) { }

  public add(agent: Agent): Observable<Agent> {
    return this.http.post(this.apiService, agent).pipe(
      map((res) => res.data as Agent)
    );
  }

  public baja(idAgente: number, query: string = ''): Observable<any> {
    return this.serviciosVisitas.delete(`/agentes/${idAgente}${query}`).pipe(
      map((res) => res )
    );
  }

  public alta(idAgente: number, data: any): Observable<any> {
    return this.serviciosVisitas.patch(`/agentes/${idAgente}/alta`, data).pipe(
      map((res) => res )
    );
  }

  public nuevo(agente: AgenteNuevoModel): Observable<any> {
    return this.servicesGestores.post(`/agentes/alta`, agente).pipe(
      map((res) => res )
    );
  }

  public camposExtras(agenteCamposExtras: IAgenteCamposExtras): Observable<any> {
    return this.serviciosVisitas.post(`/agentes/campos-extras`, agenteCamposExtras).pipe(
      map((res) => res )
    );
  }

  public obtenerInformacionAdicional(agenteId: number, idCliente: number): Observable<IServicesTypeKobraResponse<ICampoExtraAgente[]>> {
    return this.serviciosVisitas.get<ICampoExtraAgente[]>(`/agentes/${agenteId}/campos-extras?idCliente=${idCliente}`)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  public getHistorial(idCobrador: number, query:string): Observable<Agent> {
    return this.servicesGestores.getHistorial(idCobrador, query).pipe(
      map((res) => res)
    );
  }

  public descargarHistorialUbicaciones(idCobrador: number, query:string) {
    return this.servicesGestores.descargarHistorialUbicaciones(idCobrador, query);
  }

  public validar(idCobrador: number): Observable<number> {
    return this.servicesKobra.post(`${this.adminServices}/${idCobrador}/validar`, null).pipe(
      map((res) => res.data.rows || 0)
    );
  }

  public desvincularcionMasiva( idCliente: number, idCobrador: any, idsCobradoresproductos: any){
    let queryParam = '';
    queryParam += `?idsCobradoresproductos=${idsCobradoresproductos}`;

    return this.serviciosKobra.delete(`/clientes/${idCliente}/cobradores/${idCobrador}/desvinculacion-productos` + queryParam);
  }

  public desvincularcionMasivaAgentes( idCliente: number, masivo: boolean, idsCobradores: any){
    let queryParam = '';
    queryParam += `?idsCobradores=${idsCobradores}`;

    if(idCliente){
      queryParam += `&idCliente=${idCliente}`;
    }

    if(masivo){
      queryParam += `&masivo=${masivo}`;
    }

    return this.serviciosKobra.delete(`/desvinculacion-masiva-agentes` + queryParam);
  }

  public currentAddress(id: number): Observable<AgentAddress> {
    return this.http.get(`${this.apiService}/${id}/current-address`).pipe(
      map((res) => {
        let agentAddress: AgentAddress;
        let responseData: any = res.data;
        if (responseData) {
          agentAddress = new AgentAddress();
          agentAddress.id = responseData.id;
          agentAddress.street = responseData.street;
          agentAddress.outdoorNumber = responseData.outdoorNumber;
          agentAddress.indoorNumber = responseData.indoorNumber;
          agentAddress.neighborhoodID = responseData.neighborhoodID;
          agentAddress.neighborhood.id = responseData.neighborhoodID;
          agentAddress.neighborhood.name = responseData.neighborhoodName;
          agentAddress.postalCode = responseData.postalCode;
          agentAddress.stateID = responseData.stateID;
          agentAddress.state.id = responseData.stateID;
          agentAddress.state.name = responseData.stateName;
          agentAddress.cityID = responseData.cityID;
          agentAddress.city.id = responseData.cityID;
          agentAddress.city.name = responseData.cityName;

        }
        return agentAddress;
      })
    );
  }

  public curretnBank(id: number): Observable<AgentBank> {
    return this.serviciosSaldos.get(`${this.servicesURL}/${id}/cuenta-bancaria`).pipe(
      map((res) => {
        let responseData: any = res.data;
        let agentBank: AgentBank = AgentBank.map(res.data);
        return agentBank;
      })
    );
  }
  /*
  public ganancias(idCobrador: number): Observable<GananciaCobrador> {
    return this.servicesKobra.get(`${this.servicesURL}/${idCobrador}/ganancias`).pipe(
      map((res) => <GananciaCobrador>res.data)
    );
  }
  */

  public getAll(query: string = ''): Observable<Agent[]> {
    return this.http.get(`${this.apiService}${query}`).pipe(
      map((res) => <Agent[]>res.data)
    );
  }

  public getOne(id: number, query: string = ''): Observable<Agent> {
    return this.servicesGestores.get(`/agentes/${id}${query}`).pipe(
      map(res =>{
        let agent:Agent = Agent.map(res.data);
        return agent;
      })
    );
    // return this.http.get(`${this.adminServices}/${id}${query}`).pipe(
    //   map(res => {
    //     let agent: Agent = <Agent>res.data;
    //     agent.validado = false;
    //     if (res.data.validacionCelular) {
    //       agent.validado = res.data.validacionCelular.validado == 1 ? true : false;
    //     }
    //     agent.buroLaboral = res.data.buroLaboral == 1 ? true : false;
    //     agent.disponible = res.data.disponible == 1 ? true : false;
    //     return agent;
    //   })
    // );
  }

  public getFinancialsLinked(id: number): Observable<AgenteProductosFinanciera[]> {
    return this.serviciosKobra.get(`${this.servicesURL}/${id}/productos-vinculados`).pipe(
      map(res => AgenteProductosFinanciera.mapArrayData((res.data) ? res.data : null))
    );
  }


  public getPercentageDeAllocation(id: number, query: string = ''): Observable<PercentageDeallocation> {
    return this.serviciosKobra.get(this.servicesURL + '/' + id + '/desasignacion' + query)
      .pipe(
        map(
          res => {
            let percentageDeallocation: PercentageDeallocation = PercentageDeallocation.map(res.data);
            return percentageDeallocation;
          }
        )
      )
  }

  public obtenerListado(pagination: Pagination, query: string = '', page): Observable<AccountPaginator> {
    let rows = (pagination.perPage || 100);
    let start = 0;
    if (page > 1) {
      start = ((page - 1) * rows) + 1;
    }
    query += (!query ? '?' : '&') + `start=${start}`;
    query += '&rows=' + rows;
    return this.serviciosKobra.get(`${this.servicesURL}/grid${query}`).pipe(
      map((res) => {
        let response: any = res.data;

        let paginator = new AccountPaginator();
        paginator.pagination.currentPage = response.page;
        paginator.pagination.pageCount = response.pages;
        paginator.pagination.perPage = rows;
        paginator.pagination.totalCount = response.totalRows;
        paginator.data = [];
        for (let i = 0; i < response.pageData.length; i++) {
          let agentData = response.pageData[i];
          let agent: Agent = new Agent();
          agent.id = agentData.agentID;
          agent.certified = +agentData.agentCritified ? true : false;
          agent.createdAt = agentData.agentCreatedAt;
          agent.fullName = agentData.agentFullName;
          agent.profilePicture = agentData.agentProfilePicture;
          agent.address.city = agentData.municipio;
          agent.address.state = agentData.estado;
          agent.address.postalCode = agentData.postalCode;
          agent.checklist.certification = agentData.agentChecklistCertification;
          agent.checklist.personalReferences = agentData.agentChecklistPersonalReferences;
          agent.checklist.profilePicture = agentData.agentChecklistProfilePicture;
          agent.checklist.workExperience = agentData.agentChecklistWorkExperience;
          agent.checklist.workReferences = agentData.agentChecklistWorkReferences;
          agent.visibility = agentData.visibility;
          agent.desassignedAccountCount = agentData.desassignedAccountCount;
          agent.assignedAccountCount = agentData.assignedAccountCount;
          agent.buroLaboral = agentData.bureauLabor;
          agent.cedulaFiscal = agentData.cedulaFiscal;
          agent.disponible = agentData.disponible;
          agent.primerContacto = agentData.primerContacto;
          agent.cobradorEstatus.nombre = agentData.cobradorEstatus;
          agent.ranking = agentData.ranking;
          agent.deleted = agentData.deleted;


          for (let j = 0; j < agentData.agentVisitsCount; j++) {
            agent.visits.push(new Visit());
          }
          paginator.data.push(agent);
        }
        return paginator;
      })
    );
  }

  public update(agent: any): Observable<any> {
    return this.servicesGestores.patch(`/agentes/${agent.id}`, agent);
  }

  public getLinkedLenders(agentID: number, idCliente: number): Observable<GenericReservedActive> {
    let query = '';

    if (idCliente) {
      query += '?idCliente=' + idCliente;
    }

    return this.serviciosVisitas.get(`${this.visitServices}/${agentID}/clientes-cuentas-apartadas-activas${query}`).pipe(
      map(res => {
        let sumActivas = 0;
        let sumApartadas = 0;
        let genericsReservedActiveResponse: GenericReservedActiveResponse[] = [];

        for (let i = 0; i < res.data.length; i++) {
          sumActivas += +res.data[i].totalCuentasActivas;
          sumApartadas += +res.data[i].totalCuentasApartadas;

          let agentReservedActiveResponse: GenericReservedActiveResponse = {
            nombre: res.data[i].nombreFinanciera,
            totalActivas: +res.data[i].totalCuentasActivas,
            totalApartadas: +res.data[i].totalCuentasApartadas
          };

          genericsReservedActiveResponse.push(agentReservedActiveResponse);
        }

        let stateReservedActive: GenericReservedActive = {
          genericsReservedActiveResponse: genericsReservedActiveResponse,
          sumActivas: sumActivas,
          sumApartadas: sumApartadas,
        };

        return stateReservedActive;
      })
    );
  }
  public getAgentStatus(): Observable<AgentStatus[]> {
    return this.serviciosVisitas.get(`${this.visitServices}/estatus`).pipe(
      map(res => <AgentStatus[]>res.data)
    );
  }

  //obtiene las cuentas apartadas activas por agente
  public getAccountReservedActive(idCliente): Observable<GenericReservedActive> {
    let query: string = ''

    if (idCliente) {
      query += '?idCliente=' + idCliente;
    }

    return this.serviciosVisitas.get(this.visitServices + '/cuentas-apartadas-activas' + query).pipe(
      map(res => {
        let sumApartadas = 0;
        let genericsReservedActiveResponse: GenericReservedActiveResponse[] = [];

        for (let i = 0; i < res.data.length; i++) {
          sumApartadas += +res.data[i].totalCuentasApartadas;

          let genericReservedActiveResponse: GenericReservedActiveResponse = {
            id: res.data[i].idAgente,
            idCobrador: res.data[i].idCobrador,
            nombre: res.data[i].nombreAgente,
            totalApartadas: +res.data[i].totalCuentasApartadas
          };

          genericsReservedActiveResponse.push(genericReservedActiveResponse);
        }

        let lenderReservedActive: GenericReservedActive = {
          genericsReservedActiveResponse: genericsReservedActiveResponse,
          sumApartadas: sumApartadas
        };

        return lenderReservedActive;
      })
    );
  }

  getAverageRate(idCobrador: number): Observable<number> {

    return this.serviciosVisitas.get(`${this.visitServices}/${idCobrador}/calificacion`).pipe(
      map(res => {
        const response: any = res.data;
        let calificacion: number = 0;

        if (+response.calificacion) {
          calificacion = +response.calificacion;
        }
        return Math.round(calificacion);
      })
    );
  }

  obtenerAgentes(term: any, correo?:string) {
    let queryParam = '';
    if(term!=null){
      if (Number.isInteger(Number(term))) {
        queryParam += `?idCobrador=${term}`;
      } else {
        queryParam += `?nombre=${term}`;
      }
    }else{
      if (correo) {
        queryParam += '?correo='+correo;
      }
    }

    return this.serviciosVisitas.get(`/agentes${queryParam}`);
  }

  obtenerAgentesConDistanciaDeCuenta(term: any, isDistancia: boolean, latitud: number, longuitud: number, idsClientes: string, idsProductos:string) {
    let queryParam = '';
    if(term!=null){
      if (Number.isInteger(Number(term))) {
        queryParam += `?idCobrador=${term}`;
      } else {
        queryParam += `?nombre=${term}`;
      }

      if(idsClientes && idsProductos) {
        queryParam += `&idsClientes=${idsClientes}&idsProductos=${idsProductos}`;
      }

      if(latitud && longuitud) {
        queryParam +=  `&isDistancia=${isDistancia}&latDestino=${latitud}&lngDestino=${longuitud}`;
      }
    }

    return this.serviciosVisitas.get(`/agentes/asignacion-cuentas${queryParam}`);
  }


  obtenerAcreditados(term: string, idFinanciera?: number) {
    let queryParam = `?term=${term}`;

    if (idFinanciera) {
      queryParam += `&idFinanciera=${idFinanciera}`;
    }

    return this.serviciosVisitas.get(`/acreditados${queryParam}`);
  }

  obtenerSolicitantes(term: string) {
    let queryParam = `?term=${term}`;
    return this.serviciosVisitas.get(`/solicitantes${queryParam}`);
  }

  buscarCobrador(term: string) {
    let queryParam = '';

    if (Number.isInteger(Number(term))) {
      queryParam += `?idCobrador=${term}`;
    } else {
      queryParam += `?nombre=${term}`;
    }

    return this.serviciosKobra.get(`/cobradores-buscar${queryParam}`);
  }

  buscarAcreditado(term: string) {
    let queryParam = '';

    if (Number.isInteger(Number(term))) {
      queryParam += `?idExterno=${term}`;
    } else {
      queryParam += `?nombre=${term}`;
    }

    return this.serviciosKobra.get(`/acreditados${queryParam}`);
  }

  public getStateMunicipalityAccountReservedActive(idCliente: any, nombre: string, isEstado: boolean): Observable<GenericReservedActive> {
    let query: string = ''

    if (isEstado) {
      query += `?estado=${nombre}`;
    } else {
      query += `?municipio=${nombre}`;
    }

    if (idCliente) {
      query += '&idCliente=' + idCliente;
    }

    return this.serviciosVisitas.get(this.visitServices + '/cuentas-apartadas-activas' + query).pipe(
      map(res => {
        let sumApartadas = 0;
        let genericsReservedActiveResponse: GenericReservedActiveResponse[] = [];

        for (let i = 0; i < res.data.length; i++) {
          sumApartadas += +res.data[i].totalCuentasApartadas;

          let genericReservedActiveResponse: GenericReservedActiveResponse = {
            id: res.data[i].idAgente,
            nombre: res.data[i].nombreAgente,
            totalApartadas: +res.data[i].totalCuentasApartadas
          };

          genericsReservedActiveResponse.push(genericReservedActiveResponse);
        }

        let lenderReservedActive: GenericReservedActive = {
          genericsReservedActiveResponse: genericsReservedActiveResponse,
          sumApartadas: sumApartadas
        };

        return lenderReservedActive;
      })
    );
  }

  public eliminarProducto(producto: ProductosFinanciera){
    return this.serviciosKobra.delete(`/cobradores/${producto.idCobrador}/cobradores-productos/${producto.idCobradorProducto}`);
  }

  vincularProducto(idCobrador: any, idFinanciera: any, productos: any) {
    return this.serviciosKobra.post(`/cobradores/${idCobrador}/vinculaciones-productos/${idFinanciera}`, productos);
  }

  vincularTiposCredito(idCobrador: any, idFinanciera: any, tiposCredito: any) {
    return this.serviciosKobra.post(`/cobradores/${idCobrador}/vinculaciones-tipos-credito/${idFinanciera}`, tiposCredito);
  }

  getDownTags(idGrupoEtiqueta : number) {
    return this.serviciosVisitas.get('/etiquetas/baja?idGrupoEtiqueta=' + idGrupoEtiqueta).pipe(
      map((res) => res.data.map(i => ({
        idTag: i.idEtiqueta,
        name: i.nombre
      })))
    );
  }

  descargarListado(pParams: string){
    return this.serviciosKobra.getFile(`/cobradores/grid-download${pParams}`)
  }

  getHistory(idAgente: number) {
    return this.servicesGestores.get(`/agentes/${idAgente}/historial`).pipe(
      map((res) => res.data.map(i => ({
        movement: i.tipoMovimiento,
        tag: i.etiqueta,
        userName: i.usuarioRegistro,
        comment: i.comentarios,
        date: i.fecha
      })))
    );
  }

  obtenerHistorialNoDisponible(idCobrador:number){
    return this.servicesGestores.get(`/agentes/${idCobrador}/historial-no-disponible`);
  }

  getBitacora<T = any>(idAgente: number, page: number, rows: number) {
    return this.servicesGestores.get<T>(`/agentes/${idAgente}/bitacora?page=${page}&rows=${rows}`);
  }

  guardarBitacora(idAgente: number, bitacora: IAgenteNvoComentario) {
    return this.servicesGestores.post<IAgenteBitacora>(`/agentes/${idAgente}/bitacora`, bitacora).pipe(
      map(res => res.data)
    );
  }
}
