import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, Params, NavigationEnd } from "@angular/router";

import { Lender } from "../lender.model";
import { LenderService } from "../lender.service";
import { MessageService } from "../../shared/message/message.service";
import { PaqueteFinanciera } from "../../paquete-financiera/paquete-financiera.model";
import { PaquetesFinancieraService } from 'src/app/saldos/paquetes-financiera/paquetes-financiera.service';
import { take } from 'rxjs/operators';
import { ApplicationService } from 'src/app/application/shared/application.service';
import { PaquetesFinancieraDetalleService } from 'src/app/saldos/paquetes-financiera/paquetes-financiera-detalle/paquetes-financiera-detalle.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'lender-detail',
  templateUrl: './detail.component.html',
  styleUrls: ['./detail.component.css'],
  providers: [
    LenderService
  ]
})
export class LenderDetailComponent implements OnInit, OnDestroy {
  private routerSubscription: Subscription;
  public idFinanciera: number;
  public lender: Lender = new Lender();
  public paqueteContratado: PaqueteFinanciera;
  public paquetesContratados: PaqueteFinanciera[];
  public nuevoPaquete: boolean = false;
  public financieraName: string;
  public loaderText: string;
  public conceptosPorPaquete: Array<any> = [];
  public cargando: boolean = true;
  hijosActivos: boolean = false;

  constructor(
    private lenderService: LenderService,
    private messageService: MessageService,
    private acRoute: ActivatedRoute,
    private pqtsFinancieraServ: PaquetesFinancieraService,
    private app: ApplicationService,
    private router: Router,
    private paquetesFinancieraDetalleService: PaquetesFinancieraDetalleService,
  ) {
    this.paquetesContratados = [];
  }

  ngOnInit() {
    this.getFinancieraName();
    this.lender = new Lender();
    this.readParams();
    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.loadPaquetesContratados(this.idFinanciera);
      }
    });
  }

  ngOnDestroy(): void {
    this.pqtsFinancieraServ.setPaqueteData(undefined);
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private loadLenderInfo(idLender: number): void {
    this.lenderService.obtenerFinanciera(idLender)
    .pipe(
      take(1)
    ).subscribe((res: any) => {
      this.financieraName = res.data[0].nombre;
    }, (error: any) => {
      this.app.showError(error);
    });
  }

  private loadPaquetesContratados(lenderId: number): void {
    //this.loaderService.showHttpLoader();
    this.lenderService.obtenerPaquetes(lenderId, null).subscribe(
      paquetesFinanciera => {
        this.paquetesContratados = paquetesFinanciera;
        //this.loaderService.hideHttpLoader();

        for( let i = 0; i < paquetesFinanciera.length; i++ ) {
          this.conceptosPorPaquete[i] = {
            cargando: true,
            conceptos: []
          };
          this.paquetesFinancieraDetalleService.getConceptos(this.idFinanciera, paquetesFinanciera[i].idFinancieraPaquete)
          .pipe(take(1))
          .subscribe((conceptos) => {
            this.conceptosPorPaquete[i].cargando = false;
            this.conceptosPorPaquete[i].conceptos = conceptos;
          }, (err) => {
            this.conceptosPorPaquete[i].cargando = false;
            this.app.showError(err);
          });
        }
      },
      error => {
        //this.loaderService.hideHttpLoader();
        this.messageService.httpError(error);
      }
    );
  }

  public paqueteAgregado(paqueteFinanciera: PaqueteFinanciera): void {
    this.paquetesContratados.push(paqueteFinanciera);
    this.loadPaquetesContratados( this.idFinanciera );
  }

  private readParams(): void {
    this.acRoute.params.subscribe(
      (params: Params) => {
        if (params['idFinanciera']) {
          this.idFinanciera = +params['idFinanciera'];

          if (!this.financieraName) {
            this.loadLenderInfo(+params['idFinanciera']);
          }

          this.loadPaquetesContratados(+params['idFinanciera']);
        }
      }
    );
  }

  getFinancieraName(): void {
    if (this.pqtsFinancieraServ.paquete) {
      this.financieraName = this.pqtsFinancieraServ.paquete.nombreFinanciera;
    } else {
      this.financieraName = undefined;
    }
  }

  cancelarPaquete(idFinancieraPaquete: number): void {
    
    let loading = this.app.showLoading('Cancelando paquete...');
    this.lenderService.cancelarPaquete(this.idFinanciera, idFinancieraPaquete)
    .pipe( take(1) )
    .subscribe(
      paquetesFinanciera => {
        this.app.showSnackbar('¡Aviso!', 'Paquete cancelado correctamente.', 3000, 'success');

        //const index: number = this.paquetesContratados.findIndex((paquete: PaqueteFinanciera) => paquete.idFinancieraPaquete == idFinancieraPaquete);
        //this.paquetesContratados.splice(index, 1);
        this.loadPaquetesContratados( this.idFinanciera );
        this.app.hideLoading(loading);
      },
      error => {
        this.app.hideLoading(loading);
        this.app.showError(error);
      }
    );
  }

  preciosPorProducto(paquete: any): void {
    if (paquete.saldo == 0 && paquete.idPaquete > 0 && paquete.paqueteActual == false) {
      return this.app.showSnackbar('¡Aviso!', 'Este paquete ya no puede ser modificado.', 3000, 'success');
    }
    this.conceptosPorPaquete = [];
    this.router.navigate(['paquetes-cliente', this.idFinanciera, 'paquetes', paquete.idFinancieraPaquete], {
      queryParamsHandling: 'preserve'
    });
    
  }
  
}
