import { Coordenadas } from './../mapbox/modelos/coordenadas.model';
import { Account } from "../account/account.model";
import { Agent } from "../agent/agent.model";
import { Checklist } from "../checklist/checklist.model";
import { Client } from "../client/client.model";
import { ClientAddress } from "../client-address/client-addres.model";
import { FileAsset } from "../file-asset/file-asset.model";
import { GeneralChecklist } from "../general-checklist/general-checklist.model"
import { Lender } from "../lender/lender.model";
import { TakenAccount } from "../taken-account/taken-account.model";
import { VisitPhoto } from "../visit-photo/visit-photo.model";
import { TipoVisitaCoordenada } from "./modelos/tipo-visita-coordenada.model";

export class Visit {
    id: number;
    folio: string;
    account: Account;
    idProducto: number;
    takenAccount: TakenAccount;
    accountVisits: Visit[];
    agentID: number;
    agent: Agent;
    cancellationReason: string;
    cancelled: boolean;
    checklist: Checklist;
    client: Client;
    clientAddress: ClientAddress;
    clientID: number;
    createdAt: Date;
    fecInicio: Date;
    fecFin: Date;
    fecInicioLocal: Date;
    fecFinLocal: Date;
    accountDays: number;
    daysUntilFisrtVisit: number;
    daysTaken: number;
    photos: FileAsset[];
    generalChecklist: GeneralChecklist;
    visitPhoto: VisitPhoto[];
    lender: Lender;
    puedeAclarar: boolean;
    rebound: boolean;
    mock: boolean;
    offline: boolean;
    finished: boolean;
    visibility: string;
    calificacionAdministradorFinanciera: number;
    calificacionSuperAdministrador: number;
    selected: boolean;
    redInterna: boolean;
    motivoCancelacion: string;
    nombreProducto: string;
    nombreEtiqueta: string;
    grupo: string;
    sucursal: string;
    nombreAgente: string;
    totalRevisitas: number;
    idAgenteM: number;
    latInicio: string;
    latFin: string;
    lngInicio: string;
    lngFin: string;
    latFotos: string;
    lngFotos: string;
    estado: string;
    municipio: string;
    colonia: string;
    codigoPostal: string;
    calle: string;
    numeroExt: string;
    numeroInt: string;
    fullAddres: string;
    nombreEtiquetaOperacion:string;
    nombreEtiquetaCuenta:string;
    idSubcuenta:string;
    idFinanciera:number;
    idCliente:number;
    coordenadasVisita: any;
    //tiposCoordenada: any;
    coordenadasColumna: String;
    telCel: String;
    dispositivo: String;
    idDomicilioAcreditado: number;
    folioDomicilio: string;
    idAcreditadoCuentaDomicilio: number;
    idChecklist: number;
    numVersion: number;
    coordenadas:string;
    comentariosIA: string;
    comentarios: string;

    constructor() {
        this.account = new Account();
        this.agent = new Agent();
        this.checklist = new Checklist();
        this.client = new Client();
        this.clientAddress = new ClientAddress();
        this.generalChecklist = new GeneralChecklist();
        this.lender = new Lender();
        this.takenAccount = new TakenAccount();
    }

    public static map(data: any): Visit {
        let instance: Visit = new Visit();
        instance.id = data.idVisita || data.id || 0;
        instance.idFinanciera = data.idFinanciera;
        instance.idCliente = data.idCliente;
        instance.folio = data.folioVisita;
        instance.idProducto = data.idProducto ? data.idProducto : null;
        instance.grupo = data.grupo;
        instance.sucursal = data.sucursal;
        // instance.account = data.attr;
        // instance.takenAccount = data.attr;
        // instance.accountVisits = data.attr;
        instance.agentID = data.idCobrador;
        // instance.agent = data.attr;
        instance.cancellationReason = data.cancellationReason;
        instance.cancelled = data.cancelled;
        instance.checklist = data.attr;
        // instance.client = data.attr;
        // instance.clientAddress = data.attr;
        instance.clientID = data.idAcreditado;
        instance.createdAt = data.createdAt;
        // instance.accountDays = data.attr;
        // instance.daysUntilFisrtVisit = data.attr;
        // instance.daysTaken = data.attr;
        // instance.photos = data.attr;
        // instance.generalChecklist = data.attr;
        // instance.visitPhoto = data.attr;
        // instance.lender = data.attr;
        instance.rebound = data.rebound;
        instance.mock = data.mock;
        instance.offline = data.offline;
        instance.finished = data.finished;
        // instance.visibility = data.attr;
        // instance.calificacionAdministradorFinanciera = data.attr;
        // instance.calificacionSuperAdministrador = data.attr;
        //instance.idCobrador =  data.idCobrador;
        instance.totalRevisitas = data.totalRevisitas;
        instance.idAgenteM = data.idAgente;
        instance.latInicio = data.latInicio;
        instance.lngInicio = data.lngInicio;
        instance.latFin = data.latFin;
        instance.lngFin = data.lngFin;
        instance.latFotos = data.latFotos;
        instance.lngFotos = data.lngFotos;
        instance.colonia = data.colonia;
        instance.calle = data.calle;
        instance.numeroExt = data.numeroExt;
        instance.numeroInt = data.numeroInt;
        instance.codigoPostal = data.codigoPostal;
        instance.idSubcuenta = data.idSubcuenta;
        instance.fecInicio = data.inicioVisita;
        instance.fecFin = data.finVisita;
        instance.fecInicioLocal = data.fecInicioLocal;
        instance.fecFinLocal = data.fecFinLocal;
        //instance.telCel = data.telCelVisita;
        instance.dispositivo = data.dispositivo;
        instance.coordenadasVisita = data.coordenadasVisita ? JSON.parse(data.coordenadasVisita) : [];
        //instance.tiposCoordenada = data.tiposCoordenada ? JSON.parse(data.tiposCoordenada) : [];
        instance.idDomicilioAcreditado =  data.idDomicilioAcreditado;
        Visit.ajustarCoordenadasPendientes(/*instance.tiposCoordenada, */instance.coordenadasVisita);

        return instance;
    }

    public static mapArray(data: any[]): Visit[] {
        let instances: Visit[] = [];
        for (let i = 0; i < data.length; i++) {
            instances.push(Visit.map(data[i]));
        }
        return instances;
    }

    public static ajustarCoordenadasPendientes(visitasCoordenadas){

        let tiposCoordenada:TipoVisitaCoordenada[] = [];
        for (let index = 0; index < visitasCoordenadas.length; index++) {
            const coordenada = visitasCoordenadas[index];

            let tipoCoordenadaActual:TipoVisitaCoordenada[] = [];
            if(tiposCoordenada.length > 0 ){
                tipoCoordenadaActual = tiposCoordenada.filter(tipoCoordenadaValidar => tipoCoordenadaValidar.tipoCoordenada == coordenada.idTipoVisitaCoordenada);
            }

            let tipoCoordenada:TipoVisitaCoordenada = tipoCoordenadaActual.length > 0 ? tipoCoordenadaActual[0] : {tipoCoordenada: coordenada.idTipoVisitaCoordenada, contador: 0, nombreCoordenada: coordenada.nomTipoVisitaCoordenada};
            if(tipoCoordenadaActual.length == 0){
                tiposCoordenada.push(tipoCoordenada);
            }
            coordenada.ubicacion = `${coordenada.lat}, ${coordenada.lng}`
            tipoCoordenada.contador++;

            if(coordenada.nomTipoVisitaCoordenada){
                coordenada.nombreCoordenada = coordenada.nomTipoVisitaCoordenada;
            }
            coordenada.nombreCoordenada = tipoCoordenada.contador == 1 ?
                coordenada.nombreCoordenada : `${coordenada.nombreCoordenada} ${tipoCoordenada.contador}`;

            //Si es coordenada original se mostrara siempre activa y con el nombre ubicación cuenta
            if(coordenada.idTipoVisitaCoordenada == 8){
                coordenada.nombreCoordenada = 'Ubicación cuenta';
                coordenada.seleccionada = true;
            }
        }
    }
}
