<app-modal [title]="title" [subtitle]="subtitle" [custom]="true">

  <mat-card class="card-ancho" >
    <mat-card-content class="contenido-cuestionario">
      <form [formGroup]="dynamicTableForm">
        <ng-container *ngFor="let respuesta of respuestas; let r = index" style="margin-left: 20px;">
          <ng-container [ngSwitch]="respuesta.idTipoRespuesta">
              <ng-container *ngSwitchCase="1">
                <mat-form-field>
                  <mat-label>{{respuesta.nombre || 'Escribe una respuesta'}}</mat-label>
                  <input *ngIf="r == 0" #primerControl matInput textSanitizer [formControlName]="respuesta.idChecklistRespuesta.toString()" type="text" [(ngModel)]="respuesta.valor">
                  <input *ngIf="r != 0" matInput textSanitizer [maxlength]="respuesta.maxCaracteres ? respuesta.maxcaracteres : 2000" [formControlName]="respuesta.idChecklistRespuesta.toString()" type="text" [(ngModel)]="respuesta.valor">
                  <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                    Esta respuesta es <strong>requerida</strong>
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="2">
                <mat-form-field>
                  <mat-label>{{respuesta.nombre || 'Escribe una respuesta'}}</mat-label>
                  <textarea *ngIf="r == 0" #primerControl matInput textSanitizer [formControlName]="respuesta.idChecklistRespuesta.toString()" rows="4" [(ngModel)]="respuesta.valor"></textarea>
                  <textarea *ngIf="r != 0" matInput textSanitizer [maxlength]="respuesta.maxCaracteres ? respuesta.maxcaracteres : 2000" [formControlName]="respuesta.idChecklistRespuesta.toString()" rows="4" [(ngModel)]="respuesta.valor"></textarea>
                  <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                    Esta respuesta es <strong>requerida</strong>
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="3">
                <mat-form-field>
                  <mat-label>{{respuesta.nombre || 'Escribe una respuesta'}}</mat-label>
                  <input *ngIf="r == 0" #primerControl matInput decimalValidator [decimalPlaces]="0" type="number" [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor">
                  <input *ngIf="r != 0" matInput decimalValidator [decimalPlaces]="0" type="number" [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor">
                  <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                    Esta respuesta es <strong>requerida</strong>
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="4">
                <mat-form-field [ngClass]="{'margen-Botton': dynamicTableForm.get(respuesta.idChecklistRespuesta.toString())?.hasError('pattern')}">
                  <mat-label>{{respuesta.nombre || 'Escribe una respuesta'}}</mat-label>
                  <input matInput
                        #primerControl
                        *ngIf="r == 0"
                        decimalValidator
                        type="number"
                        [decimalPlaces]="6"
                        [formControlName]="respuesta.idChecklistRespuesta.toString()"
                        [(ngModel)]="respuesta.valor"
                        >
                  <input matInput
                        *ngIf="r != 0"
                        decimalValidator
                        type="number"
                        [decimalPlaces]="6"
                        [formControlName]="respuesta.idChecklistRespuesta.toString()"
                        [(ngModel)]="respuesta.valor"
                        >
                  <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                    Esta respuesta es <strong>requerida</strong>
                  </mat-error>
                  <mat-error *ngIf="dynamicTableForm.get(respuesta.idChecklistRespuesta.toString())?.hasError('pattern')">
                    El valor debe ser un número decimal válido con hasta seis decimales.
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="5">
                  <mat-label>{{respuesta.nombre || ''}}</mat-label>
                  <mat-date-picker-formater
                    [label]="respuesta.nombre"
                    [placeHolder]="'Capture la fecha'"
                    [(ngModel)]="respuesta.valor"
                    [formControlName]="respuesta.idChecklistRespuesta.toString()"

                    [limpiar]="false"
                  />
                  <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                    Esta respuesta es <strong>requerida</strong>
                  </mat-error>
              </ng-container>

              <ng-container *ngSwitchCase="6">
                <mat-form-field>
                  <mat-checkbox *ngIf="r == 0" #primerControl class="kobra-checkbox" [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor">
                    {{respuesta.nombre}}
                  </mat-checkbox>
                  <mat-checkbox *ngIf="r != 0" class="kobra-checkbox" [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor">
                    {{respuesta.nombre}}
                  </mat-checkbox>
                  <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                    Esta respuesta es <strong>requerida</strong>.
                  </mat-error>
                </mat-form-field>

              </ng-container>

              <ng-container *ngSwitchCase="7">
                <mat-form-field>
                  <mat-label>{{respuesta.nombre || ''}}</mat-label>
                  <mat-radio-group  [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valor">
                    <mat-radio-button class="kobra-radio" *ngFor="let opt of respuesta.options" [value]="opt.id">
                      {{opt.label}}
                    </mat-radio-button>
                  </mat-radio-group>
                  <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                    Esta respuesta es <strong>requerida</strong>
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="8">
                <div class="negrita" style="margin-top: 10px !important;">
                  <mat-label>
                    {{respuesta.nombre}}{{respuesta.requerida?'*':''}}
                  </mat-label>
                </div>
                <input hidden matInput textSanitizer [formControlName]="respuesta.idChecklistRespuesta.toString()" type="text">
                <div class="kobra two columns" style="width: 175px;">
                  <div class="kobra column">
                    <div class="kobra two columns">
                      <div class="kobra column">
                        <mat-label>No</mat-label>
                      </div>
                      <div class="kobra column" style="margin-left: -5px;">
                        <mat-slide-toggle class="kobra-slide-toggle" [ngModelOptions]="{standalone: true}" [(ngModel)]="respuesta.switchNo" (ngModelChange)="onSwitchValueChange(respuesta, 'No')"></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                  <div class="kobra column">
                    <div class="kobra two columns">
                      <div class="kobra column">
                        <mat-label>Sí</mat-label>
                      </div>
                      <div class="kobra column" style="margin-left: -10px;">
                        <mat-slide-toggle class="kobra-slide-toggle" [ngModelOptions]="{standalone: true}" [(ngModel)]="respuesta.switchSi" (ngModelChange)="onSwitchValueChange(respuesta, 'Si')"></mat-slide-toggle>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>

              <ng-container *ngSwitchCase="9">
                <mat-form-field>
                  <mat-label>{{respuesta.nombre || 'Selecione una respuesta'}}</mat-label>
                  <mat-select *ngIf="r == 0" #primerControl [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valorTemp" (ngModelChange)="onChangeValue(respuesta)">
                    <!-- <mat-option [value]="null" disabled >Selecciona una opción</mat-option> -->
                    <mat-option *ngFor="let opt of respuesta.options" [value]="opt">{{opt.label}}</mat-option>
                  </mat-select>
                  <mat-select *ngIf="r != 0" [formControlName]="respuesta.idChecklistRespuesta.toString()" [(ngModel)]="respuesta.valorTemp" (ngModelChange)="onChangeValue(respuesta)">
                    <!-- <mat-option [value]="null" disabled >Selecciona una opción</mat-option> -->
                    <mat-option *ngFor="let opt of respuesta.options" [value]="opt">{{opt.label}}</mat-option>
                  </mat-select>
                  <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                    Esta respuesta es <strong>requerida</strong>
                  </mat-error>
                </mat-form-field>
              </ng-container>

              <ng-container *ngSwitchCase="10">
                <mat-form-field>
                  <mat-label>{{respuesta.nombre || ''}}</mat-label>
                  <input matInput textSanitizer
                    [formControlName]="respuesta.idChecklistRespuesta.toString()"
                    [(ngModel)]="respuesta.valor"
                    [ngxTimepicker]="picker"
                    readonly="true"
                    placeholder="Selecciona una hora"
                   >
                    <mat-icon matSuffix>access_time</mat-icon>
                  <ngx-material-timepicker #picker></ngx-material-timepicker>
                  <mat-error *ngIf="respuesta.requerida && validarControl(respuesta.idChecklistRespuesta.toString())">
                    Esta respuesta es <strong>requerida</strong>
                  </mat-error>
                </mat-form-field>
              </ng-container>
          </ng-container>
        </ng-container>
      </form>
    </mat-card-content>
    <mat-card-footer *ngIf="!edicion" class="footer-registros">
      <mat-label>Hay {{respuestasAgregadas.length}} pendientes de añadir.</mat-label>
    </mat-card-footer>
  </mat-card>

  <div class="action-buttons-modal" m-actions>
    <button class="boton-cancelar" mat-flat-button (click)="onClose()"><mat-icon class="kobra-icon">close</mat-icon>Cancelar</button>
    <button *ngIf="!edicion" mat-stroked-button class="boton-accion-front" (click)="save()"mat-stroked-button><mat-icon>add</mat-icon>Agregar</button>
    <button *ngIf="!edicion && respuestasAgregadas.length" mat-stroked-button class="boton-accion-front" (click)="finalizar()"mat-stroked-button><mat-icon>check</mat-icon>Aceptar</button>
    <button *ngIf="edicion" mat-stroked-button class="boton-accion-front" (click)="save()" mat-stroked-button><mat-icon>check</mat-icon>Aceptar</button>
  </div>
</app-modal>
