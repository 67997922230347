import { Domicilio } from 'src/app/shared/components/domicilio.interface';
import { TipoDato } from './enums/enumeradores.enum';
import moment from 'moment';
export class Utils {
    public static toCamelCase(sentenceCase) {
        var out = "";
        sentenceCase.split(" ").forEach(function (el, idx) {
            var add = el.toLowerCase();
            out += " " + (add.length ? (add[0] + '').toUpperCase() + add.slice(1) : '');
        });
        return out.trim();
    }

    public static obtenerDomicilioCompleto(item: Domicilio): string {
        return item.domicilioCompleto ? item.domicilioCompleto :
          (item.calle ? item.calle + ' ' : '') +
          (item.numeroExt ? item.numeroExt + ', ' : '') +
          (item.numeroInt ? item.numeroInt + ', ' : '') +
          (item.codigoPostal ? item.codigoPostal + ', ' : '') +
          (item.colonia ? item.colonia + ' ' : '') +
          (item.municipio ? item.municipio + ', ' : '') +
          (item.estado ? item.estado + '.' : '');
    }

    public static formatearValoresTipoDato(valor:string | number | boolean | null, tipoDato: TipoDato, nombre: string): string{
      let respuesta: string = '';
      if(valor != null){
        switch (tipoDato){
          case TipoDato.ENTERO:
          case TipoDato.DECIMAL:
            respuesta = this.formatearNumero(valor+'');
            break;
          case TipoDato.LOGICO:
            respuesta = valor === true ? 'Sí' : 'No';
            break;
          case TipoDato.FECHA:
            respuesta = moment(valor+'').format('DD/MM/YYYY');
            break;
          case TipoDato.FECHA_HORA:
            respuesta = moment(valor+'').format('DD/MM/YYYY HH:mm');
            break;
          default:
            respuesta = valor + '';
            break;
        }
      }
      return respuesta;
    }

    /**
   * Genera el formato de número para la vista.
   * @param {string|number} valor Es el valor que se va a formatear.
   * @returns devuelve el valor formateado del numero.
   * @Auth Uriel Yair Gámez Rosales
   */
    public static formatearNumero(valor:string | number | null): string {

      if(!valor){
        return "";
      }

      //Si no es valido el numero devuelve el valor vacio.
      if(!this.isValidNumber((valor+""))){
        return valor+"";
      }

      // Extraemos la parte entera y la parte decimal
      let [entero, decimal] = (valor+"").split('.');

      // Aplicamos el formato a la parte entera manualmente
      entero = entero.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      // Verificamos si existe la parte decimal
      if (decimal !== undefined) {
        return `${entero}.${decimal}`;
      }

      return entero;
    }

    /**
     * Valida si el dato es un numero.
     * @param {string|number} pNumberStr Es el valor que se va a validar.
     * @returns devuelve true si es numero valido y false si no lo es.
     * @Auth Uriel Yair Gámez Rosales
     */
    public static isValidNumber(pNumberStr: string): boolean {
      return /^-?\d+(\.\d+)?$/.test(pNumberStr);
    }
}
