import {Component, Inject, ViewChild} from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApplicationService } from "../../../application/shared/application.service";
import { VisitService } from "../../../visit/visit.service";
import { environmentSelector } from "../../../../environments/environment.selector";
import { LocalStorageService } from 'src/app/shared/services/local-storage.service';
import { SessionData } from '../../../shared/interfaces/session-data';
import * as moment from 'moment';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';

@Component({
  selector: 'descarga-formato.component',
  templateUrl: 'descarga-formato.component.html',
  styleUrls: ["descarga-formato.component.css"],
  providers: [
    VisitService
  ]
})
export class ModalDescargaFormatoComponent {
  private environment = environmentSelector();

  public sessionData: SessionData;
  public cargandoArchivos: boolean = false;
  public descargandoArchivo: boolean = false;
  public cardAyuda: boolean = false;
  public archivos: any;
  public momentFecha;
  public formatosDescargados:any

  constructor(
    public dialogRef: MatDialogRef<ModalDescargaFormatoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private http: HttpClient,
    private app: ApplicationService,
    private visitService: VisitService,
    private localStorageService: LocalStorageService
  ) {
    this.sessionData = this.localStorageService.getSessionData();
    this.archivos = this.data.archivos;
    this.momentFecha = moment;
    this.formatosDescargados = this.data.formatosDescargados;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  ngOnInit() {
    //this.obtenerArchivos();
  }

  public descargarArchivo(archivo: any, formato: string) {
    this.descargandoArchivo = true;
  
    const queryParams = `?idVisita=${this.data.idVisita}&idAgente=${this.data.idAgente}&idTipoFormato=1&formato=${formato}`;
  
   this.visitService.descargarFormatoVisita(queryParams, this.data.idCliente, archivo.idFormato).subscribe(event => {
      if (event.type === HttpEventType.Response) {
        this.descargandoArchivo = false;
        const blob = new Blob([event.body], { type: event.body.type });
        saveAs(blob, archivo.nombreArchivo);
      }
    }, error => {
      this.descargandoArchivo = false;
      this.app.showSnackbar(
        "Aviso",
        "Ocurrio un problema al intentar descargar el archivo.",
        3000,
        "error"
      );
    });
  }
  
  private getFileNameFromContentDisposition(header: string): string | null {
    if (header) {
      const matches = header.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
      if (matches && matches.length > 1) {
        const filename = matches[1].replace(/['"]/g, '');
        return decodeURIComponent(filename.trim());
      }
    }
    return null;
  }
  

  ayudaDescripcion(archivo: any){
    archivo.isDescripcion = !archivo.isDescripcion;
  }

}
