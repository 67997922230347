import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AccountsComponent } from '../accounts/accounts.component';
import { ActivatedRoute, Params } from '@angular/router';
import { ApplicationService } from '../../application/shared/application.service';
import { LocalStorageService } from '../../shared/services/local-storage.service';
import { ImportService } from '../import.service';
import { SessionData } from '../../shared/interfaces/session-data';
import { Batch } from '../models/batch.model';
import { environmentSelector } from '../../../environments/environment.selector';
import { take } from 'rxjs/internal/operators/take';
import { FiltrosService } from 'src/app/application/shared/filtros.service';
import {ModalGeocalizacionCuentasComponent} from '../modales/geocalizacion-cuentas.component';

@Component({
  selector: 'app-finish',
  templateUrl: './finish.component.html',
  styleUrls: ['./finish.component.css'],
  providers: [
    ImportService
  ]
})
export class FinishComponent implements OnInit, OnDestroy {
  private environment = environmentSelector();

  private _idCliente: number;
  private _idFinanciera: number;

  @Input() batch: Batch;

  @Input()
  set idCliente(value: number) {
    this._idCliente = value;
  }

  get idCliente(): number {
    return this._idCliente;
  }

  @Input()
  set idFinanciera(value: number) {
    this._idFinanciera = value;
  }

  get idFinanciera(): number {
    return this._idFinanciera;
  }

  IMPORT_STATUS: any = {
    PROCESSED_ERRORS: 1,
    ERROR: 2,
    QUEUE: 3,
    LOADING: 4,
    LOADED: 5,
    PROCESSING: 6,
    PROCESSED: 7,
    GEOLOCATING: 8,
    GEOLOCATED: 9,
    CALCULATING_COVERAGE: 10,
    CALCULATED_COVERAGE: 11,
    CALCULATING_SLA: 12,
    FINISHED: 13
  };

  checkState: boolean = true;
  urlHelp = this.environment.kobraHelp;

  visibility = '{"RangosSla.orden":"hidden"}';

  sla: Array<any> = [{
      dimensions: ["RangosSla.orden", "RangosSla.rangoCompleto"],
      filters: [
        {
          dimension: "Clientes.clienteDemo",
          operator: "equals",
          values: ["false"]
        },
        {
          dimension: "Productos.idProducto",
          operator: "equals",
          values: []
        },
        {
          dimension: "Importaciones.idImportacion",
          operator: "equals",
          values: []
        },
        {
          dimension: "Clientes.idExternoKobra",
          operator: "equals",
          values: []
        }
      ],
      measures: [
        "SlaCompromisos.compromisoCoberturaMuyAlta",
        "SlaCompromisos.compromisoCoberturaAlta",
        "SlaCompromisos.compromisoCoberturaPoca",
        "SlaCompromisos.compromisoCoberturaMuyPoca",
        "SlaCompromisos.compromisoCoberturaSinCobertura"
      ],
      order: { "RangosSla.orden": "asc" },
      segments: [],
      timeDimensions: []
    },
    {
      filters: [
        {
          dimension: "Clientes.clienteDemo",
          operator: "equals",
          values: ["false"]
        },
        {
          dimension: "Productos.idProducto",
          operator: "equals",
          values: []
        },
        {
          dimension: "Importaciones.idImportacion",
          operator: "equals",
          values: []
        },
        {
          dimension: "Clientes.idExternoKobra",
          operator: "equals",
          values: []
        }
      ],
      measures: [
        "SlaClientesConfiguraciones.totalCuentasMuyAlta",
        "SlaClientesConfiguraciones.totalCuentasAlta",
        "SlaClientesConfiguraciones.totalCuentasPoca",
        "SlaClientesConfiguraciones.totalCuentasMuyPoca",
        "SlaClientesConfiguraciones.totalCuentasSinCobertura"
      ],
      segments: [],
      timeDimensions: []
    }
  ];

  query: string;
  private sessionData: SessionData;
  private params = new URLSearchParams();
  public loading: boolean = false;
  public loadingText: string = 'Espere, por favor...';
  private idImportacion: number;
  private idTipoImportacion: number;
  //public cuentasImportadas: number;
  private erroresGenerales: Array<any>;
  private importacion: any;
  public filters: any = {
    financiera: null,
    nombre: null,
    activa: true,
    excluirDemo: false,
    filtered: false,
    page: 1
  };

  isMobileValue: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private appService: ApplicationService,
    private importService: ImportService,
    private dialog: MatDialog,
    private filtrosService: FiltrosService,
  ) { 
    this.sessionData = this.localStorageService.getSessionData();
  }

  ngOnInit() {
    if (this._idCliente == null) {
      this._idCliente = +this.activatedRoute.snapshot.queryParamMap.get('idCliente');
    }

    if (this._idFinanciera == null) {
      this._idFinanciera = +this.activatedRoute.snapshot.queryParamMap.get('idFinanciera');
    }


    setTimeout(() => {
      /*if(localStorage.getItem('importacion-temp')){
        localStorage.removeItem('importacion-temp');
      }*/
      if( !this.batch ) {
        this.loading = true;
        this.activatedRoute.params.pipe(take(1)).subscribe(
          (params: Params) => {
            if (params['idImportacion']) {
              this.activatedRoute.queryParams.pipe(take(1)).subscribe(queryParams => {
                if (Boolean(queryParams['token'])) {

                  this.localStorageService.saveToken(queryParams['token']);
                  this.sessionData = this.localStorageService.getSessionData();
                }

                if(queryParams['cc']){
                  this.importService.reloadBaseUrl(queryParams['cc']);
                }

                if( !Boolean(this.sessionData) ) {
                  window.location.href = '/auth';

                  return;
                }

                this.batch = new Batch();
                this.batch.id = +params['idImportacion'];
                this.idImportacion = +params['idImportacion'];

                this.checkBatchState(() => {
                  this.loading = false;
                });
              });
            }
          }
        );
      } else {
        //this.loadingText = 'Iniciando procesamiento de importación...';
        this.loading = true;
        this.idImportacion = +this.batch.id;
        let data: any = {
          idFinanciera: this.idFinanciera,
          idCliente: this.idCliente, 
        };

        this.importService.startProcess(this.batch, data).pipe(take(1)).subscribe(() => {
          this.loading = false;
        }, (error) => {
          this.loading = false;
          this.appService.showError(error);
        });

        this.checkBatchState();
      }

      this.loadImportacionDetail();
    }, 200);
    
  }

  ngOnDestroy() {
    this.checkState = false;
  }

  isMobile(): boolean {
    const mobileWidth = 767;
    return window.innerWidth <= mobileWidth;
  }
  

  loadImportacionDetail() {
    this.loading = true;

    this.importService.getHistory(this.idImportacion, this.params, this.idCliente).pipe(take(1)).subscribe(
      (data: Array<any>) => {
        this.loading = false;

        if( data.length == 1 ) {
          this.idTipoImportacion = +data[0].idTipoImportacion;
        } else {
          this.appService.showError('Error al cargar el tipo de importación.');
        }
      }, err => {
        this.loading = false;
        this.appService.showError(err);
      });
  }

  checkBatchState(cb = () => {}) {
    this.importService.getBatchState(this.idCliente,  this.idFinanciera, this.batch).pipe(take(1)).subscribe((batch: Batch) => {
      this.erroresGenerales = batch.resultado ? batch.resultado.split('|') : [];
      //let cuentasImportadas = ( batch.total || 0 ) - ( batch.totalErrors || 0 );
      //this.cuentasImportadas = cuentasImportadas < 0 ? 0 : cuentasImportadas;

      /*if( this.erroresGenerales ) {
        batch.totalErrors += this.erroresGenerales.length;
      }*/

      this.batch = batch;

      if( this.batch.idStatus === this.IMPORT_STATUS.FINISHED || this.batch.idStatus === this.IMPORT_STATUS.PROCESSED_ERRORS || this.batch.idStatus === this.IMPORT_STATUS.ERROR ) {
        this.createSlaQuery();
      } else {
        setTimeout(() => {
          if (this.checkState) {
            this.checkBatchState();
          }
        }, 2000);
      }

      cb();
    }, (error) => {
      this.appService.showError(error);
    });
  }

  createSlaQuery() {
    this.query = JSON.stringify(this.sla.map(query => {
      query.filters = query.filters.map(f => {
        if (f.dimension === 'Productos.idProducto' && Boolean(this.batch.idProduct)) {
          f.values = [String(this.batch.idProduct)];
        }

        if (f.dimension === 'Importaciones.idImportacion') {
          f.values = [String(this.batch.id)];
        }

        if (f.dimension === 'Clientes.idExternoKobra') {
          f.values = [String(this.sessionData.idFinanciera)];
        }

        return f;
      }).filter(f => f.values.length > 0);

      return query;
    }));
  }

  openAccounts(filter) {
    const dialogRef = this.dialog.open(AccountsComponent, {
      //width: '80%',
      //height: '90%',
      data: {
        idCliente: this.idCliente,
        idFinanciera: this.idFinanciera,
        id: this.batch.id,
        idTipoImportacion: this.idTipoImportacion,
        erroresGenerales: this.erroresGenerales,
        filter
      }
    });

    dialogRef.afterClosed().subscribe(() => {});
  }

  openDialogGeocalizarCuentas(): void {
    const dialogRef = this.dialog.open(ModalGeocalizacionCuentasComponent, {
        data:{    
            idCliente: this.idCliente,
            id: this.batch.id,
            idTipoImportacion: this.idTipoImportacion
        }
    });

    dialogRef.afterClosed().subscribe(result => {
        
    });
  }
}
